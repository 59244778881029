import React from "react";
import classes from './OriginalStages.module.scss';
import constants from "../../shared/constants";
import ReactTooltip from 'react-tooltip';

interface originalStagesProps {
	value: number;
	size?: 'small';
	styles?: object;
	identifier?: string;
}

const originalStages = (props: originalStagesProps) => {
	const { value, size, styles, identifier } = props;

	const classToApply =  [classes.Stages, size ? classes[`Stages--${size}`] : ''].join(' ');

	return (
		<ul className={classToApply} style={styles}>
			<li data-tip data-for={identifier + '-rough'} className={[classes['Stages-step'], value >= 0 ? classes['Stages-step--full'] : ''].join(' ')}>
				<ReactTooltip id={identifier + '-rough'} effect="solid">
					{constants.ORIGINAL_STAGES[0].key + ' '} {value == 0 ? '✅' : ''}
				</ReactTooltip>
			</li>
			<li data-tip data-for={identifier + '-advanced'} className={[classes['Stages-step'], value >= 20 ? classes['Stages-step--full'] : ''].join(' ')}>
				<ReactTooltip id={identifier + '-advanced'} effect="solid">
					{constants.ORIGINAL_STAGES[1].key + ' '} {value == 20 ? '✅' : ''}
				</ReactTooltip>
			</li>
			<li data-tip data-for={identifier + '-pitching'} className={[classes['Stages-step'], value >= 40 ? classes['Stages-step--full'] : ''].join(' ')}>
				<ReactTooltip id={identifier + '-pitching'} effect="solid">
					{constants.ORIGINAL_STAGES[2].key + ' '} {value == 40 ? '✅' : ''}
				</ReactTooltip>
			</li>
			<li data-tip data-for={identifier + '-partially'} className={[classes['Stages-step'], value >= 60 ? classes['Stages-step--full'] : ''].join(' ')}>
				<ReactTooltip id={identifier + '-partially'} effect="solid">
					{constants.ORIGINAL_STAGES[3].key + ' '} {value == 60 ? '✅' : ''}
				</ReactTooltip>
			</li>
			<li data-tip data-for={identifier + '-fully'} className={[classes['Stages-step'], value >= 80 ? classes['Stages-step--full'] : ''].join(' ')}>
				<ReactTooltip id={identifier + '-fully'} effect="solid">
					{constants.ORIGINAL_STAGES[4].key + ' '} {value == 80 ? '✅' : ''}
				</ReactTooltip>
			</li>
			<li data-tip data-for={identifier + '-produced'} className={[classes['Stages-step'], value >= 100 ? classes['Stages-step--full'] : ''].join(' ')}>
				<ReactTooltip id={identifier + '-produced'} effect="solid">
					{constants.ORIGINAL_STAGES[5].key + ' '} {value == 100 ? '✅' : ''}
				</ReactTooltip>
			</li>
		</ul>
	);
}

export default originalStages;
