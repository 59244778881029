import React, { Component } from "react";
import constants from '../../shared/constants';
import classes from './DocumentCard.module.scss';
import CircleImage from '../CircleImage/CircleImage';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';

interface documentCardProps {
	document: {
		title: string;
		description: string;
		url?: string;
		id?: number;
		type?: string;
	},
	userName?: string;
	userThumbnail?: string | null;
	onRemoveClicked?: (id: number) => void;
	onEditClicked?: (id: number) => void;
	hideToggle?: boolean;
	disableReordering?: boolean;
	hidePreview?: boolean;
}

interface documentCardState {
	showMenu: boolean;
}

class DocumentCard extends Component<documentCardProps, documentCardState> {
	constructor(props: documentCardProps){
		super(props);

		this.state = {
			showMenu: false,
		}
	}
	private menu!: HTMLDivElement;

	handleClickedOutsideMenu = (e: any) => {
		if (this.menu && !this.menu.contains(e.target) && this.state.showMenu) {
			this.setState({
				showMenu: false
			})
		}
	};

	componentWillMount(){
		document.addEventListener('click', this.handleClickedOutsideMenu, false);
	}

	componentWillUnmount(){
		document.removeEventListener('click', this.handleClickedOutsideMenu, false);
	}

	handleMenuClicked = () => {
		const showMenu = !this.state.showMenu;

		this.setState({
			showMenu
		})
	};

	handleRemoveClicked = (id: number) => {
		this.setState({
			showMenu: false
		});

		if(this.props.onRemoveClicked)
			this.props.onRemoveClicked(id);
	};

	render () {
		const { userName, userThumbnail, document: { id, title, description, url, type }, onEditClicked, hideToggle, disableReordering, hidePreview } = this.props;
		const { showMenu } = this.state;

		const typeLabelArray = constants.DOCUMENT_LABEL_FOR_MIME_TYPES.filter((item: { mime_type: string; label: string }) => item.mime_type === type);

		let typeLabel = '';

		if(typeLabelArray.length){
			typeLabel = typeLabelArray[0].label;
		}

		//const fileType = type ? type.split("/") : '';
		const fileType = typeLabel.replace(/ /g,"_");

		return (
			<div className={[classes.DocumentCard, disableReordering ? classes['DocumentCard--flat'] : '', hidePreview ? classes['DocumentCard--autoheight'] : ''].join(' ')}>
				<div className={[classes['DocumentCard-preview'], typeLabel.toLowerCase() !== 'image' && hidePreview ? classes['DocumentCard-preview--nopreview'] : ''].join(' ')} style={{
					backgroundImage: typeLabel.toLowerCase() === 'image' ? `url(${url})` : 'none'
				}}>
					{
						typeLabel.toLowerCase() !== 'image' && typeLabel
					}
					{
						!hideToggle &&
                        <div className={classes['DocumentCard-menu']} ref={(node: any) => { this.menu = node; }}>
							<div className={classes['DocumentCard-toggle']}>
								<Button styles={{padding: 0, color: '#232323'}} type="more" clicked={this.handleMenuClicked}>
									<FormattedMessage id={"general.more"} />
								</Button>
							</div>
                            {/* <button onClick={this.handleMenuClicked} className={classes['DocumentCard-toggle']}>MENU</button> */}
							{
								showMenu &&
                                <ul className={classes['DocumentCard-actions']}>
									{
										onEditClicked && id &&
                                        <li className={classes['DocumentCard-action']}>
											<Button
												clicked={() => onEditClicked(id)}
												type="small"
												styles={{padding: '5px 20px', fontSize: '1.1rem'}}>
												<FormattedMessage id={"general.edit"} />
											</Button>
                                            {/* <button
                                                onClick={() => onEditClicked(id)}
                                                className={classes['DocumentCard-button']}>
                                                Edit
                                            </button> */}
                                        </li>
									}
									{
										id && id > 0 &&
                                        <li className={classes['DocumentCard-action']}>
											<Button
												clicked={() => this.handleRemoveClicked(id)}
												type="small"
												styles={{padding: '5px 20px', fontSize: '1.1rem', color: '#D05A5A'}}>
												<FormattedMessage id={"general.delete"} />
											</Button>
                                            {/* <button
                                                onClick={() => this.handleRemoveClicked(id)}
                                                className={classes['DocumentCard-button']}>
                                                Remove
                                            </button> */}
                                        </li>
									}
                                </ul>
							}
                        </div>
					}
				</div>
				<div className={classes['DocumentCard-content']}>
					{
						title &&
                        <a target={'_blank'} href={url || '#'}  className={classes['DocumentCard-title']}>
							<div
								className={[classes['DocumentCard-file-type'], fileType ? classes[`DocumentCard-file-type-${fileType}`] : ''].join(' ')} />
							{title}
                        </a>
					}
					<div className={classes['DocumentCard-description']} dangerouslySetInnerHTML={{ __html: description }}  />
				</div>
				{
					userName &&
                    <div className={classes['DocumentCard-footer']}>
                        <CircleImage
                            small
                            url={userThumbnail || ''}
                            fullName={userName}/>
                    </div>
				}
				{
					url && hidePreview &&
					<div className={classes['DocumentCard-link']}>
						<a target={'_blank'} href={url}  className={classes['DocumentCard-title']}>
							view file
						</a>
					</div>
				}
			</div>
		);
	}
}

export default DocumentCard;
