import React, {Component} from 'react';
import * as routes from '../../shared/routes';
import classes from './UnauthorizedRoute.module.scss';
import Template from '../../containers/Template/Template';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

interface IProps {}

interface IState {}

class UnauthorizedRoute extends Component<IProps, IState> {

	render () {
		return (
			<Template>
				<div className={classes.UnauthorizedRoute}>
					<div className={classes['UnauthorizedRoute-content']}>
						<div className={classes['UnauthorizedRoute-text']}>
							<FormattedMessage id={'unauthorized.message'} />
						</div>
						<div className={classes['UnauthorizedRoute-redirect']}>
							<NavLink to={routes.PROFILE}>
								<FormattedHTMLMessage id={'unauthorized.cta'} />
							</NavLink>
						</div>
					</div>
				</div>
			</Template>
		);
	}
}

export default UnauthorizedRoute;
