import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IUser } from '../../shared/interfaces';

interface IAction {
    type: string;
    users: Array<IUser>;
    selected: object;
    user: IUser;
    error: string;
    removed: boolean;
    showDashboard: boolean;
    noUsersFound: boolean;
    counters: {
    	projects: number,
	    experiences: number,
    },
	tourDisabled: boolean;
}

const INITIAL_STATE = {
    users: [],
	user: null,
    selected: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
	showDashboard: false,
    error: null,
    removed: null,
	counters: {
		projects: 0,
		experiences: 0,
	}
};

const createUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { user: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createUserSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { user: action.user, isStoring: false });
};

const createUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchUsersStart = (state = INITIAL_STATE) => {
    return updateObject(state, { users: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchUsersSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { users: action.users, isFetching: false });
};

const fetchUsersFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchNetworkUsersStart = (state = INITIAL_STATE) => {
	return updateObject(state, { users: [], isFetching: true, error: null, didInvalidate: false, noUsersFound: null, });
};

const fetchNetworkUsersSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { users: action.users, isFetching: false, noUsersFound: action.users.length === 0 });
};

const fetchNetworkUsersFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchUsersByFiltersStart = (state = INITIAL_STATE) => {
	return updateObject(state, { users: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchUsersByFiltersSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { users: action.users, isFetching: false, noUsersFound: action.users.length === 0 });
};

const fetchUsersByFiltersFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchCurrentUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { selected: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchCurrentUserSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, {
		user: action.user,
		isFetching: false,
		showDashboard: action.user.dashboard === 1,
		tourDisabled: action.user.tour === 1,
		counters: {
			projects: action.user.projects || 0,
			experiences: action.user.curriculums || 0
		}
	});
};

const fetchCurrentUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true, counters: { projects: 0, experiences: 0 } });
};

const fetchUserByIdStart = (state = INITIAL_STATE) => {
	return updateObject(state, { selected: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchUserByIdSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { selected: action.selected, isFetching: false });
};

const fetchUserByIdFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, { user: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const updateUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { user: action.user, isStoring: false });
};

const updateUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const deleteUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, { isStoring: true, removed: null, error: null, didInvalidate: false });
};

const deleteUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { removed: action.removed, isStoring: false });
};

const deleteUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, removed: false, didInvalidate: true, isStoring: false });
};

const recoveryPasswordUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { user: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const recoveryPasswordUserSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { user: action.user, isStoring: false });
};

const recoveryPasswordUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const updatePasswordUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { user: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const updatePasswordUserSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { user: action.user, isStoring: false });
};

const updatePasswordUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const setTourDisabledStart = (state = INITIAL_STATE) => {
	return updateObject(state, { tourDisabled: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const setTourDisabledSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { tourDisabled: action.tourDisabled, isStoring: false });
};

const setTourDisabledFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const userReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_USER_START: return createUserStart(state);
	    case actionTypes.CREATE_USER_SUCCESS: return createUserSuccess(state, action);
	    case actionTypes.CREATE_USER_FAIL: return createUserFail(state, action);
        // READ/FETCHING
	    case actionTypes.FETCH_CURRENT_USER_START: return fetchCurrentUserStart(state);
	    case actionTypes.FETCH_CURRENT_USER_SUCCESS: return fetchCurrentUserSuccess(state, action);
	    case actionTypes.FETCH_CURRENT_USER_FAIL: return fetchCurrentUserFail(state, action);
        case actionTypes.FETCH_USERS_START: return fetchUsersStart(state);
        case actionTypes.FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action);
        case actionTypes.FETCH_USERS_FAIL: return fetchUsersFail(state, action);
	    case actionTypes.FETCH_NETWORK_USERS_START: return fetchNetworkUsersStart(state);
	    case actionTypes.FETCH_NETWORK_USERS_SUCCESS: return fetchNetworkUsersSuccess(state, action);
	    case actionTypes.FETCH_NETWORK_USERS_FAIL: return fetchNetworkUsersFail(state, action);
	    case actionTypes.FETCH_USERS_BY_FILTERS_START: return fetchUsersByFiltersStart(state);
	    case actionTypes.FETCH_USERS_BY_FILTERS_SUCCESS: return fetchUsersByFiltersSuccess(state, action);
	    case actionTypes.FETCH_USERS_BY_FILTERS_FAIL: return fetchUsersByFiltersFail(state, action);
	    case actionTypes.FETCH_USER_BY_ID_START: return fetchUserByIdStart(state);
	    case actionTypes.FETCH_USER_BY_ID_SUCCESS: return fetchUserByIdSuccess(state, action);
	    case actionTypes.FETCH_USER_BY_ID_FAIL: return fetchUserByIdFail(state, action);
        // UPDATE
        case actionTypes.UPDATE_USER_START: return updateUserStart(state);
        case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess(state, action);
        case actionTypes.UPDATE_USER_FAIL: return updateUserFail(state, action);
        // DELETE
        case actionTypes.DELETE_USER_START: return deleteUserStart(state);
        case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess(state, action);
        case actionTypes.DELETE_USER_FAIL: return deleteUserFail(state, action);

	    // RECOVERY PASSWORD
	    case actionTypes.RECOVERY_USER_PASSWORD_START: return recoveryPasswordUserStart(state);
	    case actionTypes.RECOVERY_USER_PASSWORD_SUCCESS: return recoveryPasswordUserSuccess(state, action);
	    case actionTypes.RECOVERY_USER_PASSWORD_FAIL: return recoveryPasswordUserFail(state, action);

	    // UPDATE PASSWORD
	    case actionTypes.UPDATE_USER_PASSWORD_START: return updatePasswordUserStart(state);
	    case actionTypes.UPDATE_USER_PASSWORD_SUCCESS: return updatePasswordUserSuccess(state, action);
	    case actionTypes.UPDATE_USER_PASSWORD_FAIL: return updatePasswordUserFail(state, action);

	    // SET TOUR DISABLED
	    case actionTypes.SET_TOUR_DISABLED_START: return setTourDisabledStart(state);
	    case actionTypes.SET_TOUR_DISABLED_SUCCESS: return setTourDisabledSuccess(state, action);
	    case actionTypes.SET_TOUR_DISABLED_FAIL: return setTourDisabledFail(state, action);

        default:
            return state;
    }
};

export default userReducer;
