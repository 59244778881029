import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import classes from './CurriculumItem.module.scss';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import autosize from 'autosize';
import Button from '../../Button/Button';
import MonthPicker from '../../MonthPicker/MonthPicker';
import CheckboxSlider from '../../CheckboxSlider/CheckboxSlider';
import { ICurriculum } from '../../../shared/interfaces';

toast.configure({
	autoClose: 3000,
	draggable: false,
});

interface IProps {
	setData: (field: string, value: any, id: string) => void;
	removeData: (id: number) => void;
	closeData: (id: number) => void;
    item: any;
    clicked?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    styles?: object;
    experience: any;
	onCreateExperience( data: ICurriculum ): ICurriculum;
	onUpdateExperience( id: number, data: ICurriculum ): ICurriculum;
	current: boolean;
	readOnly: boolean;
}

interface IState {
	edit: boolean;
	inProgress: boolean;
}

class CurriculumItem extends Component<IProps, IState> {
	constructor(props:IProps) {
		super(props);

		this.state = {
			edit: this.props.item.hasOwnProperty('new') && this.props.item.new,
			inProgress: true,
		};

		this.toggleEditMode = this.toggleEditMode.bind(this);
		this.close = this.close.bind(this);
		this.save = this.save.bind(this);
	}

	componentDidMount() {
		autosize(document.querySelectorAll('textarea'));
	}

	toggleEditMode() {
		const edit = !this.state.edit;

		this.setState({
			edit,
		});

		autosize(document.querySelectorAll('textarea'));
	}

	close(item: any){

		this.setState({
			edit: false,
		}, () => {
			if(item.hasOwnProperty('new')){
				this.props.closeData(item.id);
			}
		})
	}

	save(item: any) {
		//console.log('Save:', item);
		const edit = !this.state.edit;

		item.dateEnd = moment(item.dateEnd, moment.ISO_8601).isValid() ? item.dateEnd : null;

		if(item.hasOwnProperty('new')){
			delete item.new;
			delete item.edited;

			this.props.onCreateExperience(item);
		} else {
			delete item.edited;
			this.props.onUpdateExperience(item.id, item);
		}

		this.setState({
			edit,
		})
	}

	delete(id: number){
		this.props.removeData(id);
	}

	handleDateChanged = (field: string, date: any, id: string) => {
		if(id) {
			this.props.setData(field, moment(date).format('YYYY-MM-DD'), id);
		}
	};

	handleInProgressChanged = (field: string, date: any, id: string) => {
		const inProgress = !this.state.inProgress;

		this.setState({
			inProgress,
		}, () => {
			this.handleDateChanged(field, date, id);
		});
	};

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;
		const id = ev.currentTarget.getAttribute('data-id');

		if(id)
			this.props.setData(inputName, inputValue, id);
	};
	handleTextareaChanged = (ev: React.FormEvent<HTMLTextAreaElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;
		const id = ev.currentTarget.getAttribute('data-id');

		if(id)
			this.props.setData(inputName, inputValue, id);
	};

	componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
		if(this.props.experience !== nextProps.experience && nextProps.experience){
			this.props.setData('id', nextProps.experience.id, this.props.item.id);

			if (! toast.isActive('experience-saved')) {
				toast.success("Experience saved!", {
					position: toast.POSITION.TOP_RIGHT,
					toastId: 'experience-saved',
					className: 'confirm-bubble'
				});
			}
		}
	}

	render() {
		const { item, item: {id, company, role, description, dateStart, dateEnd }, styles, current, readOnly } = this.props;
		const { edit } = this.state;

		const submitDisabled = !company || !role || !moment(dateStart, moment.ISO_8601).isValid();

		//console.log(moment(dateStart, moment.ISO_8601).isValid(), submitDisabled, item);

		const textAreaStyles = {
			maxHeight: "300px",
			minHeight: "38px"
		};

		let years: any = [];

		for(let i = 0; i <= 30; i++){
			years.push(i)
		}

		return (
			<div className={[classes.CurriculumItem, edit ? classes['CurriculumItem-editing'] : ''].join(' ')} style={styles}>
				<div className={classes['CurriculumItem-wrapper']}>
					<div className={classes['CurriculumItem-wrapper-left']}>
						<div className={[classes['CurriculumItem-field'], classes['CurriculumItem-field--company']].join(' ')}>
							<label className={classes['CurriculumItem-label']}>
								<FormattedMessage id={'label.curriculum.company'} />
							</label>
							<input
								className={
									[classes['CurriculumItem-textarea'], !edit ? classes['is-readOnly'] : classes['is-editing']].join(' ')
								}
								readOnly={!edit}
								data-id={id}
								name={'company'}
								onChange={this.handleInputChanged}
								defaultValue={company} />
						</div>
						<div className={[classes['CurriculumItem-field'], classes['CurriculumItem-field--role']].join(' ')}>
							<label className={classes['CurriculumItem-label']}>
								<FormattedMessage id={'label.curriculum.role'} />
							</label>
							<input
								className={
									[classes['CurriculumItem-textarea'], !edit ? classes['is-readOnly'] : classes['is-editing']].join(' ')
								}
								readOnly={!edit}
								data-id={id}
								name={'role'}
								onChange={this.handleInputChanged}
								defaultValue={role} />
						</div>
					</div>
					<div className={[classes['CurriculumItem-field'], classes['CurriculumItem-field--description'], !description && !edit ? classes['CurriculumItem-field--description-hide'] : ''].join(' ')}>
						<label className={classes['CurriculumItem-label']}>
							<FormattedMessage id={'label.curriculum.description'} />
						</label>
						<textarea
							className={
								[classes['CurriculumItem-textarea'], !edit ? classes['is-readOnly'] : classes['is-editing']].join(' ')
							}
							readOnly={!edit}
							name={'description'}
							data-id={id}
							style={textAreaStyles}
							onChange={this.handleTextareaChanged}
							defaultValue={description}/>
					</div>
					{ edit &&
						<div className={classes['CurriculumItem-period']}>
							<div className={classes['CurriculumItem-period-container']}>
								<div className={classes['CurriculumItem-period-picker']}>
									<label className={classes['CurriculumItem-label']}>
										<FormattedMessage id={'label.curriculum.dates.from'} />
									</label>
									<MonthPicker
										years={years}
										name={`dateStart_${item.id}`}
                                        date={moment(item.dateStart)}
										placeholder={'Select a date'}
										maxDate={moment(item.dateEnd)}
										changed={(date) => this.handleDateChanged('dateStart', date, item.id)} />
								</div>

								{ moment(item.dateEnd, moment.ISO_8601).isValid() &&
									<div className={classes['CurriculumItem-period-picker']}>
										<label className={classes['CurriculumItem-label']}>
											<FormattedMessage id={'label.curriculum.dates.to'} />
										</label>
										<MonthPicker
											years={years}
											name={`dateEnd_${item.id}`}
											date={moment(item.dateEnd, moment.ISO_8601).isValid() ? moment(item.dateEnd) : null}
											placeholder={'Select a date'}
											minDate={moment(item.dateStart)}
											changed={(date) => this.handleDateChanged('dateEnd', date, item.id)} />
									</div>
								}
							</div>
							<div className={classes['CurriculumItem-current-container']}>
								<label className={classes['CurriculumItem-label']}>
									<FormattedMessage id={'label.curriculum.dates.current'} />
								</label>
								<CheckboxSlider
									name={'inProgress'}
									clicked={() => this.handleInProgressChanged('dateEnd', !moment(item.dateEnd, moment.ISO_8601).isValid() ? moment() : null, item.id)}
									checked={!moment(item.dateEnd, moment.ISO_8601).isValid()} />
							</div>
						</div>
					}
					{
						!moment(item.dateEnd, moment.ISO_8601).isValid() &&
						<div className={[classes['CurriculumItem-dateBox'], classes['CurriculumItem-dateBox--now']].join(' ')}>
							<div className={classes['CurriculumItem-month']}>
								<FormattedMessage id={'label.curriculum.dates.now'} />
							</div>
						</div>
					}
					{
						moment(dateStart, moment.ISO_8601).isValid() &&
                        <div className={classes['CurriculumItem-dateBox']}>
                            <div className={classes['CurriculumItem-month']}>
								{ moment(dateStart).format('MMM') }
                            </div>
                            <div className={classes['CurriculumItem-year']}>
								{ moment(dateStart).format('Y') }
                            </div>
                        </div>
					}
					{
						moment(dateEnd, moment.ISO_8601).isValid() &&
	                        <div className={[classes['CurriculumItem-dateBox'], classes['CurriculumItem-dateBox--end']].join(' ')}>
								<div className={classes['CurriculumItem-month']}>
									{ moment(dateEnd).format('MMM') }
								</div>
								<div className={classes['CurriculumItem-year']}>
									{ moment(dateEnd).format('Y') }
								</div>
	                        </div>
					}
				</div>
				{
					!readOnly &&
	                    <div className={classes['CurriculumItem-footer']}>
							{
								edit ? '' :
									<Button type="small" styles={{margin: '10px 0 10px 0', paddingRight: '0'}} clicked={this.toggleEditMode}>
										<FormattedMessage id={'general.edit'} />
									</Button>
							}
							{
								edit &&
	                            <Button type="small" styles={{margin: '10px 0'}} clicked={() => this.close(item)}>
	                                <FormattedMessage id={'general.close'} />
	                            </Button>
							}
							{
								!edit &&
	                            <Button type="small" styles={{margin: '10px 0', color: '#D05A5A'}} clicked={() => this.delete(item.id)}>
	                                <FormattedMessage id={'general.remove'} />
	                            </Button>
							}
							{
								edit ?
									<Button type="primary-small" disabled={submitDisabled} styles={{margin: '10px 0'}} clicked={() => this.save(item)}>
										<FormattedMessage id={'general.save'} />
									</Button>
									: ''
							}
	                    </div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		loading: state.curriculumState.isStoring,
		error: state.curriculumState.error,
		experience: state.curriculumState.curriculum,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onCreateExperience: ( data: ICurriculum ) => dispatch( actions.createCurriculum(data) ),
		onUpdateExperience: ( id: number, data: ICurriculum ) => dispatch( actions.updateCurriculum(id, data) ),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( CurriculumItem );
