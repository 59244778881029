import * as actionTypes from './actionTypes';
import MoodsService from '../../services/api/moods';
import { IResponse, IMood, IError } from '../../shared/interfaces';

// READ/FETCH
export const fetchMoodStart = () => {
    return {
        type: actionTypes.FETCH_MOOD_START,
    }
};

export const fetchMoodSuccess = (mood: IMood) => {
    return {
        type: actionTypes.FETCH_MOOD_SUCCESS,
        mood
    }
};

export const fetchMoodFail = (error: string) => {
    return {
        type: actionTypes.FETCH_MOOD_FAIL,
        error,
    }
};

export const fetchMood = (id: number) => {
	return (dispatch: any) => {

		if(id)
			dispatch(fetchMoodStart());

		MoodsService
			.get(id)
			.then(
				(response: IResponse) => {
					dispatch(fetchMoodSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchMoodFail(errorMsg));
				}
			);
	}
};

export const fetchMoodsStart = () => {
	return {
		type: actionTypes.FETCH_MOODS_START,
	}
};

export const fetchMoodsSuccess = (moods: any) => {
	return {
		type: actionTypes.FETCH_MOODS_SUCCESS,
		moods
	}
};

export const fetchMoodsFail = (error: string) => {
	return {
		type: actionTypes.FETCH_MOODS_FAIL,
		error,
	}
};

export const fetchMoods = () => {
	return (dispatch: any) => {
		dispatch(fetchMoodsStart());

		MoodsService
			.get()
            .then(
	            (response: IResponse) => {
		            dispatch(fetchMoodsSuccess(response.data));
	            },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(fetchMoodsFail(errorMsg));
	            }
            );
    }
};

export const fetchMoodsForOriginalsFilterStart = () => {
	return {
		type: actionTypes.FETCH_MOODS_FOR_ORIGINALS_FILTER_START,
	}
};

export const fetchMoodsForOriginalsFilterSuccess = (moods: IMood) => {
	return {
		type: actionTypes.FETCH_MOODS_FOR_ORIGINALS_FILTER_SUCCESS,
		moods
	}
};

export const fetchMoodsForOriginalsFilterFail = (error: string) => {
	return {
		type: actionTypes.FETCH_MOODS_FOR_ORIGINALS_FILTER_FAIL,
		error,
	}
};

export const fetchMoodsForOriginalsFilter = () => {
	return (dispatch: any) => {
		dispatch(fetchMoodsForOriginalsFilterStart());

		MoodsService
			.getForOriginalsFilter()
			.then(
				(response: IResponse) => {
					dispatch(fetchMoodsForOriginalsFilterSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchMoodsForOriginalsFilterFail(errorMsg));
				}
			);
	}
};
