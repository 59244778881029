import request from '../../shared/lib/request'
import constants from "../../shared/constants";

const create = (data: object) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
	    'Authorization': `Bearer ${authToken}`
    };

    return request({
        url:    '/media',
        method: 'POST',
        headers,
        data
    });
};

const get = (id?: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = id ? `/media/${id}` : '/media';

    return request({
        url:    endpoint,
        method: 'GET',
        headers
    });
};

const update = (id: number, data: object) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/media/${id}`,
        method: 'PATCH',
        headers,
        data
    });
};

const remove = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/media/${id}`,
        method: 'DELETE',
        headers,
    });
};


const MediaService = {
    get, create, update, remove
};

export default MediaService;
