import * as actionTypes from './actionTypes';
import MediaService from '../../services/api/media';
import { IResponse } from '../../shared/interfaces';

// UPDATE
export const createMediaStart = () => {
	return {
		type: actionTypes.CREATE_MEDIA_START,
	}
};

export const createMediaSuccess = (media: object) => {
	return {
		type: actionTypes.CREATE_MEDIA_SUCCESS,
		media
	}
};

export const createMediaFail = (error: string) => {
	return {
		type: actionTypes.CREATE_MEDIA_FAIL,
		error,
	}
};

export const createMedia = (data: object) => {
	return (dispatch: any) => {
		dispatch(createMediaStart());

		MediaService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createMediaSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(createMediaFail(errorMsg));
				}
			);
	}
};

export const resetMedia = () => {
	return {
		type: actionTypes.RESET_MEDIA,
	}
};

// READ/FETCH
export const fetchMediaListStart = () => {
    return {
        type: actionTypes.FETCH_MEDIA_LIST_START,
    }
};

export const fetchMediaListSuccess = (list: Array<object>) => {
    return {
        type: actionTypes.FETCH_MEDIA_LIST_SUCCESS,
        list
    }
};

export const fetchMediaListFail = (error: string) => {
    return {
        type: actionTypes.FETCH_MEDIA_LIST_FAIL,
        error,
    }
};

export const fetchMediaList = () => {
    return (dispatch: any) => {
        dispatch(fetchMediaListStart());

        MediaService
            .get()
            .then(
	            (response: IResponse) => {

                    dispatch(fetchMediaListSuccess(response.data));
                },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(fetchMediaListFail(errorMsg));
                }
            );
    }
};

export const fetchMediaStart = () => {
	return {
		type: actionTypes.FETCH_MEDIA_START,
	}
};

export const fetchMediaSuccess = (media: Array<object>) => {
	return {
		type: actionTypes.FETCH_MEDIA_SUCCESS,
		media
	}
};

export const fetchMediaFail = (error: string) => {
	return {
		type: actionTypes.FETCH_MEDIA_FAIL,
		error,
	}
};

export const fetchMedia = (id: number) => {
	return (dispatch: any) => {
		dispatch(fetchMediaStart());

		MediaService
			.get(id)
			.then(
				(response: IResponse) => {

					dispatch(fetchMediaSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchMediaFail(errorMsg));
				}
			);
	}
};

// UPDATE
export const updateMediaStart = () => {
    return {
        type: actionTypes.UPDATE_MEDIA_START,
    }
};

export const updateMediaSuccess = (mediaUpdated: object) => {
    return {
        type: actionTypes.UPDATE_MEDIA_SUCCESS,
        mediaUpdated
    }
};

export const updateMediaFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_MEDIA_FAIL,
        error,
    }
};

export const updateMedia = (id: number, data: object) => {
    return (dispatch: any) => {
        dispatch(updateMediaStart());

        MediaService
            .update(id, data)
            .then(
	            (response: IResponse) => {

                    dispatch(updateMediaSuccess(response.data));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(updateMediaFail(errorMsg));
                }
            );
    }
};

// DELETE
export const deleteMediaStart = () => {
    return {
        type: actionTypes.DELETE_MEDIA_START,
    }
};

export const deleteMediaSuccess = (removed: boolean) => {
    return {
        type: actionTypes.DELETE_MEDIA_SUCCESS,
        removed
    }
};

export const deleteMediaFail = (error: string) => {
    return {
        type: actionTypes.DELETE_MEDIA_FAIL,
        error,
    }
};

export const deleteMedia = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteMediaStart());

        MediaService
            .remove(id)
            .then(
	            (response: IResponse) => {

                    dispatch(deleteMediaSuccess(true));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(deleteMediaFail(errorMsg));
                }
            );
    }
};
