import React, { Component } from 'react';
import classes from './Header.module.scss';

import Logo from '../../components/Logo/Logo';
import MainNavigation from "../../components/MainNavigation/MainNavigation";
import {INavigationItem} from "../../shared/interfaces";
import {FormattedMessage} from "react-intl";
import { SPECIAL_ROUTES} from '../../shared/special-routes';
import { connect } from 'react-redux';
import Button from '../../components/Button/Button';

interface IProps {
	onToggleOffCanvas: (ev: React.MouseEvent<HTMLButtonElement>) => void;
	showOffCanvas?: boolean;
    history: any;
    currentRoute: string;
    isAuthenticated: boolean;
	projects: number;
	experiences: number;
}

class Header extends Component<IProps> {

	handlePostABriefClicked = () => {
		console.log('Post a brief clicked!');
	};

	render() {
		const { showOffCanvas, onToggleOffCanvas, history, currentRoute, isAuthenticated, experiences, projects } = this.props;

		let visibility = true;
		let partial = false;
		let transparent = false;

		SPECIAL_ROUTES.map((route: any, index: number) => {
			if(SPECIAL_ROUTES[index].path === currentRoute) {
				if(!SPECIAL_ROUTES[index].header) {
					visibility = false;
				}
				if(SPECIAL_ROUTES[index].headerPartial) {
					partial = true;
				}
				if(SPECIAL_ROUTES[index].headerTransparent) {
					transparent = true;
				}
			}
			if(SPECIAL_ROUTES[index].path === currentRoute.split('/')[1]) {
				if(!SPECIAL_ROUTES[index].header) {
					visibility = false;
				}
				if(SPECIAL_ROUTES[index].headerPartial) {
					partial = true;
				}
				if(SPECIAL_ROUTES[index].headerTransparent) {
					transparent = true;
				}
			}
		});

		const items: Array<INavigationItem> = [
			{
				label: <FormattedMessage id={'navigation.network'} />,
				to: '/network',
				tourId: 'network-step'
			},
			{
				label: <FormattedMessage id={'navigation.messages'} />,
				to: '/messages',
				name: 'Messages',
				tourId: 'messages-step'
			},
			{
				label: <FormattedMessage id={'navigation.signin'} />,
				clicked: onToggleOffCanvas,
				name: 'Sign-in',
			}
		];

		const loggedItems: Array<INavigationItem> = [
			{
				label: <FormattedMessage id={'navigation.originals'} />,
				to: '/originals',
				name: 'Originals',
				tourId: 'originals-step'
			},
			{
				label: <FormattedMessage id={'navigation.network'} />,
				to: '/network',
				tourId: 'network-step'
			},
			{
				label: <FormattedMessage id={'navigation.messages'} />,
				to: '/messages',
				name: 'Messages',
				tourId: 'messages-step'
			}
		];

		return (
			<header className={[classes['Header'], visibility ? '' : classes["Header--transparent"], partial ? classes["Header--partial"] : '', transparent ? classes["Header--bg-transparent"] : '', isAuthenticated ? '' : classes['Header--authenticated']].join(' ')}>
				<Logo to="/" position="header" />
				{/* {
					experiences >= 2 && projects >=3 &&
	                    <Button clicked={this.handlePostABriefClicked}>
	                        <FormattedMessage id={'button.postABrief'}/>
	                    </Button>
				} */}
				{
					visibility &&
		                <MainNavigation
		                    history={history}
		                    isAuthenticated={isAuthenticated}
		                    showOffCanvas={showOffCanvas}
		                    onToggleOffCanvas={onToggleOffCanvas}
		                    items={isAuthenticated ? loggedItems : items}/>
				}
			</header>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		experiences: state.curriculumState.list.length,
		projects: state.projectState.list.filter((project: any) => project.status.toLowerCase() === 'online').length,
	};
};

export default connect( mapStateToProps )( Header );
