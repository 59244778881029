import React, { Component } from 'react';
import classes from './Request.module.scss';
import { IFile } from '../../shared/interfaces';
import CircleImage from '../CircleImage/CircleImage';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import Button from '../../components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

interface IProps {
	request: {
		id: number;
		status: string;
		userId: number;
		originalId: number;
		user: {
			name: string;
			surname: string;
			id: number;
			file: IFile | null;
		};
	};
	requestUpdated: boolean;
	onUpdateRequest(originalId: number, requestId: number, status: string): any;
}

interface IState {}

class Request extends Component<IProps, IState> {
	state = {};

	handleGrantClicked = (originalId: number, requestId: number) => {
		if(originalId && requestId){
			this.props.onUpdateRequest(originalId, requestId, 'APPROVED');
		}
	};

	handleDenyClicked = (originalId: number, requestId: number) => {
		if(originalId && requestId){
			this.props.onUpdateRequest(originalId, requestId, 'REJECTED');
		}
	};

	render () {
		const { request: { id, user, originalId, status, userId } } = this.props;

        return (
			<div className={[classes.Request, status === 'REJECTED' ? classes['Request-statuses--rejected'] : '', status === 'APPROVED' ? classes['Request-statuses--approved'] : '', status === 'PENDING' ? classes['Request-statuses--pending'] : ''].join(' ')}>
				<div className={classes['Request-user']}>
					<NavLink className={classes['Request-goToProfile']} to={`/user/${userId}`}>
						<CircleImage
							small
							url={user && user.file ? user.file.url : ''}
							fullName={user.name + ' ' + user.surname} />
					</NavLink>
				</div>
				<div className={classes['Request-statuses']}>
					{ status === 'REJECTED' ? <FormattedMessage id={'button.denied'}/> : '' }
					{ status === 'APPROVED' ? <FormattedMessage id={'button.granted'}/> : '' }
					{ status !== 'APPROVED' && status !== 'REJECTED' ? <FormattedMessage id={'button.pending'}/> : '' }
				</div>
				<div className={classes['Request-actions']}>
					<Button disabled={status === 'REJECTED'} type={'danger-small'} styles={{margin: '0 20px 0 0', width: '50%', fontWeight: '600', fontSize: '1.2rem'}} clicked={() => this.handleDenyClicked(originalId, id)}>
						{ status === 'REJECTED' ?
							<FormattedMessage id={'button.denied'}/>
							:
							<FormattedMessage id={'button.deny'}/>
						}
						</Button>
					<Button disabled={status === 'APPROVED'} styles={{width: '50%', fontWeight: '600', fontSize: '1.2rem'}} type={'confirm-small'} clicked={() => this.handleGrantClicked(originalId, id)}>
						{ status === 'APPROVED' ?
							<FormattedMessage id={'button.granted'}/>
							:
							<FormattedMessage id={'button.grant'}/>
						}
					</Button>
				</div>
			</div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		requestUpdated: state.originalState.requestUpdated,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onUpdateRequest: (originalId: number, requestId: number, status: string) => dispatch(actions.updateRequest(originalId, requestId, status))
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Request );
