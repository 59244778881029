import React, { Component } from 'react';
import classes from './Main.module.scss';
import RightSidebar from '../../components/RightSidebar/RightSidebar';
import { SPECIAL_ROUTES } from '../../shared/special-routes';

interface mainProps {
    children?: any;
    currentRoute: string;
    isAuthenticated: boolean;
}

class main extends Component<mainProps> {
    render () {

        const { children, currentRoute, isAuthenticated } = this.props;

        let visibility = true;
        SPECIAL_ROUTES.map((route: any, index: number) => {
            if(SPECIAL_ROUTES[index].path === currentRoute) {
                if(!SPECIAL_ROUTES[index].sidebar) {
                    visibility = false;
                }
            }
            if(SPECIAL_ROUTES[index].path === currentRoute.split('/')[1]) {
                if(!SPECIAL_ROUTES[index].sidebar) {
                    visibility = false;
                }
            }
        });

        return (
            <main className={[classes['Main'], visibility ? '' : classes['Main--full']].join(' ')}>
                <div className={classes["Main-content"]}>
                    { children }
                </div>
                { visibility &&
                    <RightSidebar isAuthenticated={isAuthenticated} />
                }
            </main>
        )
    }
};

export default main;
