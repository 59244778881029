import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';

interface IAction {
	type: string;
    registration: object;
    error: string;
    activated: boolean;
    newUser: any;
}

const INITIAL_STATE = {
    registration: null,
	newUser: null,
    isFetching: false,
    didInvalidate: false,
    error: null,
	activated: false,
};

const createRegistrationStart = (state = INITIAL_STATE) => {
    return updateObject(state, { registration: null, isFetching: true, error: null, didInvalidate: false });
};

const createRegistrationSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { registration: action.registration, isFetching: false });
};

const createRegistrationFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const activationStart = (state = INITIAL_STATE) => {
	return updateObject(state, { newUser: null, isFetching: true, error: null, activated: false, didInvalidate: false });
};

const activationSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { newUser: action.newUser, activated: true, isFetching: false });
};

const activationFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const createRegistrationForSchoolStart = (state = INITIAL_STATE) => {
	return updateObject(state, { registration: null, isFetching: true, error: null, didInvalidate: false });
};

const createRegistrationForSchoolSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { registration: action.registration, isFetching: false });
};

const createRegistrationForSchoolFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const registrationReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // CREATE
        case actionTypes.REGISTRATION_START: return createRegistrationStart(state);
        case actionTypes.REGISTRATION_SUCCESS: return createRegistrationSuccess(state, action);
        case actionTypes.REGISTRATION_FAIL: return createRegistrationFail(state, action);

	    // ACTIVATION
	    case actionTypes.ACTIVATION_START: return activationStart(state);
	    case actionTypes.ACTIVATION_SUCCESS: return activationSuccess(state, action);
	    case actionTypes.ACTIVATION_FAIL: return activationFail(state, action);

	    // SCHOOL
	    case actionTypes.REGISTRATION_FOR_SCHOOL_START: return createRegistrationForSchoolStart(state);
	    case actionTypes.REGISTRATION_FOR_SCHOOL_SUCCESS: return createRegistrationForSchoolSuccess(state, action);
	    case actionTypes.REGISTRATION_FOR_SCHOOL_FAIL: return createRegistrationForSchoolFail(state, action);

        default:
            return state;
    }
};

export default registrationReducer;
