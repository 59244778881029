import ReactGA from 'react-ga';

const TRACK_ID = 'UA-134287583-2';

export const pageview = page => {
    try {
        // console.log('pageview', page);
        ReactGA.pageview(page);
    } catch (e) {}
};

export const event = args => {
    try {
        // console.log('event', args);
        ReactGA.event(args);
    } catch (e) {}
};

ReactGA.initialize(TRACK_ID, {
    gaOptions: {
        anonymizeIp: true
    }
});

ReactGA.set({ anonymizeIp: true });
