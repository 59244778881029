import { combineReducers } from "redux";
import userReducer from "./user";
import registrationReducer from './registration';
import sessionReducer from './session';
import curriculumReducer from './curriculum';
import fileReducer from './file';
import mediaReducer from './media';
import categoryReducer from './category';
import projectReducer from './project';
import invitationReducer from './invitation';
import skillReducer from './skill';
import moodReducer from './mood';
import distributionReducer from './distribution';
import formatReducer from './format';
import bellReducer from './bell';
import teamReducer from './team';
import notificationReducer from './notification';
import originalReducer from './original';
import attachmentReducer from './attachment';
import messageReducer from './message';

export const rootReducer = combineReducers({
	userState: userReducer,
	registrationState: registrationReducer,
	sessionState: sessionReducer,
	curriculumState: curriculumReducer,
	fileState: fileReducer,
	mediaState: mediaReducer,
	categoryState: categoryReducer,
	skillState: skillReducer,
	moodState: moodReducer,
	projectState: projectReducer,
	teamState: teamReducer,
	invitationState: invitationReducer,
	notificationState: notificationReducer,
	originalState: originalReducer,
	attachmentState: attachmentReducer,
	formatState: formatReducer,
	bellState: bellReducer,
	distributionState: distributionReducer,
	messageState: messageReducer,
});
