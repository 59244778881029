import React, {Component} from 'react';
import classes from './Placeholder.module.scss';
import {FormattedMessage} from "react-intl";

interface IProps {
    label?: string;
    symbol: string;
}

class Placeholder extends Component<IProps> {

    render () {
    	const { label, symbol } = this.props;

			return (
				<div className={classes.Placeholder}>
                    {/* <span className={classes['Placeholder-text']}>
                        { label &&
                            <FormattedMessage id={label} />
                        }
                        <span><em>{ symbol }</em></span>
                    </span> */}
				</div>
			)
    }
};

export default Placeholder;
