import React, {Component} from 'react';
import classes from './MonthPicker.module.scss';
import moment from 'moment';
import {FormattedMessage} from "react-intl";

interface IProps {
	date?: any;
    name: string;
    placeholder: string;
    changed: (date: string) => any;
	value?: string;
	minDate?: any;
	maxDate?: any;
	years: any;
}

interface IState {
	month: any;
	year: any;
	open: boolean;
	value: string;
}

class MonthPicker extends Component<IProps, IState> {

	constructor(props: IProps){
		super(props);

		// console.log(this.props.date);

		this.state = {
			open: false,
			value: moment(this.props.date).isValid() ? moment(this.props.date).format('MMMM YY') : '',
			month: moment(this.props.date).isValid() ? +moment(this.props.date).format('MM') - 1 : +moment().format('MM') - 1,
			year: moment(this.props.date).isValid() ? +moment(this.props.date).format('YYYY') : +moment().format('YYYY'),
		};
		this.monthChanged = this.monthChanged.bind(this);
		this.yearChanged = this.yearChanged.bind(this);
		this.createDate = this.createDate.bind(this);
	}

    handleClick = () => {
		const open = this.state.open;
		if(open) {
			this.createDate();
		}
        this.setState({
            open: !open
		});
    };

    monthChanged(ev: React.FormEvent<HTMLSelectElement>){
    	const month = +ev.currentTarget.value;

    	this.setState({
		    month
	    }, () => {
    		this.createDate()
	    })
    }

	yearChanged(ev: React.FormEvent<HTMLSelectElement>){
		const year = +ev.currentTarget.value;

		this.setState({
			year
		}, () => {
			this.createDate()
		})
	}

	createDate(){
    	const { month, year } = this.state;

    	const date = new Date(+year, +month, 1);

		if(this.props.minDate){
			const dateToCompare = moment(date);
			const minDate = moment(this.props.minDate);

			if(dateToCompare.isBefore(minDate)) {
				alert('End date is before start date!');
				return false;
			}
		}

		if(this.props.maxDate){
			const dateToCompare = moment(date);
			const maxDate = moment(this.props.maxDate);

			if(dateToCompare.isAfter(maxDate)) {
				alert('Start date is after end date!');
				return false;
			}
		}

    	let value = moment(date).format('MMMM YY');

    	this.setState({
		    value
	    }, () => {
    		this.props.changed(moment(date).format('YYYY-MM-DD'))
	    })
	}

	render () {
    	const { open, month, year, value } = this.state;
        const { placeholder, years } = this.props;

	    const months = moment.months();

        const selectorTpl = (
	        <div style={{ display: 'flex', justifyContent: 'center' }}>
		        <div>
			        <select value={month} onChange={this.monthChanged}>
				        {
					        months.map((label, value) => (
						        <option key={value} value={value}>{label}</option>
					        ))
				        }
			        </select>
		        </div>

		        <div>
			        <select value={year} onChange={this.yearChanged}>
				        {
					        years && years.map((value: number) => {
						        return <option value={moment().year() - value} key={value}>{moment().year() - value}</option>
					        })
				        }
			        </select>
		        </div>
	        </div>
        );

		return (
			<div className={[classes.MonthPicker, open ? classes['MonthPicker-open'] : ''].join(' ')}>
                <input
                    className={classes['MonthPicker-input']}
                    placeholder={placeholder}
                    value={value}
                    readOnly
                    onClick={this.handleClick} />

                <div className={[classes['MonthPicker-selection'], open ? classes['MonthPicker-selection-open'] : ''].join(' ')}>
                    {
                        selectorTpl
                    }
	                <button className={classes['MonthPicker-close']} onClick={this.handleClick}>Save</button>
                </div>
			</div>
		)
    }
};

export default MonthPicker;
