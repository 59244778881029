import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import truncate from 'truncate-html';
import classes from './OrderBox.module.scss';

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid: number = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	//padding: grid * 2,
	margin: `0 0 ${grid}px 0`,
	height: '60px',
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	border: '1px solid #E5E5E5',

	// change background colour if dragging
	background: isDragging ? "lightgreen" : "#FCFCFC",
	left: '0 !important',

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = (isDraggingOver: any) => ({
	//background: isDraggingOver ? "lightblue" : "lightgrey",
	//padding: grid,
});

interface OrderBoxState {
	items: Array<any>;
}

interface OrderBoxProps {
	currentItems: Array<any>;
	changedOrder: (items: any) => void;
}

class OrderBox extends Component<OrderBoxProps, OrderBoxState> {

	constructor(props: OrderBoxProps) {
		super(props);
		this.state = {
			items: []
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	componentDidMount(): void {
		this.setState({ items: this.props.currentItems || []})
	}

	onDragEnd(result: any) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index
		);

		this.setState({
			items
		}, () => { this.props.changedOrder(items) });
	}

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	render() {
		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{this.state.items.map((item: any, index: number) => {

								return (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided, snapshot) => (
											<div
												className={classes['OrderBox-item']}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}>
												{
													<div
														className={[classes['OrderBox-preview'], classes[`OrderBox-preview--${item.type}`]].join(' ')}
														style={{
															width: '20%',
															height: '100%',
															margin: '0 10px 0 0',
															backgroundSize: 'cover',
															backgroundImage: item.type === 'image' ? `url(${item.file.url})` : '',
														}}
													/>
												}
												<div style={{ padding: '10px 0', flex: '1 1 auto' }}>
													<div className={classes['OrderBox-type']}>
														{item.type} block
													</div>
													{item.type === 'text'&&
														<div
															dangerouslySetInnerHTML={{ __html: truncate(item.content, 40) }}/>
													}
												</div>
											</div>
										)}
									</Draggable>
								)
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}

export default OrderBox;
