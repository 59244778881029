import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';

interface IAction {
    type: string;
    file: object;
    error: string;
}

const INITIAL_STATE = {
    file: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
};

const resetFile = (state = INITIAL_STATE) => {
	return updateObject(state, { file: null, isStoring: false, error: null, didInvalidate: false, isStored: null });
};

const createFileStart = (state = INITIAL_STATE) => {
	return updateObject(state, { file: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createFileSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { file: action.file, isStoring: false });
};

const createFileFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchFileStart = (state = INITIAL_STATE) => {
	return updateObject(state, { file: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchFileSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { file: action.file, isFetching: false });
};

const fetchFileFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fileReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    case actionTypes.RESET_FILE: return resetFile(state);
	    // CREATE
	    case actionTypes.CREATE_FILE_START: return createFileStart(state);
	    case actionTypes.CREATE_FILE_SUCCESS: return createFileSuccess(state, action);
	    case actionTypes.CREATE_FILE_FAIL: return createFileFail(state, action);
        // READ/FETCHING
	    case actionTypes.FETCH_FILE_START: return fetchFileStart(state);
	    case actionTypes.FETCH_FILE_SUCCESS: return fetchFileSuccess(state, action);
	    case actionTypes.FETCH_FILE_FAIL: return fetchFileFail(state, action);

        default:
            return state;
    }
};

export default fileReducer;
