import * as actionTypes from './actionTypes';
import CurriculumService from '../../services/api/curriculum';
import { ICurriculum, IResponse } from '../../shared/interfaces';

// CREATE
export const createCurriculumStart = () => {
	return {
		type: actionTypes.CREATE_CURRICULUM_START,
	}
};

export const createCurriculumSuccess = (curriculum: ICurriculum) => {
	return {
		type: actionTypes.CREATE_CURRICULUM_SUCCESS,
		curriculum
	}
};

export const createCurriculumFail = (error: string) => {
	return {
		type: actionTypes.CREATE_CURRICULUM_FAIL,
		error,
	}
};

export const createCurriculum = (data: ICurriculum) => {
	return (dispatch: any) => {
		dispatch(createCurriculumStart());

		CurriculumService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createCurriculumSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(createCurriculumFail(errorMsg));
				}
			);
	}
};

// READ/FETCH
export const fetchCurriculumStart = () => {
    return {
        type: actionTypes.FETCH_CURRICULUM_START,
    }
};

export const fetchCurriculumSuccess = (curriculum: ICurriculum) => {
    return {
        type: actionTypes.FETCH_CURRICULUM_SUCCESS,
        curriculum
    }
};

export const fetchCurriculumFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CURRICULUM_FAIL,
        error,
    }
};

export const fetchCurriculum = (id: number) => {
	return (dispatch: any) => {
		dispatch(fetchCurriculumStart());

		CurriculumService
			.get(id)
            .then(
	            (response: IResponse) => {

                    dispatch(fetchCurriculumSuccess(response.data));
                },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(fetchCurriculumFail(errorMsg));
                }
            );
    }
};

export const fetchCurriculumByUserStart = () => {
	return {
		type: actionTypes.FETCH_CURRICULUM_BY_USER_START,
	}
};

export const fetchCurriculumByUserSuccess = (list: Array<ICurriculum>) => {
	return {
		type: actionTypes.FETCH_CURRICULUM_BY_USER_SUCCESS,
		list
	}
};

export const fetchCurriculumByUserFail = (error: string) => {
	return {
		type: actionTypes.FETCH_CURRICULUM_BY_USER_FAIL,
		error,
	}
};

export const fetchCurriculumByUser = (userId: number) => {
	return (dispatch: any) => {
		dispatch(fetchCurriculumByUserStart());

		CurriculumService
			.getByUser(userId)
			.then(
				(response: IResponse) => {

					dispatch(fetchCurriculumByUserSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchCurriculumByUserFail(errorMsg));
				}
			);
	}
};

// UPDATE
export const updateCurriculumStart = () => {
    return {
        type: actionTypes.UPDATE_CURRICULUM_START,
    }
};

export const updateCurriculumSuccess = (curriculum: ICurriculum) => {
    return {
        type: actionTypes.UPDATE_CURRICULUM_SUCCESS,
        curriculum
    }
};

export const updateCurriculumFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_CURRICULUM_FAIL,
        error,
    }
};

export const updateCurriculum = (id: number, data: ICurriculum) => {
    return (dispatch: any) => {
        dispatch(updateCurriculumStart());

        CurriculumService
            .update(id, data)
            .then(
	            (response: IResponse) => {

                    dispatch(updateCurriculumSuccess(response.data));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(updateCurriculumFail(errorMsg));
                }
            );
    }
};

// DELETE
export const deleteCurriculumStart = () => {
    return {
        type: actionTypes.DELETE_CURRICULUM_START,
    }
};

export const deleteCurriculumSuccess = (id: number) => {
    return {
        type: actionTypes.DELETE_CURRICULUM_SUCCESS,
        id
    }
};

export const deleteCurriculumFail = (error: string) => {
    return {
        type: actionTypes.DELETE_CURRICULUM_FAIL,
        error,
    }
};

export const deleteCurriculum = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteCurriculumStart());

        CurriculumService
            .remove(id)
            .then(
	            (response: IResponse) => {

                    dispatch(deleteCurriculumSuccess(id));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(deleteCurriculumFail(errorMsg));
                }
            );
    }
};
