import React, { Component } from 'react';
import classes from './TagsInput.module.scss';
import { WithContext as ReactTags } from 'react-tag-input';
import { ITag, IServerTag } from '../../shared/interfaces';
import './styles.scss';

const KeyCodes = {
	comma: 188,
	enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

interface tagsInputProps {
	selected: Array<string>;
	placeholder: string;
	changed: (value: Array<ITag>) => void;
	searchSuggestionsByAPI?: (q: string) => void;
	suggestions?: Array<IServerTag>;
	useIdKey?: boolean;
	lined?: boolean;
}

interface tagsInputState {
	tags: Array<ITag>,
	suggestions: Array<ITag>
}

class TagsInput extends Component<tagsInputProps, tagsInputState> {
	constructor(props: tagsInputProps) {
		super(props);

		this.state = {
			tags: this.props.selected ? this.props.selected.map((value: any) => {
				return { id: value, text: value }
			}) : [],
			suggestions: [],
		};

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleTagClick = this.handleTagClick.bind(this);
	}

	setTags(){
		this.props.changed(this.state.tags);
	}

	handleDelete(i: number) {
		const { tags } = this.state;
		this.setState({
			tags: tags.filter((tag, index) => index !== i),
		}, () => this.setTags());
	}

	handleAddition(tag: ITag) {
		this.setState(state => ({ tags: [...state.tags, tag] }), () => this.setTags());
	}

	handleDrag(tag: ITag, currPos: number, newPos: number) {
		const tags = [...this.state.tags];
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		this.setState({ tags: newTags }, () => this.setTags());
	}

	handleTagClick(index: number) {
		console.log('The tag at index ' + index + ' was clicked');
		this.setTags()
	}

	static getDerivedStateFromProps(nextProps: Readonly<tagsInputProps>, nextState: Readonly<tagsInputState>): any | null {
		let suggestions = nextState.suggestions;

		if(nextProps.suggestions){
			suggestions = nextProps.suggestions.map((tag: IServerTag) => {
				return { id: nextProps.useIdKey ? tag.id.toString() : tag.title, text: tag.title }
			});
		}

		return {
			suggestions
		}
	}

	render (){
		const { placeholder, searchSuggestionsByAPI, lined } = this.props;
		const { tags, suggestions } = this.state;

		//console.log(suggestions);

		return (
			<div className={[classes.TagsInput, lined ? classes['TagsInput--lined'] : ''].join(' ')}>
				<ReactTags
					autofocus={false}
					tags={tags}
					suggestions={suggestions}
					delimiters={delimiters}
					placeholder={placeholder}
					handleDelete={this.handleDelete}
					handleAddition={this.handleAddition}
					handleInputChange={searchSuggestionsByAPI}
					allowDragDrop={false}
					handleDrag={this.handleDrag}
					handleTagClick={this.handleTagClick}
				/>
			</div>
		);
	}
}

export default TagsInput;
