import * as actionTypes from './actionTypes';
import MessagesService from '../../services/api/messages';
import { IInterlocutorMessage, IMessage, IResponse } from '../../shared/interfaces';

// CREATE
export const createMessageStart = () => {
	return {
		type: actionTypes.CREATE_MESSAGE_START,
	}
};

export const createMessageSuccess = (message: IMessage) => {
	return {
		type: actionTypes.CREATE_MESSAGE_SUCCESS,
		message
	}
};

export const createMessageFail = (error: string) => {
	return {
		type: actionTypes.CREATE_MESSAGE_FAIL,
		error,
	}
};

export const createMessage = (data: IMessage) => {
	return (dispatch: any) => {
		dispatch(createMessageStart());

		MessagesService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createMessageSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(createMessageFail(errorMsg));
				}
			);
	}
};

// READ/FETCH
export const fetchMessagesByUserSenderStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGES_BY_USER_SENDER_START,
    }
};

export const fetchMessagesByUserSenderSuccess = (messages: IMessage) => {
    return {
        type: actionTypes.FETCH_MESSAGES_BY_USER_SENDER_SUCCESS,
        messages
    }
};

export const fetchMessagesByUserSenderFail = (error: string) => {
    return {
        type: actionTypes.FETCH_MESSAGES_BY_USER_SENDER_FAIL,
        error,
    }
};

export const fetchMessagesByUserSender = (id: number) => {
	return (dispatch: any) => {
		dispatch(fetchMessagesByUserSenderStart());

		MessagesService
			.getByUserSender(id)
            .then(
	            (response: IResponse) => {

                    dispatch(fetchMessagesByUserSenderSuccess(response.data));
                },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(fetchMessagesByUserSenderFail(errorMsg));
                }
            );
    }
};

export const fetchMessagesByUserDestinationStart = () => {
	return {
		type: actionTypes.FETCH_MESSAGES_BY_USER_DESTINATION_START,
	}
};

export const fetchMessagesByUserDestinationSuccess = (messages: IMessage) => {
	return {
		type: actionTypes.FETCH_MESSAGES_BY_USER_DESTINATION_SUCCESS,
		messages
	}
};

export const fetchMessagesByUserDestinationFail = (error: string) => {
	return {
		type: actionTypes.FETCH_MESSAGES_BY_USER_DESTINATION_FAIL,
		error,
	}
};

export const fetchMessagesByUserDestination = (id: number) => {
	return (dispatch: any) => {
		dispatch(fetchMessagesByUserDestinationStart());

		MessagesService
			.getByUserDestination(id)
			.then(
				(response: IResponse) => {

					dispatch(fetchMessagesByUserDestinationSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchMessagesByUserDestinationFail(errorMsg));
				}
			);
	}
};

export const fetchInterlocutorsStart = () => {
	return {
		type: actionTypes.FETCH_INTERLOCUTORS_START,
	}
};

export const fetchInterlocutorsSuccess = (interlocutors: IInterlocutorMessage) => {
	return {
		type: actionTypes.FETCH_INTERLOCUTORS_SUCCESS,
		interlocutors
	}
};

export const fetchInterlocutorsFail = (error: string) => {
	return {
		type: actionTypes.FETCH_INTERLOCUTORS_FAIL,
		error,
	}
};

export const fetchInterlocutors = () => {
	return (dispatch: any) => {
		dispatch(fetchInterlocutorsStart());

		MessagesService
			.getInterlocutors()
			.then(
				(response: IResponse) => {

					dispatch(fetchInterlocutorsSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchInterlocutorsFail(errorMsg));
				}
			);
	}
};

export const fetchConversationStart = (isNew: boolean) => {
	return {
		type: actionTypes.FETCH_CONVERSATION_START,
		isNew
	}
};

export const fetchConversationSuccess = (conversation: IMessage, isLastPage: boolean) => {
	return {
		type: actionTypes.FETCH_CONVERSATION_SUCCESS,
		conversation,
		isLastPage,
	}
};

export const fetchConversationFail = (error: string) => {
	return {
		type: actionTypes.FETCH_CONVERSATION_FAIL,
		error,
	}
};

export const fetchConversation = (userSenderId: number, userDestinationId: number, isNew: boolean, limit: number = 20, offset: number = 0, page: number) => {
	return (dispatch: any) => {
		dispatch(fetchConversationStart(isNew));

		MessagesService
			.getConversation(userSenderId, userDestinationId, limit, offset)
			.then(
				(response: IResponse) => {

					const isLastPage = response.data.length < page*limit;

					dispatch(fetchConversationSuccess(response.data, isLastPage));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchConversationFail(errorMsg));
				}
			);
	}
};

export const resetConversation = () => {
	return {
		type: actionTypes.RESET_CONVERSATION,
	}
};

// SET READ FOR MESSAGE
export const setReadMessageStart = () => {
	return {
		type: actionTypes.SET_READ_MESSAGE_START,
	}
};

export const setReadMessageSuccess = (messageRead: IMessage) => {
	return {
		type: actionTypes.SET_READ_MESSAGE_SUCCESS,
		messageRead
	}
};

export const setReadMessageFail = (error: string) => {
	return {
		type: actionTypes.SET_READ_MESSAGE_FAIL,
		error,
	}
};

export const setReadMessage = (messageId: number) => {
	return (dispatch: any) => {
		dispatch(setReadMessageStart());

		MessagesService
			.setRead(messageId)
			.then(
				(response: IResponse) => {
					dispatch(setReadMessageSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(setReadMessageFail(errorMsg));
				}
			);
	}
};


// GET UNREAD MESSAGES
export const fetchUnreadMessagesByUserStart = () => {
	return {
		type: actionTypes.FETCH_UNREAD_MESSAGES_BY_USER_START,
	}
};

export const fetchUnreadMessagesByUserSuccess = (newMessagesFound: boolean) => {
	return {
		type: actionTypes.FETCH_UNREAD_MESSAGES_BY_USER_SUCCESS,
		newMessagesFound
	}
};

export const fetchUnreadMessagesByUserFail = (error: string) => {
	return {
		type: actionTypes.FETCH_UNREAD_MESSAGES_BY_USER_FAIL,
		error,
	}
};

export const fetchUnreadMessagesByUser = (id: number) => {
	return (dispatch: any) => {
		dispatch(fetchUnreadMessagesByUserStart());

		MessagesService
			.getUnreadMessages(id)
			.then(
				(response: IResponse) => {

					dispatch(fetchUnreadMessagesByUserSuccess(response.data.length > 0));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchUnreadMessagesByUserFail(errorMsg));
				}
			);
	}
};
