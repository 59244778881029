import request from '../../shared/lib/request'
import constants from "../../shared/constants";
import { IMessage } from '../../shared/interfaces';

const create = (data: IMessage) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
	    'Authorization': `Bearer ${authToken}`
    };

    return request({
        url:    '/message',
        method: 'POST',
        headers,
        data
    });
};

const getInterlocutors = () => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    '/message/search/interlocutors',
        method: 'GET',
        headers
    });
};

const getByUserDestination = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/message/search?userDestinationId=${id}`,
		method: 'GET',
		headers
	});
};

const getByUserSender = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/message/search?userSenderId=${id}`,
		method: 'GET',
		headers
	});
};

const getConversation = (userSenderId: number, userDestinationId: number, limit: number, offset: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/message/search?userOne=${userSenderId}&userTwo=${userDestinationId}&limit=${limit}&offset=${offset}`,
		method: 'GET',
		headers
	});
};

const setRead = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/message/${id}`,
		method: 'PATCH',
		headers,
	});
};

const getUnreadMessages = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/message/user/${id}/unread`,
		method: 'GET',
		headers
	});
};


const MessagesService = {
    create,
	getInterlocutors,
	getByUserDestination,
	getByUserSender,
	getConversation,
	setRead,
	getUnreadMessages,
};

export default MessagesService;
