import request from '../../shared/lib/request'
import constants from "../../shared/constants";

const create = (data: object) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
	    'Authorization': `Bearer ${authToken}`
    };

    return request({
        url:    '/curriculum',
        method: 'POST',
        headers,
        data
    });
};

const get = (id?: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = id ? `/curriculum/${id}` : '/curriculum';

    return request({
        url:    endpoint,
        method: 'GET',
        headers
    });
};

const getByUser = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/curriculum/user/${id}`,
		method: 'GET',
		headers
	});
};

const update = (id: number, data: object) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/curriculum/${id}`,
        method: 'PATCH',
        headers,
        data
    });
};

const remove = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/curriculum/${id}`,
        method: 'DELETE',
        headers,
    });
};


const CurriculumService = {
    get, getByUser, create, update, remove
};

export default CurriculumService;
