import React, { Component } from "react";
import constants from '../../shared/constants';
import classes from './OriginalCard.module.scss';
import CircleImage from '../CircleImage/CircleImage';
import TagsList from '../TagsList/TagsList';
import OriginalStages from '../OriginalStages/OriginalStages';
import { FormattedMessage } from 'react-intl';
import { IOriginal } from '../../shared/interfaces';
import Button from '../Button/Button';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

interface IProps {
	original: IOriginal;
	userName?: string;
	userThumbnail?: string | null;
	clicked?: () => void;
	styles?: object;
	showClicked?: () => void;
	removeClicked?: () => void;
	titleClicked?: () => void;
	showLabelId?: string;
	requestAccessRejected?: boolean;
	requestAccessClicked?: () => void;
	requestAccessDisabled?: boolean;
	manageAccessClicked?: () => void;
	showActions?: boolean;
	full?: true;
	setFormat?: (value: string) => void;
	setMood?: (value: string) => void;
	setDistribution?: (value: string) => void;
	backgroundImageUrl?: string;
	currentUser?: number;
}

interface IState {
	showMenu: boolean;
}

class OriginalCard extends Component<IProps, IState> {
	private menu!: HTMLDivElement;

	state = {
		showMenu: false,
	};

	handleClickedOutsideMenu = (e: any) => {
		if (this.menu && !this.menu.contains(e.target) && this.state.showMenu) {
			this.setState({
				showMenu: false
			})
		}
	};

	componentWillMount(){
		document.addEventListener('click', this.handleClickedOutsideMenu, false);
	}

	componentWillUnmount(){
		document.removeEventListener('click', this.handleClickedOutsideMenu, false);
	}

	handleMenuClicked = () => {
		const showMenu = !this.state.showMenu;

		this.setState({
			showMenu
		})
	};

	handleRemoveClicked = () => {
		this.setState({
			showMenu: false,
		}, () => {
			if(this.props.removeClicked)
				this.props.removeClicked()
		})
	};

	render() {
		const {
			userName,
			userThumbnail,
			original: {
				title,
				logline,
				lookingFor,
				format,
				distribution,
				mood,
				stage,
				lastStep,
				createdAt,
				updatedAt,
				status,
				userId,
				bell,
				patamu
			},
			clicked,
			styles,
			showClicked,
			showLabelId,
			manageAccessClicked,
			requestAccessClicked,
			requestAccessDisabled,
			titleClicked,
			showActions,
			removeClicked,
			full,
			requestAccessRejected,
			setFormat,
			setMood,
			setDistribution,
			backgroundImageUrl,
			currentUser
		} = this.props;

		const {
			showMenu
		} = this.state;

		const currentStage = stage ? constants.ORIGINAL_STAGES.find((item: { value: number; key: string }) => item.key.toLowerCase() === stage.toLowerCase()) : 0;

		const stageValue = currentStage ? currentStage.value : 0;

		let dateToShow = moment(createdAt).format('MMMM D, YYYY');
		let dateToShowEdit = moment(updatedAt).format('MMMM D, YYYY');

		switch (dateToShow) {
			case moment().format('MMMM D, YYYY'): dateToShow = 'Today';
				break;
			case moment().add(-1, 'days').format('MMMM D, YYYY'): dateToShow = 'Yesterday';
				break;
			default: dateToShow = moment(createdAt).format('MMMM D, YYYY');
		}

		switch (dateToShowEdit) {
			case moment().format('MMMM D, YYYY'): dateToShowEdit = 'today';
				break;
			case moment().add(-1, 'days').format('MMMM D, YYYY'): dateToShowEdit = 'yesterday';
				break;
			default: dateToShowEdit = moment(updatedAt).format('MMMM D, YYYY');
		}

		const isApplyingToCall =
			bell.length > 0 &&
			status === 'ONLINE' &&
			currentUser == userId;

		let dateTpl = (
			createdAt &&
			<div
			className={[classes['OriginalCard-date'], classes['OriginalCard-date--created']].join(' ')}>
				<div data-tip data-for={title ? title.replace(/\s+/g, '') : ''}>
				{ dateToShow }
					<ReactTooltip id={title ? title.replace(/\s+/g, '') : ''} effect="solid">
						<FormattedMessage id="label.createdAt" />
					</ReactTooltip>
				</div>
				{
					dateToShowEdit && dateToShow.toLowerCase() != dateToShowEdit.toLowerCase() ?
					<>
						{' - '}
						<em>
							<FormattedMessage id="label.edited" />
							{' '}
							{ dateToShowEdit }
						</em>
					</>
					:
					''
				}
			</div>
		)


		let tpl = (
			<div className={[classes.OriginalCard, status == 'DRAFT' ? classes['OriginalCard--draft'] : '', full ? classes['OriginalCard--full'] : '', isApplyingToCall ? classes['OriginalCard--call'] : ''].join(' ')} style={styles}>

				{
					userName ?
                    <div className={classes['OriginalCard-header']}>
						<NavLink to={currentUser == userId ? `/profile/` : `/user/${userId}`}>
							<div className={classes['OriginalCard-User']}>
								<div
									className={classes['OriginalCard-User-img']}
									style={{backgroundImage: userThumbnail ? `url(${userThumbnail})` : 'none'}}>
										{ userName && (!userThumbnail || userThumbnail !== '') &&
											<div className={classes['OriginalCard-letter']}>
												{ userName.substring(0, 1) }
											</div>
										}
								</div>
								<div>
									<div className={classes['OriginalCard-User-name']}>
										{ userName }
									</div>
									{ dateTpl }
								</div>
							</div>
						</NavLink>
                    </div>
					:
					<div className={classes['OriginalCard-header']}>
						<div className={classes['OriginalCard-User']}>
							{ dateTpl }
						</div>
                    </div>
				}
				{
					title &&
					<div className={[classes['OriginalCard-wrapper'], backgroundImageUrl ? classes['OriginalCard-wrapper--img'] : ''].join(' ')}>

						{ backgroundImageUrl &&
							<div
							style={{backgroundImage: `url(${backgroundImageUrl})`}}
							className={[classes['OriginalCard-bg'], !showClicked ? classes['OriginalCard-bg--hidden'] : ''].join(' ')} />
						}

                        <div className={[classes['OriginalCard-title'], !showClicked ? classes['OriginalCard-title--hidden'] : ''].join(' ')}>
							{
								titleClicked ?
									<button className={classes['OriginalCard-button']} onClick={titleClicked}>
										{title}
									</button> : title
							}

                        </div>

						{
							logline &&
							<div className={[classes['OriginalCard-logline'], !showClicked ? classes['OriginalCard-logline--hidden'] : ''].join(' ')}>
								{
									titleClicked ?
									<button className={classes['OriginalCard-button']} onClick={titleClicked}>
										{ logline }
									</button>
									: logline
								}
							</div>
						}
                    </div>
				}
				<div className={classes['OriginalCard-content']}>
					{
						format.length > 0 &&
						<div className={[classes['OriginalCard-row'], classes['OriginalCard-row--noMargin'], classes['OriginalCard-row--absolute']].join(' ')}>
							<TagsList
								identifier={title}
								action={setFormat}
								items={format}/>
						</div>
					}
					{/* DISTRIBUTION HIDDEN */}
					{/* {
						distribution.length > 0 &&
                        <div className={classes['OriginalCard-row']}>
                            <label><FormattedMessage id={'label.originalDistributions'}/></label>
                            <TagsList
								identifier={title}
								action={setDistribution}
                                theme={'dark'}
                                items={distribution}/>
                        </div>
					} */}
					{
						mood.length > 0 &&
                        <div className={classes['OriginalCard-row']}>
                            <label><FormattedMessage id={'label.originalMoods'}/></label>
                            <TagsList
								identifier={title}
								action={setMood}
                                theme={'dark'}
                                items={mood}/>
                        </div>
					}
					{
						bell.length > 0 &&
                        <div className={classes['OriginalCard-row']}>
                            <label><FormattedMessage id="label.originalBells.detail" /></label>
                            <TagsList
                                theme={'dark'}
                                items={bell}/>
                        </div>
					}
					{
						stage &&
                        <div className={classes['OriginalCard-row']}>
                            <label><FormattedMessage id={'label.originalStage'}/></label>
                            <OriginalStages
								value={stageValue}
								identifier={title} />
                        </div>
					}
					{
						lookingFor &&
                        <div className={classes['OriginalCard-row']}>
                            <label><FormattedMessage id={'label.originalItem.myRole'}/></label>
                            <span>{lookingFor}</span>
                        </div>
					}
				</div>
				{
					(requestAccessClicked ||
						manageAccessClicked ||
						showClicked) &&
                    <div className={classes['OriginalCard-footer']}>
						{
							requestAccessClicked && !requestAccessRejected &&
                            <Button disabled={requestAccessDisabled || false} clicked={requestAccessClicked} type="secondary-small">
								{
									!requestAccessDisabled ?
										<FormattedMessage id="button.requestAccess"/> :
										<FormattedMessage id="button.requestAccessDisabled"/>
								}
                            </Button>
						}
	                    {
		                    requestAccessRejected &&
	                            <Button disabled={requestAccessRejected} clicked={() => console.log('Request rejected')} type="danger-small">
                                    <FormattedMessage id="button.requestAccessRejected"/>
	                            </Button>
	                    }
						{
							manageAccessClicked &&
                            <Button clicked={manageAccessClicked} type={'manage-access'}>
                                <FormattedMessage id="button.manageAccess"/>
                            </Button>
						}
						{
							showClicked && !showActions &&
                            <Button type="primary-small" clicked={showClicked}>
                                <FormattedMessage id={showLabelId || "general.edit"} />
                            </Button>
						}
	                    {
		                    showActions &&
                            <div className={classes['OriginalCard-menu']} ref={(node: any) => { this.menu = node; }}>
                                <Button styles={{padding: 0, color: '#232323', transform: 'rotate(90deg)'}} type="more" clicked={this.handleMenuClicked}>
									<FormattedMessage id={"general.more"} />
								</Button>
			                    {
				                    showMenu &&
                                    <ul className={classes['OriginalCard-actions']}>
					                    {
											bell.length > 0 ?
											showClicked &&
											(status !== 'ONLINE' || status === 'ONLINE' && patamu === null) &&
	                                            <li className={classes['OriginalCard-action']}>
	                                                <Button
	                                                    clicked={showClicked}
														type="small"
														styles={{padding: '5px 20px', fontSize: '1.1rem'}}>
                                                        <FormattedMessage id={showLabelId || "general.edit"} />
	                                                </Button>
	                                            </li>
											:
											showClicked &&
	                                            <li className={classes['OriginalCard-action']}>
	                                                <Button
	                                                    clicked={showClicked}
														type="small"
														styles={{padding: '5px 20px', fontSize: '1.1rem'}}>
                                                        <FormattedMessage id={showLabelId || "general.edit"} />
	                                                </Button>
	                                            </li>
					                    }
					                    {
					                    	removeClicked &&
	                                            <li className={classes['OriginalCard-action']}>
	                                                <Button
	                                                    clicked={this.handleRemoveClicked}
														type="small"
														styles={{padding: '5px 20px', fontSize: '1.1rem', color: '#D05A5A'}}>
														<FormattedMessage id={"general.delete"} />
	                                                </Button>
	                                            </li>
					                    }
                                    </ul>
			                    }
                            </div>
	                    }
                    </div>
				}
			</div>
		);

		if(clicked){
			tpl = (
				<button className={classes.OriginalCardButton} onClick={clicked}>
					{ tpl }
				</button>
			)
		}

		return tpl;
	}
}

export default OriginalCard;
