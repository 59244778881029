import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import { connect } from 'react-redux';
import classes from './Invites.module.scss';
import Template from '../../containers/Template/Template';
import Loader from '../Loader/Loader';
import InvitationsBox from '../InvitationsBox/InvitationsBox';
import InvitesSidebar from '../InvitesSidebar/InvitesSidebar';
import { IUser } from '../../shared/interfaces';
import { PROFILE } from '../../shared/routes';

const PRINT_CURRENT_USER = false;

interface IProps {
	onGetCurrentUser(): object;
	currentUser: IUser;
	history?: any;
	experiences: number;
	projects: number;
}

interface IState {
	currentUser: IUser | null;
	roles: any;
	buttons: Array<any>;
	baseUrl: string;
}

class Invites extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const getUrl = window.location;

		this.state = {
			currentUser: null,
			roles: [],
			buttons: [
				{
					title: <FormattedMessage id={'navigation.team.create'} />,
					description: <FormattedMessage id={'dashboard.team.create.description'} />,
					name: 'team',
					//clicked: this.handleToggleCreateTeam,
					//link: TEAM,
					link: '#'
				},
				{
					title: <FormattedMessage id={'navigation.brief.create'} />,
					description: <FormattedMessage id={'dashboard.brief.create.description'} />,
					name: 'brief',
					link: '#',
				},
				{
					title: <FormattedMessage id={'navigation.network.browse'} />,
					description: <FormattedMessage id={'dashboard.network.browse.description'} />,
					name: 'network',
					link: '#',
				}
			],
			baseUrl: getUrl.protocol + "//" + getUrl.host
		}
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
		ReactGA.pageview('/dashboard');

		this.getCurrentUser();
	}

	getCurrentUser(){
		this.props.onGetCurrentUser();
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevProps.currentUser !== this.props.currentUser && this.props.currentUser){
			this.setState({
				currentUser: this.props.currentUser
			}, () =>{
				if(PRINT_CURRENT_USER)
					console.log("Current user:", this.state.currentUser)
			});

			if(this.props.currentUser.hasOwnProperty('fileCodeImage'))
				localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, this.props.currentUser.fileCodeImage.url);

			if(this.props.currentUser.ambassador !== 1){
				this.props.history.push(PROFILE);
			}
		}
	}

	render () {
		const { roles, baseUrl } = this.state;

		return (
			<div className={classes.Invites}>
				<Template
					sidebar={<InvitesSidebar roles={roles} />}
					sidebarSize="small">
					{
						!this.props.currentUser ?
							<div className={classes["Invites-loading"]}>
								<Loader />
							</div>
							:
							<Fragment>
								<div className={[classes['Invites-row'], classes['Invites-row--column']].join(' ')}>
									<InvitationsBox
										title={<FormattedMessage id={'invites.title'} />}
										description={<FormattedMessage id={'invites.description'} />}
										referral={`${baseUrl}/registration/${this.props.currentUser.referralCode}`}/>
								</div>
							</Fragment>
					}
				</Template>
			</div>
		)
    }
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		loading: state.curriculumState.isStoring,
		error: state.curriculumState.error,
		experiences: state.curriculumState.list.length,
		projects: state.projectState.list.filter((project: any) => project.status.toLowerCase() === 'online').length,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Invites );
