import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';

interface IAction {
    type: string;
    list: Array<object>;
    invitation: object;
    error: string;
    removed: boolean;
}

const INITIAL_STATE = {
    list: [],
    invitation: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
};

const createInvitationStart = (state = INITIAL_STATE) => {
	return updateObject(state, { invitation: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createInvitationSuccess = (state = INITIAL_STATE, action: IAction) => {
	const currentList = action.invitation ? [action.invitation] : [];

	return updateObject(state, { list: currentList.concat(state.list), invitation: action.invitation, isStoring: false });
};

const createInvitationFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchInvitationsByUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { list: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchInvitationsByUserSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { list: action.list, isFetching: false });
};

const fetchInvitationsByUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const invitationReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_INVITATION_START: return createInvitationStart(state);
	    case actionTypes.CREATE_INVITATION_SUCCESS: return createInvitationSuccess(state, action);
	    case actionTypes.CREATE_INVITATION_FAIL: return createInvitationFail(state, action);
        // READ/FETCHING
	    case actionTypes.FETCH_INVITATIONS_BY_USER_START: return fetchInvitationsByUserStart(state);
	    case actionTypes.FETCH_INVITATIONS_BY_USER_SUCCESS: return fetchInvitationsByUserSuccess(state, action);
	    case actionTypes.FETCH_INVITATIONS_BY_USER_FAIL: return fetchInvitationsByUserFail(state, action);

        default:
            return state;
    }
};

export default invitationReducer;
