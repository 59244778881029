import constants from "./constants";

export const checkValidEmail = (email: string): boolean => {
    if(!email || email.trim() === ''){
        return false;
    }

    const regExp = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

    const matcher = new RegExp(regExp);
    const keywordTest = email.trim();

    return matcher.test(keywordTest);
};

export const checkValidSecureURL = (str: string) => {
	const matcher = new RegExp('^(https:\\/\\/www\\.|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$');

	return matcher.test(str);
};

export const checkValidURL = (str: string) => {
	const matcher = new RegExp('^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$');

	return !matcher.test(str);
};

export const checkValidPhone = (prefix: string, phone: string): boolean => {
    if((!prefix || !phone) && (prefix.trim() === '' || phone.trim() === '')){
        return false;
    }

    let valid = false;

    const fullPhone = prefix.trim() + phone.trim();

    if(fullPhone.length > 15){
        valid = false;
    } else {

        if(phone.trim() !== '') {
            const regExp = '^[0-9]*$';
            const matcher = new RegExp(regExp);

            const keywordTest = phone.trim();

            valid = matcher.test(keywordTest);
        } else {
            valid = false;
        }
    }

    return valid;
};

export const checkSession = (): boolean => {
	return localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN) !== null;
};

export const resetSession = (): void => {
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE);
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_NAME);
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME);
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_EMAIL);
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_ID);
};

export const resetProjectSession = (): void => {
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.CURRENT_PROJECT_ID);
	localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.CURRENT_STEP);
};

export const randomId = (): string => {
    return '_' + Math.random().toString(36).substr(2, 9);
};

export const getUnique = (arr: any, comp: any) => {

	return arr
		.map((e: any) => e[comp])

		// store the keys of the unique objects
		.map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

		// eliminate the dead keys & store unique objects
		.filter((e: any) => arr[e]).map((e: any) => arr[e]);
};

export const countWordInArray = (array_elements: any) => {

	array_elements.sort();

	let list = [];

	let current = null;
	let cnt = 0;
	for (let i = 0; i < array_elements.length; i++) {
		if (array_elements[i] != current) {
			if (cnt > 0) {

				list.push({
					value: current,
					count: cnt
				});
				//console.log(current + ' comes --> ' + cnt + ' times<br>');
			}
			current = array_elements[i];
			cnt = 1;
		} else {
			cnt++;
		}
	}
	if (cnt > 0) {
		//console.log(current + ' comes --> ' + cnt + ' times');

		list.push({
			value: current,
			count: cnt
		});
	}

	return list
};
