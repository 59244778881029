import React from 'react';
import classes from './TagsList.module.scss';
import { IServerTag } from '../../shared/interfaces';
import ReactTooltip from 'react-tooltip';

interface tagsInputProps {
	items: Array<IServerTag>;
	theme?: 'dark' | 'light';
	identifier?: string;
	action?: (value: string) => void;
}

const tagsList = (props: tagsInputProps) => {
	const { items, theme, action, identifier } = props;

	return (
		<ul className={[classes.TagsList, theme ? classes[`TagsList--${theme}`] : ''].join(' ')}>
			{
				items.map((item: IServerTag, index: number) =>
					<li className={classes['TagsList-item']} key={index}>
					{ action ?
						<button onClick={() => action(item.title)} data-tip data-for={identifier + item.title}>
							{ item.title }
							<ReactTooltip id={identifier + item.title} effect="solid">
								Filter Originals by {item.title}
							</ReactTooltip>
						</button>
						:
						item.title
					}
					</li>
				)
			}
		</ul>
	)
};

export default tagsList;
