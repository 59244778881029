import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';

interface IAction {
    type: string;
    invitations: Array<any>;
    list: Array<object>;
    team: object;
    error: string;
    removed: boolean;
    added: boolean;
}

const INITIAL_STATE = {
    list: [],
	invitations: [],
    team: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
	created: false,
	updated: false,
};

const createTeamStart = (state = INITIAL_STATE) => {
	return updateObject(state, { team: null, created: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createTeamSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { team: action.team, created: true, isStoring: false });
};

const createTeamFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchTeamByUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { team: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchTeamByUserSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { team: action.team, isFetching: false });
};

const fetchTeamByUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const sendTeamInvitationsStart = (state = INITIAL_STATE) => {
	return updateObject(state, { invitations: [], created: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const sendTeamInvitationsSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { invitations: action.invitations, created: true, isStoring: false });
};

const sendTeamInvitationsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};


const teamReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_TEAM_START: return createTeamStart(state);
	    case actionTypes.CREATE_TEAM_SUCCESS: return createTeamSuccess(state, action);
	    case actionTypes.CREATE_TEAM_FAIL: return createTeamFail(state, action);

	    // FETCH
	    case actionTypes.FETCH_TEAM_BY_USER_START: return fetchTeamByUserStart(state);
	    case actionTypes.FETCH_TEAM_BY_USER_SUCCESS: return fetchTeamByUserSuccess(state, action);
	    case actionTypes.FETCH_TEAM_BY_USER_FAIL: return fetchTeamByUserFail(state, action);

	    // SEND TEAM INVITATIONS
	    case actionTypes.SEND_TEAM_INVITATIONS_START: return sendTeamInvitationsStart(state);
	    case actionTypes.SEND_TEAM_INVITATIONS_SUCCESS: return sendTeamInvitationsSuccess(state, action);
	    case actionTypes.SEND_TEAM_INVITATIONS_FAIL: return sendTeamInvitationsFail(state, action);

        default:
            return state;
    }
};

export default teamReducer;
