import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import { connect } from 'react-redux';
import classes from './Network.module.scss';
import Template from '../../containers/Template/Template';
import Loader from '../Loader/Loader';
import { ICategory, IUser } from '../../shared/interfaces';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import UserBox from '../UserBox/UserBox';
import Select from '../Select/Select';
import {DebounceInput} from 'react-debounce-input';

const SEARCH_TYPOLOGY_OPTIONS = [
	{
		description: 'Name',
		value: 'name',
	},
	{
		description: 'Role',
		value: 'role',
	},
	// {
	// 	description: 'Projects',
	// 	value: 'type',
	// },
];

interface IProps {
	onGetCurrentUser(): object;
	onGetUsersByFilters(type: string, value: string): Array<IUser>;
	onGetNetworkUsers(): Array<IUser>;
	onGetCategories(): any;
	currentUser: IUser;
	history: any;
	match: any;
	users: Array<IUser>;
	loading: boolean;
	categories: Array<ICategory>;
	noUsersFound: boolean;
}

interface IState {
	currentUser: IUser | null;
	search: string;
	selected: Array<IUser>;
	type: any;
	searchTypology: any;
	categories: Array<any>;
	searching: boolean;
}

class Network extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			currentUser: null,
			search: '',
			selected: [],
			categories: [],
			type: {
				selected: '',
				value: 0,
				name: 'type',
				emptySelection: <FormattedMessage id={'select.empty'}/>,
				options: [],
				showList: false,
				disabled: false
			},
			searchTypology: {
				selected: SEARCH_TYPOLOGY_OPTIONS[0].description,
				value: 'name',
				name: 'searchTypology',
				emptySelection: <FormattedMessage id={'select.empty'}/>,
				options: SEARCH_TYPOLOGY_OPTIONS,
				showList: false,
				disabled: false
			},
			searching: true
		};

		this.handleSelectedUsers = this.handleSelectedUsers.bind(this);
		this.handleRemoveSelectedUser = this.handleRemoveSelectedUser.bind(this);
		this.handleTypeSelectClicked = this.handleTypeSelectClicked.bind(this);
		this.handleTypeSelectItemClicked = this.handleTypeSelectItemClicked.bind(this);
		this.handleSearchTypologySelectClicked = this.handleSearchTypologySelectClicked.bind(this);
		this.handleSearchTypologySelectItemClicked = this.handleSearchTypologySelectItemClicked.bind(this);
	}

	handleSearchButtonClicked = () => {
		const { search, searchTypology } = this.state;

		this.props.onGetUsersByFilters(searchTypology.value, search);
		this.setState({
			searching: false,
		})
	};

	onPressEnter = (e: any) => {
		if(e.keyCode === 13 && !this.state.searching && e.target.value !== this.state.search) {
			this.debounceSearch(e);
		}
	};

	debounceSearch = (e:any) => {
		this.setState({ search: e.target.value, searching: true }, () => {
			if(e.target.value !== '') {
				this.handleSearchButtonClicked();
			} else {
				this.getAllNetworkUsers();
			}
		});
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
		ReactGA.pageview('/network');

		this.props.onGetCategories();
		this.getCurrentUser();

		this.getAllNetworkUsers();
	}

	getAllNetworkUsers = () => {
		let searchTypology = {...this.state.searchTypology};
		searchTypology.value = searchTypology.value || 'role';
		searchTypology.selected = SEARCH_TYPOLOGY_OPTIONS[0].description;

		this.setState({
			searchTypology,
			search: '',
			searching: false
		});

		this.props.onGetNetworkUsers();
	};

	getCurrentUser(){
		this.props.onGetCurrentUser();
	}

	handleSelectedUsers(id: number){
		let selected = [...this.state.selected];
		const { users } = this.props;

		// It exists?
		const currentSelected = selected.filter((user: IUser) => user.id === id);

		if(currentSelected.length > 0){
			selected = selected.filter((user: IUser) => user.id !== id);
		} else {
			const newUser = users.filter((user: IUser) => user.id === id);

			selected.push(newUser[0]);
		}

		this.setState({
			selected
		}, () => {
			//console.log('Selected:', this.state.selected)
		})
	}

	handleRemoveSelectedUser(id: number){
		let selected = [...this.state.selected];

		selected = selected.filter((user: IUser) => user.id !== id);

		this.setState({
			selected
		})
	}

	handleTypeSelectClicked(ev: React.MouseEvent<HTMLButtonElement>){
		ev.preventDefault();

		let type = {...this.state.type};

		type.showList = !type.showList;

		this.setState({
			type
		});
	};

	handleTypeSelectItemClicked(ev: React.MouseEvent<HTMLButtonElement>) {
		const value = ev.currentTarget.getAttribute('data-value');
		const selected = ev.currentTarget.getAttribute('data-description');

		let type = {...this.state.type};
		type.selected = selected;
		type.value = value;
		type.showList = false;

		this.setState({
			type,
			search: value || ''
		});

		this.handleSearchButtonClicked();
	};

	handleSearchTypologySelectClicked(ev: React.MouseEvent<HTMLButtonElement>){
		ev.preventDefault();

		let searchTypology = {...this.state.searchTypology};

		searchTypology.showList = !searchTypology.showList;

		this.setState({
			searchTypology
		});
	};

	handleSearchTypologySelectItemClicked(ev: React.MouseEvent<HTMLButtonElement>) {
		const value = ev.currentTarget.getAttribute('data-value');
		const selected = ev.currentTarget.getAttribute('data-description');

		let searchTypology = {...this.state.searchTypology};
		searchTypology.selected = selected;
		searchTypology.value = value;
		searchTypology.showList = false;

		this.setState({
			searchTypology,
		}, () => {
			this.handleSearchButtonClicked();
		});
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevState.categories !== this.state.categories && this.state.categories){
			let type = {...this.state.type};

			type.options = this.state.categories.map(
				(item: any) => {
					return {
						description: item.title,
						value: item.id
					}
				});

			this.setState({ type });
		}
	}

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let currentUser = nextState.currentUser;
		let categories = nextState.categories;

		if(nextProps.currentUser) {
			currentUser = nextProps.currentUser;

			if(nextProps.currentUser.hasOwnProperty('fileCodeImage'))
				localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, nextProps.currentUser.fileCodeImage.url);
		}

		if(nextProps.categories) {
			categories = nextProps.categories;
		}

		return {
			currentUser,
			categories,
		}
	}

	render () {
		const { search, selected, type, searchTypology } = this.state;
		const { loading, users, noUsersFound } = this.props;

		const buttonTpl = (
			<Button
				clicked={this.handleSearchButtonClicked}
				styles={{padding: '3px 25px'}}
				type="search">
				{ 'Search' }
			</Button>
		);

		const resetButtonTpl = (
			<Button clicked={this.getAllNetworkUsers} type={'secondary-smaller'}>{ 'Show all' }</Button>
		);

		// @todo: Mike remove select click if it is not necessary! in this.state.selected we have all selected.
		const usersListTpl = users.map((user: IUser, index: number) =>
			<UserBox
				selected={selected.filter((sel: IUser) => sel.id === user.id).length > 0}
				key={index}
				user={user}
				complete />
			);

		const typologiesTpl = searchTypology.options.map((type: any, index: number) =>
			<button
				onClick={this.handleSearchTypologySelectItemClicked}
				data-value={type.value}
				data-description={type.description}
				key={index}
				className={[classes['Network-config-btn'], searchTypology.value == type.value ? classes['Network-config-btn--is-active'] : ''].join(' ')}>
				{ type.description }
			</button>
		);

		return (
			<Template>
				<Fragment>
					<div className={classes.Network}>
						<div className={classes['Network-content']}>
							{
								<div className={classes['Network-searchform']}>
									<div className={classes['Network-search-configs']}>
										<div className={classes['Network-search-configs-btns']}>
											<div>
											{ typologiesTpl }
											</div>
											{ resetButtonTpl }
										</div>
									</div>
									{/* <Select
										styles={{width: 'auto'}}
										clicked={this.handleSearchTypologySelectClicked}
										itemClicked={this.handleSearchTypologySelectItemClicked}
										emptySelection={searchTypology.emptySelection}
										selected={searchTypology.selected}
										value={searchTypology.value}
										name={searchTypology.name}
										showList={searchTypology.showList}
										options={searchTypology.options}
										dark /> */}
									{
										searchTypology.value === 'type' ?
											<Select
												styles={{flex: '1 1 45vw', marginTop: '27px'}}
												clicked={this.handleTypeSelectClicked}
												itemClicked={this.handleTypeSelectItemClicked}
												emptySelection={type.emptySelection}
												selected={type.selected}
												value={type.value}
												name={type.name}
												showList={type.showList}
												options={type.options}
												transparent />
											:
											// <Input
											// 	changed={
											// 		(e: any) => {
											// 			this.setState({ search: e.target.value });
											// 		}
											// 	}
											// 	disabled={searchTypology.selected === ''}
											// 	type="text"
											// 	name="search-input"
											// 	value={search || ''}
											// 	keyPressed={this.onPressEnter}
											// 	placeholderId="placeholder.searchForm"
											// 	styles={{
											// 		marginBottom: '0',
											// 		flex: '1 1 45vw',
											// 		fontSize: '1.7rem',
											// 		backgroundColor: 'transparent',
											// 		border: 'none',
											// 		borderBottom: '1px solid #E5E5E5'
											// 	}}
											// 	visible={true} />
											<div className={classes['Network-search-input-container']}>
												<DebounceInput
													minLength={3}
													debounceTimeout={400}
													placeholder="Type to search"
													onKeyDown={this.onPressEnter}
													className={classes['Network-search-input']}
													onChange={this.debounceSearch} />
													<div className={classes['Network-search-input-icon']} />
											</div>
									}
									{/* {
										buttonTpl
									} */}
								</div>
							}
							{
								loading &&
									<div className={classes['Network-loading']}>
										<Loader/>
									</div>
							}

							{
								users.length > 0 &&
	                                <div className={classes['Network-list-container']}>
	                                    <div className={classes['Network-label']}>
	                                        <FormattedMessage id={'label.results'} />
	                                    </div>
	                                    <div className={classes['Network-list']}>
											{
												usersListTpl
											}
	                                    </div>
	                                </div>
							}
							{
								noUsersFound && !loading &&
	                                <div className={classes['Network-list']}>
	                                    <div className={classes['Network-list-empty']}>
											{ `No users found with this criteria`}
	                                        <div className={classes['Network-list-empty-img']} />
	                                    </div>
	                                </div>
							}
						</div>
					</div>
				</Fragment>
			</Template>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		categories: state.categoryState.categories,
		currentUser: state.userState.user,
		users: state.userState.users,
		noUsersFound: state.userState.noUsersFound,
		loading: state.userState.isFetching,
		error: state.userState.error
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onGetNetworkUsers: () => dispatch(actions.fetchNetworkUsers()),
		onGetUsersByFilters: (type: string, value: string) => dispatch(actions.fetchUsersByFilters(type, value, true)),
		onGetCategories: () => dispatch(actions.fetchCategories()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Network );
