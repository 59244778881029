import React, {Component} from 'react';
import * as actions from "../../store/actions";
import * as Scroll from 'react-scroll';
import {connect} from "react-redux";
import { NavLink } from 'react-router-dom';
import classes from './RegistrationSchoolWizard.module.scss';
import { NEW_ORIGINAL } from '../../shared/routes';
import { IRegistration, ISession } from '../../shared/interfaces';
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Loader from '../Loader/Loader';
import Input from '../../components/Input/Input';
import CheckboxSlider from '../../components/CheckboxSlider/CheckboxSlider';
import Button from "../../components/Button/Button";
import RegistrationSchoolWizardHeader from './RegistrationSchoolWizardHeader/RegistrationSchoolWizardHeader';
import { checkSession } from '../../shared/helpers';

const Element = Scroll.Element;

interface IProps {
	code?: string;
	loading?: boolean;
	error?: string;
	isAuthenticated?: boolean;
	showOffCanvas?: boolean;
	registration: object;
	onRegistration(data: IRegistration): IRegistration;
	onToggleOffCanvasVisibilityClicked?: (ev: React.MouseEvent<HTMLButtonElement>, hide?: boolean) => void;
	loadingSession: boolean;
	errorSession: string;
	onSignin(data: ISession): ISession;
}

interface IState {
	controls: any;
	checkboxes: any;
	showAddOriginal: boolean;
}

class RegistrationSchoolWizard extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			controls: {
				firstName: {
					name: 'firstName',
					value: '',
					placeholderId: 'landing.holden.firstName',
					label: 'landing.holden.firstName',
					type: 'text',
					styles: {
						margin: '0 0 15px 0',
					},
					visible: true,
					readOnly: false,
				},
				lastName: {
					name: 'lastName',
					value: '',
					placeholderId: 'landing.holden.lastName',
					label: 'landing.holden.lastName',
					type: 'text',
					styles: {
						margin: '0 0 15px 0',
					},
					visible: true,
					readOnly: false,
				},
				email: {
					name: 'email',
					value: '',
					placeholderId: 'landing.holden.email',
					label: 'landing.holden.email',
					type: 'email',
					styles: {
						margin: '0 0 15px 0',
					},
					visible: true,
					readOnly: false,
				},
				password: {
					name: 'password',
					value: '',
					placeholderId: 'placeholder.password',
					label: 'placeholder.password',
					type: 'password',
					styles: {
						margin: '0 0 15px 0',
					},
					visible: true,
					readOnly: false,
				},
			},
			checkboxes: {
				policy: {
					name: 'policy',
					labelId: 'landing.holden.rules',
					checked: false,
					type: 'toggle',
					styles: {
						margin: '0 0 10px 0',
					},
					link: '/regolamento-scuolaholden'
				},
				cookie: {
					name: 'cookie',
					labelId: 'landing.holden.privacy',
					checked: false,
					type: 'toggle',
					styles: {
						margin: '0 0 10px 0',
					},
					link: '/regolamento-scuolaholden'
				},
			},
			showAddOriginal: checkSession(),
		};
	}

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;

		let controls = {...this.state.controls};

		controls[inputName].value = inputValue;

		this.setState({
			controls
		});
	};

	handleCheckboxChanged = (ev: React.MouseEvent<HTMLButtonElement>) => {
		const buttonName = ev.currentTarget.getAttribute('data-name');

		if(buttonName){

			let checkboxes = {...this.state.checkboxes};

			checkboxes[buttonName].checked = !checkboxes[buttonName].checked;

			this.setState({
				checkboxes
			});
		}
	};

	submitHandler = () => {
		const { controls: { email, password, firstName, lastName } } = this.state;

		let data: IRegistration = {
			username: email.value,
			email: email.value,
			password: password.value,
			name: firstName.value,
			surname: lastName.value,
			role: 'school'
		};

		this.props.onRegistration(data);
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.registration !== prevProps.registration && this.props.registration){
			const { controls: { email, password } } = this.state;

			this.props.onSignin({ email: email.value, password: password.value})
		}

		if(this.props.isAuthenticated !== prevProps.isAuthenticated && this.props.isAuthenticated){
			this.setState({
				showAddOriginal: this.props.isAuthenticated
			})
		}
	}

	render () {
		const { controls, checkboxes, showAddOriginal } = this.state;

		const inputElementsTpl = Object.keys(controls).map((key: string) => {
			return (
				<Input
					label={controls[key].label}
					key={controls[key].name}
					styles={controls[key].styles}
					name={controls[key].name}
					value={controls[key].value}
					changed={this.handleInputChanged.bind(this)}
					placeholderId={controls[key].placeholderId}
					visible={controls[key].visible}
					readOnly={controls[key].readOnly}
					type={controls[key].type}/>
			)
		});

		const checkboxElementsTpl = Object.keys(checkboxes).map((key: string) => {
			return (
				<CheckboxSlider
					key={checkboxes[key].name}
					name={checkboxes[key].name}
					clicked={this.handleCheckboxChanged.bind(this)}
					labelId={checkboxes[key].labelId}
					checked={checkboxes[key].checked}
					link={checkboxes[key].link}
					styles={checkboxes[key].styles}
					simple={true}/>
			)
		});

		const { loadingSession, loading, error, errorSession } = this.props;

		return (
			<div className={classes['RegistrationSchoolWizard']}>
				<div className={classes['RegistrationSchoolWizard-section-content']}>
					<div className={classes['RegistrationSchoolWizard-indicator']}>
						{
							showAddOriginal && !errorSession && !error ? 2 : 1
						}
					</div>
					
					{
						showAddOriginal && !errorSession && !error ?
						<div className={classes['RegistrationSchoolWizard-left']}>
							<h4 className={classes['RegistrationSchoolWizard-title']}><FormattedHTMLMessage id="landing.holden.uploadOriginal.txt" /></h4>
							<br/>
							<h4 className={[classes['RegistrationSchoolWizard-title'], classes['RegistrationSchoolWizard-title--patamu']].join(' ')}>
								<FormattedMessage id="landing.holden.patamu.title" />
							</h4>
							<div className={[classes['RegistrationSchoolWizard-section-txt'], classes['RegistrationSchoolWizard-section-txt--small']].join(' ')}>
								<p><FormattedHTMLMessage id="landing.holden.patamu.txt" /></p>
							</div>
						</div>
						:
						<div className={classes['RegistrationSchoolWizard-left']}>
							<h4 className={classes['RegistrationSchoolWizard-title']}><FormattedMessage id="landing.holden.createAccount.title" /></h4>
							<div className={classes['RegistrationSchoolWizard-section-txt']}>
								<p><FormattedHTMLMessage id="landing.holden.createAccount.txt" /></p>
							</div>
						</div>
					}
					
					<div className={classes['RegistrationSchoolWizard-right']}>
						<Element name="registrationElement" id="registrationElement">
							{
								showAddOriginal && !errorSession && !error ?
									<NavLink
										className={classes['RegistrationSchoolWizard-Add']}
										to={NEW_ORIGINAL}>
										<h4 className={classes['MyOriginalsAdd-title']}>
											<FormattedMessage id={'label.addNewOriginal'} />
										</h4>
									</NavLink>
									:
									<div className={classes["RegistrationSchoolWizard-form"]}>
										{/* <RegistrationSchoolWizardHeader
											title="registration.form.title" /> */}
										<div className={classes["RegistrationSchoolWizard-content"]}>
											<div className={classes["RegistrationSchoolWizard-step"]}>
												{
													inputElementsTpl
												}
												{
													checkboxElementsTpl
												}
												{
													(loadingSession || loading) &&
													<div className={classes["RegistrationSchoolWizard-loading"]}>
														<Loader />
													</div>
												}
												{
													(error || errorSession) &&
													<div className={classes['RegistrationSchoolWizard-errors']}>
														<span>{ error || errorSession }</span>
													</div>
												}
												{
													(loading || loadingSession) &&
													<div className={classes['RegistrationSchoolWizard-feedback']}>
														{
															loading && <span>{ 'Registration...' }</span>
														}
														{
															loadingSession && <span>{ 'Signin...' }</span>
														}
													</div>
												}
											</div>
										</div>
										<div className={classes["RegistrationSchoolWizard-footer"]}>
											<div></div>
											<Button disabled={loadingSession || loading || !checkboxes.policy.checked || !checkboxes.cookie.checked || !controls.firstName.value || !controls.lastName.value || !controls.email.value || !controls.password.value} clicked={this.submitHandler} type="confirm">
												<FormattedMessage id={'navigation.signup'}/>
											</Button>
										</div>
									</div>
							}
						</Element>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		registration: state.registrationState.registration,
		loading: state.registrationState.loading,
		error: state.registrationState.error,
		loadingSession: state.sessionState.isFetching,
		errorSession: state.sessionState.error,
		isAuthenticated: state.sessionState.token !== null
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onRegistration: ( data: IRegistration ) => dispatch(actions.registrationForSchool( data )),
		onSignin: ( data: ISession ) => dispatch( actions.session( data ) ),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( RegistrationSchoolWizard );
