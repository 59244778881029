import React, {Component} from 'react';
import classes from './SelectMedia.module.scss';
import {FormattedMessage} from "react-intl";

interface IProps {
    label?: string;
    vertical: boolean;
    action?: (ev: React.MouseEvent<HTMLButtonElement>, type: string) => void;
}

class SelectMedia extends Component<IProps> {

    state = {
        types: [
            {
                name: "image",
                label: "label.types.image"
            },
            {
                name: "video",
                label: "label.types.media"
            },
            // {
            //     name: "embed",
            //     label: "label.types.embed"
            // },
            {
                name: "text",
                label: "label.types.text"
            }
        ]
    }

    render () {
        const { label, vertical, action } = this.props;
        const { types } = this.state;
        
        const typesTpl = types.map((item, index) => {
            return (
                action ?
                    (
                        <button
                            key={index}
                            className={[classes["SelectMedia-item"], classes[`SelectMedia-item--${item.name}`]].join(' ')}
                            onClick={(ev) => action(ev, item.name)}>
                            <FormattedMessage id={item.label} />
                        </button>
                    )
                : 
                    (
                        <div
                            key={index}
                            className={[classes["SelectMedia-item"], classes[`SelectMedia-item--${item.name}`]].join(' ')}>
                            <FormattedMessage id={item.label} />
                        </div>
                    )
            );
        })

			return (
				<div className={[classes["SelectMedia"], !vertical ? classes["SelectMedia-horizontal"] : ''].join(' ')}>
                    {
                        label &&
                        <div className={classes["SelectMedia-label"]}>
                            <FormattedMessage id={label} />
                        </div>
                    }
                    {
                        typesTpl
                    }
				</div>
			)
    }
};

export default SelectMedia;
