import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import classes from './Projects.module.scss';
import ProjectItem from './ProjectItem/ProjectItem';
import BoxLink from '../BoxLink/BoxLink';
import BackgroundBox from '../BackgroundBox/BackgroundBox';
import Placeholder from '../Placeholder/Placeholder';
import * as routes from '../../shared/routes';
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import { IMedia } from '../../shared/interfaces';
import constants from '../../shared/constants';

const scroll = Scroll.animateScroll;

interface IProps {
	list?: any;
	selected?: number;
	styles?: object;
	onEdit: (id: number, lastStep: string) => any;
	onDelete: (id: number) => any;
	addWorkNumber: number;
	readOnly: boolean;
}

interface IState {
	selected: number;
	settings: any;
	listHeight: number;
	listMargin: number;
	topPosition: number;
	scrollDown: boolean;
}

class Projects extends Component<IProps, IState> {
	constructor(props:IProps) {
		super(props);

		this.state = {
			selected: -1,
			settings: {
				dots: false,
				infinite: true,
				speed: 500,
				slidesToShow: 3,
				slidesToScroll: 1,
				swipeToSlide: true,
				focusOnSelect: true,
				centerMode: false,
				responsive: [
					{
					  breakpoint: 1023,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					  }
					},
					{
					  breakpoint: 600,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					  }
					},
					{
					  breakpoint: 480,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					  }
					}
				  ]
			},
			listHeight: 35,
			listMargin: 35,
			topPosition: 0,
			scrollDown: true,
		};

		this.handleChangeSelectedItem = this.handleChangeSelectedItem.bind(this);
		this.handleEditProject = this.handleEditProject.bind(this);
		this.handleDeleteProject = this.handleDeleteProject.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.listenToScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.listenToScroll);
	}

	handleChangeSelectedItem(index: number){
		this.setState({
			selected: index
		});

		scroll.scrollTo(150);
	}

	handleEditProject(id: number, lastStep: string){
		this.props.onEdit(id, lastStep);
	}

	handleDeleteProject(id: number){
		this.props.onDelete(id);
	}

	listenToScroll = () => {
		const winScroll =
		  document.body.scrollTop || document.documentElement.scrollTop;

		const height =
		  document.documentElement.scrollHeight -
		  document.documentElement.clientHeight

		const scrolled = winScroll / height;

		const scrollDown = this.state.topPosition < scrolled ? true : false;

		let listHeight = this.state.topPosition;
		let listMargin = this.state.topPosition;

		if (winScroll < 0) {
			listHeight = 35;
			listMargin = 35;
		} else if (winScroll > 160) {
			listHeight = 7;
			listMargin = 16.4;
		} else {
			listHeight = 35 - 7 * (winScroll / 35);
			listMargin = 35 - 7 * (winScroll / 35) + (winScroll / 15.5);
			if (listHeight > 35) {
				listHeight = 35;
				listMargin = 35;
			}
			if (listHeight < 7) {
				listHeight = 7;
				listMargin = 16.4;
			}
		}

		this.setState({
			topPosition: scrolled,
			scrollDown: scrollDown,
			listHeight: listHeight,
			listMargin: listMargin,
		});

		// console.log(winScroll, scrolled, scrollDown, listHeight, listMargin);
	};

	render () {
		const { list, addWorkNumber, readOnly } = this.props;
		const { selected, settings, listHeight, listMargin, scrollDown } = this.state;

		const selectedItemTpl = selected >= 0 && list[selected] ?
			<ProjectItem
				readOnly={readOnly}
				onDeleteClicked={this.handleDeleteProject}
				onEditClicked={this.handleEditProject}
				onCloseClicked={() => this.handleChangeSelectedItem(-1)}
				item={list[selected].project} /> : null;

		const projectsTpl = list.map((data: any, index: number) => {

			const cover = data.project.media && data.project.media.find((content: IMedia) => content.preview && content.type === 'image');

			const background = cover && cover.file.childrens.length ? cover.file.childrens[1].url : false;

			const isDraft = data.project.status === 'DRAFT';

			const projectType = data.project.category ? data.project.category.title : '';

			return (
				<div key={index} className={[classes['Project-list-item'], selected === index ? classes['Project-list-item--selected'] : '', isDraft ? classes['Project-list-item--draft'] : ''].join(' ')}>
					{
						background
						?
						<BackgroundBox
							clicked={() => this.handleChangeSelectedItem(index)}
							backgroundUrl={background}
							type={projectType}
							shadow={listHeight < 11 ? true : false}/>
						:
						<button onClick={() => this.handleChangeSelectedItem(index)} className={classes['Project-list-item-background']}>
							<Placeholder label={'label.projectItem.nocover'} symbol=":(" />
							{
								data.project.categoryId &&
								<div className={[classes['Project-list-item--type'], listHeight < 11 ? classes['Project-list-item--type-small'] : ''].join(' ')}>
									<span>{ projectType }</span>
								</div>
							}
						</button>
					}
				</div>
			)
		});

		const addWorkTpl = [];
		for (let i=0; i < addWorkNumber; i++) {
			addWorkTpl.push(
				<div key={i} className={classes['Project-list-item']}>
					<BoxLink to={routes.NEW_WORK} />
				</div>
			);
		}

		return (
			<div className={classes.Projects}>
				<div className={classes['Project-list-border']} />
				<div className={[classes['Projects-list'], classes['Projects-list--fixed'], listHeight < 11 ? classes['Projects-list--shadow'] : '', "project-list"].join(' ')} style={{height: `${listHeight*1.8}vh`}}>
					<Slider {...settings}>
						{ projectsTpl }
						{ !readOnly && addWorkTpl }
					</Slider>
				</div>
				<div className={classes['Project-list-spacer']} style={{height: `${listMargin*1.88}vh`, marginTop: '-2vw', marginBottom: '2.7vw', display: 'block'}} />
				<div className={classes['Projects-content']}>
					{ selectedItemTpl }
				</div>

				{ addWorkNumber <= 0 && !readOnly &&
					<div style={{textAlign: 'left', width: '100%', display: 'flex'}}>
						<NavLink to={routes.NEW_WORK} title="Add Project" className={classes['Project-list-addItem']}>
							<div className="addwork-step">
								<FormattedMessage id={'addWork.title'} />
							</div>
							<div className={classes['Project-list-addItem-icon']}>+
							</div>
						</NavLink>
					</div>
				}
			</div>
		);
	}
}

export default Projects;
