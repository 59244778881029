import * as actionTypes from './actionTypes';
import RegistrationsService from '../../services/api/registrations';
import { IError, IRegistration, IResponse } from '../../shared/interfaces';

// REGISTRATION
export const registrationStart = () => {
    return {
        type: actionTypes.REGISTRATION_START,
    }
};

export const registrationSuccess = (registration: IRegistration) => {
    return {
        type: actionTypes.REGISTRATION_SUCCESS,
        registration
    }
};

export const registrationFail = (error: string) => {
    return {
        type: actionTypes.REGISTRATION_FAIL,
        error,
    }
};

export const registration = (data: IRegistration) => {
    return (dispatch: any) => {
        dispatch(registrationStart());

        RegistrationsService
            .create(data)
            .then(
	            (response: IResponse) => {

                    dispatch(registrationSuccess(response.data));
                },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(registrationFail(errorMsg));
	            }
            );
    }
};

// ACTIVATION
export const activationStart = () => {
	return {
		type: actionTypes.ACTIVATION_START,
	}
};

export const activationSuccess = (newUser: object) => {
	return {
		type: actionTypes.ACTIVATION_SUCCESS,
		newUser
	}
};

export const activationFail = (error: string) => {
	return {
		type: actionTypes.ACTIVATION_FAIL,
		error,
	}
};

export const activation = (code: string) => {
	return (dispatch: any) => {
		dispatch(activationStart());

		RegistrationsService
			.activation(code)
			.then(
				(response: IResponse) => {

					dispatch(activationSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(activationFail(errorMsg));
				}
			);
	}
};

// REGISTRATION FOR SCHOOL
export const registrationForSchoolStart = () => {
	return {
		type: actionTypes.REGISTRATION_FOR_SCHOOL_START,
	}
};

export const registrationForSchoolSuccess = (registration: IRegistration) => {
	return {
		type: actionTypes.REGISTRATION_FOR_SCHOOL_SUCCESS,
		registration
	}
};

export const registrationForSchoolFail = (error: string) => {
	return {
		type: actionTypes.REGISTRATION_FOR_SCHOOL_FAIL,
		error,
	}
};

export const registrationForSchool = (data: IRegistration) => {
	return (dispatch: any) => {
		dispatch(registrationForSchoolStart());

		RegistrationsService
			.registrationForSchool(data)
			.then(
				(response: IResponse) => {

					dispatch(registrationForSchoolSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(registrationForSchoolFail(errorMsg));
				}
			);
	}
};
