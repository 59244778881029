import React, { Component } from 'react';
import classes from './LoggedUserMenu.module.scss';
import { FormattedMessage } from 'react-intl';
import { resetSession } from '../../../shared/helpers';

interface IProps {
	history?: any;
}

class LoggedUserMenu extends Component<IProps> {

	handleLogoutClicked = () => {
		resetSession();
		window.location.href = "/";
		// this.props.history.push('/');
	};

	handleProfileClicked = () => {
		this.props.history.push('/profile');
	};

	render () {

		return (
			<div className={classes['LoggedUserMenu']}>
				<div className={classes['LoggedUserMenu-item']} onClick={this.handleProfileClicked}>
					<FormattedMessage id="navigation.yourProfile" />
				</div>
				<div className={classes['LoggedUserMenu-item']} onClick={this.handleLogoutClicked}>
					<FormattedMessage id="navigation.logout" />
				</div>
			</div>
		)
	}
}

export default LoggedUserMenu;
