import React from 'react';
import classes from './Steps.module.scss';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';
import Step5 from './Step5/Step5';

interface stepsProps {
	step: number;
	setData: (field: string, value: any) => void;
	forceStatusButtons: (status: boolean) => void;
	data: any;
	editMode: boolean;
}

const steps = ({ step, setData, data, editMode, forceStatusButtons }: stepsProps) => {

	//console.log(questions);

	let contentTpl = null;

	switch (step) {
		case 1: contentTpl = data && (
			<Step1
				forceStatusButtons={forceStatusButtons}
				editMode={editMode}
				setData={setData}
				data={data} />
		);
			break;
		case 2: contentTpl = (
			<Step2 editMode={editMode} setData={setData} data={data} />
		);
			break;
		case 3: contentTpl = (
			<Step3 editMode={editMode} setData={setData} data={data} />
		);
			break;
		case 4: contentTpl = (
			<Step4 editMode={editMode} setData={setData} data={data} />
		);
			break;
		case 5: contentTpl = (
			<Step5 editMode={editMode} setData={setData} data={data} />
		);
			break;
		default: contentTpl = null;
	}

	return (
		<div className={classes.Steps}>
			<div className={classes['Steps-content']}>
				{
					contentTpl
				}
			</div>
		</div>
	)
};

export default steps;
