import React from 'react';
import classes from './MyOriginalsAdd.module.scss';
import { NavLink } from 'react-router-dom';
import { NEW_ORIGINAL } from '../../../shared/routes';
import { FormattedMessage } from 'react-intl';

const myOriginalsAdd = () => (
    <NavLink
        className={classes['MyOriginalsAdd']}
        to={NEW_ORIGINAL}>
	    <h4 className={classes['MyOriginalsAdd-title']}>
		    <FormattedMessage id={'label.addNew'} />
	    </h4>
    </NavLink>
);

export default myOriginalsAdd;
