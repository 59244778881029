import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { ISkill } from '../../shared/interfaces';

interface IAction {
    type: string;
    skill: ISkill;
    skills: Array<ISkill>;
    error: string;
}

const INITIAL_STATE = {
    skill: null,
	skills: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
};

const fetchSkillStart = (state = INITIAL_STATE) => {
	return updateObject(state, { skill: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchSkillSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { skill: action.skill, isFetching: false });
};

const fetchSkillFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchSkillsStart = (state = INITIAL_STATE) => {
	return updateObject(state, { skills: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchSkillsSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { skills: action.skills, isFetching: false });
};

const fetchSkillsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const skillReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // READ/FETCHING
	    case actionTypes.FETCH_SKILL_START: return fetchSkillStart(state);
	    case actionTypes.FETCH_SKILL_SUCCESS: return fetchSkillSuccess(state, action);
	    case actionTypes.FETCH_SKILL_FAIL: return fetchSkillFail(state, action);
	    case actionTypes.FETCH_SKILLS_START: return fetchSkillsStart(state);
	    case actionTypes.FETCH_SKILLS_SUCCESS: return fetchSkillsSuccess(state, action);
	    case actionTypes.FETCH_SKILLS_FAIL: return fetchSkillsFail(state, action);


	    default:
            return state;
    }
};

export default skillReducer;
