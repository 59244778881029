import React, {Component, Fragment} from 'react';
import classes from './Rules.module.scss';
import "./styles.scss";
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import {NavLink, Link} from "react-router-dom";
import * as ReactGA from '../../GA';


class Holden extends Component {

    componentDidMount() {
        ReactGA.pageview('/regolamento-scuolaholden');
    }

    render () {

        return (
            <div className={[classes.Rules, classes['Rules--holden']].join(' ')}>
                <div className={classes['Rules-content']}>
                    <h1>REGOLAMENTO</h1>
                    <h2>Art. 1 - CALL</h2>
                    <p>Artchivio Srl in collaborazione con Patamu.com lancia in esclusiva per Scuola Holden una Call per Soggetti, Sceneggiature e Format (di seguito Call), pubblicata sulla landing page club.artchivio.net/scuolaholden e indirizzata agli alumni della Scuola Holden.</p>

                    <p>La Call offre agli alumni della Scuola Holden l'opportunità di sviluppare un progetto originale insieme ad un team di professionisti del settore. I progetti e gli autori che superano i criteri di selezione (senza restrizioni nel numero), saranno opzionati da Elsinore Film ed Artchivio e verranno seguiti nello sviluppo da Annamaria Morelli (produttrice per Elsinore Film), Daniele Orazi (agente per Officine Artistiche e Do.), Jacopo Bistacchia (PR e comunicazione) e Fulvio Risuleo (regista e sceneggiatore).  </p>

                    <p>La Call è a tema libero ed è rivolta a Soggetti, Sceneggiature e Concept pensati per i seguenti prodotti (in ordine di preferenza):</p>
                    <ul>
                        <li>Lungometraggi: fiction, documentari e animazione</li>
                        <li>Serie Tv</li>
                        <li>Cortometraggi</li>
                        <li>Interactive & New Media</li>
                    </ul>

                    <h2>Art. 2 - PARTECIPANTI</h2>
                    <p>La call è rivolta a giovani autori, scrittori e registi maggiorenni e diplomati alla Scuola Holden. Il link <a href="club.artchivio.net/scuolaholden" target="_blank" rel="noopener noreferrer">club.artchivio.net/scuolaholden</a>, necessario per l'iscrizione alla Call sarà distribuito solo ed esclusivamente agli alumni della Scuola Holden tramite la segreteria della scuola</p>

                    <h2>Art. 3 - PARTECIPARE</h2>
                    <p>Per partecipare è necessario registrarsi su club.artchivio.net e inserire la propria idea nella sezione My Originals del profilo, seguendo le indicazioni del form.</p>

                    <p>Sarà necessario inserire il tag ScuolaHolden nell'ultimo step del caricamento prima di pubblicare il progetto, per essere identificati come partecipanti alla call.</p>

                    <p>Per completare il processo di applicazione alla call, dopo la pubblicazione, verrà offerta l'opportunità di proteggere il progetto marcandolo temporalmente e gratuitamente su Patamu.com. La marcatura Patamu ha validità legale senza vincoli di esclusiva sulle opere depositate: gli autori sono liberi di utilizzare il servizio anche se sono iscritti ad una collecting tradizionale come la SIAE.</p>

                    <p>Ogni partecipante ha la possibilità di inviare e marcare una sola proposta.</p>

                    <p>La Call è aperta a idee riconducibili a uno o più autori, a condizione che almeno uno degli autori rientri nei criteri di selezione all'Articolo 2.</p>

                    <p>Una volta pubblicato il progetto per la Call, il tuo account club.artchivio.net sarà disponibile e potrai usare il servizio di Artchivio e caricare altri Originals in qualsiasi momento.</p>

                    <h2>Art.4 - SCADENZA</h2>
                    <p>La scadenza della Call è prevista per il 20 febbraio 2020 alle ore 23:59. Le proposte inviate successivamente a questa scadenza potrebbero non saranno prese considerazione, fatto salvo deroghe eccezionali a piena discrezione di Artchivio Srl.</p>

                    <h2>Art.5 - SELEZIONE</h2>
                    <p>Una Giuria presieduta dal team editoriale di Artchivio S.r.l. esaminerà le proposte pervenute e, a proprio insindacabile giudizio, si riserva la possibilità di selezionare i progetti più validi per inserirli in un percorso di sviluppo, packaging e fundraising.</p>

                    <p>I progetti verranno selezionati in base ai seguenti criteri:</p>
                    <ul>
                        <li>Qualità e originalità dell'idea e dei materiali inviati.</li>
                        <li>Potenziale di realizzazione e distribuzione dell'opera.</li>
                    </ul>

                    <p>E verrà inoltre analizzata l'originalità,  la coerenza e l'approfondimento delle seguenti aree:</p>
                    <ul>
                        <li>Struttura narrativa</li>
                        <li>Personaggi</li>
                        <li>Tono e Stile</li>
                    </ul>

                    <p>La Giuria ha facoltà di non selezionare e/o selezionare più progetti per il percorso di sviluppo, packaging e fundraising.</p>

                    <h2>Art. 6 - PROGETTI SELEZIONATI</h2>
                    <p>I partecipanti che saranno selezionati per il percorso di sviluppo verranno contattati da Artchivio Srl personalmente, tramite i dati forniti nel form, a partire da 30 giorni dallo scadere della Call.</p>

                    <p>I progetti che meglio rispondono ai criteri di selezione verranno opzionati da Elsinore Film ed Artchivio Srl con un contratto che verrà stipulato in buona fede tra le parti successivamente alla selezione del progetto. Inoltre i progetti selezionati e opzionati accederanno a un percorso di editing, packaging e fundraising seguito dal team di Artchivio e supportato da Annamaria Morelli (produttrice per Elsinore Film), Daniele Orazi (agente per Officine Artistiche e Do.), Jacopo Bistacchia (PR e comunicazione) e Fulvio Risuleo (regista e sceneggiatore).</p>

                    <h2>Art.7 - ORIGINALITÀ DEI MATERIALI INVIATI</h2>
                    <p>Sono ammesse alla Call solo idee originali. Il partecipante sottoscrivendo al regolamento dichiara di essere in possesso della proprietà intellettuale dell'opera e dei materiali inviati e solleva l'organizzazione da qualsiasi controversia legata ad eventuali conflitti sui diritti patrimoniali dell'opera. Il partecipante, inoltre, dichiara di essere autore dei contenuti forniti (soggetto, trattamento, sceneggiatura, dossier... ) e che tali contenuti siano originali e inediti.</p>

                    <h2>Art.8 - DISPOSIZIONI GENERALI</h2>
                    <p>L'invio telematico del form di partecipazione implica l'accettazione integrale del regolamento. Per quanto non previsto dal regolamento, il team di selezione di Artchivio S.r.l. si riserva la decisione finale.</p>

                    <p>I materiali inviati per la selezione non saranno resi pubblici o condivisi in alcun modo senza il consenso dei rispettivi autori.</p>

                    <p>Ulteriori informazioni riguardo alla call o allo sviluppo del progetto possono essere richieste scrivendo al seguente indirizzo email: <a href="mailto:info@artchivio.net">info@artchivio.net</a>.</p>


                    <h1 id="privacy">PRIVACY</h1>

                    <h2>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</h2>
                    <h3>Art. 13 Regolamento (UE) 2016/679 (“GDPR”)</h3>
                    <p>La società Artchivio Srl è impegnata nella tutela e nella salvaguardia dei dati personali dei nostri clienti e degli utenti, dati acquisti sul sito web https://club.artchivio.net. Il trattamento dei tuoi dati personali è ispirato ai principi di liceità, correttezza e trasparenza e viene svolto secondo i termini e le condizioni della presente Informativa che ti invitiamo quindi a leggere con particolare attenzione. Essa contiene informazioni rilevanti su perché e su come trattiamo i tuoi dati personali ed elenca i tuoi diritti relativamente a detti trattamenti.</p>

                    <p>Ti informiamo inoltre che la presente Informativa si applica esclusivamente alle attività presenti sul nostro Sito e non si applica ai dati personali raccolti tramite link di collegamento a siti esterni. Ti invitiamo a consultare la loro Informativa per sapere come verranno trattati i tuoi dati.</p>

                    <h2>TITOLARE e RESPONSABILE DEL TRATTAMENTO</h2>
                    <p>La società Artchivio Srl, con sede 00186 Roma, Via dei barbieri, 5, P.IVA 14817111009 – è il Titolare del trattamento dei dati personali (nel seguito: il Titolare). Il trattamento dei dati personali potrà avvenire anche da parte di soggetti terzi per conto del Titolari e in tal caso il Titolare provvederà alla loro nomina quali Responsabili ed a definire per iscritto i loro compiti e i limiti del trattamento. Per qualsiasi richiesta o informazione connessa alla presente Informativa puoi rivolgerti all'indirizzo: <a href="mailto:info@artchivio.net">info@artchivio.net</a>.</p>

                    <h2>TIPOLOGIA DI DATI RACCOLTI e BASE GIURIDICA DEL TRATTAMENTO</h2>

                    <p>Il nostro Sito web raccoglie diverse categorie di dati personali:</p>
                    <p>I c.d. dati di navigazione (indirizzo IP; tipo di browser e parametri del dispositivo usato per connettersi al sito; nome dell'internet service provider (ISP); data e orario di visita; pagina web di provenienza del visitatore (referral) e di uscita). Questi dati vengono raccolti ed utilizzati in forma anonima e aggregata per fini statistici, per permettere il corretto funzionamento del sito e per motivi di sicurezza. Il trattamento di questa tipologia di dati è quindi consentita in base ad un legittimo interesse del Titolare e non necessita del tuo consenso.</p>
                    <p>Sul Sito sono presenti alcune funzionalità per comunicare con noi, per sottoporci il tuo curriculum a fini lavorativi e per usufruire dei nostri servizi. Per sfruttare queste funzionalità, Ti chiederemo di fornirci i tuoi dati di contatto (nome, cognome e indirizzo e-mail) e potremo richiedere anche i tuoi dati fiscali (anagrafica, codice fiscale, partita iva). Questi dati sono trattati per il fine specifico per il quale sono stati raccolti (rispondere alle richieste, marketing, selezione del personale, emissione fatture fiscali e gestione pagamenti) con strumenti manuali e/o informatici e telematici in modo da garantirne la sicurezza, l'integrità e la riservatezza nel rispetto delle disposizioni vigenti.</p>
                    <p>Il conferimento di tutti i dati fin qui elencati è facoltativo ed il loro trattamento è fondato sul tuo consenso. Il tuo rifiuto a fornire i dati richiesti non comporterà conseguenze neanche per la navigazione sul nostro Sito ma non ci permetterà di fornirti il servizio richiesto.</p>

                    <p>Ti informiamo inoltre che potrai scegliere in autonomia a quali cookie prestare il tuo consenso selezionando l'opzione di tuo gradimento sul tuo browser dalle impostazioni di servizio, raggiungibili ai seguenti link:</p>
                    <ul>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href="https://support.apple.com/it-it/HT201265">Safari</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/chrome/answer/95647?hl=it">Google Chrome</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">Mozilla Firefox</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a>
                        </li>
                    </ul>

                    <h2>DESTINATARI DEI DATI</h2>
                    <p>I dati personali raccolti sul Sito sono destinati agli organi di amministrazione del Titolare, al personale amministrativo, commerciale, a quello addetto alla comunicazione ed agli amministratori di sistema dei Titolari, ed in generale a tutti i responsabili, preposti ed incaricati del trattamento dei dati nominati dai Titolari nell'esercizio delle proprie funzioni. I dati potranno inoltre essere comunicati a soggetti terzi che forniscono servizi esterni di gestione del Sito, di ricerche di mercato, fornitori di servizi tecnici, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione e che svolgono attività di profilazione.</p>

                    <p>I tuoi dati potranno essere  eventualmente  trasferiti all'estero,  in  conformità a  quanto  previsto dalla  normativa  vigente, anche in Paesi non appartenenti all'Unione Europea. Il trasferimento in Paesi Extra UE, oltre ai casi in cui questo è garantito da decisioni di adeguatezza della Commissione Europea, è effettuato in modo da fornire garanzie appropriate e opportune ai sensi del GDPR.</p>

                    <p>Ti Informiamo infine che i dati raccolti non saranno mai diffusi e non saranno oggetto di comunicazione al pubblico senza il tuo esplicito consenso, salvo le comunicazioni necessarie che possono comportare il trasferimento di dati ad enti pubblici, a consulenti o ad altri soggetti per l'adempimento degli obblighi di legge.</p> 

                    <h2>PERIODO DI CONSERVAZIONE DEI DATI PERSONALI</h2>
                    <p>I dati personali raccolti verranno conservati in una forma che consenta l'identificazione degli interessati per un arco di tempo non superiore al conseguimento delle finalità per le quali i dati personali sono trattati. I dati di contatto per finalità di marketing saranno conservati sino alla tua comunicazione di cancellazione dal servizio. I dati relativi alle transazioni commerciali verranno conservati per il tempo previsto dalla normativa sulla conservazione delle scritture contabili. Puoi richiedere ulteriori informazioni sul periodo di conservazione dei tuoi dati e sui principi generali che applichiamo puoi contattare: <a href="mailto:info@artchivio.net">info@artchivio.net</a>.</p>

                    <h2>DIRITTI DELL'INTERESSATO</h2>
                    <p>Ti informiamo che ti sono riconosciuti i seguenti diritti:</p>
                    <ul>
                        <li>diritto di chiedere ai Contitolari l'accesso ai tuoi dati personali e la loro rettifica o la cancellazione degli stessi o la limitazione del trattamento o di opporti al loro trattamento,</li>
                        <li>il diritto alla portabilità dei dati;</li>
                        <li>il diritto di proporre reclamo all'autorità di controllo (Garante per la Protezione dei dati personali).</li>
                        <li>È inoltre tuo diritto di ottenere dal Titolare la conferma che sia o meno in corso un trattamento di dati e di ottenere le seguenti informazioni: le finalità, le categorie di dati trattati, i destinatari o le categorie di destinatari e tutte le informazioni sulla loro origine.</li>
                    </ul>

                    <p>Per tutte le informazioni necessarie e per esercitare i tuoi diritti puoi contattare l'indirizzo: <a href="mailto:info@artchivio.net">info@artchivio.net</a>.</p>

                    <p>La presente Informativa sul trattamento dei dati personali è stata redatta in data 29 novembre 2019 e potrà subire integrazioni o variazioni in funzione di eventuali modifiche normative nazionali o internazionali applicabili al Sito.</p>
                </div>
            </div>
        )
    }
};

export default Holden;
