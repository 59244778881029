import React from 'react';
import ReactGA from 'react-ga';
import { IntlProvider, addLocaleData } from 'react-intl';
import {BrowserRouter} from "react-router-dom";
import './App.scss';

import itLocaleData from 'react-intl/locale-data/it';
import enLocaleData from 'react-intl/locale-data/en';

import itMessages from './translations/it.json';
import enMessages from './translations/en.json';
import RouterWrapper from './containers/RouterWrapper/RouterWrapper';

ReactGA.initialize('UA-134287583-2');

addLocaleData(itLocaleData);
addLocaleData(enLocaleData);

const messages: any = {
    en: enMessages,
    it: itMessages,
};

let language = navigator.language.toLowerCase().split(/[_-]+/)[0];
if(!messages[language]){
    language = Object.keys(messages)[0];
}

// Test for english (remove comment on language)
//language = 'en';

const app = () => (
	<IntlProvider defaultLocale="it" locale={language} messages={messages[language] || messages['it']}>
		<BrowserRouter>
			<RouterWrapper />
		</BrowserRouter>
	</IntlProvider>
);

export default app;
