import React, { Component } from 'react';
import classes from './Backdrop.module.scss';

interface IProps {
    children?: any;
    show: boolean;
}

class Backdrop extends Component<IProps> {

	componentDidMount() {
		document.body.style.overflow = 'hidden';
	}
	componentWillUnmount() {
		document.body.style.overflow = 'auto';
	}

	render () {

		const { children, show } = this.props;

		return (
			show ? (
				<div className={classes.Backdrop}>
					{
						children
					}
				</div>
			) : null
		);
	}
};

export default Backdrop;
