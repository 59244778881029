import React, { Component } from 'react';
import classes from './OffCanvasSidebar.module.scss';

interface IProps {
	children: any;
    onOffCanvasSidebarCloseClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    transparent?: boolean;
}

class OffCanvasSidebar extends Component<IProps> {
    private offCanvasSidebar!: HTMLDivElement;

    handleClickedOutsideOffCanvasSidebar = (e: any) => {
		if (this.offCanvasSidebar && !this.offCanvasSidebar.contains(e.target)) {
			this.props.onOffCanvasSidebarCloseClicked(e);
		}
    };

    componentWillMount(){
        document.addEventListener('click', this.handleClickedOutsideOffCanvasSidebar, false);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.handleClickedOutsideOffCanvasSidebar, false);
    }

    render () {

        const { children, transparent } = this.props;

        return (
            <div
                className={[classes['OffCanvasSidebar'], transparent ? classes['OffCanvasSidebar--transparent'] : ''].join(' ')}
                ref={(node: any) => { this.offCanvasSidebar = node; }}>
				<div className={classes["OffCanvasSidebar-content"]}>
					{ children }
				</div>
			</div>
        )
    }
}

export default OffCanvasSidebar;
