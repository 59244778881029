import React, {Fragment} from 'react';
import classes from './Input.module.scss';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

interface inputProps {
	label?: string;
    type: string;
    name: string;
    placeholderId?: string;
    readOnly?: boolean;
    visible?: boolean;
    value?: string;
    disabled?: boolean;
    clicked?: (ev: React.MouseEvent<HTMLInputElement>) => void;
    changed: (ev: React.FormEvent<HTMLInputElement>) => void;
    keyPressed?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
    styles?: object;
    capitalize?: boolean;
    error?: boolean;
    tabIndex?: number;
    valid?: boolean;
	button?: any;
	required?: boolean;
	tooltip?: string | object;
}

const input = ({ label, type, name, button, placeholderId, value, disabled, clicked, changed, styles, capitalize, error, valid, readOnly, visible, keyPressed, required, tooltip }: inputProps) => {

	return (
		<React.Fragment>
			{ label && visible &&
				<label
					className={classes["Input-label"]}
					htmlFor={name}
					data-tip
					data-for={`${required || tooltip ? name : ''}`}>
					<FormattedMessage id={label}/>
						{ required &&
							<Fragment>
								<span className={classes['Input-required']}>*</span>
								<ReactTooltip id={name} effect="solid">Required</ReactTooltip>
							</Fragment>
						}
						{ tooltip &&
							<Fragment>
								<span className={classes['Input-info']} />
								<ReactTooltip id={name} effect="solid">{ tooltip }</ReactTooltip>
							</Fragment>
						}
				</label>
			}
			<FormattedMessage id={placeholderId || 'general.emptyMessage'} defaultMessage={''}>
				{
					(placeholder: any) => visible ?
						<input
							placeholder={placeholder}
							style={styles}
							name={name}
							className={[classes.Input, classes[`Input--${name}`], disabled ? classes["is-disabled"] : '', capitalize ? classes["Input--capitalize"] : '', error ? classes["is-invalid"] : '', valid ? classes["is-valid"] : '', required ? classes['is-required'] : ''].join(' ')}
							disabled={disabled}
							defaultValue={value}
							type={type}
							onChange={changed}
							readOnly={readOnly}
							autoComplete="off"
							onKeyDown={keyPressed}
							onClick={clicked}/> : null
				}
			</FormattedMessage>
			{ button }
		</React.Fragment>
	)
};

export default input;
