import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IMedia } from '../../shared/interfaces';

interface IAction {
    type: string;
    list: Array<IMedia>;
    media: IMedia;
    mediaUpdated: IMedia;
    error: string;
    removed: boolean;
}

const INITIAL_STATE = {
    list: [],
    media: null,
	mediaUpdated: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
};

const resetMedia = (state = INITIAL_STATE) => {
	return updateObject(state, { media: null, /*mediaUpdated: null,*/ });
};

const createMediaStart = (state = INITIAL_STATE) => {
	return updateObject(state, { media: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createMediaSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { media: action.media, isStoring: false });
};

const createMediaFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchMediaListStart = (state = INITIAL_STATE) => {
    return updateObject(state, { list: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchMediaListSuccess = (state = INITIAL_STATE, action: IAction) => {

    return updateObject(state, { list: action.list, isFetching: false });
};

const fetchMediaListFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchMediaStart = (state = INITIAL_STATE) => {
	return updateObject(state, { media: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchMediaSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { media: action.media, isFetching: false });
};

const fetchMediaFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateMediaStart = (state = INITIAL_STATE) => {
    return updateObject(state, { mediaUpdated: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const updateMediaSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { mediaUpdated: action.mediaUpdated, isStoring: false });
};

const updateMediaFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const deleteMediaStart = (state = INITIAL_STATE) => {
    return updateObject(state, { isStoring: true, removed: null, error: null, didInvalidate: false });
};

const deleteMediaSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { removed: action.removed, isStoring: false });
};

const deleteMediaFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, removed: false, didInvalidate: true, isStoring: false });
};

const mediaReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_MEDIA_START: return createMediaStart(state);
	    case actionTypes.CREATE_MEDIA_SUCCESS: return createMediaSuccess(state, action);
	    case actionTypes.CREATE_MEDIA_FAIL: return createMediaFail(state, action);
        // READ/FETCHING
        case actionTypes.FETCH_MEDIA_LIST_START: return fetchMediaListStart(state);
        case actionTypes.FETCH_MEDIA_LIST_SUCCESS: return fetchMediaListSuccess(state, action);
        case actionTypes.FETCH_MEDIA_LIST_FAIL: return fetchMediaListFail(state, action);
	    case actionTypes.FETCH_MEDIA_START: return fetchMediaStart(state);
	    case actionTypes.FETCH_MEDIA_SUCCESS: return fetchMediaSuccess(state, action);
	    case actionTypes.FETCH_MEDIA_FAIL: return fetchMediaFail(state, action);
        // UPDATE
        case actionTypes.UPDATE_MEDIA_START: return updateMediaStart(state);
        case actionTypes.UPDATE_MEDIA_SUCCESS: return updateMediaSuccess(state, action);
        case actionTypes.UPDATE_MEDIA_FAIL: return updateMediaFail(state, action);
        // DELETE
        case actionTypes.DELETE_MEDIA_START: return deleteMediaStart(state);
        case actionTypes.DELETE_MEDIA_SUCCESS: return deleteMediaSuccess(state, action);
        case actionTypes.DELETE_MEDIA_FAIL: return deleteMediaFail(state, action);

	    case actionTypes.RESET_MEDIA: return resetMedia(state);

        default:
            return state;
    }
};

export default mediaReducer;
