import React, {Component} from 'react';
import classes from './Privacy.module.scss';

interface IState {
	// titleId: string;
}

class Privacy extends Component<IState> {

    render () {
    	// const { titleId, descriptionId } = this.state;

			return (
				<div className={classes.Privacy}>
                    <div className={classes['Privacy-content']}>
						<h1>PRIVACY</h1>
						<h2>1. PREMISE</h2>
						<p>This section contains information on how to <a href="https://artchivio.net" target="_blank">artchivio.net</a> manages information relating to the processing of data from users on <a href="https://artchivio.net" target="_blank">artchivio.net</a>.</p>
						<p>This information is also valid for the purposes of the Code regarding the protection of personal data (Article 13 of Legislative Decree No. 196/2003) for individuals who interact with <a href="https://artchivio.net" target="_blank">artchivio.net</a> and can be reached at the address corresponding to the initial page:<br/><br/>
						<a href="https://artchivio.net" target="_blank">artchivio.net</a><br/><br/>
						The information is provided only for <a href="https://artchivio.net" target="_blank">artchivio.net</a> and not for other websites that may be consulted by the user through links contained therein.<br/>
						The purpose of this document is to provide information on the methods, timing and nature of the information that the data controllers must provide to users when connecting to the <a href="https://artchivio.net" target="_blank">artchivio.net</a> web pages, regardless of the purpose of the connection, according to the Recommendation n . 2/2001 that the European authorities for the protection of personal data, gathered by art. 29 of the directive n. 95/46 / EC, adopted on 17 May 2001 for the collection of personal data online.
						</p>

						<h2>2. DATA PROCESSING</h2>

						<h3>PROPRIETOR</h3>
						<p>During the use of <a href="https://artchivio.net" target="_blank">artchivio.net</a> the data related to identified or identifiable persons can be processed to provide services or improve the latter for basic services such as the identification of the user language as for more complex services.</p>
						<p>The data controller is Artchivio S.r.l. based in:<br/><br/>
						Via delle Mantellate 29a 00165 Roma</p>
						
						<h3>LOCATION</h3>
						<p>The processing of data generated by the use of <a href="https://artchivio.net" target="_blank">artchivio.net</a> takes place at the headquarters of the data controller with the intervention of the persons in charge. In case of necessity, the data connected to the newsletter services can be processed by the person in charge of the treatment or subjects appointed by it for this purpose at the relevant office.</p>

						<h3>COOKIES</h3>
						<p>Cookies are data sets that are sent by <a href="https://artchivio.net" target="_blank">artchivio.net</a> to the user's internet browser and are stored and then transmitted back to the site at the next visit.<br/>
						They can be used to monitor sessions, to authenticate a user so that they can access a site without typing their name and password each time and to memorize their preferences.<br/>
						There are many types of cookies classifiable by technical aspects, duration, origin and function.</p>
						
						<h3>TECHNICAL COOKIES</h3>
						<p>There are different technologies that are used to store information in the user's computer, which are then collected by the sites. Among these the most known and used are HTML cookies.<br/>
						They are used for navigation and to facilitate access and use of the site by the user. They are necessary for the transmission of communications and for the supplier to provide the service requested by the customer.
						The settings used to manage or disable cookies may vary depending on the internet browser used. In any case, the user can manage or request the general deactivation or cancellation of cookies, modifying the settings of his internet browser. This deactivation can slow down or prevent access to some parts of the website.<br/>
						The use of technical cookies allows the safe and efficient use of the website.<br/>
						The cookies that are inserted in the browser and retransmitted by Google Analytics or the Blogger Stats service or similar are technical only if used for the purposes of site optimization directly by the owner of the site, which can collect information in aggregate form on the number of users and how they visit the site. Under these conditions, the same rules apply to analytics cookies, in terms of information and consent, provided for technical cookies.<br/>
						They can be used to monitor sessions, to authenticate a user so that they can access a site without typing their name and password each time and to memorize their preferences.</p>

						<h3>DURATION COOKIES</h3>
						<p>From the point of view of duration we can distinguish temporary session cookies that are deleted automatically at the end of the browsing session and are used to identify the user and thus avoid logging on every page visited and the permanent ones that remain active in the PC until expiry or cancellation by the user.<br/><br/>
						Session cookies may be installed in order to allow optimal use of the site.<br/>
						They are not stored permanently but only for the duration of the navigation until the browser is closed and disappear when the browser is closed. Their use is strictly limited to the transmission of session identifiers consisting of random numbers generated by the server necessary to allow the safe and efficient exploration of the site.</p>
						
						<h3>NAVIGATION DATA</h3>
						<p>The computer systems and software procedures used to operate this website acquire, during their normal operation, some personal data whose transmission is implicit in the use of Internet communication protocols.<br/>
						This is information that is not collected to be associated with identified interested parties, but which by their very nature could, through processing and association with data held by third parties, allow users to be identified.
						This category of data includes IP addresses or domain names of the computers used by users who connect to the site, the addresses in URI notation of the requested resources, the time of the request, the method used in submitting the request to the server, the file size obtained in response, the numerical code indicating the status of the response given by the server and other parameters related to the operating system and the user's computer environment.<br/>
						These data are used for the sole purpose of obtaining anonymous statistical information on the use of the site and to check its correct functioning and are deleted immediately after being treated.<br/>
						The data could also be used to ascertain responsibility in case of hypothetical IT crimes to the detriment of the site: apart from this possibility, the data on web contacts do not persist for more than seven days.</p>
						
						<h3>DATA PROVIDED BY THE USER</h3>
						<p>The optional, explicit and voluntary sending of e-mails to the addresses indicated on this site entails the subsequent acquisition of the sender's address, necessary to respond to requests, as well as any other personal data included in the message.<br/>
						Specific summary information will be progressively reported or displayed on the pages of the site and prepared for particular services on request.</p>
						
						<h3>YOUR RIGHTS</h3>
						<p>Below, we provide you with a summary of your rights. You can exercise them at any time by sending an e-mail to <a href="mailto:info@artchivio.net">info@artchivio.net</a></p>
						<p><strong>The right to be informed.</strong><br/>
						You have the right to receive clear, transparent and easily understandable information regarding the use of your personal data and your rights. Furthermore, we inform you of further information in this Notice.</p>
						<p><strong>The right of access and rectification.</strong>
						You have the right to access, correct or update your personal data at any time. We understand the importance of this aspect and in case you want to exercise this right, please contact us.</p>
						<p><strong>The right to data portability.</strong>
						The personal data you have provided us is portable. This implies that they can be moved, copied or transmitted electronically based on certain requirements.</p>
						<p><strong>The right to be forgotten.</strong>
						Under certain circumstances, you have the right to request that you delete your data. If you wish to delete your personal data in our possession, you can inform us and we will undertake to follow all the procedures to respond to your request by virtue of the legal requirements. If the collected personal data are no longer necessary and we are not legally obliged to keep them in our possession, we will do our best to delete them, destroy them or make them permanently anonymous.</p>
						<p><strong>The right to limit processing.</strong>
						In some cases, you have the right to limit the processing of your personal data.</p>
						<p><strong>The right to object</strong>
						In some cases, you have the right to object to certain processing, including processing rights for direct marketing (for example, to receive e-mails in which we are communicating possible opportunities).</p>
						<p><strong>The right to lodge a complaint with a supervisory authority.</strong>
						You have the right to file a complaint directly with a supervisory authority for the processing of your personal data.</p>
						<p><strong>The right to withdraw consent.</strong>
						If you have consented to the processing of your personal data (we request your consent for legal reasons for the processing of your personal data), you have the right to revoke it at any time (even if you do so, it does not imply that the personal data first shared is uneven). You may withdraw your consent to the processing of your personal data at any time by contacting us and providing us with the information below.</p>

						<h3>Rights related to automated decision making.</h3>
						<p>You have the right not to be subject to a decision based solely on the automated process and having legal effects or other significant effects for you.<br/><br/>
						In particular, you have the right to:</p>
						<p><strong>Get a human intervention;</strong></p>
						<p><strong>Express your point of view;</strong></p>
						<p><strong>Get an explanation of the decision taken after an agreement;</strong></p>
						<p><strong>Dispute the aforementioned decision.</strong></p>
						<p>Further information and advice on your rights can be obtained from the data protection supervisor in your country.</p>

						<h2>3. FREEDOM TO SHARE</h2>
						<p>The user is always free to provide the personal data required for sending informative material, exception made when the navigation of the website requires it.<br/>
						Failure to provide personal data, on the other hand, may make it impossible to obtain what has been originally requested.</p>

						<h2>4. DATA PROCESSING </h2>
						<p>Personal data are processed directly by Artchivio S.r.l. or by its representatives, with automated or manual tools for the time strictly necessary to achieve the purposes for which they were collected.<br/>
						Specific security measures are observed to prevent data loss, illicit or incorrect use and unauthorized access.</p>
						
						<h2>5. RIGHTS OF THOSE INTERESTED</h2>
						<p>The information about the content, origin, accuracy, integration, updating or correction of data is accessible to the owner of the same as their existence in the archives of <a href="https://artchivio.net" target="_blank">artchivio.net</a> which may be verified by request. Likewise, you have the right to request cancellation, transformation into anonymous form or blocking of data processed in violation of the law, and to oppose in any case, for legitimate reasons, to their treatment.<br/><br/>
						Requests should be addressed:</p>
						<p>via e-mail: <a href="mailto:info@artchivio.net">info@artchivio.net</a></p>
						<p>by mail: via delle Mantellate 29a</p>

						<h2>6. DATA SECURITY</h2>
						<p>Data security is guaranteed during the use of the platform by the compliance of the procedures adopted by <a href="https://artchivio.net" target="_blank">artchivio.net</a> with the Privacy Code (Legislative Decree 196/2003) with particular regard to the personal data of the user.</p>

						<h2>7. CHANGES TO THE PRIVACY POLICY</h2>
						<p>This document, published on: <a href="https://artchivio.net/privacy" target="_blank">artchivio.net/privacy</a>
						constitutes the privacy policy of this site. It may be subject to changes or updates. We invite you to consult it to be aware of any updates and / or changes.<br/><br/>
						The document is in compliance with the relevant regulations.</p>
                    </div>
				</div>
			)
    }
};

export default Privacy;
