import React, { Component } from 'react';
import classes from './ChecksRows.module.scss';
import { ICheckRow } from '../../shared/interfaces';

interface IProps {
	label: any;
	options: Array<ICheckRow>;
	clicked: (value: string) => void;
	handleChecksRowsClicked?: (value: string) => void;
	disabled?: boolean;
}

class ChecksRows extends Component<IProps> {
	constructor(props: IProps) {
		super(props);

		this.handleChecksRowsClicked = this.handleChecksRowsClicked.bind(this);
	}

	handleChecksRowsClicked = (value: string) => {
		this.props.clicked(value);
	};

	render() {
		const { label, options,  disabled }: IProps = this.props;

		const optionsTpl = options.map((option: ICheckRow) => {
			const { value, label, selected } = option;

			const optionClass = [
				classes['ChecksRows-option'],
				selected ? classes['is-active'] : ''
			].join(' ');

			return (
				<button
					key={value}
					onClick={() => this.handleChecksRowsClicked(value)}
					className={optionClass}>
					<span className={classes['ChecksRows-description']}>{label}</span>
					<div className={classes['ChecksRows-round']}>{ /* Mike! put flag here! */}</div>
				</button>
			);
		});

		return (
			<div className={classes.ChecksRows}>
				{label && (
					<label className={classes['ChecksRows-label']}>{label}</label>
				)}
				<div className={classes['ChecksRows-content']}>{optionsTpl}</div>
			</div>
		);
	}
}

export default ChecksRows;
