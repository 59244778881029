import React, {Component} from 'react';
import * as Scroll from 'react-scroll';
import * as routes from '../../shared/routes';
import classes from './RegistrationForSchool.module.scss';
import * as ReactGA from '../../GA';
import { History } from 'history';
import { checkSession } from '../../shared/helpers';
import RegistrationSchoolWizard from '../RegistrationSchoolWizard/RegistrationSchoolWizard';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Button from '../Button/Button';
import Slider from "react-slick";
import darioAlbertini from '../../assets/img/dario-albertini.png';
import fulvioRisuleo from '../../assets/img/fulvio-risuleo.png';
import annamariaMorelli from '../../assets/img/annamaria-morelli.png';
import ludovicoBessegato from '../../assets/img/ludovico-bessegato.png';
import phaymBhuiyan from '../../assets/img/phaym-bhuiyan.png';
import jacopoBistacchia from '../../assets/img/jacopo-bistacchia.png';
import annamariaGranatello from '../../assets/img/annamaria-granatello.png';
import danieleOrazi from '../../assets/img/daniele-orazi.png';
import PatamuCollab from '../../assets/img/patamu-logo-collab.svg';
import logoElsinore from '../../assets/img/logo-elsinore.svg';
import Rail from '../Rail/Rail';

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const peoples = [
    {
        name: 'Annamaria',
        surname: 'Morelli',
        role: 'Produttrice',
        logo: logoElsinore,
        img: annamariaMorelli,
        hidden: false,
        desc: 'è una produttrice con molti anni di esperienza nel settore cinematografico e televisivo. Ha iniziato a lavorare come Story Editor per <em>Rai</em> e proseguito la sua carriera con una lunga collaborazione con <em>Mediaset</em>, producendo molte serie Tv italiane di successo. Come produttore cinematografico ha lavorato per <em>Mediatrade</em> e <em>Cattleya</em>, tra gli altri, nella produzione di numerosi film pluripremiati. Durante la sua carriera si è distinta per i tanti autori di cui ha curato l’esordio, tra cui Paolo Sorrentino con la sua opera prima <em>\"L\'uomo in più\"</em>. Da febbraio 2017 a luglio 2019 è membro del consiglio di amministrazione e responsabile delle produzioni e coproduzioni per <em>TimVision</em>. Il lavoro svolto in questo periodo da lei e dal suo team ha portato record di ascolti per la piattaforma e importanti riconoscimenti.<br/>Attualmente è impegnata con la sua società, <em>Elsinore film</em>, nello sviluppo e la (co)produzione di contenuti originali sia seriali sia cinematografici, con una predilezione per la bellezza delle voci femminili e delle nuove generazioni.'
	},
	{
        name: 'Daniele',
        surname: 'Orazi',
        role: 'Agente',
        logo: '',
        img: danieleOrazi,
		hidden: false,
		desc: 'rappresenta e gestisce le carriere di molti talenti acclamati. Dopo gli studi in sociologia all\'Università La Sapienza di Roma, ha iniziato la sua professione nel mondo dello spettacolo. Orazi ha lavorato per 13 anni in agenzie di talenti prima di fondare <em>"Officine Artistiche"</em> nel 2005, dove si è dedicato al successo dei suoi clienti. Dal 2008 è membro della <em>LARA</em>, un\'associazione di agenti, nella quale ricopre anche la carica di Vice Presidente. Dal 2007 è membro dell\'<em>Accademia del cinema italiano David di Donatello</em>. Attualmente, Daniele Orazi è fondatore e proprietario di <em>DO</em>, un\'agenzia specializzata nella gestione dei talenti, nelle pubbliche relazioni e nella consulenza d\'immagine e nella produzione cinematografica.'
    },
    {
        name: 'Fulvio',
        surname: 'Risuleo',
        role: 'Regista e sceneggiatore',
        logo: '',
        img: fulvioRisuleo,
        hidden: false,
        desc: 'è nato a Roma nel 1991. Ha frequentato il <em>"Centro Sperimentale di Cinematografia"</em> nella classe di regia diplomandosi nel 2013. Nel 2014 con il suo saggio di laurea <em>"Lievito Madre"</em> ha vinto il terzo premio nella sezione <em>Cinéfondation</em> del festival di Cannes. Nel 2015 con il suo cortometraggio <e >"Varicella"</em> ha vinto nuovamente la <em>Semaine de la Critique</em> al festival di Cannes. Nel 2016 ha pubblicato il suo primo fumetto intitolato <em>"Pixel"</em> pubblicato dall\'editore Ultra - Lit. Il suo primo lungometraggio intitolato <em>"Guarda in alto"</em> con Giacomo Ferrara è uscito in sala nel 2018 e nello stesso anno ha diretto <em>"Il Caso Ziqqurat"</em>, la prima serie interattiva prodotta in Italia. Recentemente al cinema il secondo lungometraggio, <em>"Il colpo del cane"</em> interpretato da Edoardo Pesce e in libreria il suo fumetto <em>"Sniff"</em> edito da Cogonino Press.'
	},
	{
        name: 'Jacopo',
        surname: 'Bistacchia',
        role: 'Pr & Comunicazione',
        logo: '',
        img: jacopoBistacchia,
        hidden: false,
        desc: 'ha iniziato la sua carriera nel 2007 come giornalista freelance collaborando con emittenti televisive  come <em>MTV</em>, <em>Roma Tre</em> e molti altri. Nel 2012 ha iniziato a lavorare in <em>YD\'actor</em> come ufficio stampa e agente di talenti. Nel 2015 è entrato a <em>Cafiero & Partners</em> come ufficio stampa e consulente artistico per film, programmi TV e talenti di successo. Oggi lavora come libero professionista seguendo la comunicazione di molti film, come <em>"Manuel"</em>, diretto da <Dario Albertini e premiato al <em>Festival del Cinema di Venezia</em>. Da qualche hanno lavora anche come ufficio stampa per <em>Alice nella Città</em> uno degli eventi, dedicati al cinema, più importante della capitale.'
    },
];

interface IProps {
	history: History;
}

class RegistrationForSchool extends Component<IProps> {

	componentDidMount(): void {
		// if(checkSession()){
		// 	this.props.history.push(routes.MY_ORIGINALS)
		// }
		ReactGA.pageview('/scuolaholden');
	}

	scrollToRegister = () => {
		scroller.scrollTo('registrationElement', {
			duration: 1500,
			smooth: true,
			offset: -100, // Scrolls to element + 50 pixels down the page
		});
	}

	render () {
		
		return (
			<div className={classes.RegistrationForSchool}>

				<div className={[classes['RegistrationForSchool-section'], classes['RegistrationForSchool-section--opening']].join(' ')}>
					<div className={classes['RegistrationForSchool-section-content']}>
						<div>
							<h1 className={classes['RegistrationForSchool-title']}><FormattedMessage id="landing.holden.h1" /></h1>
							<h2 className={classes['RegistrationForSchool-title']}><FormattedMessage id="landing.holden.h2" /></h2>
						</div>
						<div>
							<h3 className={classes['RegistrationForSchool-title']}><FormattedMessage id="landing.holden.h3" /></h3>
							<div className={classes['RegistrationForSchool-date']}>
								<FormattedMessage id="landing.holden.date" />
							</div>
						</div>
						<Button
						clicked={this.scrollToRegister}
						type="confirm-orange-solid">
							<FormattedMessage id="landing.holden.cta" />
						</Button>

                        <div className={classes['RegistrationForSchool-patamu-collab']}>
                            <img src={PatamuCollab} alt="Patamu" />
                        </div>
					</div>
				</div>

				<div className={[classes['RegistrationForSchool-section'], classes['RegistrationForSchool-section--rail']].join(' ')}>
					<div className={classes['RegistrationForSchool-section-content']}>

						<div className={classes['RegistrationForSchool-section-txt']}>
							<p><FormattedHTMLMessage id="landing.holden.rail.txt" /></p>
						</div>

                        <Rail elements={peoples} more />
					</div>
				</div>

				<div className={[classes['RegistrationForSchool-section'], classes['RegistrationForSchool-section--after-rail']].join(' ')}>
					<div className={classes['RegistrationForSchool-section-content']}>
						<div className={classes['RegistrationForSchool-section-txt']}>
							<p><FormattedHTMLMessage id="landing.holden.artchivio.txt" /></p>
						</div>
					</div>
				</div>

				<div className={[classes['RegistrationForSchool-section'], classes['RegistrationForSchool-section--registration']].join(' ')}>
					<RegistrationSchoolWizard />
				</div>

			</div>
		)
	}
}

export default RegistrationForSchool;
