import * as actionTypes from './actionTypes';
import AttachmentsService from '../../services/api/attachments';
import { IResponse } from '../../shared/interfaces';

// UPDATE
export const createAttachmentStart = () => {
	return {
		type: actionTypes.CREATE_ATTACHMENT_START,
	}
};

export const createAttachmentSuccess = (attachment: object) => {
	return {
		type: actionTypes.CREATE_ATTACHMENT_SUCCESS,
		attachment
	}
};

export const createAttachmentFail = (error: string) => {
	return {
		type: actionTypes.CREATE_ATTACHMENT_FAIL,
		error,
	}
};

export const createAttachment = (data: object) => {
	return (dispatch: any) => {
		dispatch(createAttachmentStart());

		AttachmentsService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createAttachmentSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(createAttachmentFail(errorMsg));
				}
			);
	}
};

export const resetAttachment = () => {
	return {
		type: actionTypes.RESET_ATTACHMENT,
	}
};

// READ/FETCH
export const fetchAttachmentsStart = () => {
    return {
        type: actionTypes.FETCH_ATTACHMENTS_START,
    }
};

export const fetchAttachmentsSuccess = (list: Array<object>) => {
    return {
        type: actionTypes.FETCH_ATTACHMENTS_SUCCESS,
        list
    }
};

export const fetchAttachmentsFail = (error: string) => {
    return {
        type: actionTypes.FETCH_ATTACHMENTS_FAIL,
        error,
    }
};

export const fetchAttachments = () => {
    return (dispatch: any) => {
        dispatch(fetchAttachmentsStart());

        AttachmentsService
            .get()
            .then(
	            (response: IResponse) => {

                    dispatch(fetchAttachmentsSuccess(response.data));
                },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(fetchAttachmentsFail(errorMsg));
                }
            );
    }
};

export const fetchAttachmentStart = () => {
	return {
		type: actionTypes.FETCH_ATTACHMENT_START,
	}
};

export const fetchAttachmentSuccess = (attachment: Array<object>) => {
	return {
		type: actionTypes.FETCH_ATTACHMENT_SUCCESS,
		attachment
	}
};

export const fetchAttachmentFail = (error: string) => {
	return {
		type: actionTypes.FETCH_ATTACHMENT_FAIL,
		error,
	}
};

export const fetchAttachment = (id: number) => {
	return (dispatch: any) => {
		dispatch(fetchAttachmentStart());

		AttachmentsService
			.get(id)
			.then(
				(response: IResponse) => {

					dispatch(fetchAttachmentSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchAttachmentFail(errorMsg));
				}
			);
	}
};

// UPDATE
export const updateAttachmentStart = () => {
    return {
        type: actionTypes.UPDATE_ATTACHMENT_START,
    }
};

export const updateAttachmentSuccess = (attachmentUpdated: object) => {
    return {
        type: actionTypes.UPDATE_ATTACHMENT_SUCCESS,
        attachmentUpdated
    }
};

export const updateAttachmentFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_ATTACHMENT_FAIL,
        error,
    }
};

export const updateAttachment = (id: number, data: object) => {
    return (dispatch: any) => {
        dispatch(updateAttachmentStart());

        AttachmentsService
            .update(id, data)
            .then(
	            (response: IResponse) => {

                    dispatch(updateAttachmentSuccess(response.data));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(updateAttachmentFail(errorMsg));
                }
            );
    }
};

// DELETE
export const deleteAttachmentStart = () => {
    return {
        type: actionTypes.DELETE_ATTACHMENT_START,
    }
};

export const deleteAttachmentSuccess = (removed: boolean) => {
    return {
        type: actionTypes.DELETE_ATTACHMENT_SUCCESS,
        removed
    }
};

export const deleteAttachmentFail = (error: string) => {
    return {
        type: actionTypes.DELETE_ATTACHMENT_FAIL,
        error,
    }
};

export const deleteAttachment = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteAttachmentStart());

        AttachmentsService
            .remove(id)
            .then(
	            (response: IResponse) => {

                    dispatch(deleteAttachmentSuccess(true));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(deleteAttachmentFail(errorMsg));
                }
            );
    }
};
