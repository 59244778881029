import React from 'react';
import classes from './Logo.module.scss';
import { NavLink } from "react-router-dom";

import logoUrl from '../../assets/img/logo.svg';

interface logoProps {
    to: string;
    alt?: string;
    position: string;
    black?: boolean;
}

const logo = (props: logoProps) => {

    const { to, alt, position, black } = props;

    const logoClass = [classes["Logo"], classes[`Logo--${position}`], black ? classes[`Logo--black`] : ''].join(' ');

    return (
        <NavLink to={to} className={logoClass}>
            <img className={classes["Logo-image"]} src={logoUrl} alt={alt || 'Artchivio'} />
            <h1 className={classes["Logo-text"]}>{ `Artchivio` }</h1>
            <span className={classes["Logo-payoff"]}>Motore creativo</span>
        </NavLink>
    );
};

export default logo;
