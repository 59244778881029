export const WELCOME = "/";
export const REGISTRATION = "/registration/:code?";
export const REGISTRATION_FOR_SCHOOL = "/scuolaholden";
export const BRIEFS = "/briefs";
export const NETWORK = "/network";
export const MESSAGES = "/messages";
export const PROFILE = "/profile";
export const USER = "/user/:user";
export const ACTIVATION = "/activation/:code";
export const INVITES = "/invites";
export const TEAM = "/team";
export const ADD_WORK = "/add-work/:step?";
export const EDIT_WORK = "/work/:id/:step?";
export const NEW_WORK = "/add-work/1";
export const TEAM_INVITATIONS = "/team/invitations";
export const PRIVACY = "/privacy";
export const HOLDENRULES = "/regolamento-scuolaholden";
export const RECOVERY = "/recovery/:code";
export const ERROR_USER = "/error/user-not-found";
export const NOTIFICATIONS = "/profile/:notifications";
export const ORIGINALS = "/originals";
export const ORIGINAL = "/originals/:id";
export const MY_ORIGINALS = "/my-originals";
export const MY_ORIGINALS_REQUESTS = "/my-originals/requests";
export const ADD_ORIGINAL = "/add-original/:step?";
export const EDIT_ORIGINAL = "/original/:id/:step?";
export const NEW_ORIGINAL = "/add-original/1";
