import React, {Component, createRef} from 'react';
import stepClasses from '../Steps.module.scss';
import constants from '../../../../shared/constants';
import classes from './Step2.module.scss';
import './DragList.scss';
import { IAddWorkContentBlocks, IAttachment, IFile, IInput, IOrder, IOriginal } from '../../../../shared/interfaces';
import { FormattedMessage } from 'react-intl';
import UploadBox from "../../../UploadBox/UploadBox";
import Loader from '../../../Loader/Loader';
import DocumentCard from "../../../DocumentCard/DocumentCard";
import * as actions from '../../../../store/actions';
import { sortable } from 'react-sortable';
import { connect } from 'react-redux';
import * as ReactGA from '../../../../GA';
import Input from '../../../Input/Input';
import EditorTextarea from '../../../EditorTextarea/EditorTextarea';
import Modal from '../../../../containers/Modal/Modal';
import Button from '../../../Button/Button';

class SortItem extends React.Component {
	render() {
		return (
			<div {...this.props}>
				{this.props.children}
			</div>
		)
	}
}

const SortableItem = sortable(SortItem);

interface IProps {
	setData: (field: string, value: any) => void;
	onCreateAttachment: (data: object) => object;
	onUpdateAttachment: (id: number, data: object) => object;
	onCreateFile: (data: any) => any;
	onAddAttachmentToOriginal: (id: number, attachmentId: number) => any;
	onRemoveAttachmentFromOriginal: (id: number, attachmentId: number) => any;
	onChangeAttachmentsOrder: (id: number, data: Array<IOrder>) => Array<IOrder>;
	onResetFile: () => any;
	onResetAttachment: () => any;
	data?: any;
	attachment: any;
	file: any;
	attachmentUpdated: any;
	attachmentRemoved: boolean;
	attachmentAdded: boolean;
	updatedAttachments: Array<IAttachment>;
	original: IOriginal;
	attachmentStoring: boolean;
	fileStoring: boolean;
	associateAttachmentToOriginal: boolean;
	editMode: boolean;
}

interface IState {
	contentBlocks: Array<IAddWorkContentBlocks>;
	savedBlocks: Array<IAddWorkContentBlocks>;
	uploadedFile: {
		title: string;
		content: string;
		file: IFile | null;
	};
	textIndex: number;
	idToSave: any;
	attachmentToRemove: number;
	newAttachment: any;
	newAttachmentId: any;
	orderChanged: boolean;
	newOrder: any;
	showOrderModal: boolean;
	attachmentToEdit: {
		id: number;
		content: any;
		draft: boolean;
	};
	attachmentUpdated: IAttachment | null;
	showEditModal: boolean;
	currentUser: { name: string; thumbnailUrl?: string | null };
	title: IInput;
	description: string;
	mediaToEdit: {
		id: number;
		title?: string;
		description?: string;
	}
}

class Step2 extends Component<IProps, IState> {
	private previewContainer = createRef<HTMLDivElement>();
	private previewImage = createRef<HTMLImageElement>();
	private upload = createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);

		this.state = {
			showOrderModal: false,
			attachmentToRemove: -1,
			contentBlocks: [],
			savedBlocks: [],
			uploadedFile: {
				title: '',
				content: '',
				file: null,
			},
			textIndex: -1,
			idToSave: [],
			newAttachment: null,
			newAttachmentId: null,
			orderChanged: false,
			newOrder: {
				attachment: [],
				draft: []
			},
			attachmentUpdated: null,
			attachmentToEdit: {
				id: -1,
				content: '',
				draft: false,
			},
			showEditModal: false,
			currentUser: {
				name: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_NAME) + ' ' + localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME),
				thumbnailUrl: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE)
			},
			title: {
				name: 'title',
				placeholderId: 'placeholder.document.title',
				value: '',
				visible: true,
				readOnly: false,
				type: 'text',
			},
			description: '',
			mediaToEdit: {
				id: -1,
				title: '',
				description: '',
			}
		};

		this.saveAttachment = this.saveAttachment.bind(this);
		this.removeCurrentFile = this.removeCurrentFile.bind(this);
	}

	componentDidMount(): void {
		window.scrollTo(0,0);

		const { editMode, data } = this.props;

		if(editMode && data.hasOwnProperty('id')) {
			ReactGA.pageview(`/original/${data.id}/3`);
		}
		else {
			ReactGA.pageview('/add-original/3');
		}

		if(this.props.data && this.props.data.attachment) {
			let currentBlocks = this.props.updatedAttachments.length > 0 ? this.props.updatedAttachments : this.props.data.attachment;

			this.setState({
				savedBlocks: currentBlocks
			});
		}
	}

	handleUploadFileContent = (uploaded: any) => {
		const data = new FormData();
		//(uploaded[0]);
		data.append('file', uploaded[0]);

		this.props.onCreateFile(data);
	};

	removeCurrentFile = () => {
		this.props.onResetFile();

		const uploadedFile = {
			title: '',
			content: '',
			file: null,
		};

		this.setState({
			uploadedFile
		})
	};

	saveAttachment(edit?: boolean){
		const currentOriginal = this.props.data.id;
		const savedBlocks = [...this.state.savedBlocks];
		let data = null;

		// @ts-ignore
		const getMaxSort = savedBlocks.length > 0 ? savedBlocks.reduce((max, p) => p.sort > max ? p.sort : max, savedBlocks[0].sort) : -1;

		if(currentOriginal){

			if(!edit) {
				data = {
					type: "file",
					title: this.state.title.value,
					content: this.state.description,
					preview: true,
					fileCode: this.state.uploadedFile.file && this.state.uploadedFile.file.code,
					sort: getMaxSort ? getMaxSort + 1 : 0
				};

				this.props.onCreateAttachment(data);
			} else {
				const mediaToEdit = {...this.state.mediaToEdit};

				this.props.onUpdateAttachment(mediaToEdit.id, {
					title: mediaToEdit.title,
					content: mediaToEdit.description
				})
			}
		}
	}

	removeAttachment(id: number | undefined){
		const currentOriginal = this.props.data.id;

		if(currentOriginal && id){
			this.props.onRemoveAttachmentFromOriginal(currentOriginal, id);
		}
	}

	editAttachment(id: number | undefined){
		const currentOriginal = this.props.data.id;

		if(currentOriginal && id){
			const block = this.state.savedBlocks.filter((block: IAddWorkContentBlocks) => block.id === id)[0];

			if(block.hasOwnProperty('id') && block.id) {
				let mediaToEdit = {
					id: block.id,
					title: block.title,
					description: block.content,
				};

				this.setState({
					showEditModal: true,
					mediaToEdit
				})
			}
		}
	}

	componentWillUnmount(): void {
		this.props.onResetFile();
		this.props.onResetAttachment();
	}

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputValue = ev.currentTarget.value;

		let title = {...this.state.title};

		title.value = inputValue;

		this.setState({
			title,
		});
	};

	handleChangeDescription = (value: any) => {
		this.setState({
			description: value,
		});
	};

	handleEditAttachmentTitle = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputValue = ev.currentTarget.value;

		let mediaToEdit = {...this.state.mediaToEdit};

		mediaToEdit.title = inputValue;

		this.setState({
			mediaToEdit
		});
	};

	handleEditAttachmentDescription = (value: any) => {

		let mediaToEdit = {...this.state.mediaToEdit};

		mediaToEdit.description = value;

		this.setState({
			mediaToEdit
		});
	};

	onDocumentsSort = (sortedList: any) => {
		console.log("NEW",sortedList);
		console.log("OLD",this.state.savedBlocks);
		const currentOriginal = this.props.data.id;

		if(currentOriginal){
			const savedBlocks = sortedList.map((item: IAddWorkContentBlocks, index: number) => {
				return {
					...item,
					sort: index
				}
			});

			this.setState({
				savedBlocks
			}, () => {
				const newOrder = savedBlocks.map((item: IAddWorkContentBlocks) => { return { id: item.id, sort: item.sort } });

				this.props.onChangeAttachmentsOrder( this.props.data.id, newOrder );
			});
		}
	};

	onToggleShowEditModal(forceClose?: boolean){
		const showEditModal = forceClose ? false : !this.state.showEditModal;

		this.setState({
			showEditModal,
			attachmentUpdated: null,
		})
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevState.uploadedFile.file !== this.state.uploadedFile.file && this.state.uploadedFile.file){
			const node = this.previewImage.current!;
			const container = this.previewContainer.current!;
			const uploadBox = this.upload.current!;

			let title = {...this.state.title};

			title.value = this.state.uploadedFile.file ? this.state.uploadedFile.file.name : '';

			this.setState({
				title
			});

			if(container && node && uploadBox){
				//uploadBox.style.display = 'none';
				node.src = this.state.uploadedFile.file ? this.state.uploadedFile.file.url : '';
				container.style.display = 'flex';
			}
		}
		if(prevState.newAttachment !== this.state.newAttachment && this.state.newAttachment){
			this.props.onCreateAttachment(this.state.newAttachment);
		}
		if(prevState.newAttachmentId !== this.state.newAttachmentId && this.state.newAttachmentId){
			this.props.onResetFile();
			const originalId = this.props.data.id;
			const attachmentId = this.state.newAttachmentId;

			let title = {...this.state.title};
			title.value = '';
			const description = '';

			this.setState({
				title,
				description
			}, () => {
				this.props.onAddAttachmentToOriginal(originalId, attachmentId);
			})
		}
		if(prevState.attachmentUpdated !== this.state.attachmentUpdated && this.state.attachmentUpdated){
			this.onToggleShowEditModal(true);
		}
	}

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let savedBlocks: Array<any> = [];
		let uploadedFile = nextState.uploadedFile;
		let newAttachment = nextState.newAttachment;
		let newAttachmentId = nextState.newAttachmentId;
		let newOrder = nextState.newOrder;
		let attachmentUpdated = nextState.attachmentUpdated;

		if(nextProps.attachment){
			newAttachmentId = nextProps.attachment.id;
		}

		if(nextProps.file){
			uploadedFile = {
				title: nextProps.file[0].name,
				content: '',
				file: nextProps.file[0]
			};
		} else {
			uploadedFile = {
				title: '',
				content: '',
				file: null,
			};
		}

		if(nextProps.updatedAttachments.length > 0) {
			savedBlocks = nextProps.updatedAttachments.filter((m: any) => m.coverType === 0).sort((a, b) => (a.sort > b.sort) ? 1 : -1);
			newOrder.attachment = savedBlocks;
		}

		if(nextProps.attachmentUpdated){
			attachmentUpdated = nextProps.attachmentUpdated;

			savedBlocks = nextProps.updatedAttachments
				.filter((m: any) => m.coverType === 0).sort((a, b) => (a.sort > b.sort) ? 1 : -1)
				.map((block: IAddWorkContentBlocks) => {
				return {
					...block,
					title: nextProps.attachmentUpdated && block.id === nextProps.attachmentUpdated.id ? nextProps.attachmentUpdated.title : block.title,
					content: nextProps.attachmentUpdated && block.id === nextProps.attachmentUpdated.id ? nextProps.attachmentUpdated.content: block.content,
				}
			});

			newOrder.attachment = savedBlocks;
		}

		return {
			savedBlocks,
			uploadedFile,
			newAttachment,
			newAttachmentId,
			newOrder,
			attachmentUpdated
		}
	}

	render () {
		const { uploadedFile, savedBlocks, currentUser, title, description, showEditModal, mediaToEdit } = this.state;

		const list = savedBlocks.map((block: IAddWorkContentBlocks, index: number) => {

			return (
				<div key={index} className={classes['Step2-document']}>
					<SortableItem
						onSortItems={this.onDocumentsSort}
						items={savedBlocks}
						sortId={index}>
						<DocumentCard
							onRemoveClicked={() => this.removeAttachment(block.id)}
							onEditClicked={() => this.editAttachment(block.id)}
							key={index}
							userName={currentUser.name}
							userThumbnail={currentUser.thumbnailUrl}
							document={{
								id: block.id || -1,
								title: block.title || block.file.name,
								description: block.content,
								url: block.file.url,
								type: block.file.mimeType
							}} />
					</SortableItem>
				</div>
			)
		});

		return (
			<div className={classes.Step2}>
				<div className={classes['Step2-content']}>
					<div className={[classes['Step2-row'], classes['Step2-row--large']].join(' ')}>
						<div className={stepClasses['Steps-label']}>
							<FormattedMessage id={'label.originalContent'} />
						</div>
						<div className={classes['Step2-row--flex']}>
							<div className={[classes['Step2-flex-item'], classes['Step2-flex-item--large']].join(' ')}>
								<UploadBox
									reference={this.upload}
									titleId={'label.addOriginal.uploadDocument'}
									accept={constants.DOCUMENT_MIME_TYPES}
									onSelectedFiles={this.handleUploadFileContent}
									multiple={false}
									squared
									large
									fullWidth
									maxSize={16384000}
									styles={{margin: '0'}}/>
							</div>
							<div className={classes['Step2-flex-item']}>
								<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
									<div className={stepClasses['Steps-info-box-content']}>
										<FormattedMessage id={'myOriginals.documentsInfo'} />
									</div>
									<div className={stepClasses['Steps-info-box-icon']} />
								</div>
							</div>
						</div>
					</div>
					<div className={stepClasses['Steps-main']}>
						{
							/*
							<div style={{overflow:'scroll',height:600}}>
								<PDFReader url="https://server-dev.artchivio.net/file/5ce45bec8d498"/>
							</div>*/
						}
						{
							(this.props.fileStoring ||
								this.props.attachmentStoring ||
								this.props.associateAttachmentToOriginal) &&
								<div className={classes['Step2-loading']}>
									<Loader />
								</div>
						}
						{
							uploadedFile.file &&
								<div className={classes['Step2-container']}>
									<div className={[classes['Step2-row'], classes['Step2-row-add']].join(' ')}>
										<label className={stepClasses['Steps-label']}>
											<FormattedMessage id={'label.document.title'} />
										</label>
										<Input
											name={title.name}
											value={title.value}
											changed={this.handleInputChanged.bind(this)}
											placeholderId={title.placeholderId}
											visible={title.visible}
											readOnly={title.readOnly}
											type={title.type}/>
									</div>
									<div className={[classes['Step2-row'], classes['Step2-row-add']].join(' ')}>
										<EditorTextarea
											styles={{minHeight: '60px'}}
											toolbarHidden={true}
											value={description}
											labelId={'label.document.description'}
											placeholderId={'placeholder.document.description'}
											onChange={this.handleChangeDescription}/>
									</div>
									<div className={classes['Step2-actions']}>
										<Button
											styles={{padding: 0, color: '#D05A5A'}}
											type="small"
											clicked={this.removeCurrentFile}>
											<FormattedMessage id={"general.delete"} />
										</Button>
										<Button
											styles={{padding: 0, color: '#5AD075'}}
											type="small"
											disabled={title.value.length === 0}
											clicked={() => this.saveAttachment(false)}>
											<FormattedMessage id={"label.addOriginal.addDocument"} />
										</Button>
									</div>
								</div>
						}
						<div className={classes['Step2-documents']}>
							{
								savedBlocks.length > 0 ?
									list
										:
										''
									// <span style={{
									// 	color: 'red',
									// 	display:
									// 		this.props.fileStoring ||
									// 		this.props.attachmentStoring ||
									// 		this.props.associateAttachmentToOriginal ||
									// 		uploadedFile.file ? 'none' : 'block'
									// 	}
									// }>{ 'Attachments not found!' }</span>
							}
						</div>
					</div>
					{
						showEditModal &&
							<Modal
								large={true}
								forceClose={true}
								title={<FormattedMessage id={'edit.title'}/>}
								onModalCloseClicked={() => this.onToggleShowEditModal(true)}>
								<div className={classes['Step2-row']}>
									<label className={stepClasses['Steps-label']}>
										<FormattedMessage id={'label.document.title'} />
									</label>
									<Input
										name={title.name}
										value={mediaToEdit.title || ''}
										changed={this.handleEditAttachmentTitle.bind(this)}
										placeholderId={title.placeholderId}
										visible={title.visible}
										readOnly={title.readOnly}
										type={title.type}/>
								</div>
								<div className={classes['Step2-row']}>
									<EditorTextarea
										color="white"
										toolbarHidden={true}
										value={mediaToEdit.description}
										labelId={'label.document.description'}
										placeholderId={'placeholder.document.description'}
										onChange={this.handleEditAttachmentDescription}/>
								</div>
								<div className={classes["EditModal-footer"]}>
									<Button clicked={() => this.saveAttachment(true)} type="confirm">
										<FormattedMessage id={'button.confirm'}/>
									</Button>
								</div>
							</Modal>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		attachment: state.attachmentState.attachment,
		attachmentUpdated: state.attachmentState.attachmentUpdated,
		attachmentStoring: state.attachmentState.isStoring,
		file: state.fileState.file,
		fileStoring: state.fileState.isStoring,
		associateAttachmentToOriginal: state.originalState.isStoring,
		loading: state.attachmentState.isFetching,
		error: state.attachmentState.error,
		attachmentRemoved: state.originalState.attachmentRemoved,
		attachmentAdded: state.originalState.attachmentAdded,
		updatedAttachments: state.originalState.updatedAttachments,
		original: state.originalState.original,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onCreateAttachment: (data: any) => dispatch(actions.createAttachment(data)),
		onUpdateAttachment: (id: number, data: any) => dispatch(actions.updateAttachment(id, data)),
		onChangeAttachmentsOrder: (id: number, data: Array<IOrder>) => dispatch(actions.orderAttachmentsForOriginal(id, data)),
		onCreateFile: (data: any) => dispatch(actions.createFile(data)),
		onAddAttachmentToOriginal: (id: number, attachmentId: number) => dispatch(actions.addAttachmentToOriginal(id, attachmentId)),
		onRemoveAttachmentFromOriginal: (id: number, attachmentId: number) => dispatch(actions.removeAttachmentFromOriginal(id, attachmentId)),
		onResetFile: () => dispatch(actions.resetFile()),
		onResetAttachment: () => dispatch(actions.resetAttachment()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Step2 );
