import React, { Component } from 'react';
import classes from './RouterWrapper.module.scss';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import * as routes from '../../shared/routes';

// Containers
import Header from '../../containers/Header/Header';
import Main from '../../containers/Main/Main';
import Footer from '../../containers/Footer/Footer';

// Components
import Welcome from '../../components/Welcome/Welcome';
import Registration from "../../components/Registration/Registration";
import Briefs from "../../components/Briefs/Briefs";
import Network from "../../components/Network/Network";
import Originals from '../../components/Originals/Originals';
import OriginalDetail from '../../components/OriginalDetail/OriginalDetail';
import Messages from "../../components/Messages/Messages";
import Invites from "../../components/Invites/Invites";
import Privacy from "../../components/Privacy/Privacy";
import Profile from "../../components/Profile/Profile";
import User from "../../components/User/User";
import Work from "../../components/Work/Work";
import MyOriginals from '../../components/MyOriginals/MyOriginals';
import MyOriginalsRequests from '../../components/MyOriginalsRequests/MyOriginalsRequests';
import Original from '../../components/Original/Original';
import Activation from "../../components/Activation/Activation";
import Recovery from "../../components/Recovery/Recovery";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import RegistrationForSchool from '../../components/RegistrationForSchool/RegistrationForSchool';
import HoldenRules from "../../components/Rules/Holden";
import CookieBanner from "../../components/CookieBanner/CookieBanner";

// PrivateRoute
import PrivateRoute from '../../hocs/PrivateRoute';
import { checkSession } from '../../shared/helpers';

// State interface
interface IState {
	showRegistrationOffCanvas: boolean;
	showOffCanvas: boolean;
	isAuthenticated: boolean;
	currentRoute: string;
	cookieConsent: boolean;
}

class RouterWrapper extends Component<any, IState> {
	private unsubscribeFromHistory: any;

	constructor(props: any) {
		super(props);

		this.state = {
			showRegistrationOffCanvas: true,
			showOffCanvas: false,
			isAuthenticated: false,
			currentRoute: '',
			cookieConsent: false,
		}
	}

	setCurrentRoute (currentRoute: string) {
		this.setState({
			currentRoute
		}, () => {
			//console.log('Current route:', this.state.currentRoute);
		})

	}

	componentDidMount(): void {
		this.setState({
			isAuthenticated: checkSession(),
			currentRoute: this.props.location.pathname
		}, () => {
			//console.log('User authenticated:', this.state.isAuthenticated);
			//console.log('Current route:', this.state.currentRoute);
		});

		this.checkCookieConsent();
	}

	componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
		if(this.props.location !== nextProps.location){
			this.setCurrentRoute(nextProps.location.pathname);
		}
		this.checkCookieConsent();
	}

	checkCookieConsent = () => {
		const cookieConsent = localStorage.getItem('cookieConsent');
        if(cookieConsent === 'accept') {
            this.setState({
                cookieConsent: true
            })
        } else {
			this.setState({
                cookieConsent: false
            })
		}
	};

	handleToggleRegistrationOffCanvasVisibility = (ev: React.MouseEvent<HTMLButtonElement>, hide?: boolean) => {
		ev.preventDefault();

		let showRegistrationOffCanvas = this.state.showRegistrationOffCanvas;

		if(hide){
			showRegistrationOffCanvas = !showRegistrationOffCanvas;
		} else {
			showRegistrationOffCanvas = !this.state.showRegistrationOffCanvas;
		}

		this.setState({
			showRegistrationOffCanvas
		})
	};

	handleToggleSigninOffCanvasVisibility = () => {

		const showOffCanvas = !this.state.showOffCanvas;

		this.setState({
			showOffCanvas
		})
	};

	componentWillMount() {
		const { history } = this.props;
		this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
		this.handleLocationChange(history.location);
	}

	componentWillUnmount() {
		if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
	}

	handleLocationChange = (location: any) => {
		//this.handleToggleSigninOffCanvasVisibility();
		this.setState({
			showOffCanvas: false,
			showRegistrationOffCanvas: false,
		})
	};

	render() {
		const { showRegistrationOffCanvas, showOffCanvas, cookieConsent } = this.state;

		const routing = (
			<Switch>
				<Route
					exact={true}
					path={routes.WELCOME}
					render={
						(props) =>
						<Welcome {...props}
							onToggleCanvas={this.handleToggleSigninOffCanvasVisibility}
							checkSession={checkSession()} />
					}
				/>
				<Route exact={true} path={routes.NETWORK} component={Network} />
				<Route exact={true} path={routes.MESSAGES} component={Messages} />
				<Route exact={true} path={routes.PRIVACY} component={Privacy} />
				<Route exact={true} path={routes.HOLDENRULES} component={HoldenRules} />
				<Route
					path={routes.REGISTRATION}
					exact={true}
					render={
						(props) =>
							<Registration
								{...props}
								showOffCanvas={showRegistrationOffCanvas}
								onToggleOffCanvasVisibilityClicked={this.handleToggleRegistrationOffCanvasVisibility}
							/>
					}
				/>
				<Route exact={true} path={routes.REGISTRATION_FOR_SCHOOL} component={RegistrationForSchool} />
				<Route exact={true} path={routes.ACTIVATION} component={Activation} />
				<Route exact={true} path={routes.RECOVERY} component={Recovery} />
				<Route exact={true} path={routes.ERROR_USER} component={ErrorPage} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.PROFILE} component={Profile} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.USER} component={User} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.INVITES} component={Invites} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.MY_ORIGINALS} component={MyOriginals} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.MY_ORIGINALS_REQUESTS} component={MyOriginalsRequests} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.ADD_ORIGINAL} component={Original} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.EDIT_ORIGINAL} component={Original} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.ORIGINALS} component={Originals} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.ORIGINAL} component={OriginalDetail} />
				{
					// Temp hidden
					// <PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.TEAM} component={Team} />
					// <PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.TEAM_INVITATIONS} component={Search} />
				}
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.ADD_WORK} component={Work} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.EDIT_WORK} component={Work} />
				<PrivateRoute exact={true} isAuthenticated={checkSession()} path={routes.NOTIFICATIONS} component={Profile} />
				<Redirect to={'/'}/>
			</Switch>
		);

		const currentRouteString = this.state.currentRoute.toLowerCase().replace("/", "-");

		return (
			<div className={[classes['RouterWrapper'], `route-${currentRouteString}`].join(' ')}>
				<Header
					currentRoute={this.state.currentRoute}
					isAuthenticated={checkSession()}
					history={this.props.history}
					onToggleOffCanvas={this.handleToggleSigninOffCanvasVisibility}
					showOffCanvas={showOffCanvas} />
				<Main currentRoute={this.state.currentRoute} isAuthenticated={checkSession()}>
					{ routing }
				</Main>
				<CookieBanner show={cookieConsent} checkCookieConsent={this.checkCookieConsent} />
				<Footer currentRoute={this.state.currentRoute} />
			</div>
		)
	}
}

export default withRouter(RouterWrapper);
