import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as routes from "../../shared/routes";
import * as Scroll from 'react-scroll';
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import moment from 'moment';
import { connect } from 'react-redux';
import classes from './Profile.module.scss';
import './Tour.scss';
import Template from '../../containers/Template/Template';
import Curriculum from '../Curriculum/Curriculum';
import Projects from '../Projects/Projects';
import Loader from '../Loader/Loader';
import { countWordInArray, randomId } from '../../shared/helpers';
import Modal from '../../containers/Modal/Modal';
import Button from '../Button/Button';
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar';
import { ICurriculum, INotification, IProject, IUser } from '../../shared/interfaces';
import Notifications from '../Notifications/Notifications';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

toast.configure({
	autoClose: 3000,
	draggable: false,
});

const PRINT_CURRENT_USER = false;
let scroll = Scroll.animateScroll;

interface IProps {
	onGetCurrentUser(): IUser;
	onGetUserById(userId: number): IUser;
	onGetExperiencesList( userId: number ): object;
	onDeleteExperience( id: number ): any;
	onDeleteProject( id: number ): any;
	onGetProjectsByUserId(userId: number): object;
	onSetTourDisabled(userId: number): void;
	experienceRemoved: boolean;
	experiences: Array<ICurriculum>;
	currentUser: IUser;
	profile: IUser;
	projects: Array<IProject>;
	history: any;
	loadingProjects: boolean;
	loading: boolean;
	projectRemoved: boolean;
	loadingExperiences: boolean;
	match: any;
	errorUser: any;
	notifications: Array<INotification>;
	loadingNotifications: boolean;
	tourDisabled: boolean;
}

interface IState {
	currentUser: IUser | null;
	projects: Array<any>;
	experiences: Array<ICurriculum>;
	lastExperienceRemovedId: number | null;
	addWorkNumber: number;
	projectsLoading: boolean;
	experiencesLoading: boolean;
	projectIdToRemove: number | null;
	showProjectDeleteDialog: boolean;
	showExperienceDeleteDialog: boolean;
	roles: any;
	readOnly: boolean;
	profile: IUser | null;
	showNotifications: boolean;
	steps: Array<any>;
	run: boolean;
	stepIndex: number;
	profileVisible: boolean;
}

class Profile extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			showProjectDeleteDialog: false,
			showExperienceDeleteDialog: false,
			currentUser: null,
			projects: [],
			experiences: [],
			lastExperienceRemovedId: null,
			addWorkNumber: 3,
			projectsLoading: true,
			experiencesLoading: true,
			projectIdToRemove: null,
			roles: [],
			readOnly: false,
			profile: null,
			showNotifications: false,
			steps: [
				{
					target: '.addwork-step',
					content: <div className="Tour-content"><h2>Start with your best</h2><p>Here is a collection of your best finished projects. By <strong>adding one finished project your Portfolio will be visible</strong> in the Network.</p></div>,
					disableBeacon: true,
				},
				{
					target: '.addcv-step',
					content: <div className="Tour-content"><h2>Your story</h2><p>Add your current and previous <strong>work</strong> and <strong>academic experiences</strong>.</p></div>,
				},
			],
			run: false,
			stepIndex: 0, // a controlled tour
			profileVisible: false
		};

		this.addExperience = this.addExperience.bind(this);
		this.setExperience = this.setExperience.bind(this);
		this.getExperiences = this.getExperiences.bind(this);
		this.getProjects = this.getProjects.bind(this);
		this.closeExperience = this.closeExperience.bind(this);
		this.removeExperience = this.removeExperience.bind(this);
		this.onDeleteExperience = this.onDeleteExperience.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.onEditProject = this.onEditProject.bind(this);
		this.onDeleteProject = this.onDeleteProject.bind(this);
		this.onToggleDeleteProjectConfirm = this.onToggleDeleteProjectConfirm.bind(this);
		this.onToggleDeleteExperienceConfirm = this.onToggleDeleteExperienceConfirm.bind(this);
		this.removeProject = this.removeProject.bind(this);
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);

		let userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);

		const {
			match: {
				params: { user, notifications }
			}
		} = this.props;

		if(notifications === 'notifications'){
			this.setState({
				showNotifications: true,
			})
		}

		//if(user && user !== userId){
		if(user){
			//console.log('User:', user);
			ReactGA.pageview(`/profile/${user}`);

			userId = user;

			this.setState({
				readOnly: true,
			}, () => {
				if(userId) {
					this.getProfile(+userId)
				}
			});
		} else {
			ReactGA.pageview(`/profile`);

			this.setState({
				readOnly: false,
			}, () => {

				if(userId) {
					this.getCurrentUser(+userId)
				}
			});
		}

		this.setState({
			projectsLoading: this.state.projects.length > 0
		});
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {

		const {
			match: {
				params: { user, notifications }
			}
		} = this.props;

		if(notifications !== 'notifications' && this.state.showNotifications){
			this.setState({
				showNotifications: false,
			})
		} else {
			if(notifications === 'notifications' && !this.state.showNotifications){
				this.setState({
					showNotifications: true,
				})
			}
		}

		if(!user && this.state.readOnly){

			let userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);

			this.setState({
				readOnly: false
			}, () => {
				userId && this.getCurrentUser(+userId);
			});
		}


		if(prevProps.experiences !== this.props.experiences){
			//console.log(nextProps.experiences);
			const experiences = this.props.experiences.map((cv: any) => {
				return {
					...cv,
					dateStart: moment(cv.dateStart).format('YYYY-MM-DD'),
					dateEnd: moment(cv.dateEnd).format('YYYY-MM-DD'),
				}
			});

			this.setState({
				experiences
			})
		}

		if(prevProps.experienceRemoved !== this.props.experienceRemoved && this.props.experienceRemoved){
			this.setState({
				lastExperienceRemovedId: null,
				showExperienceDeleteDialog: false,
			}, () => {
				this.refreshData();

				toast.success("Experience removed!", {
					position: toast.POSITION.TOP_RIGHT,
					className: 'confirm-bubble'
				});
			})
		}

		if(prevProps.currentUser !== this.props.currentUser && this.props.currentUser){

			this.setState({
				currentUser: this.props.currentUser,
				run: !this.props.tourDisabled,
			}, () =>{
				if(PRINT_CURRENT_USER)
					console.log("Current user:", this.state.currentUser)
			});

			if(this.props.currentUser.hasOwnProperty('fileCodeImage') && !this.state.readOnly)
				localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, this.props.currentUser.fileCodeImage.url);
		}

		if(this.props.profile !== prevProps.profile && this.props.profile){

			this.setState({
				profile: this.props.profile
			});
		}

		if(this.props.projects !== prevProps.projects){
			let currentRoles: any = [];
			let projectsOnline: boolean = false;

			const projects = this.props.projects.map((project: IProject) => {
				if(project.projectRole && project.status === 'ONLINE'){
					currentRoles.push(project.projectRole)
				}
				if(project.status === 'ONLINE') {
					projectsOnline = true;
				}

				return {
					project
				};
			});

			currentRoles = countWordInArray(currentRoles);

			//console.log(countWordInArray(currentRoles));

			let roles: any = [];

			currentRoles.forEach((role: any) => {
				roles.push(role.value);
			});

			const addWorkNumber = this.state.addWorkNumber - projects.length;

			this.setState({
				projects,
				roles,
				addWorkNumber: addWorkNumber > 0 ? addWorkNumber : 0,
				profileVisible: projectsOnline
			});

			//console.log('Current projects', this.props.projects);
		}

		if(prevProps.projectRemoved !== this.props.projectRemoved && this.props.projectRemoved){

			this.setState({
				projectIdToRemove: null,
				showProjectDeleteDialog: false,
				addWorkNumber: 3,
			});

			const userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);

			if(userId) {
				this.getProjects(+userId);

				toast.success("Project removed!", {
					position: toast.POSITION.TOP_RIGHT,
					className: 'confirm-bubble'
				});
			}
		}

		if(this.props.errorUser !== prevProps.errorUser && this.props.errorUser){
			this.setState({ currentUser: null }, () => {
				this.props.history.push(routes.ERROR_USER)
			})
		}
	}

	getCurrentUser(userId: number){
		this.getExperiences(userId);
		this.getProjects(userId);

		this.props.onGetCurrentUser();
	}

	getProfile(userId: number){
		this.getExperiences(userId);
		this.getProjects(userId);

		this.props.onGetUserById(userId);
	}

	getExperiences(userId: number) {
		this.props.onGetExperiencesList(userId);
	}

	getProjects(userId: number) {
		this.props.onGetProjectsByUserId(userId);
	}

	addExperience(){
		const userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);

		let experiences = [...this.state.experiences];

		const checkEmptyExperiences = experiences.filter(cv => cv.hasOwnProperty('new') && cv.new);

		if(userId && !checkEmptyExperiences.length){
			const emptyExperience = {
				id: randomId(),
				userId: +userId,
				company: '',
				role: '',
				description: '',
				dateStart: null,
				dateEnd: null,
				new: true
			};

			experiences.push(emptyExperience);

			this.setState({
				experiences
			}, () => {
				scroll.scrollToBottom({
					containerId: "curriculum-list",
					duration: 2000
				});
			})
		} else {
			if(!userId)
				console.error('Error on retrieving user id.');
			else
				alert('Save before last experience inserted.');
		}
	}

	onToggleDeleteExperienceConfirm(){
		const showExperienceDeleteDialog = !this.state.showExperienceDeleteDialog;

		this.setState({
			showExperienceDeleteDialog
		})
	}

	onDeleteExperience(id: number){
		this.setState({
			lastExperienceRemovedId: id,
		}, () => {
			this.onToggleDeleteExperienceConfirm()
		})
	}

	removeExperience(){
		if(this.state.lastExperienceRemovedId)
			this.props.onDeleteExperience(this.state.lastExperienceRemovedId)
	}

	closeExperience(id: number) {
		const experiences = this.state.experiences.filter((item: any) => item.id.toString() !== id.toString());

		this.setState({
			experiences
		})
	}

	setExperience(field: string, value: any, id: string){
		if(field === 'dateStart' || field === 'dateEnd'){
			value = moment(value).isValid() ? value : null;
		}

		let experiences = this.state.experiences.map((item: ICurriculum) => {

			if(item.new){
				return {
					...item,
					[field]: value,
					edited: true,
				}
			} else {
				if(item.id && +item.id === +id){

					return {
						...item,
						[field]: value,
						edited: true,
					}
				} else {
					return item;
				}
			}
		});

		this.setState({
			experiences
		}, () => {
			//console.log("New experiences:", experiences)
		})
	}

	refreshData(){
		const { currentUser } = this.state;

		if(currentUser)
			this.getExperiences(currentUser.id);
	}

	onEditProject(id: number, lastStep: string,){
		//console.log(`/work/${id}/${lastStep}`);
		this.props.history.push(`/work/${id}/${lastStep}`);
	}

	onToggleDeleteProjectConfirm(){
		const showProjectDeleteDialog = !this.state.showProjectDeleteDialog;

		this.setState({
			showProjectDeleteDialog
		})
	}

	onDeleteProject(id: number){
		this.setState({
			projectIdToRemove: id,
		}, () => {
			this.onToggleDeleteProjectConfirm()
		})
	}

	removeProject(){
		if(this.state.projectIdToRemove)
			this.props.onDeleteProject(this.state.projectIdToRemove)
	}

	handleJoyrideCallback = (data: any) => {
		const { action, index, status, type } = data;

		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
		  // Update state to advance the tour
		  this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
		else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
		  // Need to set our running state to false, so we can restart if we click start again.
		  this.setState({ run: false }, () => {

		  	if(this.state.currentUser)
		  	    this.props.onSetTourDisabled(this.state.currentUser.id)
		  });
		}

		// console.groupCollapsed(type);
		// console.log(data); //eslint-disable-line no-console
		// console.groupEnd();
	};

	restartTour = () => {
		this.setState({
			run: true
		})
	}

	render () {
		const { history, notifications, loadingProjects, loadingNotifications, tourDisabled } = this.props;

		const {
			projects,
			experiences,
			addWorkNumber,
			showProjectDeleteDialog,
			showExperienceDeleteDialog,
			roles,
			readOnly,
			showNotifications,
			steps,
			run,
			stepIndex,
			profileVisible
		} = this.state;

		if(showNotifications){
			return (

				<div className={classes.Profile}>
					<Template
						sidebar={<ProfileSidebar readOnly={readOnly} roles={roles} tourDisabled={run} restartTour={this.restartTour} />}
						sidebarSize="small">
						{
							loadingNotifications  ?
								<div className={classes["Profile-loading"]}>
									<Loader />
								</div> :
								<Notifications
									list={notifications}
									history={history}
									isRoute />
						}
					</Template>
				</div>
			)
		}

		return (
			<div className={classes.Profile}>
				{
					<Template
						sidebar={<ProfileSidebar readOnly={readOnly} roles={roles} tourDisabled={run} restartTour={this.restartTour} profileInvisible={!profileVisible} />}
						sidebarSize="small">
						{
							loadingProjects  ?
								<div className={classes["Profile-loading"]}>
									<Loader />
								</div>
								:
								<Fragment>
									<div className="first-step addwork-step">
									<div className={[classes['Profile-row'], classes['Profile-row--block'], classes['Profile-row--extra']].join(' ')}>
										<Projects
											readOnly={readOnly}
											onDelete={this.onDeleteProject}
											onEdit={this.onEditProject}
											list={projects}
											addWorkNumber={addWorkNumber} />
									</div>
									</div>
									<div className="second-step">
									<div className={[classes['Profile-row'], classes['Profile-row--column']].join(' ')}>
										<Curriculum
											readOnly={readOnly}
											loading={this.props.loadingExperiences}
											closeData={this.closeExperience}
											removeData={this.onDeleteExperience}
											add={this.addExperience}
											setData={this.setExperience}
											list={experiences}
											title={<FormattedMessage id={'curriculum.title'} />}/>
									</div>
									</div>
								</Fragment>
						}
					</Template>
				}
				{
					showProjectDeleteDialog &&
                    <Modal
	                    title={<FormattedMessage id={'remove.project.title'}/>}
                        onModalCloseClicked={this.onToggleDeleteProjectConfirm}>
						<div className={classes['Profile-remove-buttons']}>
							<Button type="danger" styles={{marginBottom: '10px'}} clicked={this.removeProject}><FormattedMessage id={'remove.project.confirm'}/></Button>
							<Button type="small" clicked={this.onToggleDeleteProjectConfirm}><FormattedMessage id={'remove.project.undo'}/></Button>
						</div>
                    </Modal>
				}
				{
					showExperienceDeleteDialog &&
                    <Modal
                        title={<FormattedMessage id={'remove.experience.title'}/>}
                        onModalCloseClicked={this.onToggleDeleteExperienceConfirm}>
                        <div className={classes['Profile-remove-buttons']}>
                            <Button type="danger" styles={{marginBottom: '10px'}} clicked={this.removeExperience}><FormattedMessage id={'remove.experience.confirm'}/></Button>
                            <Button type="small" clicked={this.onToggleDeleteExperienceConfirm}><FormattedMessage id={'remove.experience.undo'}/></Button>
                        </div>
                    </Modal>
				}
				{
					run &&
						<Joyride
							run={run}
							continuous={true}
							scrollToFirstStep={true}
							showProgress={true}
							showSkipButton={true}
							callback={this.handleJoyrideCallback}
							steps={steps}
							stepIndex={stepIndex}
							styles={{
								options: {
									primaryColor: '#FD970C',
								}
							}} />
				}
			</div>
		)
    }
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		tourDisabled: state.userState.tourDisabled,
		profile: state.userState.selected,
		errorUser: state.userState.error,
		loading: state.curriculumState.isStoring,
		error: state.curriculumState.error,
		experiences: state.curriculumState.list,
		loadingExperiences: state.curriculumState.isFetching,
		experienceRemoved: state.curriculumState.removed,
		projects: state.projectState.list,
		loadingProjects: state.projectState.isFetching,
		projectRemoved: state.projectState.removed,
		notifications: state.notificationState.list,
		loadingNotifications: state.notificationState.isFetching,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetUserById: (userId: number) => dispatch(actions.fetchUserById(userId)),
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onSetTourDisabled: (userId: number) => dispatch(actions.setTourDisabled(userId)),
		onGetExperiencesList: ( userId: number ) => dispatch( actions.fetchCurriculumByUser(userId) ),
		onDeleteExperience: ( id: number ) => dispatch( actions.deleteCurriculum(id) ),
		onGetProjectsByUserId: (userId: number) => dispatch(actions.fetchProjectsByUser(userId)),
		onDeleteProject: (id: number) => dispatch(actions.deleteProject(id)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Profile );
