import React from 'react';
import classes from './LockIndicator.module.scss';
import ReactTooltip from 'react-tooltip';

interface indicatorProps {
	lock?: boolean;
	small?: boolean;
}

const LockIndicator = ({ lock, small }: indicatorProps) => {

	const tip = lock ? <p>Informations will be visible only to you and to those you give access to</p> : <p>Only these informations will be visible to all the Club members.</p>;

	const iconTpl = (
		small ? 
		<div className={classes['Original-lock-icon']} />
		:
		<div className={classes['Original-lock-icon']} data-tip data-for='lock'>
			<ReactTooltip id='lock' effect="solid" place="top" className={classes['Original-lock-tip']}>
				{ tip }
			</ReactTooltip>
		</div>
	);

	return (
		<div
			className={[classes["Original-lock"], lock ? '' : classes["Original-lock--unlock"], small ? classes["Original-lock--small"] : ''].join(' ')}>
				{ iconTpl }
		</div>
	)
};

export default LockIndicator;
