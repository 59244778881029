import React, { Component } from 'react';
import classes from './Step5.module.scss';
import stepClasses from '../Steps.module.scss';
import constants from '../../../../shared/constants';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import * as ReactGA from '../../../../GA';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { IBell, IInput, IMood, IOriginalCover, IOriginalRequest, ISelect, IUser } from '../../../../shared/interfaces';
import UsersAutocomplete from '../../../UsersAutocomplete/UsersAutocomplete';
import OriginalCard from '../../../OriginalCard/OriginalCard';
import TagsInput from '../../../TagsInput/TagsInput';
import CheckboxSlider from '../../../CheckboxSlider/CheckboxSlider';
import UploadBox from '../../../UploadBox/UploadBox';
import Button from '../../../Button/Button';
import Loader from '../../../Loader/Loader';

interface IProps {
	setData: (field: string, value: any) => void;
	data: any;
	onGetUsersByFilters(type: string, value: string): Array<IUser>;
	onGetRequestsByOriginal(id: number): Array<IOriginalRequest>;
	onCreateRequest(userId: number, originalId: number): Array<IOriginalRequest>;
	onRemoveRequest(originalId: number, requestId: number): any;
	onResetFile: () => void;
	onResetCover: () => void;
	onCreateFile: (data: any) => any;
	file: any;
	savingFile: boolean;
	onUpdateCover(originalId: number, data: { fileCodeImage: string }): void;
	onRemoveCover(originalId: number): void;
	onGetBells: () => Array<IBell>;
	bellsList: Array<IMood>;
	requests: Array<IOriginalRequest>;
	users: Array<IUser>;
	loading: boolean;
	editMode: boolean;
	currentCoverUrls: IOriginalCover | null;
	coverStoring: boolean;
	coverRemoved: boolean;
	coverError: string;
}

interface IState {
	title: IInput;
	bells: Array<string>;
	description: string;
	inspiration: string;
	suggestions: Array<ISelect>;
	currentUser: { name: string; thumbnailUrl?: string | null };
	currentOriginalId: number | null;
	currentRequestsUserObjects: Array<{ userId: number; userName: string }>;
	applyForCall: boolean;
}

interface ITempRequest {
	userId: number;
	userName: string;
}

class Step5 extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			currentOriginalId: null,
			bells: [],
			title: {
				name: 'title',
				placeholderId: 'placeholder.originalTitle',
				value: '',
				visible: true,
				readOnly: false,
				type: 'text',
			},
			description: '',
			inspiration: '',
			suggestions: [],
			currentUser: {
				name: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_NAME) + ' ' + localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME),
				thumbnailUrl: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE)
			},
			currentRequestsUserObjects: [],
			applyForCall: false
		};
	}

	componentDidMount(): void {
		window.scrollTo(0,0);
		this.props.onGetBells();

		const { editMode, data } = this.props;

		if(editMode && data.hasOwnProperty('id')) {
			ReactGA.pageview(`/original/${data.id}/1`);
		}
		else {
			ReactGA.pageview('/add-original/1');
		}
	}

	componentWillUnmount(): void {
		this.props.onResetFile();
		this.props.onResetCover();
	}

	handleUploadCover = (uploaded: any) => {
		const data = new FormData();

		data.append('file', uploaded[0]);

		this.props.onCreateFile(data);
	};

	handleRemoveCover = () => {
		if(this.state.currentOriginalId)
			this.props.onRemoveCover(this.state.currentOriginalId);
	};

	handleChangeUsers = (values: Array<ISelect>) => {
		const { currentOriginalId } = this.state;

		if(currentOriginalId && values.length){

			const currentRequestsUserObjects = values.map((value: ISelect) => {
				return {
					userId: +value.value,
					userName: value.label,
				}
			});

			this.setState({
				currentRequestsUserObjects: this.state.currentRequestsUserObjects.concat(currentRequestsUserObjects)
			}, () => {
				this.props.setData('requestsUsersIds', this.state.currentRequestsUserObjects.map((value: ITempRequest) => +value.userId));
			})
		}
	};

	removeUserRequest = (userId: number) => {
		const { currentOriginalId } = this.state;

		if(currentOriginalId){

			const currentRequestsUserObjects = this.state.currentRequestsUserObjects.filter((user: ITempRequest) => user.userId !== userId);

			this.setState({
				currentRequestsUserObjects
			}, () => {
				this.props.setData('requestsUsersIds', this.state.currentRequestsUserObjects.map((value: ITempRequest) => +value.userId));
			})
		}
	};

	onSearchUser = (key: string) => {
		if(key.length > 2){
			this.props.onGetUsersByFilters('name', key);
		} else {
			if(this.state.suggestions.length){
				this.setState({
					suggestions: []
				})
			}
		}
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevProps.requests !== this.props.requests) {
			this.setState({
				currentRequestsUserObjects: this.props.requests
					.filter((request: IOriginalRequest) => request.status === 'APPROVED')
					.map((request: IOriginalRequest) => {
					return {
						userId: request.userId,
						userName: request.user.name + ' ' + request.user.surname
					}
				})
			}, () => {
				this.props.setData('requestsUsersIds', this.state.currentRequestsUserObjects.map((value: ITempRequest) => +value.userId));
			})
		}

		if(this.props.data && !this.state.currentOriginalId) {
			this.setState({
				currentOriginalId: this.props.data.id
			}, () => {
				console.log('Current original ID:', this.props.data.id);

				this.props.onGetRequestsByOriginal(this.props.data.id);
			})
		}

		if(prevProps.users !== this.props.users) {
			let suggestions: Array<ISelect> = [];

			let idsToExclude: Array<number> = this.state.currentRequestsUserObjects.length ? this.state.currentRequestsUserObjects.map((request: { userId: number; userName: string }) => +request.userId) : [];

			idsToExclude = idsToExclude.concat(this.props.requests.map((item: IOriginalRequest) => item.userId));

			if(localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID)){
				idsToExclude.push(Number(localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID)))
			}

			idsToExclude = idsToExclude.concat(this.props.data.collaboratorsInDraft);

			if(this.props.users.length) {
				const filteredUsers = this.props.users.filter((user: IUser) => {
					return idsToExclude.indexOf(user.id) === -1;
				});

				suggestions = filteredUsers.map((user: IUser) => {
					return {
						value: user.id.toString(),
						label: user.name + ' ' + user.surname,
					}
				});
			}

			this.setState({
				suggestions
			})
		}

		if(this.props.file !== prevProps.file && this.props.file){
			const file = this.props.file[0];

			const data = {
				fileCodeImage: file.code
			};

			if(this.state.currentOriginalId)
				this.props.onUpdateCover(this.state.currentOriginalId, data)
		}

		if(this.props.currentCoverUrls !== prevProps.currentCoverUrls && this.props.currentCoverUrls){
			this.props.onResetFile();
		}
	}

	handleChangeTags = (key: string, value: Array<any>) => {
		this.setState({
			bells: value,
		}, () => {
			this.props.setData(key, value);
		});
	};

	handleApplyForCall = () => {

		let applying = this.state.applyForCall;

		this.setState({
			applyForCall: !applying
		})
	};

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let title = nextState.title;
		let description = nextState.description;
		let inspiration = nextState.inspiration;
		let bells = nextState.bells;
		let applyForCall = nextState.applyForCall;

		if(nextProps.data){
			title = {
				...title,
				value: nextProps.data.title || '',
			};

			description = nextProps.data.description;
			inspiration = nextProps.data.inspiration;
			bells = nextProps.data.bell.map((text: any) => text.title);
			applyForCall = bells.length > 0 ? true : nextState.applyForCall;
		}

		return {
			title,
			bells,
			description,
			inspiration,
			applyForCall
		}
	}

    render () {
		const { suggestions, currentUser: { name, thumbnailUrl }, currentRequestsUserObjects, bells, applyForCall } = this.state;
		const { loading, data, requests, bellsList, coverStoring, savingFile, currentCoverUrls } = this.props;

		const collaboratorsTpl = requests.filter((request: IOriginalRequest) => request.status ==='COLLABORATION' || request.status ==='WAITING').map((request: IOriginalRequest, index: number) => {
			return (
				<span key={index}>{ request.user.name } { request.user.surname }</span>
			)
		});

	    const pendingRequest = requests.filter((request: IOriginalRequest) => request.status === 'PENDING');
	    const rejectedRequest = requests.filter((request: IOriginalRequest) => request.status === 'REJECTED');

        return (
	        <div className={classes.Step5}>
		        <div className={classes['Step5-content']}>
					<div className={[classes['Step5-row'], classes['Step5-row--large'], classes['Step5-row--noline']].join(' ')}>

						<div className={classes['Step5-row--flex']}>
							<div className={[classes['Step5-flex-item'], classes['Step5-flex-item--small']].join(' ')}>
								<div className={classes['Step5-header-small']}>
									<label className={stepClasses['Steps-label']} style={{marginBottom: 0}}>
										<FormattedMessage id={'placeholder.originalPreview'} />
									</label>
									{
										currentCoverUrls &&
										<Button
											type="danger-smaller"
											clicked={this.handleRemoveCover}>
											{ 'Remove cover' }
										</Button>
									}
								</div>

								<div className={classes['Step5-cover']}>
									{
										!currentCoverUrls && !coverStoring && !savingFile &&
										<UploadBox
											disabled={coverStoring || savingFile}
											titleId={'upload.cover.title'}
											accept={'image/jpeg, image/png'}
											onSelectedFiles={this.handleUploadCover}
											multiple={false}
											styles={{margin: '0 0 10px 0'}}/>
									}
								</div>

								<div className={classes['Step5-card-container']}>
									{
										(coverStoring || savingFile) &&
										<div className={classes['Step5-loading']}>
											<Loader />
										</div>
									}
									<OriginalCard
										backgroundImageUrl={currentCoverUrls ? currentCoverUrls.medium : undefined}
										original={data}
										userName={name}
										userThumbnail={thumbnailUrl}
										full />
								</div>
							</div>
							<div className={[classes['Step5-flex-item'], classes['Step5-flex-item--large']].join(' ')}>

								{/* <div className={[classes['Step5-spacer-item'], classes['Step5-highlight-item']].join(' ')}>
									<div className={classes['Step5-icon-indicator']}>💡</div>
									<CheckboxSlider
										name={'apply-for-call'}
										clicked={this.handleApplyForCall.bind(this)}
										labelId={applyForCall ? 'label.originalApplyingForCallTrue' : 'label.originalApplyingForCall'}
										checked={applyForCall}
										tooltip={<FormattedHTMLMessage id="label.originalApplyingForCallTooltip" />}
										optional
										autoSize />

									{
										applyForCall &&
										<div className={classes['Step5-row-item']} style={{marginTop: '25px'}}>
											<FormattedMessage id={'myOriginals.bellInfo'} defaultMessage={'Add new bell'}>
												{
													(placeholder: any) =>
														<TagsInput
															changed={(values: Array<any>) => this.handleChangeTags('bell', values)}
															suggestions={bellsList}
															placeholder={placeholder}
															selected={bells} />
												}
											</FormattedMessage>

											<div className={classes['Step5-flex-item']} style={{marginTop: '10px'}}>
												<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
													<div className={stepClasses['Steps-info-box-content']}>
														<FormattedHTMLMessage id={'myOriginals.chipsInfo.call'} />
													</div>
													<div className={stepClasses['Steps-info-box-icon']} />
												</div>
											</div>
										</div>
									}
								</div> */}


								<label className={stepClasses['Steps-label']}>
									<FormattedMessage id={'label.originalGrantAccess'} />
								</label>
								<p style={{marginTop: 0}}>
									<FormattedMessage id="placeholder.originalPreview.access.text.full" /></p>
								<UsersAutocomplete
									isSingleValue={true}
									loading={loading}
									selected={[]}
									suggestions={suggestions}
									onSubmit={this.handleChangeUsers}
									onInputChanged={this.onSearchUser}/>
								<div className={classes['Step5-requests']}>
									{ currentRequestsUserObjects.length > 0 &&
										<label className={stepClasses['Steps-label']}>
											<FormattedMessage id={'label.usersWithAccess'} />
										</label>
									}
									{
										currentRequestsUserObjects.map((request: ITempRequest, index: number) => {
											return (
												<span key={index}>{ request.userName } <button className={classes['Step5-requests-button']} onClick={() => this.removeUserRequest(request.userId)}><span>REMOVE</span></button></span>
											)
										})
									}
								</div>
								{
									pendingRequest.length > 0 &&
                                    <div className={classes['Step5-requests']}>
                                        <label className={stepClasses['Steps-label']}>
                                            <FormattedMessage id={'label.usersPending'} />
                                        </label>
										{
											pendingRequest.map((request: IOriginalRequest, index: number) => {
												return (
													<span key={index}>{ request.user.name } { request.user.surname }</span>
												)
											})
										}
                                    </div>
								}
								{
									rejectedRequest.length > 0 &&
									<div className={classes['Step5-requests']}>
                                        <label className={stepClasses['Steps-label']}>
                                            <FormattedMessage id={'label.usersRejected'} />
                                        </label>
										{
											rejectedRequest.map((request: IOriginalRequest, index: number) => {
												return (
													<span key={index}>{ request.user.name } { request.user.surname }</span>
												)
											})
										}
									</div>
								}

								{ collaboratorsTpl.length > 0 &&
									<div className={classes['Step5-requests']}>
										<label className={stepClasses['Steps-label']}>
											<FormattedMessage id={'label.originalCollaborations'} />
										</label>
										{ collaboratorsTpl }
									</div>
								}
							</div>
						</div>

					</div>
		        </div>
	        </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		users: state.userState.users,
		loading: state.userState.isFetching,
		error: state.userState.error,
		requests: state.originalState.requests,
		bellsList: state.bellState.bells,
		file: state.fileState.file,
		savingFile: state.fileState.isStoring,
		currentCoverUrls: state.originalState.currentCoverUrls,
		coverStoring: state.originalState.coverStoring,
		coverRemoved: state.originalState.coverRemoved,
		coverError: state.originalState.coverError,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetUsersByFilters: (type: string, value: string) => dispatch(actions.fetchUsersByFilters(type, value)),
		onGetRequestsByOriginal: (id: number) => dispatch(actions.fetchRequestsByOriginal(id)),
		onCreateRequest: (userId: number, originalId: number) => dispatch(actions.addRequestToOriginal(userId, originalId)),
		onRemoveRequest: (originalId: number, requestId: number) => dispatch(actions.removeRequestFromOriginal(originalId, requestId)),
		onGetBells: () => dispatch(actions.fetchBells()),
		onCreateFile: (data: any) => dispatch(actions.createFile(data)),
		onResetFile: () => dispatch(actions.resetFile()),
		onUpdateCover: (originalId: number, data: { fileCodeImage: string }) => dispatch(actions.updateCover(originalId, data)),
		onRemoveCover: (originalId: number) => dispatch(actions.deleteCover(originalId)),
		onResetCover: () => dispatch(actions.resetCover()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Step5 );
