import React, { Component, Fragment } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import autosize from 'autosize';
import { FormattedMessage } from 'react-intl';
import classes from './EditorTextarea.module.scss';
import './style.scss';
import ReactTooltip from 'react-tooltip';

interface IProps {
    toolbarHidden?: boolean;
    labelId?: string;
    placeholderId?: string;
    styles?: object;
    value?: any;
	onChange?: any;
	color?: string;
	required?: boolean;
	isPlainText?: boolean;
	minHeight?: boolean;
	maxLength?: number;
	validation?: (status: boolean) => void;
	autosize?: boolean;
	id?: string;
}
interface IState {
    editorState: any;
    error: boolean;
    value: string;
}

const EDITOR_OPTIONS = {
    options: ['inline', 'history'],
    inline: {
	    options: ['bold', 'italic', 'underline', 'strikethrough'],
	    bold: { className: classes['EditorTextarea-button'] },
	    italic: { className: classes['EditorTextarea-button'] },
	    underline: { className: classes['EditorTextarea-button'] },
	    strikethrough: { className: classes['EditorTextarea-button'] }
    }
};

class EditorTextarea extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const html = props.value ? props.value : '<p></p>';
		const contentBlock = htmlToDraft(html);

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			this.state = {
				editorState,
				error: false,
				value: props.value,
			};
		}
	}

	componentDidMount() {
		if(this.props.isPlainText && autosize && this.props.id){
			// autosize(document.querySelectorAll('textarea'));
			autosize(document.getElementById(this.props.id));
		}
	}

	checkLength = (value: string) => {
		let isOk = true;

		if (this.props.maxLength) {
			if (value.length > this.props.maxLength) {
				isOk = false;
			}
		}

		this.setState({
			error: !isOk
		}, () => {
			if(this.props.validation){
				this.props.validation(!isOk)
			}
		});

		return isOk;
	};

	onEditorStateChange = (editorState: any) => {
		const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));

		this.checkLength(value);

		const { isPlainText } = this.props;

		if(!isPlainText){
			this.setState({
				editorState,
			}, this.props.onChange(value))
		}
	};

	onTextAreaChange = (value: string) => {
		this.checkLength(value);

		this.setState({
			value,
		}, () => {
			this.props.onChange(this.state.value);
		})
	};

    render() {
    	const {
    		editorState,
		    error,
		    value
	    } = this.state;

	    const {
			toolbarHidden,
			labelId,
	        placeholderId,
			styles,
			color,
		    isPlainText,
			required,
			//value,
			minHeight,
			maxLength,
			id
	    } = this.props;

	    return (
	        <div className={[
	        	classes.EditorTextarea,
		        minHeight ? classes['EditorTextarea-minheight'] : '',
		        error ? classes['EditorTextarea--error'] : ''
	        ].join(' ')}>
				{ labelId &&
					<label className={classes["EditorTextarea-label"]} data-for={placeholderId}>
						<FormattedMessage id={labelId}/>
						{
							required &&
							<Fragment>
								<span style={{color: '#D05A5A', marginLeft: '4px', fontSize: '1.3rem'}}>*</span>
								<ReactTooltip id={placeholderId} effect="solid">Required</ReactTooltip>
							</Fragment>
						}
					</label>
				}
		        {
		        	!isPlainText ?
				        <>
		                    <FormattedMessage
		                        id={placeholderId || 'general.emptyMessage'}
		                        defaultMessage={''}>
						        {
							        (placeholder: any) => (
								        <Editor
									        stripPastedStyles={true}
									        wrapperStyle={styles}
									        toolbarHidden={toolbarHidden}
									        editorState={editorState}
									        toolbarClassName={[classes['EditorTextarea-toolbar'], color ? classes[`EditorTextarea-toolbar--${color}`] : ''].join(' ')}
									        wrapperClassName={[classes['EditorTextarea-wrapper'], color ? classes[`EditorTextarea-wrapper--${color}`] : ''].join(' ')}
									        editorClassName={
										        toolbarHidden ?
											        classes['EditorTextarea-editor'] :
											        [classes['EditorTextarea-editor'], classes['EditorTextarea-editor--withToolbar']].join(' ')
									        }
									        toolbar={EDITOR_OPTIONS}
									        placeholder={placeholder}
									        onEditorStateChange={this.onEditorStateChange}
								        />
							        )
						        }
		                    </FormattedMessage>
					        <textarea
								id={id}
						        style={{width: '1px', height: '1px', visibility: 'hidden'}}
						        disabled
						        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
			            </> :
                        <FormattedMessage
                            id={placeholderId || 'general.emptyMessage'}
                            defaultMessage={''}>
					        {
						        (placeholder: any) => (
						        	<div className={[classes['EditorTextarea-wrapper'], color ? classes[`EditorTextarea-wrapper--${color}`] : ''].join(' ')}>
	                                    <textarea
											id={id}
		                                    style={styles}
	                                        placeholder={placeholder}
	                                        className={classes['EditorTextarea-editor']}
	                                        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => this.onTextAreaChange(e.currentTarget.value)}
	                                        value={value}
	                                    />
							        </div>
						        )
					        }
                        </FormattedMessage>

		        }
		        {
		        	maxLength &&
				        <span className={classes['EditorTextarea-counter']}>{ `${value ? value.length : '0'}/${maxLength}`  }</span>
		        }
	         </div>
	    );
    }
}

export default EditorTextarea;
