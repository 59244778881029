import React, {Component, Fragment} from 'react';
import moment from 'moment';
import stepsClasses from '../Steps.module.scss';
import classes from './Step3.module.scss';
import Input from '../../../Input/Input';
import ChecksButtons from '../../../ChecksButtons/ChecksButtons';
import { FormattedMessage } from 'react-intl';
import MonthPicker from '../../../MonthPicker/MonthPicker';
import * as ReactGA from '../../../../GA';
import { IAnswer } from '../../../../shared/interfaces';
import ReactTooltip from 'react-tooltip';

interface IProps {
	questions: any;
	setData: (field: string, value: any) => void;
	data: any;
}

interface IState {
	controls: any;
	questions: any;
	answers: any;
}

class Step3 extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			controls: {
				projectRole: {
					name: 'projectRole',
					value: '',
					label: 'label.role',
					placeholderId: 'placeholder.role',
					type: 'text',
					styles: {
						margin: '0 0 10px 0',
					},
					visible: true,
					readOnly: false,
					required: true
				},
				client: {
					name: 'client',
					value: '',
					label: 'label.client',
					placeholderId: 'placeholder.client',
					type: 'text',
					styles: {
						margin: '0 0 10px 0',
					},
					visible: true,
					readOnly: false,
					tooltip: "Who financed your project? Who published your project?"
				},
				projectDate: {
					name: 'projectDate',
					value: '',
					label: 'label.date',
					placeholderId: 'When did you complete this project?',
					type: 'date',
					styles: {
						margin: '0 0 10px 0',
					},
					visible: true,
					readOnly: false,
					required: true
				},
			},
			questions: [],
			answers: [],
		};
	}

	componentDidMount(): void {
		window.scrollTo(0,0);
		ReactGA.pageview('/add-work/3');
	}

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;

		let controls = {...this.state.controls};

		controls[inputName].value = inputValue;

		this.setState({
			controls,
		}, () => {
			this.props.setData(inputName, inputValue);
		});
	};

	handleDateChanged = (date: any, name: string) => {
		const inputName = name;
		const inputValue = moment(date).format('YYYY-MM-DD');

		let controls = {...this.state.controls};

		controls[inputName].value = inputValue;

		this.setState({
			controls,
		}, () => {
			this.props.setData(inputName, inputValue);
		});
	};


	handleChecksButtonsChanged = (field: string, parentId: string, selectedIds: Array<{ id: string }>): void => {
		const question = {
			id: +parentId,
			answer: selectedIds
		};

		this.props.setData('questions', question);
	};

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let controls = nextState.controls;
		let questions = nextState.questions;
		let answers = nextState.answers;

		if(nextProps.questions && !nextState.questions.length){
			questions = nextProps.questions;
		}

		if(nextProps.data){
			controls = {
				...controls,
				projectRole: {
					...controls.projectRole,
					value: nextProps.data.projectRole || ''
				},
				projectDate: {
					...controls.projectDate,
					value: nextProps.data.projectDate || ''
				},
				client: {
					...controls.client,
					value: nextProps.data.client || ''
				},
			};

			answers = nextProps.data.questions;
		}

		return {
			controls,
			questions,
			answers
		}
	}

	render () {
        const { controls, questions, answers } = this.state;

	    const inputElementsTpl = Object.keys(controls).map((key: string) => {
		    let years: any = [];

		    for(let i = -5; i <= 20; i++){
			    years.push(i)
		    }

		    return (
				<div key={key} className={classes['Step3-question']}>
					{
						controls[key].type === 'text'
						?
							<Input
								key={controls[key].name}
								styles={controls[key].styles}
								name={controls[key].name}
								value={controls[key].value}
								label={controls[key].label}
								changed={this.handleInputChanged.bind(this)}
								placeholderId={controls[key].placeholderId}
								visible={controls[key].visible}
								readOnly={controls[key].readOnly}
								type={controls[key].type}
								required={controls[key].required}
								tooltip={controls[key].tooltip} />
						:
							<div>
								<div
									className={stepsClasses['Steps-label']}
									data-tip
									data-for={`${controls[key].required ? controls[key].name : ''}`}>
									<FormattedMessage id={controls[key].label} />
									{ controls[key].required ?
										<Fragment>
											<span
												style={{color: '#D05A5A', marginLeft: '4px', fontSize: '1.3rem'}}>
												*
											</span>
											<ReactTooltip id={controls[key].name} effect="solid">
												Required
											</ReactTooltip>
										</Fragment>
										: ''
									}
								</div>
								<MonthPicker
									years={years}
									date={controls[key].value}
									name={`dateStart_${controls[key].name}`}
									placeholder={controls[key].placeholderId}
									changed={(date) => this.handleDateChanged(date, controls[key].name)} />
							</div>
					}
				</div>
		    )
		});

		const questionsTpl = questions.map((question: any, index: number) => {
			const answersData = answers.find((i: { id: string }) => +i.id === +question.id);

			return (
				<div className={classes['Step3-row']} key={index}>
					<div className={[classes['Step3-flex-item'], classes['Step3-flex-item--large']].join(' ')}>
						<ChecksButtons
							parentId={question.id}
							key={question.id}
							name={question.title}
							changed={this.handleChecksButtonsChanged}
							options={question.answers}
							selectedIds={answersData ? answersData.answer.map((item: IAnswer) => { return { id: item.id } }) : []}
							label={question.title}
							maxSelections={3}
							required={true}
						/>
					</div>
					{/* <div className={classes['Step3-flex-item']}>
						<div className={[stepsClasses['Steps-info-box'], stepsClasses['Steps-info-box--info']].join(' ')}>
							<div className={stepsClasses['Steps-info-box-content']}>
								<FormattedMessage id={'myOriginals.chipsInfo'} />
							</div>
							<div className={stepsClasses['Steps-info-box-icon']} />
						</div>
					</div> */}
				</div>
			)
		});


        return (
	        <div className={classes.Step3}>
		        <div className={classes['Step3-content']}>
					<div className={[stepsClasses['Steps-main--full'], stepsClasses['Steps-main--justify']].join(' ')}>
						{ inputElementsTpl }
					</div>
			        <div className={stepsClasses['Steps-main--full']}>
				        { questionsTpl }
			        </div>
		        </div>
	        </div>
        )
    }
}

export default Step3;
