import React from 'react';
import classes from './Select.module.scss';
import SelectItem from './SelectItem/SelectItem'

interface IProps {
	styles: object;
	emptySelection: object;
	options: any;
	name: string;
	placeholder?: string;
	showList: boolean;
	clicked: any;
	itemClicked: any;
	reference?: any;
	disabled?: boolean;
	selected: boolean;
    value: any;
    dark?: boolean;
    transparent?: boolean;
}

const select = (props: IProps) => {
    const { reference, disabled, emptySelection, selected, options, name, clicked, itemClicked, styles, placeholder, showList, dark, transparent } = props;

    const allOptions = options.map((item: any, i: number) => {
        return <SelectItem
                    key={i}
                    id={item.id}
                    itemClicked={itemClicked}
                    inputName={name}
                    description={item['description']}
                    value={item['value']} />
    });

    return (
        <div style={styles}
             className={[classes.Select, showList ? classes["is-open"] : '', disabled ? classes["is-disabled"] : '', dark ? classes['Select--dark'] : '', transparent ? classes['Select--transparent'] : ''].join(' ')}
             ref={reference}>
            <button
                className={classes["Select-button"]}
                onClick={clicked}
                disabled={disabled}
                placeholder={placeholder}
                data-name={name}
            >{ selected || emptySelection }</button>
            <div className={[classes["Select-list"], showList ? classes["is-visible"] : ''].join(' ')}>{ allOptions }</div>
        </div>
    );
};

export default select;
