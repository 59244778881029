import React, { Fragment } from 'react';
import classes from './BackgroundBox.module.scss';

interface backgroundBoxProps {
	children?: any;
	backgroundUrl: string;
	clicked?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
	type?: string;
	shadow?: boolean;
}

const backgroundBox = ({ children, backgroundUrl, clicked, type, shadow }: backgroundBoxProps) => {

	const backgroundStyles = {
		backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none'
	};

    return (
			<Fragment>
				<button onClick={clicked} className={classes.BackgroundBox} style={backgroundStyles}>
					<div className={classes["BackgroundBox-content"]}>
						{
							children
						}
					</div>
					{
						type &&
						<div className={[classes['BackgroundBox--type'], shadow ? classes['BackgroundBox--type-small'] : ''].join(' ')}>
							<span>{ type }</span>
						</div>
					}
				</button>
			</Fragment>
    );
};

export default backgroundBox;
