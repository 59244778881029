import request from '../../shared/lib/requestNoApi'
import constants from '../../shared/constants';

// Axios
import axios from 'axios';
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const API_BASE = process.env.REACT_APP_API_ENDPOINT && process.env.REACT_APP_API_ENDPOINT.replace('/api', '');

const reset = () => {
	if(source){
		source.cancel('Previous request cancelled');
	}

	return true;
};

const create = async (data: object) => {
	if(source){
		source.cancel('Previous request cancelled');
	}
	source = CancelToken.source();
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`,
		'Content-Type': 'multipart/form-data'
	};

	/*
    return request({
        url:    '/file',
        method: 'POST',
        headers,
        data
    });*/

	let res = await axios.post(
		API_BASE + '/file',
		data, {
			headers,
			cancelToken: source.token
		}).then((response)=>{
		return response;
	}).catch((error) => {
		if (axios.isCancel(error)) {
			console.log('POST Request cancelled');
		}
	});

	if(res)
		return res.data;
	else
		return {
			data: null,
			message: 'Request cancelled',
		}
};

const get = (id: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	//const endpoint = id ? `/file/${id}` : '/file';

	return request({
		url:    `/file/${id}`,
		method: 'GET',
		headers
	});
};

const FilesService = {
    create, get, reset
};

export default FilesService;
