import React, {Component, Fragment} from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import constants from '../../shared/constants';
import { IOriginal, IOriginalRequest, ISelect, IUser } from '../../shared/interfaces';
import * as routes from "../../shared/routes";
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import { connect } from 'react-redux';
import classes from './MyOriginals.module.scss';
import Template from '../../containers/Template/Template';
import Loader from '../Loader/Loader';
import MyOriginalsSidebar from '../MyOriginalsSidebar/MyOriginalsSidebar';
import MyOriginalsAdd from './MyOriginalsAdd/MyOriginalsAdd';
import OriginalCard from '../OriginalCard/OriginalCard';
import OriginalEmptyCard from '../OriginalEmptyCard/OriginalEmptyCard';
import Modal from '../../containers/Modal/Modal';
import UsersAutocomplete from '../UsersAutocomplete/UsersAutocomplete';
import Button from '../Button/Button';
import Masonry from 'react-masonry-component';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import './Tour.scss';

const LIMIT = 12;

interface IProps {
	onGetUsersByFilters(type: string, value: string): Array<IUser>;
	onGetCurrentUser(): IUser;
	onGetMyOriginals(userId: number, page: number, limit: number, offset: number): Array<IOriginal>;
	onGetRequestsByOriginal(id: number): Array<IOriginalRequest>;
	onCreateRequest(userId: number, originalId: number): Array<IOriginalRequest>;
	onRemoveRequest(originalId: number, requestId: number): any;
	onDeleteOriginal(originalId: number): any;
	onResetMyOriginals(): void;
	onSetTourDisabled(userId: number): void;
	currentUser: IUser;
	history?: any;
	originals: Array<IOriginal>;
	originalsError: any;
	originalsLoading: boolean;
	userLoading: boolean;
	requests: Array<IOriginalRequest>;
	requestAdded: boolean;
	requestRemoved: boolean;
	requestsLoading: boolean;
	users: Array<IUser>;
	usersLoading: boolean;
	usersError: boolean;
	storingRequest: boolean;
	isLastPage: boolean;
	originalRemoved: boolean;
	tourDisabled: boolean;
}

interface IState {
	currentUser: IUser | null;
	roles: any;
	showManageAccessModal: boolean;
	originalToManage: IOriginal | null;
	suggestions: Array<ISelect>;
	currentPage: number;
	showOriginalDeleteDialog: boolean;
	originalIdToRemove: number;
	steps: Array<any>;
	run: boolean;
	stepIndex: number;
}

const masonryOptions = {
	transitionDuration: 0,
	gutter: '.gutter-sizer',
};

class MyOriginals extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			currentUser: null,
			roles: [],
			showManageAccessModal: false,
			originalToManage: null,
			suggestions: [],
			currentPage: 1,
			showOriginalDeleteDialog: false,
			originalIdToRemove: -1,
			steps: [
				{
					target: '.myoriginals-step',
					content: <div className="Tour-content"><h2>This is where the magic happens</h2><p>This is your <strong>private space</strong> to <strong>develop new ideas</strong>.</p></div>,
					disableBeacon: true,
				},
				{
					target: '.originals-step',
					content: <div className="Tour-content"><h2>Sharing is caring</h2><p>Here you can <strong>browse all the Originals, new ideas published</strong> by the members of the Club, and <strong>request access</strong> to them.</p></div>,
				},
				{
					target: '.myprofile-step',
					content: <div className="Tour-content"><h2>Introduce yourself</h2><p>Add a collection of your best <strong>finished projects</strong>, your <strong>work</strong> and <strong>academic experiences</strong>. These informations will be <strong>visible to everyone</strong>.</p></div>
				},
				{
					target: '.network-step',
					content: <div className="Tour-content"><h2>Meet the Club</h2><p>Here you can <strong>browse the Network</strong>. You <strong>will be visible</strong> as soon as you <strong>add one finished project to your Portfolio</strong>.</p></div>,
				},
				{
					target: '.messages-step',
					content: <div className="Tour-content"><h2>Chat to create</h2><p>Meaningful connections start with a chat, here you can find all your <strong>conversations with the other members</strong>.</p></div>,
				},
				// {
				// 	target: '.addwork-step',
				// 	content: <div className="Tour-content"><h2>Start with your bests</h2><p>Complete your portfolio by <strong>adding</strong> at least <strong>one project in order to be visible</strong> in the Network.</p></div>,
				// },
				// {
				// 	target: '.addcv-step',
				// 	content: <div className="Tour-content"><h2>Your story</h2><p>Add any <strong>professional</strong> or <strong>educational experience</strong> about your current and previous <strong>work</strong>.</p></div>,
				// },
				// {
				// 	target: '.becreative-step',
				// 	content: <div className="Tour-content"><h2>BE CREATIVE!</h2></div>,
				// },
			],
			run: false,
			stepIndex: 0, // a controlled tour
		};
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
		ReactGA.pageview('/my-originals');

		this.getCurrentUser();
	}

	getCurrentUser(){
		if(!this.props.currentUser)
			this.props.onGetCurrentUser();
		else {
			this.props.onGetMyOriginals(this.props.currentUser.id, 1, LIMIT, 0);
			this.initScroll();
		}
	}

	initScroll = () => {
		window.addEventListener('scroll', this.handleScroll, false);
	};

	handleScroll = () => {
		const { originalsLoading, isLastPage } = this.props;
		const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
		const body = document.body;
		const html = document.documentElement;
		const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
		const windowBottom = windowHeight + window.pageYOffset;
		if (windowBottom >= docHeight - 900 && !originalsLoading && !isLastPage) {
			this.setState({
				currentPage: this.state.currentPage + 1
			}, () => {
				this.props.onGetMyOriginals(this.props.currentUser.id, this.state.currentPage, LIMIT, (+this.state.currentPage - 1)*LIMIT)
			})
		}
	};

	onSearchUser = (key: string) => {
		if(key.length > 2){
			this.props.onGetUsersByFilters('name', key);
		} else {
			if(this.state.suggestions.length){
				this.setState({
					suggestions: []
				})
			}
		}
	};

	handleChangeUsers = (values: Array<ISelect>) => {
		const { originalToManage } = this.state;

		if(originalToManage && originalToManage.id && values.length){
			let data = {
				userId: +values[0].value,
				originalId: +originalToManage.id,
			};

			this.props.onCreateRequest(data.userId, data.originalId);
		}
	};

	handleToggleVisibilityManageAccessModal = (original: IOriginal | null) => {
		const showManageAccessModal = !this.state.showManageAccessModal;

		if(original && original.id) {
			console.log('Show manage access modal:', original ? original.id : null, showManageAccessModal);
			this.props.onGetRequestsByOriginal(original.id)
		}

		this.setState({
			showManageAccessModal,
			originalToManage: original
		})
	};

	removeOriginalClicked = (id: number) => {
		this.setState({
			originalIdToRemove: id,
			showOriginalDeleteDialog: true,
		});
	};

	onRemoveOriginal = () => {
		const { originalIdToRemove } = this.state;

		if(originalIdToRemove){
			this.props.onDeleteOriginal(originalIdToRemove);
		}
	};

	removeUserRequest = (requestId: number) => {
		const { originalToManage } = this.state;

		if(originalToManage && originalToManage.id){
			this.props.onRemoveRequest(originalToManage.id, requestId);
		}
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {

		if(prevProps.currentUser !== this.props.currentUser && this.props.currentUser){
			this.setState({
				currentUser: this.props.currentUser,
				run: !this.props.tourDisabled,
			}, () =>{
				this.props.onGetMyOriginals(this.props.currentUser.id, 1, LIMIT, 0);
				this.initScroll();
			});

			if(this.props.currentUser.hasOwnProperty('fileCodeImage'))
				localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, this.props.currentUser.fileCodeImage.url);
		}

		if(prevProps.users !== this.props.users) {
			let suggestions: Array<ISelect> = [];

			let idsToExclude: Array<number> = this.props.requests.length ? this.props.requests.map((request: IOriginalRequest) => request.userId) : [];

			if(localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID)){
				idsToExclude.push(Number(localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID)))
			}

			if(this.props.users.length) {
				const filteredUsers = this.props.users.filter((user: IUser) => {
					return idsToExclude.indexOf(user.id) === -1;
				});

				suggestions = filteredUsers.map((user: IUser) => {
					return {
						value: user.id.toString(),
						label: user.name + ' ' + user.surname,
					}
				});
			}

			this.setState({
				suggestions
			});
		}

		if(prevProps.originalRemoved !== this.props.originalRemoved && this.props.originalRemoved){
			this.setState({
				originalIdToRemove: -1,
				showOriginalDeleteDialog: false,
			}, () => {
				toast.success("Original removed!", {
					position: toast.POSITION.TOP_RIGHT,
					className: 'confirm-bubble'
				});
			})
		}

		if(this.props.requestAdded !== prevProps.requestAdded && this.props.requestAdded){
			toast.success("User added!", {
				position: toast.POSITION.TOP_RIGHT,
				className: 'confirm-bubble'
			});
		}

		if(this.props.requestRemoved !== prevProps.requestRemoved && this.props.requestRemoved){
			toast.success("User removed!", {
				position: toast.POSITION.TOP_RIGHT,
				className: 'confirm-bubble'
			});
		}
	}

	onToggleDeleteOriginalConfirm = () => {
		const showOriginalDeleteDialog = !this.state.showOriginalDeleteDialog;

		this.setState({
			showOriginalDeleteDialog
		})
	};

	componentWillUnmount(): void {
		this.props.onResetMyOriginals();
		window.removeEventListener('scroll', this.handleScroll, false);
	}

	handleJoyrideCallback = (data: any) => {
		const { action, index, status, type } = data;

		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
		  // Update state to advance the tour
		  this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		}
		else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
		  // Need to set our running state to false, so we can restart if we click start again.
		  this.setState({ run: false }, () => {

		  	if(this.state.currentUser)
		  	    this.props.onSetTourDisabled(this.state.currentUser.id)
		  });
		}

		// console.groupCollapsed(type);
		// console.log(data); //eslint-disable-line no-console
		// console.groupEnd();
	};

	restartTour = () => {
		this.setState({
			run: true
		})
	}

	render () {
		const { roles, showManageAccessModal, originalToManage, suggestions, showOriginalDeleteDialog, steps, stepIndex, run, currentUser } = this.state;
		const { originals, originalsLoading, userLoading, requests, requestsLoading, usersLoading, storingRequest, tourDisabled } = this.props;

		const pendingRequest = requests.filter((request: IOriginalRequest) => request.status === 'PENDING');
		const approvedRequest = requests.filter((request: IOriginalRequest) => request.status === 'APPROVED');
		const rejectedRequest = requests.filter((request: IOriginalRequest) => request.status === 'REJECTED');

		const addOriginalsTpl = [
			<OriginalEmptyCard key="1" id="original-empty-1" />,
			<OriginalEmptyCard key="2" id="original-empty-2" />,
			<OriginalEmptyCard key="3" id="original-empty-3" />
		];

		return (
			<div className={classes.MyOriginals}>
				<Template
					sidebar={<MyOriginalsSidebar roles={roles} tourDisabled={run} restartTour={this.restartTour} />}
					sidebarSize="small">
					<Fragment>
						<div className={classes['MyOriginals-header']}>
							<div className={classes['MyOriginals-header-left']}>
								<h2 className={classes['MyOriginals-title']}>
									My <span></span><em>Originals</em>
								</h2>
								<FormattedHTMLMessage id={'myOriginals.description'} />
								<div className={classes['MyOriginals-description']}>
									<FormattedMessage id={'addOriginal.step.1.description'} />
								</div>
							</div>
							<div className={classes['MyOriginals-header-right']}>
								<MyOriginalsAdd />
							</div>
						</div>
						{
                            <div className={classes['MyOriginals-container']}>
                                <Masonry
                                    className={[classes['MyOriginals-list']].join(' ')}
                                    options={masonryOptions}>
									{/* <MyOriginalsAdd /> */}
									{
										originals.map((original: IOriginal, index: number) => {
											const id = original.hasOwnProperty('id') && original.id;
											const lastStep = original.hasOwnProperty('lastStep') && original.lastStep ? original.lastStep : 1;
											const editRoute = id ? routes.EDIT_ORIGINAL.replace(':id', id.toString()).replace(':step', lastStep.toString()) : routes.PROFILE;

											let backgroundImageUrl = original.file && original.file.childrens ? original.file.childrens[1].url : undefined;

											return (
												<OriginalCard
													backgroundImageUrl={backgroundImageUrl}
													showActions={true}
													key={index}
													titleClicked={id ? () => this.props.history.push(routes.ORIGINAL.replace(':id', id.toString())) : undefined}
													manageAccessClicked={() => this.handleToggleVisibilityManageAccessModal(original)}
													showClicked={() => this.props.history.push(editRoute)}
													removeClicked={id ? () => this.removeOriginalClicked(id) : () => console.error('Original not found.')}
													showLabelId={'general.edit'}
													original={original}
													currentUser={currentUser ? currentUser.id : undefined} />
											)
										})
									}
									{ 
										addOriginalsTpl.map((addOriginal: any, index: number) => {
											return(
												index < addOriginalsTpl.length - originals.length &&
												addOriginal
											)
										})
									}
                                    <div className="gutter-sizer" style={{width: '3%'}} />
                                </Masonry>
                            </div>
						}
					</Fragment>
					{
						(userLoading || originalsLoading) &&
							<div className={classes["MyOriginals-loading"]}>
								<Loader white={true} />
							</div>
					}
				</Template>
				{
					showManageAccessModal && originalToManage &&
	                    <Modal
		                    checkBackdropClicked={true}
	                        title={originalToManage && originalToManage.title}
	                        onModalCloseClicked={() => this.handleToggleVisibilityManageAccessModal(null)}>
                            <label className={[classes['MyOriginals-label'], classes['MyOriginals-label--small']].join(' ')}>
                                <FormattedMessage id={'label.originalGrantAccess'} />
                            </label>
                            <UsersAutocomplete
                                isSingleValue={true}
                                loading={usersLoading}
                                selected={[]}
                                suggestions={suggestions}
                                onSubmit={this.handleChangeUsers}
                                onInputChanged={this.onSearchUser}/>
		                    {
		                    	storingRequest && <span>Waiting...</span>
		                    }

		                    {
		                    	requestsLoading ?
				                    <div className={classes['MyOriginals-requests-loading']}>
										<Loader />
									</div>
									:
				                    <>
					                    <div className={classes['MyOriginals-requests']}>
											<label className={[classes['MyOriginals-label'], classes['MyOriginals-label--small']].join(' ')}>
												<FormattedMessage id={'label.usersWithAccess'} />
											</label>
						                    {
						                        /*
							                    requests.filter((request: IOriginalRequest) => request.status !=='COLLABORATION').map((request: IOriginalRequest, index: number) => {
								                    return (
									                    <li key={index}>{ request.user.name } { request.user.surname } <button onClick={() => this.removeUserRequest(request.id)}>X</button></li>
								                    )
							                    })
							                    */
						                    }
						                    {
						                    	approvedRequest.length > 0 ?
								                    approvedRequest.map((request: IOriginalRequest, index: number) => {
									                    return (
										                    <span key={index}>{ request.user.name } { request.user.surname } <button onClick={() => this.removeUserRequest(request.id)}>X</button></span>
									                    )
								                    }) : <FormattedHTMLMessage id={'label.noneAccess'} />
						                    }
					                    </div>
					                    {
					                    	pendingRequest.length > 0 &&
	                                            <div className={classes['MyOriginals-requests']}>
	                                                <label className={[classes['MyOriginals-label'], classes['MyOriginals-label--small']].join(' ')}>
	                                                    <FormattedMessage id={'label.usersPending'} />
	                                                </label>
								                    {
									                    pendingRequest.map((request: IOriginalRequest, index: number) => {
										                    return (
											                    <span key={index}>{ request.user.name } { request.user.surname }</span>
										                    )
									                    })
								                    }
	                                            </div>
					                    }
					                    {
						                    rejectedRequest.length > 0 &&
	                                            <div className={classes['MyOriginals-requests']}>
	                                                <label className={[classes['MyOriginals-label'], classes['MyOriginals-label--small']].join(' ')}>
	                                                    <FormattedMessage id={'label.usersRejected'} />
	                                                </label>
								                    {
									                    rejectedRequest.map((request: IOriginalRequest, index: number) => {
										                    return (
											                    <span key={index}>{ request.user.name } { request.user.surname }</span>
										                    )
									                    })
								                    }
	                                            </div>
					                    }
										<div className={classes['MyOriginals-requests-footer']}>
											<NavLink className={classes['MyOriginals-requests-footer-btn']} to={routes.MY_ORIGINALS_REQUESTS}>
												<FormattedMessage id="myOriginalsRequests.manage" />
											</NavLink>
										</div>
				                    </>
		                    }
	                    </Modal>
				}
				{
					showOriginalDeleteDialog &&
                    <Modal
                        title={<FormattedMessage id={'remove.original.title'}/>}
                        onModalCloseClicked={this.onToggleDeleteOriginalConfirm}>
                        <div className={classes['Profile-remove-buttons']}>
                            <Button type="danger" styles={{marginBottom: '10px'}} clicked={this.onRemoveOriginal}><FormattedMessage id={'remove.original.confirm'}/></Button>
                            <Button type="small" clicked={this.onToggleDeleteOriginalConfirm}><FormattedMessage id={'remove.original.undo'}/></Button>
                        </div>
                    </Modal>
				}
				{
					run &&
						<Joyride
							run={run}
							continuous={true}
							scrollToFirstStep={true}
							showProgress={true}
							showSkipButton={true}
							callback={this.handleJoyrideCallback}
							steps={steps}
							stepIndex={stepIndex}
							styles={{
								options: {
									primaryColor: '#FD970C',
								}
							}} />
				}
			</div>
		)
    }
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		tourDisabled: state.userState.tourDisabled,
		originals: state.originalState.myList,
		originalsError: state.originalState.error,
		originalsLoading: state.originalState.isFetching,
		isLastPage: state.originalState.isLastPage,
		requests: state.originalState.requests,
		requestAdded: state.originalState.requestAdded,
		requestRemoved: state.originalState.requestRemoved,
		requestsLoading: state.originalState.isFetchingRequests,
		users: state.userState.users,
		loadingUsers: state.userState.isFetching,
		errorUsers: state.userState.error,
		storingRequest: state.originalState.isStoring,
		originalRemoved: state.originalState.removed,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetUsersByFilters: (type: string, value: string) => dispatch(actions.fetchUsersByFilters(type, value)),
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onGetMyOriginals: (userId: number, page: number, limit: number, offset: number) => dispatch(actions.fetchOriginalsByUser(userId, page, limit, offset)),
		onGetRequestsByOriginal: (id: number) => dispatch(actions.fetchRequestsByOriginal(id)),
		onCreateRequest: (userId: number, originalId: number) => dispatch(actions.addAccessToOriginal(userId, originalId)),
		onRemoveRequest: (originalId: number, requestId: number) => dispatch(actions.removeRequestFromOriginal(originalId, requestId)),
		onDeleteOriginal: (originalId: number) => dispatch(actions.deleteOriginal(originalId)),
		onResetMyOriginals: () => dispatch(actions.resetMyOriginals()),
		onSetTourDisabled: (userId: number) => dispatch(actions.setTourDisabled(userId)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( MyOriginals );
