import React, {Component} from 'react';
import classes from './Activation.module.scss';
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import * as routes from "../../shared/routes";
import Button from "../Button/Button";
import UploadBox from "../UploadBox/UploadBox";
import EditorTextarea from "../EditorTextarea/EditorTextarea";
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import constants from '../../shared/constants';
import { resetSession } from '../../shared/helpers';
import CircleImage from '../CircleImage/CircleImage';
import CityAutocomplete from '../CityAutocomplete/CityAutocomplete';
import * as ReactGA from '../../GA';

interface IProps {
	onActivation: (code: string) => any;
	onCreateFile: (data: any) => any;
	onUpdateUser: (id: number, data: any) => any;
	onGetUser: (id: number, data: any) => any;
	activationError: string;
	file: any;
	updatedUser: any;
	newUser: any;
	match: any;
	history: any;
	loading: boolean;
}

interface IState {
    biography?: any;
	file?: object | null;
    user: any;
    city: string;
}

class Activation extends Component<IProps, IState> {

    state = {
    	user: {
		    token: '',
		    uid: 4,
		    name: '',
		    surname: '',
		    email: '',
	    },
	    file: null,
	    city: '',
        biography: '',
    };

    componentDidMount(): void {
		resetSession();
		ReactGA.pageview('/activation');
	    const { match: { params: { code } } } = this.props;

	    const user = {
	    	token: '',
		    uid: 0,
		    name: '',
		    surname: '',
		    email: '',
	    };

	    this.setState({
		    user
	    });

	    if(code){
			this.props.onActivation(code);
	    } else {
	    	this.props.history.push(routes.WELCOME);
	    }

	    this.resetImage = this.resetImage.bind(this);
    }

    resetImage(){
    	this.setState({
		    file: null,
	    }, () => {

		    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE);
	    })
    }

	saveUserInfo = () => {
		const { file, biography, city, user: { uid } } = this.state;

		if(uid) {
			let data = {
				biography,
				fileCodeImage: '',
				city
			};

			if(file){
				data = {
					...data,
					fileCodeImage: file['code'],
				}
			}

			this.props.onUpdateUser(uid, data);
		}
    };

    handleUploadImage = (uploaded: any) => {
	    const data = new FormData();
	    //console.log(uploaded[0]);
	    data.append('file', uploaded[0]);

	    this.props.onCreateFile(data);
    };

    handleChangeAbout = (value: any) => {
        this.setState({
            biography: value
        });
    };

	handleChangeCity = (place: any) => {
		const { name } = place;

		if(name){
			this.setState({
				city: name
			})
		} else {
			alert('City not found');
		}
	};

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    	if(this.props.file !== nextProps.file && nextProps.file){
    		const file = nextProps.file[0];

    		this.setState({
			    file
		    }, () => {
			    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, file.url);
		    });
	    }

    	if(this.props.newUser !== nextProps.newUser && nextProps.newUser){
    		console.log(nextProps.newUser);

    		this.setState({
			    user: nextProps.newUser
		    }, () => {
			    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN, this.state.user.token);
			    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_ID, this.state.user.uid.toString());
			    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_NAME, this.state.user.name);
			    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME, this.state.user.surname);
			    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_EMAIL, this.state.user.email);
		    })
	    }

	    if(this.props.updatedUser !== nextProps.updatedUser && nextProps.updatedUser){

	    	setTimeout(() => {
	    		this.props.history.push(routes.MY_ORIGINALS)
		    }, 1000)
	    }

	    if(this.props.activationError !== nextProps.activationError && nextProps.activationError){
	    	this.props.history.push(routes.WELCOME);
	    }
    }

	render () {
    	const { biography, file} = this.state;

			return (
				<div className={classes.Activation}>
                    <div className={classes['Activation-container']}>
                        <div className={classes['Activation-header']}>
                            <h3 className={classes['Activation-header-title']}>
                                <FormattedHTMLMessage id="activation.title" />
                            </h3>
                            <h4 className={classes['Activation-header-subtitle']}>
                                <FormattedHTMLMessage id="activation.next" />
                            </h4>
                        </div>
                        <div className={classes['Activation-content']}>
	                        {
								file ?
									<div className={classes['Activation-content-preview']}>
										<div>
											<CircleImage
												large
												styles={{
													margin: '0 auto 30px'
												}}
												url={file['url']} />
										</div>
										<div>
											<Button type="small" styles={{ margin: '10px auto 20px'}} clicked={() => this.setState({file: null})}>
												<FormattedMessage id="general.remove" />
											</Button>
										</div>
									</div>
									:
			                        <UploadBox
				                        titleId={'upload.image.title'}
				                        accept={'image/jpeg, image/png'}
				                        onSelectedFiles={this.handleUploadImage}
				                        multiple={false}
				                        styles={{margin: '0 0 10px 0'}}/>
							}
							<div className={classes['Activation-content-space']}>
								<CityAutocomplete
									onPlaceSelected={this.handleChangeCity}
									name="city"
									label="user.city" />
							</div>
							<div className={classes['Activation-content-space']}>
								<EditorTextarea
									isPlainText={true}
									toolbarHidden={false}
									value={biography}
									autosize
									id="activation-textarea"
									labelId={'label.aboutYou'}
									placeholderId={'placeholder.about'}
									onChange={this.handleChangeAbout}/>
							</div>
                        </div>
                        <div className={classes['Activation-footer']}>
                            <Button clicked={this.saveUserInfo} disabled={this.props.loading}>
                                <FormattedMessage id="activation.save" />
                            </Button>
                        </div>
                    </div>
				</div>
			)
    }
}

const mapStateToProps = (state: any) => {
	return {
		file: state.fileState.file,
		loading: state.fileState.isStoring,
		error: state.mediaState.error,
		newUser: state.registrationState.newUser,
		activationError: state.registrationState.error,
		updatedUser: state.userState.user,
		userLoading: state.userState.loading,
		userError: state.userState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onCreateFile: (data: any) => dispatch(actions.createFile(data)),
		onActivation: (code: string) => dispatch(actions.activation(code)),
		onUpdateUser: (id: number, data: any) => dispatch(actions.updateUser(id, data)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Activation );
