import * as actionTypes from './actionTypes';
import OriginalsService from '../../services/api/originals';
import {
	IAttachment,
	IMedia,
	IOrder,
	IOriginal, IOriginalCover,
	IOriginalRequest,
	IResponse,
	ISearchOriginal
} from '../../shared/interfaces';

// CREATE
export const createOriginalStart = () => {
	return {
		type: actionTypes.CREATE_ORIGINAL_START,
	}
};

export const createOriginalSuccess = (original: IOriginal) => {
	return {
		type: actionTypes.CREATE_ORIGINAL_SUCCESS,
		original
	}
};

export const createOriginalFail = (error: string) => {
	return {
		type: actionTypes.CREATE_ORIGINAL_FAIL,
		error,
	}
};

export const createOriginal = (data: IOriginal) => {
	return (dispatch: any) => {
		dispatch(createOriginalStart());

		OriginalsService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createOriginalSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(createOriginalFail(errorMsg));
				}
			);
	}
};

// READ/FETCH
export const fetchOriginalStart = () => {
    return {
        type: actionTypes.FETCH_ORIGINAL_START,
    }
};

export const fetchOriginalSuccess = (original: IOriginal, updatedMedia: Array<IMedia>, updatedAttachments: Array<IAttachment>) => {
    return {
        type: actionTypes.FETCH_ORIGINAL_SUCCESS,
        original,
	    updatedMedia,
	    updatedAttachments,
    }
};

export const fetchOriginalFail = (error: string) => {
    return {
        type: actionTypes.FETCH_ORIGINAL_FAIL,
        error,
    }
};

export const fetchOriginal = (id: number, edit?: boolean) => {
	return (dispatch: any) => {
		dispatch(fetchOriginalStart());

		OriginalsService
			.get(id, edit)
            .then(
	            (response: IResponse) => {
		            const updatedMedia = response.data.media;
		            const updatedAttachments = response.data.attachment;

                    dispatch(fetchOriginalSuccess(response.data, updatedMedia, updatedAttachments));
                },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(fetchOriginalFail(errorMsg));
                }
            );
    }
};

export const fetchOriginalsByUserStart = (page: number) => {
	return {
		type: actionTypes.FETCH_ORIGINALS_BY_USER_START,
		page
	}
};

export const fetchOriginalsByUserSuccess = (list: Array<IOriginal>, limit: number) => {
	return {
		type: actionTypes.FETCH_ORIGINALS_BY_USER_SUCCESS,
		list,
		limit
	}
};

export const fetchOriginalsByUserFail = (error: string) => {
	return {
		type: actionTypes.FETCH_ORIGINALS_BY_USER_FAIL,
		error,
	}
};

export const fetchOriginalsByUser = (userId: number, page: number, limit: number = 20, offset: number = 0) => {
	return (dispatch: any) => {
		dispatch(fetchOriginalsByUserStart(page));

		OriginalsService
			.getByUser(userId, limit, offset)
			.then(
				(response: IResponse) => {

					dispatch(fetchOriginalsByUserSuccess(response.data, limit));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchOriginalsByUserFail(errorMsg));
				}
			);
	}
};

export const searchOriginalsStart = (page: number) => {
	return {
		type: actionTypes.SEARCH_ORIGINALS_START,
		page
	}
};

export const searchOriginalsSuccess = (results: Array<IOriginal>) => {
	return {
		type: actionTypes.SEARCH_ORIGINALS_SUCCESS,
		results
	}
};

export const searchOriginalsFail = (error: string) => {
	return {
		type: actionTypes.SEARCH_ORIGINALS_FAIL,
		error,
	}
};

export const searchOriginals = (data: ISearchOriginal, page: number) => {
	return (dispatch: any) => {
		dispatch(searchOriginalsStart(page));

		OriginalsService
			.search(data)
			.then(
				(response: IResponse) => {

					dispatch(searchOriginalsSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(searchOriginalsFail(errorMsg));
				}
			);
	}
};

// UPDATE
export const updateOriginalStart = () => {
    return {
        type: actionTypes.UPDATE_ORIGINAL_START,
    }
};

export const updateOriginalSuccess = (original: IOriginal) => {
    return {
        type: actionTypes.UPDATE_ORIGINAL_SUCCESS,
        original
    }
};

export const updateOriginalFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_ORIGINAL_FAIL,
        error,
    }
};

export const updateOriginal = (id: number, data: IOriginal, step?: string) => {
    return (dispatch: any) => {
        dispatch(updateOriginalStart());

        OriginalsService
            .update(id, data, step)
            .then(
	            (response: IResponse) => {

                    dispatch(updateOriginalSuccess(response.data));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(updateOriginalFail(errorMsg));
                }
            );
    }
};

// DELETE
export const deleteOriginalStart = () => {
    return {
        type: actionTypes.DELETE_ORIGINAL_START,
    }
};

export const deleteOriginalSuccess = (id: number) => {
    return {
        type: actionTypes.DELETE_ORIGINAL_SUCCESS,
        id
    }
};

export const deleteOriginalFail = (error: string) => {
    return {
        type: actionTypes.DELETE_ORIGINAL_FAIL,
        error,
    }
};

export const deleteOriginal = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteOriginalStart());

        OriginalsService
            .remove(id)
            .then(
	            (response: IResponse) => {

                    dispatch(deleteOriginalSuccess(id));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(deleteOriginalFail(errorMsg));
                }
            );
    }
};

// ADD MEDIA
export const addMediaToOriginalStart = () => {
	return {
		type: actionTypes.CREATE_ORIGINAL_MEDIA_START,
	}
};

export const addMediaToOriginalSuccess = (updatedMedia: Array<IMedia>) => {
	return {
		type: actionTypes.CREATE_ORIGINAL_MEDIA_SUCCESS,
		updatedMedia
	}
};

export const addMediaToOriginalFail = (error: string) => {
	return {
		type: actionTypes.CREATE_ORIGINAL_MEDIA_FAIL,
		error,
	}
};

export const addMediaToOriginal = (id: number, mediaId: string) => {
	return (dispatch: any) => {
		dispatch(addMediaToOriginalStart());

		OriginalsService
			.addMedia(id, mediaId)
			.then(
				(response: IResponse) => {
					const updatedMedia = response.data.media;

					dispatch(addMediaToOriginalSuccess(updatedMedia));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(addMediaToOriginalFail(errorMsg));
				}
			);
	}
};

// REMOVE MEDIA
export const removeMediaFromOriginalStart = () => {
	return {
		type: actionTypes.DELETE_ORIGINAL_MEDIA_START,
	}
};

export const removeMediaFromOriginalSuccess = (updatedMedia: Array<IMedia>) => {
	return {
		type: actionTypes.DELETE_ORIGINAL_MEDIA_SUCCESS,
		updatedMedia
	}
};

export const removeMediaFromOriginalFail = (error: string) => {
	return {
		type: actionTypes.DELETE_ORIGINAL_MEDIA_FAIL,
		error,
	}
};

export const removeMediaFromOriginal = (id: number, mediaId: string) => {
	return (dispatch: any) => {
		dispatch(removeMediaFromOriginalStart());

		OriginalsService
			.removeMedia(id, mediaId)
			.then(
				(response: IResponse) => {

					const updatedMedia = response.data.media.map((media: any) => media);

					dispatch(removeMediaFromOriginalSuccess(updatedMedia));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(removeMediaFromOriginalFail(errorMsg));
				}
			);
	}
};

// ORDER MEDIA
export const orderMediaForOriginalStart = () => {
	return {
		type: actionTypes.CHANGE_MEDIA_ORDER_FOR_ORIGINAL_START,
	}
};

export const orderMediaForOriginalSuccess = (updatedMedia: any) => {
	return {
		type: actionTypes.CHANGE_MEDIA_ORDER_FOR_ORIGINAL_SUCCESS,
		updatedMedia
	}
};

export const orderMediaForOriginalFail = (error: string) => {
	return {
		type: actionTypes.CHANGE_MEDIA_ORDER_FOR_ORIGINAL_FAIL,
		error,
	}
};

export const orderMediaForOriginal = (id: number, mediaArray: Array<IOrder>) => {
	return (dispatch: any) => {
		dispatch(orderMediaForOriginalStart());

		const data = {
			media: mediaArray
		};

		OriginalsService
			.orderMedia(id, data)
			.then(
				(response: IResponse) => {
					const updatedMedia = response.data.media;

					dispatch(orderMediaForOriginalSuccess(updatedMedia));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(orderMediaForOriginalFail(errorMsg));
				}
			);
	}
};

// ADD ATTACHMENT
export const addAttachmentToOriginalStart = () => {
	return {
		type: actionTypes.CREATE_ORIGINAL_ATTACHMENT_START,
	}
};

export const addAttachmentToOriginalSuccess = (updatedAttachments: Array<IAttachment>) => {
	return {
		type: actionTypes.CREATE_ORIGINAL_ATTACHMENT_SUCCESS,
		updatedAttachments
	}
};

export const addAttachmentToOriginalFail = (error: string) => {
	return {
		type: actionTypes.CREATE_ORIGINAL_ATTACHMENT_FAIL,
		error,
	}
};

export const addAttachmentToOriginal = (id: number, attachmentId: number) => {
	return (dispatch: any) => {
		dispatch(addAttachmentToOriginalStart());

		OriginalsService
			.addAttachment(id, attachmentId)
			.then(
				(response: IResponse) => {
					const updatedAttachments = response.data.attachment;

					dispatch(addAttachmentToOriginalSuccess(updatedAttachments));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(addAttachmentToOriginalFail(errorMsg));
				}
			);
	}
};

// REMOVE ATTACHMENT
export const removeAttachmentFromOriginalStart = () => {
	return {
		type: actionTypes.DELETE_ORIGINAL_ATTACHMENT_START,
	}
};

export const removeAttachmentFromOriginalSuccess = (updatedAttachments: Array<IAttachment>) => {
	return {
		type: actionTypes.DELETE_ORIGINAL_ATTACHMENT_SUCCESS,
		updatedAttachments
	}
};

export const removeAttachmentFromOriginalFail = (error: string) => {
	return {
		type: actionTypes.DELETE_ORIGINAL_ATTACHMENT_FAIL,
		error,
	}
};

export const removeAttachmentFromOriginal = (id: number, attachmentId: number) => {
	return (dispatch: any) => {
		dispatch(removeAttachmentFromOriginalStart());

		OriginalsService
			.removeAttachment(id, attachmentId)
			.then(
				(response: IResponse) => {

					const updatedAttachments = response.data.attachment.map((attachment: any) => attachment);

					dispatch(removeAttachmentFromOriginalSuccess(updatedAttachments));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(removeAttachmentFromOriginalFail(errorMsg));
				}
			);
	}
};

// GET REQUESTS
export const fetchRequestsByOriginalStart = () => {
	return {
		type: actionTypes.FETCH_REQUESTS_BY_ORIGINAL_START,
	}
};

export const fetchRequestsByOriginalSuccess = (requests: Array<IOriginalRequest>) => {
	return {
		type: actionTypes.FETCH_REQUESTS_BY_ORIGINAL_SUCCESS,
		requests
	}
};

export const fetchRequestsByOriginalFail = (error: string) => {
	return {
		type: actionTypes.FETCH_REQUESTS_BY_ORIGINAL_FAIL,
		error,
	}
};

export const fetchRequestsByOriginal = (originalId: number) => {
	return (dispatch: any) => {
		dispatch(fetchRequestsByOriginalStart());

		OriginalsService
			.getRequestsByOriginal(originalId)
			.then(
				(response: IResponse) => {
					dispatch(fetchRequestsByOriginalSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchRequestsByOriginalFail(errorMsg));
				}
			);
	}
};

export const fetchRequestsByUserStart = () => {
	return {
		type: actionTypes.FETCH_REQUESTS_BY_USER_START,
	}
};

export const fetchRequestsByUserSuccess = (requests: Array<IOriginalRequest>) => {
	return {
		type: actionTypes.FETCH_REQUESTS_BY_USER_SUCCESS,
		requests
	}
};

export const fetchRequestsByUserFail = (error: string) => {
	return {
		type: actionTypes.FETCH_REQUESTS_BY_USER_FAIL,
		error,
	}
};

export const fetchRequestsByUser = (userId: number) => {
	return (dispatch: any) => {
		dispatch(fetchRequestsByUserStart());

		OriginalsService
			.getRequestsByUser(userId)
			.then(
				(response: IResponse) => {
					dispatch(fetchRequestsByUserSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchRequestsByUserFail(errorMsg));
				}
			);
	}
};

// ADD REQUEST
export const addRequestToOriginalStart = () => {
	return {
		type: actionTypes.ADD_REQUEST_START,
	}
};

export const addRequestToOriginalSuccess = (request: IOriginalRequest) => {
	return {
		type: actionTypes.ADD_REQUEST_SUCCESS,
		request
	}
};

export const addRequestToOriginalFail = (error: string) => {
	return {
		type: actionTypes.ADD_REQUEST_FAIL,
		error,
	}
};

export const addRequestToOriginal = (userId: number, originalId: number) => {
	return (dispatch: any) => {
		dispatch(addRequestToOriginalStart());

		OriginalsService
			.addRequest(userId, originalId)
			.then(
				(response: IResponse) => {
					dispatch(addRequestToOriginalSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(addRequestToOriginalFail(errorMsg));
				}
			);
	}
};

// UPDATE REQUEST
export const updateRequestStart = () => {
	return {
		type: actionTypes.UPDATE_REQUEST_START,
	}
};

export const updateRequestSuccess = (request: IOriginalRequest) => {
	return {
		type: actionTypes.UPDATE_REQUEST_SUCCESS,
		request
	}
};

export const updateRequestFail = (error: string) => {
	return {
		type: actionTypes.UPDATE_REQUEST_FAIL,
		error,
	}
};

export const updateRequest = (originalId: number, requestId: number, status: string) => {
	return (dispatch: any) => {
		dispatch(updateRequestStart());

		OriginalsService
			.updateRequest(originalId, requestId, status)
			.then(
				(response: IResponse) => {
					dispatch(updateRequestSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(updateRequestFail(errorMsg));
				}
			);
	}
};

// REMOVE REQUEST
export const removeRequestFromOriginalStart = () => {
	return {
		type: actionTypes.DELETE_REQUEST_START,
	}
};

export const removeRequestFromOriginalSuccess = (requestIdRemoved: number) => {
	return {
		type: actionTypes.DELETE_REQUEST_SUCCESS,
		requestIdRemoved
	}
};

export const removeRequestFromOriginalFail = (error: string) => {
	return {
		type: actionTypes.DELETE_REQUEST_FAIL,
		error,
	}
};

export const removeRequestFromOriginal = (originalId: number, requestId: number) => {
	return (dispatch: any) => {
		dispatch(removeRequestFromOriginalStart());

		OriginalsService
			.removeRequest(originalId, requestId)
			.then(
				(response: IResponse) => {
					dispatch(removeRequestFromOriginalSuccess(requestId));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(removeRequestFromOriginalFail(errorMsg));
				}
			);
	}
};

// ADD ACCESS
export const addAccessToOriginalStart = () => {
	return {
		type: actionTypes.ADD_ACCESS_START,
	}
};

export const addAccessToOriginalSuccess = (request: IOriginalRequest) => {
	return {
		type: actionTypes.ADD_ACCESS_SUCCESS,
		request
	}
};

export const addAccessToOriginalFail = (error: string) => {
	return {
		type: actionTypes.ADD_ACCESS_FAIL,
		error,
	}
};

export const addAccessToOriginal = (userId: number, originalId: number) => {
	return (dispatch: any) => {
		dispatch(addAccessToOriginalStart());

		OriginalsService
			.addAccess(userId, originalId)
			.then(
				(response: IResponse) => {
					dispatch(addAccessToOriginalSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(addAccessToOriginalFail(errorMsg));
				}
			);
	}
};

// ORDER ATTACHMENTS
export const orderAttachmentsForOriginalStart = () => {
	return {
		type: actionTypes.CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_START,
	}
};

export const orderAttachmentsForOriginalSuccess = (updatedAttachments: any) => {
	return {
		type: actionTypes.CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_SUCCESS,
		updatedAttachments
	}
};

export const orderAttachmentsForOriginalFail = (error: string) => {
	return {
		type: actionTypes.CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_FAIL,
		error,
	}
};

export const orderAttachmentsForOriginal = (id: number, attachmentsArray: Array<IOrder>) => {
	return (dispatch: any) => {
		dispatch(orderAttachmentsForOriginalStart());

		const data = {
			attachment: attachmentsArray
		};

		OriginalsService
			.orderAttachments(id, data)
			.then(
				(response: IResponse) => {
					const updatedAttachments = response.data.attachment;

					dispatch(orderAttachmentsForOriginalSuccess(updatedAttachments));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(orderAttachmentsForOriginalFail(errorMsg));
				}
			);
	}
};

export const resetMyOriginals = () => {
	return {
		type: actionTypes.RESET_MY_ORIGINALS,
	}
};

// UPDATE COVER
export const updateCoverStart = () => {
	return {
		type: actionTypes.UPDATE_ORIGINAL_COVER_START,
	}
};

export const updateCoverSuccess = (coverUrls: IOriginalCover | null) => {
	return {
		type: actionTypes.UPDATE_ORIGINAL_COVER_SUCCESS,
		coverUrls
	}
};

export const updateCoverFail = (error: string) => {
	return {
		type: actionTypes.UPDATE_ORIGINAL_COVER_FAIL,
		error,
	}
};

export const updateCover = (id: number, data: { fileCodeImage: string }) => {
	return (dispatch: any) => {
		dispatch(updateCoverStart());

		OriginalsService
			.updateCover(id, data)
			.then(
				(response: IResponse) => {
					const coverUrls: IOriginalCover | null = response.data && response.data.file.childrens ? {
						thumbnail: response.data.file.childrens[0].url,
						medium: response.data.file.childrens[1].url,
						large: response.data.file.childrens[2].url,
					} : null;

					dispatch(updateCoverSuccess(coverUrls));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(updateCoverFail(errorMsg));
				}
			);
	}
};

// DELETE COVER
export const deleteCoverStart = () => {
	return {
		type: actionTypes.DELETE_ORIGINAL_COVER_START,
	}
};

export const deleteCoverSuccess = () => {
	return {
		type: actionTypes.DELETE_ORIGINAL_COVER_SUCCESS
	}
};

export const deleteCoverFail = (error: string) => {
	return {
		type: actionTypes.DELETE_ORIGINAL_COVER_FAIL,
		error,
	}
};

export const deleteCover = (id: number) => {
	return (dispatch: any) => {
		dispatch(deleteCoverStart());

		OriginalsService
			.removeCover(id)
			.then(
				() => {
					dispatch(deleteCoverSuccess());
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(deleteCoverFail(errorMsg));
				}
			);
	}
};

export const resetCover = () => {
	return {
		type: actionTypes.RESET_ORIGINAL_COVER
	}
};

export const resetMyOriginal = () => {
	return {
		type: actionTypes.RESET_MY_ORIGINAL
	}
};

// PATAMU
export const addPatamuToOriginalStart = () => {
	return {
		type: actionTypes.ADD_PATAMU_TO_ORIGINAL_START,
	}
};

export const addPatamuToOriginalSuccess = (patamu?: string) => {
	return {
		type: actionTypes.ADD_PATAMU_TO_ORIGINAL_SUCCESS,
		patamu
	}
};

export const addPatamuToOriginalFail = (error: string) => {
	return {
		type: actionTypes.ADD_PATAMU_TO_ORIGINAL_FAIL,
		error,
	}
};

export const addPatamuToOriginal = (id: number, data: { patamu: string | number }) => {
	return (dispatch: any) => {
		dispatch(addPatamuToOriginalStart());

		OriginalsService
			.addPatamu(id, data)
			.then(
				(response: IResponse) => {
					const { data: { patamu } } = response;

					dispatch(addPatamuToOriginalSuccess(patamu));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(addPatamuToOriginalFail(errorMsg));
				}
			);
	}
};
