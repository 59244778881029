import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { INotification } from '../../shared/interfaces';
import moment from 'moment';

interface IAction {
    type: string;
    list: Array<INotification>;
    error: string;
    notificationRead: INotification;
}

const INITIAL_STATE = {
    list: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    notificationRead: null,
};

const fetchNotificationsStart = (state = INITIAL_STATE) => {
    return updateObject(state, { isFetching: state.list.length === 0, error: null, didInvalidate: false });
};

const fetchNotificationsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { list: action.list, isFetching: false });
};

const fetchNotificationsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const setReadNotificationStart = (state = INITIAL_STATE) => {
    return updateObject(state, { notificationRead: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const setReadNotificationSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(
    	state, {
    		notificationRead: action.notificationRead,
		    list: state.list.map(
		    	(notification: INotification) => {
				    notification.readAt = notification.id === action.notificationRead.id ?
					    action.notificationRead.readAt : notification.readAt;

				    return notification;
			    }
		    ),
		    isStoring: false
    	});
};

const setReadNotificationFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const setReadAllNotificationByUserIdStart = (state = INITIAL_STATE) => {
	return updateObject(state, { notificationRead: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const setReadAllNotificationByUserIdSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(
		state, {
			list: action.list.length > 0 ? state.list.map(
				(notification: INotification) => {
					notification.readAt = notification.readAt === null ?
						 moment().format('YYYY-MM-DD HH:MM:SS') : notification.readAt;

					return notification;
				}
			) : state.list,
			isStoring: false
		});
};

const setReadAllNotificationByUserIdFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const notificationReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // READ/FETCHING
        case actionTypes.FETCH_NOTIFICATIONS_START: return fetchNotificationsStart(state);
        case actionTypes.FETCH_NOTIFICATIONS_SUCCESS: return fetchNotificationsSuccess(state, action);
        case actionTypes.FETCH_NOTIFICATIONS_FAIL: return fetchNotificationsFail(state, action);
        // SET READ
        case actionTypes.SET_READ_NOTIFICATION_START: return setReadNotificationStart(state);
        case actionTypes.SET_READ_NOTIFICATION_SUCCESS: return setReadNotificationSuccess(state, action);
        case actionTypes.SET_READ_NOTIFICATION_FAIL: return setReadNotificationFail(state, action);
	    // SET READ ALL
	    case actionTypes.SET_ALL_READ_NOTIFICATION_BY_USER_START: return setReadAllNotificationByUserIdStart(state);
	    case actionTypes.SET_ALL_READ_NOTIFICATION_BY_USER_SUCCESS: return setReadAllNotificationByUserIdSuccess(state, action);
	    case actionTypes.SET_ALL_READ_NOTIFICATION_BY_USER_FAIL: return setReadAllNotificationByUserIdFail(state, action);

        default:
            return state;
    }
};

export default notificationReducer;
