import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import {
	IAttachment,
	IMedia,
	IOriginal,
	IOriginalCover,
	IOriginalRequest,
	IUserOriginalsRequests
} from '../../shared/interfaces';

interface IAction {
	id: number;
    type: string;
    list: Array<IOriginal>;
    limit: number;
	results: Array<IOriginal>;
    original: IOriginal;
    request: IOriginalRequest;
    requestId: number;
    requests: Array<IOriginalRequest>;
    error: string;
    added: boolean;
    updatedMedia: Array<IMedia>;
	updatedAttachments: Array<IAttachment>;
	requestIdRemoved: number;
	page: number;
	storingCover: boolean;
	coverRemoved: boolean;
	coverError: string;
	coverUrls: IOriginalCover;
	patamu: string | null | number;
}

const INITIAL_STATE = {
    myList: [],
	paginatedList: [],
    original: null,
	requests: [],
	userRequests: [],
	request: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
	created: false,
	updated: false,
	mediaAdded: false,
	mediaRemoved: false,
	updatedMedia: [],
	attachmentAdded: false,
	attachmentRemoved: false,
	updatedAttachments: [],
	requestAdded: false,
	requestRemoved: false,
	requestUpdated: false,
	updatedRequests: [],
	isFetchingRequests: false,
	isFetchingUserRequests: false,
	addingRequest: false,
	isLastPage: false,
	storingCover: false,
	coverRemoved: false,
	coverError: '',
	currentCoverUrls: null,
	patamu: null,
};

const resetMyOriginals = (state = INITIAL_STATE) => {
	return updateObject(state, { myList: [] });
};

const resetMyOriginal = (state = INITIAL_STATE) => {
	return updateObject(state, { original: null, currentCoverUrls: null });
};

const createOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { original: null, updatedMedia: [], updatedAttachments: [], created: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	const oldList: Array<IOriginal> = [...state.myList];
	const updatedList = oldList.concat(action.original);

	return updateObject(state, { original: action.original, myList: updatedList, created: true, isStoring: false });
};

const createOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { original: null, currentCoverUrls: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, {
		original: action.original,
		updatedMedia: action.updatedMedia,
		updatedAttachments: action.updatedAttachments,
		currentCoverUrls: action.original && action.original.file && action.original.file.childrens ? {
			thumbnail: action.original.file.childrens[0].url,
			medium: action.original.file.childrens[1].url,
			large: action.original.file.childrens[2].url
		} : null,
		patamu: action.original.patamu,
		isFetching: false
	});
};

const fetchOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const searchOriginalsStart = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { paginatedList: action.page === 1 ? [] : state.paginatedList, isFetching: true, error: null, didInvalidate: false });
};

const searchOriginalsSuccess = (state = INITIAL_STATE, action: IAction) => {
	const oldPaginatedList: Array<IOriginal> = [...state.paginatedList];
	const updatedPaginatedList = oldPaginatedList.concat(action.results);

	return updateObject(state, { paginatedList: updatedPaginatedList, isLastPage: action.results.length === 0, isFetching: false });
};

const searchOriginalsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchOriginalsByUserStart = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { myList: action.page === 1 ? [] : state.myList, isFetching: true, error: null, didInvalidate: false });
};

const fetchOriginalsByUserSuccess = (state = INITIAL_STATE, action: IAction) => {
	const oldMyList: Array<IOriginal> = [...state.myList];
	const updatedMyList = oldMyList.concat(action.list);

	return updateObject(state, { myList: updatedMyList, isLastPage: action.list.length < action.limit, isFetching: false });
};

const fetchOriginalsByUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateOriginalStart = (state = INITIAL_STATE) => {
    return updateObject(state, { original: null, updatedMedia: [], isStoring: true, error: null, didInvalidate: false, updated: false, });
};

const updateOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { original: action.original, isStoring: false, updated: true, });
};

const updateOriginalFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const deleteOriginalStart = (state = INITIAL_STATE) => {
    return updateObject(state, { isStoring: true, removed: null, error: null, didInvalidate: false });
};

const deleteOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { removed: true, isLastPage: false, myList: state.myList.filter((item:IOriginal) => item.id !== action.id),  isStoring: false });
};

const deleteOriginalFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, removed: false, didInvalidate: true, isStoring: false });
};

const addMediaToOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { mediaAdded: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const addMediaToOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	const original = {
		// @ts-ignore
		...state.project,
		media: action.updatedMedia,
	};

	return updateObject(state, { mediaAdded: true, original, updatedMedia: action.updatedMedia, isStoring: false });
};

const addMediaToOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const removeMediaFromOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { mediaRemoved: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const removeMediaFromOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	const original = {
		// @ts-ignore
		...state.project,
		media: action.updatedMedia,
	};

	return updateObject(state, { updatedMedia: action.updatedMedia, original, mediaRemoved: true, isStoring: false });
};

const removeMediaFromOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const orderMediaForOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const orderMediaForOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	const original = {
		// @ts-ignore
		...state.project,
		media: action.updatedMedia,
	};

	return updateObject(state, { updatedMedia: action.updatedMedia, original, isStoring: false });
};

const orderMediaForOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const addAttachmentToOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { attachmentAdded: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const addAttachmentToOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	const updatedOriginal = updateObject(state.original, { attachment: action.updatedAttachments });
	return updateObject(state, { attachmentAdded: true, original: updatedOriginal, updatedAttachments: action.updatedAttachments, isStoring: false });
};

const addAttachmentToOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const removeAttachmentFromOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { attachmentRemoved: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const removeAttachmentFromOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	const updatedOriginal = updateObject(state.original, { attachment: action.updatedAttachments });
	return updateObject(state, { updatedAttachments: action.updatedAttachments, original: updatedOriginal, attachmentRemoved: true, isStoring: false });
};

const removeAttachmentFromOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const orderAttachmentsForOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { error: null, didInvalidate: false });
};

const orderAttachmentsForOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { updatedAttachments: action.updatedAttachments });
};

const orderAttachmentsForOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, didInvalidate: true });
};

// Requests
const fetchRequestsByOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { requests: [], isFetchingRequests: true, error: null, didInvalidate: false });
};

const fetchRequestsByOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { requests: action.requests, isFetchingRequests: false });
};

const fetchRequestsByOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetchingRequests: false, didInvalidate: true });
};
const fetchRequestsByUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { userRequests: [], isFetchingUserRequests: true, error: null, didInvalidate: false });
};

const fetchRequestsByUserSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { userRequests: action.requests, isFetchingUserRequests: false });
};

const fetchRequestsByUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetchingUserRequests: false, didInvalidate: true });
};

const addRequestToOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { requestAdded: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const addRequestToOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	let requests: Array<IOriginalRequest> = state.requests ? [...state.requests] : [];
	requests.push(action.request);

	return updateObject(state, { requestAdded: true, requests, isStoring: false });
};

const addRequestToOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const updateRequestStart = (state = INITIAL_STATE) => {
	return updateObject(state, { requestUpdated: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const updateRequestSuccess = (state = INITIAL_STATE, action: IAction) => {

	let userRequests = state.userRequests.map((req: IUserOriginalsRequests) => {
		if(req.id === action.request.originalId) {
			const newReq = req.request.map((r: { id: number, status: string }) => {
				if(r.id === action.request.id){
					return action.request;
				} else {
					return r;
				}
			});

			return {
				...req,
				request: newReq
			}
		} else {
			return req;
		}
	});

	return updateObject(state, { requestUpdated: true, userRequests, isStoring: false });
};

const updateRequestFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const removeRequestFromOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { requestRemoved: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const removeRequestFromOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	let currentRequests: Array<IOriginalRequest> = state.requests ? [...state.requests] : [];
	return updateObject(state, {requests: currentRequests.filter((request: IOriginalRequest) => request.id !== action.requestIdRemoved), requestRemoved: true, isStoring: false });
};

const removeRequestFromOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const addAccessToOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { requestAdded: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const addAccessToOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	let requests: Array<IOriginalRequest> = state.requests ? [...state.requests] : [];
	requests.push(action.request);

	return updateObject(state, { requestAdded: true, requests, isStoring: false });
};

const addAccessToOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const updateCoverStart = (state = INITIAL_STATE) => {
	return updateObject(state, { coverError: '', coverStoring: true });
};

const updateCoverSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, {
		currentCoverUrls: action.coverUrls,
		coverStoring: false
	});
};

const updateCoverFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { coverError: action.error, coverStoring: false, });
};

const removeCoverStart = (state = INITIAL_STATE) => {
	return updateObject(state, { coverError: '', coverStoring: true, coverRemoved: false, });
};

const removeCoverSuccess = (state = INITIAL_STATE) => {
	return updateObject(state, { coverStoring: false, coverRemoved: true, currentCoverUrls: null });
};

const removeCoverFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { coverError: action.error, coverStoring: false });
};

const resetCover = (state = INITIAL_STATE) => {
	return updateObject(state, { currentCoverUrls: null });
};

const addPatamuToOriginalStart = (state = INITIAL_STATE) => {
	return updateObject(state, { patamu: state.patamu, isStoring: true, error: null, didInvalidate: false });
};

const addPatamuToOriginalSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { patamu: action.patamu, isStoring: false });
};

const addPatamuToOriginalFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const originalReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_ORIGINAL_START: return createOriginalStart(state);
	    case actionTypes.CREATE_ORIGINAL_SUCCESS: return createOriginalSuccess(state, action);
	    case actionTypes.CREATE_ORIGINAL_FAIL: return createOriginalFail(state, action);
        // READ/FETCHING
	    case actionTypes.FETCH_ORIGINAL_START: return fetchOriginalStart(state);
	    case actionTypes.FETCH_ORIGINAL_SUCCESS: return fetchOriginalSuccess(state, action);
	    case actionTypes.FETCH_ORIGINAL_FAIL: return fetchOriginalFail(state, action);
	    case actionTypes.SEARCH_ORIGINALS_START: return searchOriginalsStart(state, action);
	    case actionTypes.SEARCH_ORIGINALS_SUCCESS: return searchOriginalsSuccess(state, action);
	    case actionTypes.SEARCH_ORIGINALS_FAIL: return searchOriginalsFail(state, action);
	    case actionTypes.FETCH_ORIGINALS_BY_USER_START: return fetchOriginalsByUserStart(state, action);
	    case actionTypes.FETCH_ORIGINALS_BY_USER_SUCCESS: return fetchOriginalsByUserSuccess(state, action);
	    case actionTypes.FETCH_ORIGINALS_BY_USER_FAIL: return fetchOriginalsByUserFail(state, action);
        // UPDATE
        case actionTypes.UPDATE_ORIGINAL_START: return updateOriginalStart(state);
        case actionTypes.UPDATE_ORIGINAL_SUCCESS: return updateOriginalSuccess(state, action);
        case actionTypes.UPDATE_ORIGINAL_FAIL: return updateOriginalFail(state, action);
        // DELETE
        case actionTypes.DELETE_ORIGINAL_START: return deleteOriginalStart(state);
        case actionTypes.DELETE_ORIGINAL_SUCCESS: return deleteOriginalSuccess(state, action);
        case actionTypes.DELETE_ORIGINAL_FAIL: return deleteOriginalFail(state, action);
        // MEDIA
	    case actionTypes.CREATE_ORIGINAL_MEDIA_START: return addMediaToOriginalStart(state);
	    case actionTypes.CREATE_ORIGINAL_MEDIA_SUCCESS: return addMediaToOriginalSuccess(state, action);
	    case actionTypes.CREATE_ORIGINAL_MEDIA_FAIL: return addMediaToOriginalFail(state, action);
	    case actionTypes.DELETE_ORIGINAL_MEDIA_START: return removeMediaFromOriginalStart(state);
	    case actionTypes.DELETE_ORIGINAL_MEDIA_SUCCESS: return removeMediaFromOriginalSuccess(state, action);
	    case actionTypes.DELETE_ORIGINAL_MEDIA_FAIL: return removeMediaFromOriginalFail(state, action);
	    case actionTypes.CHANGE_MEDIA_ORDER_FOR_ORIGINAL_START: return orderMediaForOriginalStart(state);
	    case actionTypes.CHANGE_MEDIA_ORDER_FOR_ORIGINAL_SUCCESS: return orderMediaForOriginalSuccess(state, action);
	    case actionTypes.CHANGE_MEDIA_ORDER_FOR_ORIGINAL_FAIL: return orderMediaForOriginalFail(state, action);
	    // ATTACHMENTS
	    case actionTypes.CREATE_ORIGINAL_ATTACHMENT_START: return addAttachmentToOriginalStart(state);
	    case actionTypes.CREATE_ORIGINAL_ATTACHMENT_SUCCESS: return addAttachmentToOriginalSuccess(state, action);
	    case actionTypes.CREATE_ORIGINAL_ATTACHMENT_FAIL: return addAttachmentToOriginalFail(state, action);
	    case actionTypes.DELETE_ORIGINAL_ATTACHMENT_START: return removeAttachmentFromOriginalStart(state);
	    case actionTypes.DELETE_ORIGINAL_ATTACHMENT_SUCCESS: return removeAttachmentFromOriginalSuccess(state, action);
	    case actionTypes.DELETE_ORIGINAL_ATTACHMENT_FAIL: return removeAttachmentFromOriginalFail(state, action);
	    case actionTypes.CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_START: return orderAttachmentsForOriginalStart(state);
	    case actionTypes.CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_SUCCESS: return orderAttachmentsForOriginalSuccess(state, action);
	    case actionTypes.CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_FAIL: return orderAttachmentsForOriginalFail(state, action);
	    // REQUESTS
	    case actionTypes.FETCH_REQUESTS_BY_ORIGINAL_START: return fetchRequestsByOriginalStart(state);
	    case actionTypes.FETCH_REQUESTS_BY_ORIGINAL_SUCCESS: return fetchRequestsByOriginalSuccess(state, action);
	    case actionTypes.FETCH_REQUESTS_BY_ORIGINAL_FAIL: return fetchRequestsByOriginalFail(state, action);
	    case actionTypes.FETCH_REQUESTS_BY_USER_START: return fetchRequestsByUserStart(state);
	    case actionTypes.FETCH_REQUESTS_BY_USER_SUCCESS: return fetchRequestsByUserSuccess(state, action);
	    case actionTypes.FETCH_REQUESTS_BY_USER_FAIL: return fetchRequestsByUserFail(state, action);
	    case actionTypes.ADD_REQUEST_START: return addRequestToOriginalStart(state);
	    case actionTypes.ADD_REQUEST_SUCCESS: return addRequestToOriginalSuccess(state, action);
	    case actionTypes.ADD_REQUEST_FAIL: return addRequestToOriginalFail(state, action);
	    case actionTypes.DELETE_REQUEST_START: return removeRequestFromOriginalStart(state);
	    case actionTypes.DELETE_REQUEST_SUCCESS: return removeRequestFromOriginalSuccess(state, action);
	    case actionTypes.DELETE_REQUEST_FAIL: return removeRequestFromOriginalFail(state, action);
	    case actionTypes.UPDATE_REQUEST_START: return updateRequestStart(state);
	    case actionTypes.UPDATE_REQUEST_SUCCESS: return updateRequestSuccess(state, action);
	    case actionTypes.UPDATE_REQUEST_FAIL: return updateRequestFail(state, action);
	    case actionTypes.ADD_ACCESS_START: return addAccessToOriginalStart(state);
	    case actionTypes.ADD_ACCESS_SUCCESS: return addAccessToOriginalSuccess(state, action);
	    case actionTypes.ADD_ACCESS_FAIL: return addAccessToOriginalFail(state, action);
	    // COVER
	    case actionTypes.UPDATE_ORIGINAL_COVER_START: return updateCoverStart(state);
	    case actionTypes.UPDATE_ORIGINAL_COVER_SUCCESS: return updateCoverSuccess(state, action);
	    case actionTypes.UPDATE_ORIGINAL_COVER_FAIL: return updateCoverFail(state, action);
	    case actionTypes.DELETE_ORIGINAL_COVER_START: return removeCoverStart(state);
	    case actionTypes.DELETE_ORIGINAL_COVER_SUCCESS: return removeCoverSuccess(state);
	    case actionTypes.DELETE_ORIGINAL_COVER_FAIL: return removeCoverFail(state, action);
	    case actionTypes.RESET_ORIGINAL_COVER: return resetCover(state);

	    case actionTypes.RESET_MY_ORIGINALS: return resetMyOriginals(state);
	    case actionTypes.RESET_MY_ORIGINAL: return resetMyOriginal(state);

	    // PATAMU
	    case actionTypes.ADD_PATAMU_TO_ORIGINAL_START: return addPatamuToOriginalStart(state);
	    case actionTypes.ADD_PATAMU_TO_ORIGINAL_SUCCESS: return addPatamuToOriginalSuccess(state, action);
	    case actionTypes.ADD_PATAMU_TO_ORIGINAL_FAIL: return addPatamuToOriginalFail(state, action);

        default:
            return state;
    }
};

export default originalReducer;
