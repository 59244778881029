import React, { Component } from "react";
import classes from './AnimatedPlaceholder.module.scss';

interface IProps {
    size?: string;
    circle?: boolean;
    dark?: boolean;
}

interface IState {
}

class AnimatedPlaceholder extends Component<IProps, IState> {

	state = {};

	render() {

        const { size, circle, dark } = this.props;

        return (
            <div className={[
                classes['AnimatedPlaceholder'],
                dark ? classes['AnimatedPlaceholder--dark'] : '',
                circle ? classes['AnimatedPlaceholder--circle'] : '',
                size ? classes[`AnimatedPlaceholder--${size}`] : '',
            ].join(' ')} />
        );
	}
}

export default AnimatedPlaceholder;
