import React, {Component} from 'react';
import classes from './StepsIndicator.module.scss';

interface IState {
    steps: any;
    currentStep: number;
}

class StepsIndicator extends Component<IState> {

    render () {
        const { steps, currentStep } = this.props;

        const stepsTpl = steps.map((step: any, index: number) => {
            return (
                <div
                    key={index}
                    className={[classes['StepsIndicator-step'], currentStep === index+1 ? classes['StepsIndicator-step--active'] : ''].join(' ')}>
                    <span>{ index+1 }</span>
                </div>
            );
        })

        return (
            <div className={classes.StepsIndicator}>
                { stepsTpl }
            </div>
        )
    }
};

export default StepsIndicator;
