import React, { Component } from "react";
import classes from './OriginalEmptyCard.module.scss';
import { NEW_ORIGINAL } from '../../shared/routes';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import AnimatedPlaceholder from '../AnimatedPlaceholder/AnimatedPlaceholder';

interface IProps {
    id: string;
}

interface IState {
}

class OriginalEmptyCard extends Component<IProps, IState> {

	state = {};

	componentWillMount(){
	}

	componentWillUnmount(){
	}

	render() {

        const { id } = this.props;

        return (
            <NavLink to={NEW_ORIGINAL} className={classes['OriginalEmptyCard']}>
                <div className={classes['OriginalEmptyCard-header']}>
                    <div>
                        <AnimatedPlaceholder size="two" circle />
                    </div>
                    <div>
                        <AnimatedPlaceholder size="eight" />
                        <AnimatedPlaceholder size="six" />
                    </div>
                </div>
                <div className={classes['OriginalEmptyCard-wrapper']}>
                    <div className={classes['OriginalEmptyCard-plus']} data-tip data-for={id}>
                        <ReactTooltip effect="solid" id={id}>
                            <FormattedMessage id={'label.addNewOriginal'} />
                        </ReactTooltip>
                    </div>
                </div>
                <div className={classes['OriginalEmptyCard-content']}>
                    <AnimatedPlaceholder dark />
                    <AnimatedPlaceholder dark size="eight" />
                </div>
                <div className={classes['OriginalEmptyCard-footer']}></div>
            </NavLink>
        );
	}
}

export default OriginalEmptyCard;
