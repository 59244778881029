import request from '../../shared/lib/request'
import constants from "../../shared/constants";

const create = (data: object) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
	    'Authorization': `Bearer ${authToken}`
    };

    return request({
        url:    '/user',
        method: 'POST',
        headers,
        data
    });
};

const getNetworkUsers = () => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = `/users/network/all/all`;

	return request({
		url:    endpoint,
		method: 'GET',
		headers
	});
};

const get = (id?: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = id ? `/users/${id}` : '/users';

    return request({
        url:    endpoint,
        method: 'GET',
        headers
    });
};

const getByFilters = (typology: string, value: string, network: boolean) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = `/users/${network ? 'network' : 'search'}/${typology}/${value}`;

	return request({
		url:    endpoint,
		method: 'GET',
		headers,
	});
};

const update = (id: number, data: object) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/users/${id}`,
        method: 'PATCH',
        headers,
        data
    });
};

const remove = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/users/${id}`,
        method: 'DELETE',
        headers,
    });
};

const recoveryPassword = (data: object) => {
	const headers = {
		'Content-Type': 'application/json'
	};

	return request({
		url:    `/users/recovery/password`,
		method: 'PATCH',
		headers,
		data
	});
};

const updatePassword = (data: object) => {
	const headers = {
		'Content-Type': 'application/json'
	};

	return request({
		url:    `/users/update/password`,
		method: 'PATCH',
		headers,
		data
	});
};

const setTourDisabled = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/users/${id}/tour`,
		method: 'PATCH',
		headers,
	});
};

const UsersService = {
    get, getByFilters, getNetworkUsers, setTourDisabled, create, update, remove, recoveryPassword, updatePassword
};

export default UsersService;
