import React from 'react';
import classes from './CircleImage.module.scss';

interface circleImageProps {
    url?: string;
    small?: boolean;
    medium?: boolean;
	large?: boolean;
	vertical?: boolean;
    styles?: object;
    username?: string;
    fullName?: string;
}

const circleImage = (props: circleImageProps) => {

    const { url, small, styles, username, large, medium, fullName, vertical } = props;

    const circleThumbnailImageClass = [classes['CircleImage-thumbnail'], small ? classes['CircleImage-thumbnail--small'] : '', medium ? classes['CircleImage-thumbnail--medium'] : '', large ? classes['CircleImage-thumbnail--large'] : ''].join(' ');

    const circleImageStyles = {
	    ...styles,
		backgroundImage: url ? `url(${url})` : 'none'
    };

    return (
    	<div className={[classes.CircleImage, vertical ? classes['CircleImage--vertical'] : ''].join(' ')}>
	        <div style={circleImageStyles} className={circleThumbnailImageClass}>
	            { username && !url &&
	                <div className={classes['CircleImage-letter']}>
	                    { username.substring(0, 1)  }
	                </div>
	            }
	        </div>
		    { fullName &&
	            <div className={classes['CircleImage-fullName']}>
				    { fullName }
	            </div>
		    }
	    </div>
    );
};

export default circleImage;
