import React, { Component, createRef } from 'react';
import classes from './Step4.module.scss';
import stepClasses from '../Steps.module.scss';
import Input from '../../../Input/Input';
import EditorTextarea from '../../../EditorTextarea/EditorTextarea';
import ImagePicker from './ImagePicker/ImagePicker';
import UploadBox from '../../../UploadBox/UploadBox';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import * as ReactGA from '../../../../GA';
import TagsInput from '../../../TagsInput/TagsInput';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { IFile, IMedia, IMood, ISkill } from '../../../../shared/interfaces';
import { checkValidSecureURL } from '../../../../shared/helpers';

interface IProps {
	setData: (field: string, value: any) => void;
	data: any;
	onUpdateMedia: (id: number, data: any) => any;
	onCreateMedia: (data: any) => any;
	onCreateFile: (data: any) => any;
	onAddMediaToProject: (id: number, mediaId: string) => any;
	onResetFile: () => any;
	onResetMedia: () => any;
	onGetSkills: () => Array<ISkill>;
	onGetMoods: () => Array<IMood>;
	file: IFile;
	mediaAdded: boolean;
	updatedMedia: Array<IMedia>;
	mediaCreated: IMedia;
	savingMedia: boolean;
	savingFile: boolean;
	associateMediaToProject: boolean;
	moodsList: Array<IMood>;
	skillsList: Array<ISkill>;
	mediaUpdated: IMedia,
}

interface IState {
	title: any;
	description: any;
	images: any;
	media: any;
	newMedia: any;
	newMediaId: any;
	projectLink: any;
	currentNewFile: any;
	moods: Array<string>;
	skills: Array<string>;
}

class Step4 extends Component<IProps, IState> {
	private upload = createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);

		this.state = {
			title: {
				name: 'title',
				placeholderId: 'placeholder.projectTitle',
				value: '',
				visible: true,
				readOnly: false,
				type: 'text',
			},
			projectLink: {
				name: 'projectLink',
				placeholderId: 'placeholder.projectLink',
				value: '',
				visible: true,
				readOnly: false,
				type: 'url',
			},
			description: '',
			media: [],
			images: {
				label: 'label.projectChoosePreview',
				selected: '',
				available: [
					{
						image: 'https://static.adweek.com/adweek.com-prod/wp-content/uploads/2018/07/voice-0709-content-2018.png',
						selected: false,
					},
					{
						image: 'https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/McKinsey%20Digital/Our%20Insights/Creativitys%20bottom%20line%20How%20winning%20companies%20turn%20creativity%20into%20business%20value%20and%20growth/Creativitys-bottom-line-1536x1536_Standard.ashx',
						selected: false,
					},
					{
						image: 'https://images.theconversation.com/files/183880/original/file-20170829-5012-mu9wnk.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=496&fit=clip',
						selected: false,
					}
				]
			},
			newMedia: null,
			newMediaId: null,
			currentNewFile: null,
			skills: [],
			moods: [],

		};

		this.handleUploadImagePreview = this.handleUploadImagePreview.bind(this);
	}

	componentDidMount(): void {
		window.scrollTo(0,0);
		ReactGA.pageview('/add-work/4');
		this.props.onGetMoods();
		this.props.onGetSkills();

		if(this.props.data && this.props.data.media) {
			let media = this.props.data.media;

			this.setState({
				media,
				currentNewFile: null,
			});
		}
	}

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;

		let title = {...this.state.title};

		title.value = inputValue;

		this.setState({
			title,
		}, () => {
			this.props.setData(inputName, inputValue);
		});
	};

	handleUploadImagePreview = (uploaded: any) => {
		//console.log("Image: ", uploaded);
		const data = new FormData();
		data.append('file', uploaded[0]);

		this.props.onCreateFile(data);
	};

	handleChangeDescription = (value: any) => {
		this.setState({
			description: value,
		}, () => {
			this.props.setData('description', value);
		});
	};

	handleSelectImage = (id: number) => {
		let media = [...this.state.media];

		const currentPreview = media.filter((item: any) => item.preview && item.type === 'image');

		currentPreview.forEach((prev: any) => {
			this.props.onUpdateMedia(prev.id, { preview: false, coverType: prev.coverType });
		});


		this.props.onUpdateMedia(id, { preview: true });


		//this.props.onUpdateMedia(id.toString(), { preview: true });
	};

	handleChangeSkills = (value: Array<any>) => {
		this.setState({
			skills: value,
		}, () => {
			this.props.setData('skills', value);
		});
	};

	handleChangeMoods = (value: Array<any>) => {
		this.setState({
			moods: value,
		}, () => {
			this.props.setData('moods', value);
		});
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevState.currentNewFile !== this.state.currentNewFile && this.state.currentNewFile){

			this.props.onCreateMedia({
				fileCode: this.state.currentNewFile[0].code,
				//preview: true,
				type: 'image',
				coverType: 2,
			})
		}
		if(prevState.newMedia !== this.state.newMedia && this.state.newMedia){
			this.props.onCreateMedia(this.state.newMedia);
		}
		if(prevState.newMediaId !== this.state.newMediaId && this.state.newMediaId){
			this.props.onResetFile();
			const projectId = this.props.data.id;
			const mediaId = this.state.newMediaId;
			this.props.onAddMediaToProject(projectId, mediaId);
			this.props.onResetMedia();
		}
	}

	componentWillUnmount(): void {
		this.props.onResetFile();
		this.props.onResetMedia();
	}

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let title = nextState.title;
		let description = nextState.description;
		let projectLink = nextState.projectLink;
		let media = nextState.media;
		let newMedia = nextState.newMedia;
		let newMediaId = nextState.newMediaId;
		let currentNewFile = nextState.currentNewFile;
		let skills = nextState.skills;
		let moods = nextState.moods;

		if(nextProps.data){
			title = {
				...title,
				value: nextProps.data.title || '',
			};

			projectLink = {
				...projectLink,
				value: nextProps.data.projectLink || '',
			};

			description = nextProps.data.description || '';

			//const mediaList = nextProps.data.hasOwnProperty('media') && nextProps.data.media ? nextProps.data.media : [];

			//media = mediaList.filter((m: IMedia) => m.type === 'image');

			skills = nextProps.data.skills.map((text: any) => text.title);
			moods = nextProps.data.moods.map((text: any) => text.title);
		}

		if(nextProps.file){
			currentNewFile = nextProps.file;
		} else {
			currentNewFile = null;
		}

		if(nextProps.mediaCreated){
			media = media.concat(nextProps.mediaCreated);

			if(currentNewFile)
				newMediaId = nextProps.mediaCreated.id;
			else{
				media.map((m: IMedia) => {
					m.preview = nextProps.mediaCreated.id === m.id ? nextProps.mediaCreated.preview : m.preview;
				});
			}
		}

		if(nextProps.mediaUpdated){
			media = media.map((m: IMedia) => {
				if(m.type === 'image' && nextProps.mediaUpdated.id === m.id)
					m.preview = nextProps.mediaUpdated.preview;

				return m;
			});
		}

		if(nextProps.updatedMedia.length > 0){
			media = nextProps.updatedMedia.filter((m: any) => m.type === 'image');

			if(nextProps.mediaCreated && nextProps.mediaCreated.hasOwnProperty('preview') && nextProps.mediaCreated.preview) {
				const mediaCreatedCur = nextProps.updatedMedia.filter((m: any) => m.type === 'image');

				mediaCreatedCur.map((m: any) => {
					m.preview = nextProps.mediaCreated.id === m.id;
				});

				media = mediaCreatedCur;
			}
		}

		return {
			title,
			projectLink,
			description,
			media,
			currentNewFile,
			newMedia,
			newMediaId,
			skills,
			moods
		}
	}

    render () {

		const { title, description, images, media, skills, moods, projectLink } = this.state;
		const { savingMedia, savingFile, skillsList, moodsList } = this.props;

        return (
	        <div className={classes.Step4}>
		        <div className={classes['Step4-content']}>
					<div className={[classes['Step4-column'], classes['Step4-column--large']].join(' ')}>
						<div className={[classes['Step4-row'], classes['Step4-row--noline']].join(' ')}>
							<Input
								name={title.name}
								value={title.value}
								changed={this.handleInputChanged.bind(this)}
								placeholderId={title.placeholderId}
								visible={title.visible}
								readOnly={title.readOnly}
								type={title.type}
								label={'label.projectItem.title'}
								required={true} />
						</div>
						<div className={[classes['Step4-row'], classes['Step4-row--noline']].join(' ')}>
							<EditorTextarea
								toolbarHidden={false}
								value={description}
								labelId={'label.projectDescription'}
								placeholderId={'placeholder.projectDescription'}
								onChange={this.handleChangeDescription}
								required={true} />
						</div>
						<div className={[classes['Step4-row'], classes['Step4-row--noline']].join(' ')}>
							<small
								style={{
								color: projectLink.value && !checkValidSecureURL(projectLink.value) ? 'red' : '#BBB', display: 'block',
								marginBottom: '5px'
								}}>
									{ <FormattedHTMLMessage id={'alert.https'} /> }
							</small>
							<Input
								name={projectLink.name}
								error={projectLink.value && !checkValidSecureURL(projectLink.value)}
								value={projectLink.value}
								changed={this.handleInputChanged.bind(this)}
								placeholderId={projectLink.placeholderId}
								label={'label.projectItem.link'}
								visible={projectLink.visible}
								readOnly={projectLink.readOnly}
								type={projectLink.type}
								styles={{marginBottom: '0'}}
								tooltip={<FormattedHTMLMessage id={'alert.https'} />} />
						</div>
						<div className={classes['Step4-row']}>
							<label className={stepClasses['Steps-label']}>
								<FormattedMessage id={'label.projectSkills'} />
							</label>
							<FormattedMessage id={'placeholder.addNewSkill'} defaultMessage={'Add new tag'}>
								{
									(placeholder: any) => <TagsInput changed={this.handleChangeSkills} suggestions={skillsList} placeholder={placeholder} selected={skills}/>
								}
							</FormattedMessage>
							<div style={{marginTop: '15px'}} className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
								<div className={stepClasses['Steps-info-box-content']}>
									<FormattedMessage id={'myOriginals.chipsInfo'} />
								</div>
								<div className={stepClasses['Steps-info-box-icon']} />
							</div>
						</div>
						{/* <div className={[classes['Step4-row'], classes['Step4-row--noline']].join(' ')}>
							<label className={stepClasses['Steps-label']}>
								<FormattedMessage id={'label.projectMoods'} />
							</label>
							<FormattedMessage id={'placeholder.addNewMood'} defaultMessage={'Add new tag'}>
								{
									(placeholder: any) => <TagsInput changed={this.handleChangeMoods} suggestions={moodsList} placeholder={placeholder} selected={moods}/>
								}
							</FormattedMessage>
						</div> */}
					</div>
					<div className={[classes['Step4-column'], classes['Step4-column--small']].join(' ')}>
						<ImagePicker
							label={images.label}
							selected={images.selected}
							images={media}
							loading={savingMedia || savingFile}
							addComponent={
								<UploadBox
									squared
									reference={this.upload}
									accept={'image/jpeg, image/png'}
									onSelectedFiles={this.handleUploadImagePreview}
									multiple={false}
									styles={{margin: '0 0 30px 0'}} />}
							action={this.handleSelectImage} />
						{
							(this.props.savingMedia || this.props.savingMedia || this.props.associateMediaToProject ) && 'Waiting please...'
						}
					</div>
		        </div>
	        </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		skillsList: state.skillState.skills,
		moodsList: state.moodState.moods,
		mediaCreated: state.mediaState.media,
		mediaUpdated: state.mediaState.mediaUpdated,
		file: state.fileState.file,
		savingFile: state.fileState.isStoring,
		savingMedia: state.mediaState.isStoring,
		associateMediaToProject: state.projectState.isStoring,
		error: state.mediaState.error,
		mediaAdded: state.projectState.mediaAdded,
		updatedMedia: state.projectState.updatedMedia
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetSkills: () => dispatch(actions.fetchSkills()),
		onGetMoods: () => dispatch(actions.fetchMoods()),
		onUpdateMedia: (id: number, data: any) => dispatch(actions.updateMedia(id, data)),
		onCreateMedia: (data: any) => dispatch(actions.createMedia(data)),
		onCreateFile: (data: any) => dispatch(actions.createFile(data)),
		onResetFile: () => dispatch(actions.resetFile()),
		onResetMedia: () => dispatch(actions.resetMedia()),
		onAddMediaToProject: (id: number, mediaId: string) => dispatch(actions.addMediaToProject(id, mediaId)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Step4 );
