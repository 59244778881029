import React, { Component } from 'react';
import classes from './Step4.module.scss';
import stepClasses from '../Steps.module.scss';
import Input from '../../../Input/Input';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import constants from "../../../../shared/constants";
import * as ReactGA from '../../../../GA';
import { FormattedMessage } from 'react-intl';
import TagsInput from '../../../TagsInput/TagsInput';
// https://github.com/whoisandy/react-rangeslider
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import './Rangeslider.scss';
import { IDistribution, IFormat, IInput, IMood } from '../../../../shared/interfaces';

const RANGE_STEPS = {
	[constants.ORIGINAL_STAGES[0].value]: constants.ORIGINAL_STAGES[0].key,
	[constants.ORIGINAL_STAGES[1].value]: constants.ORIGINAL_STAGES[1].key,
	[constants.ORIGINAL_STAGES[2].value]: constants.ORIGINAL_STAGES[2].key,
	[constants.ORIGINAL_STAGES[3].value]: constants.ORIGINAL_STAGES[3].key,
	// [constants.ORIGINAL_STAGES[4].value]: constants.ORIGINAL_STAGES[4].key,
	// [constants.ORIGINAL_STAGES[5].value]: constants.ORIGINAL_STAGES[5].key,
};

interface IProps {
	setData: (field: string, value: any) => void;
	data: any;
	editMode: boolean;
	onGetMoods: () => Array<IMood>;
	moodsList: Array<IMood>;
	onGetDistributions: () => Array<IDistribution>;
	distributionsList: Array<IDistribution>;
	onGetFormats: () => Array<IFormat>;
	formatsList: Array<IFormat>;
}

interface IState {
	lookingFor: IInput;
	moods: Array<string>;
	formats: Array<string>;
	distributions: Array<string>;
	stage: {
		value: number;
		name: string;
	}
}

class Step4 extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			lookingFor: {
				name: 'lookingFor',
				placeholderId: 'placeholder.originalLookingFor',
				value: '',
				visible: true,
				readOnly: false,
				type: 'text'
			},
			moods: [],
			distributions: [],
			formats: [],
			stage: {
				value: 0,
				name: 'Rough draft',
			}
		};
	}

	componentDidMount(): void {
		window.scrollTo(0,0);
		this.props.onGetMoods();
		this.props.onGetDistributions();
		this.props.onGetFormats();

		const { editMode, data } = this.props;

		if(editMode && data.hasOwnProperty('id')) {
			ReactGA.pageview(`/original/${data.id}/4`);
		}
		else {
			ReactGA.pageview('/add-original/4');
		}
	}

	handleChangeSlider = (value: number) => {
		let stage = {
			value,
			name: constants.ORIGINAL_STAGES.filter((stage: { value: number; key: string }) => stage.value === value)[0].key,
		};

		this.setState({
			stage
		}, () => {
			this.props.setData('stage', stage.name);
		})
	};

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;

		let lookingFor = {...this.state.lookingFor};

		lookingFor.value = inputValue;

		this.setState({
			lookingFor,
		}, () => {
			this.props.setData(inputName, inputValue);
		});
	};

	handleChangeTags = (key: string, value: Array<any>) => {
		this.setState({
			moods: value,
		}, () => {
			this.props.setData(key, value);
		});
	};

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let lookingFor = nextState.lookingFor;
		let moods = nextState.moods;
		let distributions = nextState.distributions;
		let formats = nextState.formats;
		let stage = nextState.stage;

		if(nextProps.data){
			lookingFor = {
				...lookingFor,
				value: nextProps.data.lookingFor || '',
			};

			stage = {
				value: nextProps.data.stage ? constants.ORIGINAL_STAGES.filter((stage: { value: number; key: string }) => stage.key === nextProps.data.stage)[0].value : 0,
				name: nextProps.data.stage || 'Rough draft'
			};

			moods = nextProps.data.mood.map((text: any) => text.title);
			distributions = nextProps.data.distribution.map((text: any) => text.title);
			formats = nextProps.data.format.map((text: any) => text.title);
		}

		return {
			lookingFor,
			moods,
			formats,
			distributions,
			stage,
		}
	}

    render () {

		const { lookingFor, moods, formats, distributions, stage } = this.state;
		const { moodsList, formatsList, distributionsList } = this.props;

        return (
	        <div className={classes.Step4}>
		        <div className={classes['Step4-content']}>
					<div className={[classes['Step4-row'], classes['Step4-row--large'], classes['Step4-row--noline']].join(' ')}>
						<div className={classes['Step4-row--flex']}>
							<div className={[classes['Step4-flex-item'], classes['Step4-flex-item--large']].join(' ')}>
								<label className={stepClasses['Steps-label']}>
									<FormattedMessage id={'label.originalStage'} />
								</label>
								<Slider
									min={0}
									max={60}
									step={20}
									value={stage.value}
									format={(value: number) => value + ' %'}
									labels={RANGE_STEPS}
									onChange={this.handleChangeSlider}/>
							</div>
							<div className={classes['Step4-flex-item']}>
								<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
									<div className={stepClasses['Steps-info-box-content']}>
										<FormattedMessage id={'myOriginals.stageInfo'} />
									</div>
									<div className={stepClasses['Steps-info-box-icon']} />
								</div>
							</div>
						</div>

						<div className={classes['Step4-row--flex']}>
							<div className={[classes['Step4-flex-item'], classes['Step4-flex-item--large']].join(' ')}>
								<label className={[stepClasses['Steps-label'], stepClasses['Steps-label--nomargin']].join(' ')}>
									<FormattedMessage id={'label.originalItem.myRole'} />
								</label>
								<Input
									name={lookingFor.name}
									value={lookingFor.value}
									changed={this.handleInputChanged.bind(this)}
									placeholderId={lookingFor.placeholderId}
									visible={lookingFor.visible}
									readOnly={lookingFor.readOnly}
									type={lookingFor.type} />
							</div>
							<div className={classes['Step4-flex-item']}>
								<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
									<div className={stepClasses['Steps-info-box-content']}>
										<FormattedMessage id={'myOriginals.myRoleInfo'} />
									</div>
									<div className={stepClasses['Steps-info-box-icon']} />
								</div>
							</div>
						</div>

						<div className={classes['Step4-row--flex']}>
							<div className={[classes['Step4-flex-item'], classes['Step4-flex-item--large']].join(' ')}>
								<label className={[stepClasses['Steps-label'], stepClasses['Steps-label--nomargin']].join(' ')}>
									<FormattedMessage id={'label.originalFormats'} />
								</label>
								<FormattedMessage id={'myOriginals.formatInfo'} defaultMessage={'Add new format'}>
									{
										(placeholder: any) => <TagsInput changed={(values: Array<any>) => this.handleChangeTags('format', values)} suggestions={formatsList} placeholder={placeholder} selected={formats}/>
									}
								</FormattedMessage>
							</div>
							<div className={classes['Step4-flex-item']}>
								<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
									<div className={stepClasses['Steps-info-box-content']}>
										<FormattedMessage id={'myOriginals.chipsInfo'} />
									</div>
									<div className={stepClasses['Steps-info-box-icon']} />
								</div>
							</div>
						</div>

						{/* DISTRIBUTION HIDDEN */}
						{/* <div className={classes['Step4-row--flex']}>
							<div className={[classes['Step4-flex-item'], classes['Step4-flex-item--large']].join(' ')}>
								<label className={[stepClasses['Steps-label'], stepClasses['Steps-label--nomargin']].join(' ')}>
									<FormattedMessage id={'label.originalDistributions'} />
								</label>
								<FormattedMessage id={'myOriginals.distributionInfo'} defaultMessage={'Add new distribution'}>
									{
										(placeholder: any) => <TagsInput changed={(values: Array<any>) => this.handleChangeTags('distribution', values)} suggestions={distributionsList} placeholder={placeholder} selected={distributions}/>
									}
								</FormattedMessage>
							</div>
							<div className={classes['Step4-flex-item']}>
								<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
									<div className={stepClasses['Steps-info-box-content']}>
										<FormattedMessage id={'myOriginals.chipsInfo'} />
									</div>
									<div className={stepClasses['Steps-info-box-icon']} />
								</div>
							</div>
						</div> */}

						<div className={classes['Step4-row--flex']}>
							<div className={[classes['Step4-flex-item'], classes['Step4-flex-item--large']].join(' ')}>
								<label className={[stepClasses['Steps-label'], stepClasses['Steps-label--nomargin']].join(' ')}>
									<FormattedMessage id={'label.originalMoods'} />
								</label>
								<FormattedMessage id={'myOriginals.moodInfo'} defaultMessage={'Start typing genre'}>
									{
										(placeholder: any) => <TagsInput changed={(values: Array<any>) => this.handleChangeTags('mood', values)} suggestions={moodsList} placeholder={placeholder} selected={moods}/>
									}
								</FormattedMessage>
							</div>
							<div className={classes['Step4-flex-item']}>
								<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
									<div className={stepClasses['Steps-info-box-content']}>
										<FormattedMessage id={'myOriginals.chipsInfo'} />
									</div>
									<div className={stepClasses['Steps-info-box-icon']} />
								</div>
							</div>
						</div>

					</div>
		        </div>
	        </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		moodsList: state.moodState.moods,
		distributionsList: state.distributionState.distributions,
		formatsList: state.formatState.formats,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetMoods: () => dispatch(actions.fetchMoods()),
		onGetDistributions: () => dispatch(actions.fetchDistributions()),
		onGetFormats: () => dispatch(actions.fetchFormats()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Step4 );
