import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IAttachment } from '../../shared/interfaces';

interface IAction {
    type: string;
    list: Array<IAttachment>;
    attachment: IAttachment;
    attachmentUpdated: IAttachment | null;
    error: string;
    removed: boolean;
}

const INITIAL_STATE = {
    list: [],
    attachment: null,
	attachmentUpdated: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
};

const resetAttachment = (state = INITIAL_STATE) => {
	return updateObject(state, { attachment: null, isStoring: false, error: null, didInvalidate: false, isStored: null, attachmentUpdated: null, });
};

const createAttachmentStart = (state = INITIAL_STATE) => {
	return updateObject(state, { attachment: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createAttachmentSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { attachment: action.attachment, isStoring: false });
};

const createAttachmentFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchAttachmentsStart = (state = INITIAL_STATE) => {
    return updateObject(state, { list: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchAttachmentsSuccess = (state = INITIAL_STATE, action: IAction) => {

    return updateObject(state, { list: action.list, isFetching: false });
};

const fetchAttachmentsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchAttachmentStart = (state = INITIAL_STATE) => {
	return updateObject(state, { attachment: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchAttachmentSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { attachment: action.attachment, isFetching: false });
};

const fetchAttachmentFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateAttachmentStart = (state = INITIAL_STATE) => {
    return updateObject(state, { attachmentUpdated: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const updateAttachmentSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { attachmentUpdated: action.attachmentUpdated, isStoring: false });
};

const updateAttachmentFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const deleteAttachmentStart = (state = INITIAL_STATE) => {
    return updateObject(state, { isStoring: true, removed: null, error: null, didInvalidate: false });
};

const deleteAttachmentSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { removed: action.removed, isStoring: false });
};

const deleteAttachmentFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, removed: false, didInvalidate: true, isStoring: false });
};

const attachmentReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_ATTACHMENT_START: return createAttachmentStart(state);
	    case actionTypes.CREATE_ATTACHMENT_SUCCESS: return createAttachmentSuccess(state, action);
	    case actionTypes.CREATE_ATTACHMENT_FAIL: return createAttachmentFail(state, action);
        // READ/FETCHING
        case actionTypes.FETCH_ATTACHMENTS_START: return fetchAttachmentsStart(state);
        case actionTypes.FETCH_ATTACHMENTS_SUCCESS: return fetchAttachmentsSuccess(state, action);
        case actionTypes.FETCH_ATTACHMENTS_FAIL: return fetchAttachmentsFail(state, action);
	    case actionTypes.FETCH_ATTACHMENT_START: return fetchAttachmentStart(state);
	    case actionTypes.FETCH_ATTACHMENT_SUCCESS: return fetchAttachmentSuccess(state, action);
	    case actionTypes.FETCH_ATTACHMENT_FAIL: return fetchAttachmentFail(state, action);
        // UPDATE
        case actionTypes.UPDATE_ATTACHMENT_START: return updateAttachmentStart(state);
        case actionTypes.UPDATE_ATTACHMENT_SUCCESS: return updateAttachmentSuccess(state, action);
        case actionTypes.UPDATE_ATTACHMENT_FAIL: return updateAttachmentFail(state, action);
        // DELETE
        case actionTypes.DELETE_ATTACHMENT_START: return deleteAttachmentStart(state);
        case actionTypes.DELETE_ATTACHMENT_SUCCESS: return deleteAttachmentSuccess(state, action);
        case actionTypes.DELETE_ATTACHMENT_FAIL: return deleteAttachmentFail(state, action);

	    case actionTypes.RESET_ATTACHMENT: return resetAttachment(state);

        default:
            return state;
    }
};

export default attachmentReducer;
