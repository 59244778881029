import * as actionTypes from './actionTypes';
import { IError, IInvitation, IResponse } from '../../shared/interfaces';
import InvitationsService from '../../services/api/invitations';

// CREATE
export const createInvitationStart = () => {
	return {
		type: actionTypes.CREATE_INVITATION_START,
	}
};

export const createInvitationSuccess = (invitation: IInvitation) => {
	return {
		type: actionTypes.CREATE_INVITATION_SUCCESS,
		invitation
	}
};

export const createInvitationFail = (error: string) => {
	return {
		type: actionTypes.CREATE_INVITATION_FAIL,
		error,
	}
};

export const createInvitation = (data: IInvitation) => {
	return (dispatch: any) => {
		dispatch(createInvitationStart());

		InvitationsService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createInvitationSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(createInvitationFail(errorMsg));
				}
			);
	}
};

export const fetchInvitationsByUserStart = () => {
	return {
		type: actionTypes.FETCH_INVITATIONS_BY_USER_START,
	}
};

export const fetchInvitationsByUserSuccess = (list: Array<IInvitation>) => {
	return {
		type: actionTypes.FETCH_INVITATIONS_BY_USER_SUCCESS,
		list
	}
};

export const fetchInvitationsByUserFail = (error: string) => {
	return {
		type: actionTypes.FETCH_INVITATIONS_BY_USER_FAIL,
		error,
	}
};

export const fetchInvitationsByUser = (userId: number) => {
	return (dispatch: any) => {
		dispatch(fetchInvitationsByUserStart());

		InvitationsService
			.getByUser(userId)
			.then(
				(response: IResponse) => {

					dispatch(fetchInvitationsByUserSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchInvitationsByUserFail(errorMsg));
				}
			);
	}
};
