// REGISTRATION
export const REGISTRATION_START = 'REGISTRATION_START';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';

export const REGISTRATION_FOR_SCHOOL_START = 'REGISTRATION_FOR_SCHOOL_START';
export const REGISTRATION_FOR_SCHOOL_SUCCESS = 'REGISTRATION_FOR_SCHOOL_SUCCESS';
export const REGISTRATION_FOR_SCHOOL_FAIL = 'REGISTRATION_FOR_SCHOOL_FAIL';

// SESSION
export const SESSION_START = 'SESSION_START';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_FAIL = 'SESSION_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

// ACTIVATION
export const ACTIVATION_START = 'ACTIVATION_START';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';

// USERS
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = 'FETCH_CURRENT_USER_FAIL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_NETWORK_USERS_START = 'FETCH_NETWORK_USERS_START';
export const FETCH_NETWORK_USERS_SUCCESS = 'FETCH_NETWORK_USERS_SUCCESS';
export const FETCH_NETWORK_USERS_FAIL = 'FETCH_NETWORK_USERS_FAIL';

export const FETCH_USERS_BY_FILTERS_START = 'FETCH_USERS_BY_FILTERS_START';
export const FETCH_USERS_BY_FILTERS_SUCCESS = 'FETCH_USERS_BY_FILTERS_SUCCESS';
export const FETCH_USERS_BY_FILTERS_FAIL = 'FETCH_USERS_BY_FILTERS_FAIL';

export const FETCH_USER_BY_ID_START = 'FETCH_USER_BY_ID_START';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAIL = 'FETCH_USER_BY_ID_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const RECOVERY_USER_PASSWORD_START = 'RECOVERY_USER_PASSWORD_START';
export const RECOVERY_USER_PASSWORD_SUCCESS = 'RECOVERY_USER_PASSWORD_SUCCESS';
export const RECOVERY_USER_PASSWORD_FAIL = 'RECOVERY_USER_PASSWORD_FAIL';

export const UPDATE_USER_PASSWORD_START = 'UPDATE_USER_PASSWORD_START';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL';

export const SET_TOUR_DISABLED_START = 'SET_TOUR_DISABLED_START';
export const SET_TOUR_DISABLED_SUCCESS = 'SET_TOUR_DISABLED_SUCCESS';
export const SET_TOUR_DISABLED_FAIL = 'SET_TOUR_DISABLED_FAIL';

// CURRICULUM
export const CREATE_CURRICULUM_START = 'CREATE_CURRICULUM_START';
export const CREATE_CURRICULUM_SUCCESS = 'CREATE_CURRICULUM_SUCCESS';
export const CREATE_CURRICULUM_FAIL = 'CREATE_CURRICULUM_FAIL';

export const FETCH_CURRICULUM_LIST_START = 'FETCH_CURRICULUM_LIST_START';
export const FETCH_CURRICULUM_LIST_SUCCESS = 'FETCH_CURRICULUM_LIST_SUCCESS';
export const FETCH_CURRICULUM_LIST_FAIL = 'FETCH_CURRICULUM_LIST_FAIL';

export const FETCH_CURRICULUM_START = 'FETCH_CURRICULUM_START';
export const FETCH_CURRICULUM_SUCCESS = 'FETCH_CURRICULUM_SUCCESS';
export const FETCH_CURRICULUM_FAIL = 'FETCH_CURRICULUM_FAIL';

export const FETCH_CURRICULUM_BY_USER_START = 'FETCH_CURRICULUM_BY_USER_START';
export const FETCH_CURRICULUM_BY_USER_SUCCESS = 'FETCH_CURRICULUM_BY_USER_SUCCESS';
export const FETCH_CURRICULUM_BY_USER_FAIL = 'FETCH_CURRICULUM_BY_USER_FAIL';

export const UPDATE_CURRICULUM_START = 'UPDATE_CURRICULUM_START';
export const UPDATE_CURRICULUM_SUCCESS = 'UPDATE_CURRICULUM_SUCCESS';
export const UPDATE_CURRICULUM_FAIL = 'UPDATE_CURRICULUM_FAIL';

export const DELETE_CURRICULUM_START = 'DELETE_CURRICULUM_START';
export const DELETE_CURRICULUM_SUCCESS = 'DELETE_CURRICULUM_SUCCESS';
export const DELETE_CURRICULUM_FAIL = 'DELETE_CURRICULUM_FAIL';

// FILES
export const CREATE_FILE_START = 'CREATE_FILE_START';
export const CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS';
export const CREATE_FILE_FAIL = 'CREATE_FILE_FAIL';

export const FETCH_FILE_START = 'FETCH_FILE_START';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';
export const FETCH_FILE_FAIL = 'FETCH_FILE_FAIL';

export const RESET_FILE = 'RESET_FILE';

// MEDIA
export const CREATE_MEDIA_START = 'CREATE_MEDIA_START';
export const CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS';
export const CREATE_MEDIA_FAIL = 'CREATE_MEDIA_FAIL';

export const FETCH_MEDIA_LIST_START = 'FETCH_MEDIA_LIST_START';
export const FETCH_MEDIA_LIST_SUCCESS = 'FETCH_MEDIA_LIST_SUCCESS';
export const FETCH_MEDIA_LIST_FAIL = 'FETCH_MEDIA_LIST_FAIL';

export const FETCH_MEDIA_START = 'FETCH_MEDIA_START';
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
export const FETCH_MEDIA_FAIL = 'FETCH_MEDIA_FAIL';

export const UPDATE_MEDIA_START = 'UPDATE_MEDIA_START';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAIL = 'UPDATE_MEDIA_FAIL';

export const DELETE_MEDIA_START = 'DELETE_MEDIA_START';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAIL = 'DELETE_MEDIA_FAIL';

export const RESET_MEDIA = 'RESET_MEDIA';

// ATTACHMENTS
export const CREATE_ATTACHMENT_START = 'CREATE_ATTACHMENT_START';
export const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_FAIL = 'CREATE_ATTACHMENT_FAIL';

export const FETCH_ATTACHMENTS_START = 'FETCH_ATTACHMENTS_START';
export const FETCH_ATTACHMENTS_SUCCESS = 'FETCH_ATTACHMENTS_SUCCESS';
export const FETCH_ATTACHMENTS_FAIL = 'FETCH_ATTACHMENTS_FAIL';

export const FETCH_ATTACHMENT_START = 'FETCH_ATTACHMENT_START';
export const FETCH_ATTACHMENT_SUCCESS = 'FETCH_ATTACHMENT_SUCCESS';
export const FETCH_ATTACHMENT_FAIL = 'FETCH_ATTACHMENT_FAIL';

export const UPDATE_ATTACHMENT_START = 'UPDATE_ATTACHMENT_START';
export const UPDATE_ATTACHMENT_SUCCESS = 'UPDATE_ATTACHMENT_SUCCESS';
export const UPDATE_ATTACHMENT_FAIL = 'UPDATE_ATTACHMENT_FAIL';

export const DELETE_ATTACHMENT_START = 'DELETE_ATTACHMENT_START';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAIL = 'DELETE_ATTACHMENT_FAIL';

export const RESET_ATTACHMENT = 'RESET_ATTACHMENT';

// NOTIFICATIONS
export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAIL = 'FETCH_NOTIFICATIONS_FAIL';
export const SET_READ_NOTIFICATION_START = 'SET_READ_NOTIFICATION_START';
export const SET_READ_NOTIFICATION_SUCCESS = 'SET_READ_NOTIFICATION_SUCCESS';
export const SET_READ_NOTIFICATION_FAIL = 'SET_READ_NOTIFICATION_FAIL';
export const SET_ALL_READ_NOTIFICATION_BY_USER_START = 'SET_ALL_READ_NOTIFICATION_BY_USER_START';
export const SET_ALL_READ_NOTIFICATION_BY_USER_SUCCESS = 'SET_ALL_READ_NOTIFICATION_BY_USER_SUCCESS';
export const SET_ALL_READ_NOTIFICATION_BY_USER_FAIL = 'SET_ALL_READ_NOTIFICATION_BY_USER_FAIL';

// CATEGORIES
export const FETCH_CATEGORY_START = 'FETCH_CATEGORY_START';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAIL = 'FETCH_CATEGORY_FAIL';
export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL';

// SKILLS
export const FETCH_SKILL_START = 'FETCH_SKILL_START';
export const FETCH_SKILL_SUCCESS = 'FETCH_SKILL_SUCCESS';
export const FETCH_SKILL_FAIL = 'FETCH_SKILL_FAIL';
export const FETCH_SKILLS_START = 'FETCH_SKILLS_START';
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_FAIL = 'FETCH_SKILLS_FAIL';

// MOODS
export const FETCH_MOOD_START = 'FETCH_MOOD_START';
export const FETCH_MOOD_SUCCESS = 'FETCH_MOOD_SUCCESS';

export const FETCH_MOOD_FAIL = 'FETCH_MOOD_FAIL';
export const FETCH_MOODS_START = 'FETCH_MOODS_START';
export const FETCH_MOODS_SUCCESS = 'FETCH_MOODS_SUCCESS';
export const FETCH_MOODS_FAIL = 'FETCH_MOODS_FAIL';

export const FETCH_MOODS_FOR_ORIGINALS_FILTER_START = 'FETCH_MOODS_FOR_ORIGINALS_FILTER_START';
export const FETCH_MOODS_FOR_ORIGINALS_FILTER_SUCCESS = 'FETCH_MOODS_FOR_ORIGINALS_FILTER_SUCCESS';
export const FETCH_MOODS_FOR_ORIGINALS_FILTER_FAIL = 'FETCH_MOODS_FOR_ORIGINALS_FILTER_FAIL';

// DISTRIBUTIONS
export const FETCH_DISTRIBUTION_START = 'FETCH_DISTRIBUTION_START';
export const FETCH_DISTRIBUTION_SUCCESS = 'FETCH_DISTRIBUTION_SUCCESS';
export const FETCH_DISTRIBUTION_FAIL = 'FETCH_DISTRIBUTION_FAIL';

export const FETCH_DISTRIBUTIONS_START = 'FETCH_DISTRIBUTIONS_START';
export const FETCH_DISTRIBUTIONS_SUCCESS = 'FETCH_DISTRIBUTIONS_SUCCESS';
export const FETCH_DISTRIBUTIONS_FAIL = 'FETCH_DISTRIBUTIONS_FAIL';

export const FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_START = 'FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_START';
export const FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_SUCCESS = 'FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_SUCCESS';
export const FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_FAIL = 'FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_FAIL';

// FORMATS
export const FETCH_FORMAT_START = 'FETCH_FORMAT_START';
export const FETCH_FORMAT_SUCCESS = 'FETCH_FORMAT_SUCCESS';
export const FETCH_FORMAT_FAIL = 'FETCH_FORMAT_FAIL';

export const FETCH_FORMATS_START = 'FETCH_FORMATS_START';
export const FETCH_FORMATS_SUCCESS = 'FETCH_FORMATS_SUCCESS';
export const FETCH_FORMATS_FAIL = 'FETCH_FORMATS_FAIL';

export const FETCH_FORMATS_FOR_ORIGINALS_FILTER_START = 'FETCH_FORMATS_FOR_ORIGINALS_FILTER_START';
export const FETCH_FORMATS_FOR_ORIGINALS_FILTER_SUCCESS = 'FETCH_FORMATS_FOR_ORIGINALS_FILTER_SUCCESS';
export const FETCH_FORMATS_FOR_ORIGINALS_FILTER_FAIL = 'FETCH_FORMATS_FOR_ORIGINALS_FILTER_FAIL';

// BELLS
export const FETCH_BELL_START = 'FETCH_BELL_START';
export const FETCH_BELL_SUCCESS = 'FETCH_BELL_SUCCESS';
export const FETCH_BELL_FAIL = 'FETCH_BELL_FAIL';

export const FETCH_BELLS_START = 'FETCH_BELLS_START';
export const FETCH_BELLS_SUCCESS = 'FETCH_BELLS_SUCCESS';
export const FETCH_BELLS_FAIL = 'FETCH_BELLS_FAIL';

export const FETCH_BELLS_FOR_ORIGINALS_FILTER_START = 'FETCH_BELLS_FOR_ORIGINALS_FILTER_START';
export const FETCH_BELLS_FOR_ORIGINALS_FILTER_SUCCESS = 'FETCH_BELLS_FOR_ORIGINALS_FILTER_SUCCESS';
export const FETCH_BELLS_FOR_ORIGINALS_FILTER_FAIL = 'FETCH_BELLS_FOR_ORIGINALS_FILTER_FAIL';

// PROJECTS
export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';

export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';

export const FETCH_PROJECT_START = 'FETCH_PROJECT_START';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAIL = 'FETCH_PROJECT_FAIL';

export const FETCH_PROJECTS_BY_USER_START = 'FETCH_PROJECTS_BY_USER_START';
export const FETCH_PROJECTS_BY_USER_SUCCESS = 'FETCH_PROJECTS_BY_USER_SUCCESS';
export const FETCH_PROJECTS_BY_USER_FAIL = 'FETCH_PROJECTS_BY_USER_FAIL';

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';

export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';

export const CREATE_PROJECT_MEDIA_START = 'CREATE_PROJECT_MEDIA_START';
export const CREATE_PROJECT_MEDIA_SUCCESS = 'CREATE_PROJECT_MEDIA_SUCCESS';
export const CREATE_PROJECT_MEDIA_FAIL = 'CREATE_PROJECT_MEDIA_FAIL';

export const DELETE_PROJECT_MEDIA_START = 'DELETE_PROJECT_MEDIA_START';
export const DELETE_PROJECT_MEDIA_SUCCESS = 'DELETE_PROJECT_MEDIA_SUCCESS';
export const DELETE_PROJECT_MEDIA_FAIL = 'DELETE_PROJECT_MEDIA_FAIL';

export const CHANGE_MEDIA_ORDER_FOR_PROJECT_START = 'CHANGE_MEDIA_ORDER_FOR_PROJECT_START';
export const CHANGE_MEDIA_ORDER_FOR_PROJECT_SUCCESS = 'CHANGE_MEDIA_ORDER_FOR_PROJECT_SUCCESS';
export const CHANGE_MEDIA_ORDER_FOR_PROJECT_FAIL = 'CHANGE_MEDIA_ORDER_FOR_PROJECT_FAIL';

// ORIGINALS
export const CREATE_ORIGINAL_START = 'CREATE_ORIGINAL_START';
export const CREATE_ORIGINAL_SUCCESS = 'CREATE_ORIGINAL_SUCCESS';
export const CREATE_ORIGINAL_FAIL = 'CREATE_ORIGINAL_FAIL';

export const FETCH_ORIGINALS_START = 'FETCH_ORIGINALS_START';
export const FETCH_ORIGINALS_SUCCESS = 'FETCH_ORIGINALS_SUCCESS';
export const FETCH_ORIGINALS_FAIL = 'FETCH_ORIGINALS_FAIL';

export const SEARCH_ORIGINALS_START = 'SEARCH_ORIGINALS_START';
export const SEARCH_ORIGINALS_SUCCESS = 'SEARCH_ORIGINALS_SUCCESS';
export const SEARCH_ORIGINALS_FAIL = 'SEARCH_ORIGINALS_FAIL';

export const FETCH_ORIGINAL_START = 'FETCH_ORIGINAL_START';
export const FETCH_ORIGINAL_SUCCESS = 'FETCH_ORIGINAL_SUCCESS';
export const FETCH_ORIGINAL_FAIL = 'FETCH_ORIGINAL_FAIL';

export const FETCH_ORIGINALS_BY_USER_START = 'FETCH_ORIGINALS_BY_USER_START';
export const FETCH_ORIGINALS_BY_USER_SUCCESS = 'FETCH_ORIGINALS_BY_USER_SUCCESS';
export const FETCH_ORIGINALS_BY_USER_FAIL = 'FETCH_ORIGINALS_BY_USER_FAIL';

export const UPDATE_ORIGINAL_START = 'UPDATE_ORIGINAL_START';
export const UPDATE_ORIGINAL_SUCCESS = 'UPDATE_ORIGINAL_SUCCESS';
export const UPDATE_ORIGINAL_FAIL = 'UPDATE_ORIGINAL_FAIL';

export const DELETE_ORIGINAL_START = 'DELETE_ORIGINAL_START';
export const DELETE_ORIGINAL_SUCCESS = 'DELETE_ORIGINAL_SUCCESS';
export const DELETE_ORIGINAL_FAIL = 'DELETE_ORIGINAL_FAIL';

export const CREATE_ORIGINAL_MEDIA_START = 'CREATE_ORIGINAL_MEDIA_START';
export const CREATE_ORIGINAL_MEDIA_SUCCESS = 'CREATE_ORIGINAL_MEDIA_SUCCESS';
export const CREATE_ORIGINAL_MEDIA_FAIL = 'CREATE_ORIGINAL_MEDIA_FAIL';

export const DELETE_ORIGINAL_MEDIA_START = 'DELETE_ORIGINAL_MEDIA_START';
export const DELETE_ORIGINAL_MEDIA_SUCCESS = 'DELETE_ORIGINAL_MEDIA_SUCCESS';
export const DELETE_ORIGINAL_MEDIA_FAIL = 'DELETE_ORIGINAL_MEDIA_FAIL';

export const CHANGE_MEDIA_ORDER_FOR_ORIGINAL_START = 'CHANGE_MEDIA_ORDER_FOR_ORIGINAL_START';
export const CHANGE_MEDIA_ORDER_FOR_ORIGINAL_SUCCESS = 'CHANGE_MEDIA_ORDER_FOR_ORIGINAL_SUCCESS';
export const CHANGE_MEDIA_ORDER_FOR_ORIGINAL_FAIL = 'CHANGE_MEDIA_ORDER_FOR_ORIGINAL_FAIL';

export const CREATE_ORIGINAL_ATTACHMENT_START = 'CREATE_ORIGINAL_ATTACHMENT_START';
export const CREATE_ORIGINAL_ATTACHMENT_SUCCESS = 'CREATE_ORIGINAL_ATTACHMENT_SUCCESS';
export const CREATE_ORIGINAL_ATTACHMENT_FAIL = 'CREATE_ORIGINAL_ATTACHMENT_FAIL';

export const DELETE_ORIGINAL_ATTACHMENT_START = 'DELETE_ORIGINAL_ATTACHMENT_START';
export const DELETE_ORIGINAL_ATTACHMENT_SUCCESS = 'DELETE_ORIGINAL_ATTACHMENT_SUCCESS';
export const DELETE_ORIGINAL_ATTACHMENT_FAIL = 'DELETE_ORIGINAL_ATTACHMENT_FAIL';

export const CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_START = 'CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_START';
export const CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_SUCCESS = 'CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_SUCCESS';
export const CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_FAIL = 'CHANGE_ATTACHMENTS_ORDER_FOR_ORIGINAL_FAIL';

export const FETCH_REQUESTS_BY_ORIGINAL_START = 'FETCH_REQUESTS_BY_ORIGINAL_START';
export const FETCH_REQUESTS_BY_ORIGINAL_SUCCESS = 'FETCH_REQUESTS_BY_ORIGINAL_SUCCESS';
export const FETCH_REQUESTS_BY_ORIGINAL_FAIL = 'FETCH_REQUESTS_BY_ORIGINAL_FAIL';

export const FETCH_REQUESTS_BY_USER_START = 'FETCH_REQUESTS_BY_USER_START';
export const FETCH_REQUESTS_BY_USER_SUCCESS = 'FETCH_REQUESTS_BY_USER_SUCCESS';
export const FETCH_REQUESTS_BY_USER_FAIL = 'FETCH_REQUESTS_BY_USER_FAIL';

export const ADD_REQUEST_START = 'ADD_REQUEST_START';
export const ADD_REQUEST_SUCCESS = 'ADD_REQUEST_SUCCESS';
export const ADD_REQUEST_FAIL = 'ADD_REQUEST_FAIL';

export const UPDATE_REQUEST_START = 'UPDATE_REQUEST_START';
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAIL = 'UPDATE_REQUEST_FAIL';

export const DELETE_REQUEST_START = 'DELETE_REQUEST_START';
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_FAIL = 'DELETE_REQUEST_FAIL';

export const ADD_ACCESS_START = 'ADD_ACCESS_START';
export const ADD_ACCESS_SUCCESS = 'ADD_ACCESS_SUCCESS';
export const ADD_ACCESS_FAIL = 'ADD_ACCESS_FAIL';

export const RESET_MY_ORIGINALS = 'RESET_MY_ORIGINALS';
export const RESET_MY_ORIGINAL = 'RESET_MY_ORIGINAL';

export const UPDATE_ORIGINAL_COVER_START = 'UPDATE_ORIGINAL_COVER_START';
export const UPDATE_ORIGINAL_COVER_SUCCESS = 'UPDATE_ORIGINAL_COVER_SUCCESS';
export const UPDATE_ORIGINAL_COVER_FAIL = 'UPDATE_ORIGINAL_COVER_FAIL';

export const DELETE_ORIGINAL_COVER_START = 'DELETE_ORIGINAL_COVER_START';
export const DELETE_ORIGINAL_COVER_SUCCESS = 'DELETE_ORIGINAL_COVER_SUCCESS';
export const DELETE_ORIGINAL_COVER_FAIL = 'DELETE_ORIGINAL_COVER_FAIL';

export const RESET_ORIGINAL_COVER = 'RESET_ORIGINAL_COVER';

export const ADD_PATAMU_TO_ORIGINAL_START = 'ADD_PATAMU_TO_ORIGINAL_START';
export const ADD_PATAMU_TO_ORIGINAL_SUCCESS = 'ADD_PATAMU_TO_ORIGINAL_SUCCESS';
export const ADD_PATAMU_TO_ORIGINAL_FAIL = 'ADD_PATAMU_TO_ORIGINAL_FAIL';

// TEAMS
export const CREATE_TEAM_START = 'CREATE_TEAM_START';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAIL = 'CREATE_TEAM_FAIL';

export const FETCH_TEAM_BY_USER_START = 'FETCH_TEAM_BY_USER_START';
export const FETCH_TEAM_BY_USER_SUCCESS = 'FETCH_TEAM_BY_USER_SUCCESS';
export const FETCH_TEAM_BY_USER_FAIL = 'FETCH_TEAM_BY_USER_FAIL';

export const SEND_TEAM_INVITATIONS_START = 'SEND_TEAM_INVITATIONS_START';
export const SEND_TEAM_INVITATIONS_SUCCESS = 'SEND_TEAM_INVITATIONS_SUCCESS';
export const SEND_TEAM_INVITATIONS_FAIL = 'SEND_TEAM_INVITATIONS_FAIL';

// INVITATIONS
export const CREATE_INVITATION_START = 'CREATE_INVITATION_START';
export const CREATE_INVITATION_SUCCESS = 'CREATE_INVITATION_SUCCESS';
export const CREATE_INVITATION_FAIL = 'CREATE_INVITATION_FAIL';

export const FETCH_INVITATIONS_BY_USER_START = 'FETCH_INVITATIONS_BY_USER_START';
export const FETCH_INVITATIONS_BY_USER_SUCCESS = 'FETCH_INVITATIONS_BY_USER_SUCCESS';
export const FETCH_INVITATIONS_BY_USER_FAIL = 'FETCH_INVITATIONS_BY_USER_FAIL';

// MESSAGES
export const CREATE_MESSAGE_START = 'CREATE_MESSAGE_START';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL';

export const FETCH_MESSAGES_BY_USER_SENDER_START = 'FETCH_MESSAGES_BY_USER_SENDER_START';
export const FETCH_MESSAGES_BY_USER_SENDER_SUCCESS = 'FETCH_MESSAGES_BY_USER_SENDER_SUCCESS';
export const FETCH_MESSAGES_BY_USER_SENDER_FAIL = 'FETCH_MESSAGES_BY_USER_SENDER_FAIL';

export const FETCH_MESSAGES_BY_USER_DESTINATION_START = 'FETCH_MESSAGES_BY_USER_DESTINATION_START';
export const FETCH_MESSAGES_BY_USER_DESTINATION_SUCCESS = 'FETCH_MESSAGES_BY_USER_DESTINATION_SUCCESS';
export const FETCH_MESSAGES_BY_USER_DESTINATION_FAIL = 'FETCH_MESSAGES_BY_USER_DESTINATION_FAIL';

export const FETCH_INTERLOCUTORS_START = 'FETCH_INTERLOCUTORS_START';
export const FETCH_INTERLOCUTORS_SUCCESS = 'FETCH_INTERLOCUTORS_SUCCESS';
export const FETCH_INTERLOCUTORS_FAIL = 'FETCH_INTERLOCUTORS_FAIL';

export const FETCH_CONVERSATION_START = 'FETCH_CONVERSATION_START';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_FAIL = 'FETCH_CONVERSATION_FAIL';

export const SET_READ_MESSAGE_START = 'SET_READ_MESSAGE_START';
export const SET_READ_MESSAGE_SUCCESS = 'SET_READ_MESSAGE_SUCCESS';
export const SET_READ_MESSAGE_FAIL = 'SET_READ_MESSAGE_FAIL';

export const FETCH_UNREAD_MESSAGES_BY_USER_START = 'FETCH_UNREAD_MESSAGES_BY_USER_START';
export const FETCH_UNREAD_MESSAGES_BY_USER_SUCCESS = 'FETCH_UNREAD_MESSAGES_BY_USER_SUCCESS';
export const FETCH_UNREAD_MESSAGES_BY_USER_FAIL = 'FETCH_UNREAD_MESSAGES_BY_USER_FAIL';

export const RESET_CONVERSATION = 'RESET_CONVERSATION';

// AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
