import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IMood } from '../../shared/interfaces';

interface IAction {
    type: string;
    mood: IMood;
    moods: Array<IMood>;
    error: string;
}

const INITIAL_STATE = {
    mood: null,
	moods: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
};

const fetchMoodStart = (state = INITIAL_STATE) => {
	return updateObject(state, { mood: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchMoodSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { mood: action.mood, isFetching: false });
};

const fetchMoodFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchMoodsStart = (state = INITIAL_STATE) => {
	return updateObject(state, { moods: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchMoodsSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { moods: action.moods, isFetching: false });
};

const fetchMoodsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchMoodsForOriginalsFilterStart = (state = INITIAL_STATE) => {
	return updateObject(state, { moods: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchMoodsForOriginalsFilterSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { moods: action.moods, isFetching: false });
};

const fetchMoodsForOriginalsFilterFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const moodReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // READ/FETCHING
	    case actionTypes.FETCH_MOOD_START: return fetchMoodStart(state);
	    case actionTypes.FETCH_MOOD_SUCCESS: return fetchMoodSuccess(state, action);
	    case actionTypes.FETCH_MOOD_FAIL: return fetchMoodFail(state, action);

	    case actionTypes.FETCH_MOODS_START: return fetchMoodsStart(state);
	    case actionTypes.FETCH_MOODS_SUCCESS: return fetchMoodsSuccess(state, action);
	    case actionTypes.FETCH_MOODS_FAIL: return fetchMoodsFail(state, action);

	    case actionTypes.FETCH_MOODS_FOR_ORIGINALS_FILTER_START: return fetchMoodsForOriginalsFilterStart(state);
	    case actionTypes.FETCH_MOODS_FOR_ORIGINALS_FILTER_SUCCESS: return fetchMoodsForOriginalsFilterSuccess(state, action);
	    case actionTypes.FETCH_MOODS_FOR_ORIGINALS_FILTER_FAIL: return fetchMoodsForOriginalsFilterFail(state, action);


	    default:
            return state;
    }
};

export default moodReducer;
