import React, { Component } from 'react';
import classes from './Button.module.scss';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from "react-intl";

interface IProps {
    children: object | string;
    clicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    styles?: object;
    disabled?: boolean;
	type?: string;
	tooltip?: any;
	id?: string;
}

class Button extends Component<IProps, {}> {
	render(){
		const { children, clicked, styles, disabled, type, tooltip, id } = this.props;

		const buttonClass = [classes.Button, disabled ? classes['Button--disabled'] : '', type ? classes[`Button--${type}`] : ''].join(' ');
		return (
			<button
				disabled={disabled}
				style={styles}
				onClick={clicked}
				className={buttonClass}
				data-tip
				data-for={id}>
				{ children }
				{ tooltip &&
					<ReactTooltip effect="solid" id={id}>
						<FormattedMessage id={tooltip} />
					</ReactTooltip>
				}
			</button>
		);
	}
}

export default Button;
