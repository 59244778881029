import request from '../../shared/lib/request'
import constants from '../../shared/constants';

const get = (id?: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = id ? `/mood/${id}` : '/mood';

	return request({
		url:    endpoint,
		method: 'GET',
		headers
	});
};

const getForOriginalsFilter = () => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    '/mood/filter/original',
		method: 'GET',
		headers
	});
};

const MoodsService = {
    get, getForOriginalsFilter
};

export default MoodsService;
