import React from 'react';
import classes from './Loader.module.scss';

interface ILoader {
    styles?: object,
    white?: boolean,
}

const loader = ( props: ILoader ) => {
    return (
        <div style={props.styles} className={classes.Loader}>
            <div className={classes["Loader-content"]}>
                { props.white ?
                    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 49.7 43" xmlSpace="preserve">
                        <polygon style={{fill: '#ffffff'}} className={classes["Loader-0"]} points="27.2,40.1 28.9,43 32.7,43 29.1,36.9 "/>
                        <polygon style={{fill: '#ffffff'}} className={classes["Loader-1"]} points="31.5,32.7 37.5,43 41.2,43 33.4,29.5 "/>
                        <polygon style={{fill: '#ffffff'}} className={classes["Loader-2"]} points="37.7,22.1 35.8,25.4 46,43 49.7,43 "/>
                        <polygon style={{fill: '#ffffff'}} className={classes["Loader-3"]} points="33.4,14.8 17.1,43 20.8,43 35.2,18 "/>
                        <polygon style={{fill: '#ffffff'}} className={classes["Loader-4"]} points="29.1,7.4 8.5,43 12.3,43 31,10.6 "/>
                        <polygon style={{fill: '#ffffff'}} className={classes["Loader-5"]} points="24.8,0 0,43 3.7,43 26.7,3.2 "/>
                    </svg>
                :
                    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 49.7 43" xmlSpace="preserve">
                        <polygon className={classes["Loader-0"]} points="27.2,40.1 28.9,43 32.7,43 29.1,36.9 "/>
                        <polygon className={classes["Loader-1"]} points="31.5,32.7 37.5,43 41.2,43 33.4,29.5 "/>
                        <polygon className={classes["Loader-2"]} points="37.7,22.1 35.8,25.4 46,43 49.7,43 "/>
                        <polygon className={classes["Loader-3"]} points="33.4,14.8 17.1,43 20.8,43 35.2,18 "/>
                        <polygon className={classes["Loader-4"]} points="29.1,7.4 8.5,43 12.3,43 31,10.6 "/>
                        <polygon className={classes["Loader-5"]} points="24.8,0 0,43 3.7,43 26.7,3.2 "/>
                    </svg>
                }
            </div>
        </div>
    )
};

export default loader;
