import React, { Component, Fragment } from 'react';
import {FormattedMessage} from 'react-intl';
import ReactPlayer from 'react-player';
import moment from 'moment';
import classes from './ProjectItem.module.scss';
import Button from '../../Button/Button';
import { IAnswer, IQuestion } from '../../../shared/interfaces';

interface IProps {
	item: any;
	styles?: object;
	history?: any;
	onCloseClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
	onEditClicked: (id: number, lastStep: string) => any;
	onDeleteClicked: (id: number) => any;
	readOnly: boolean;
}

interface IState {
	tab: string;
}

class ProjectItem extends Component<IProps, IState> {
	constructor(props:IProps) {
		super(props);

		this.handleEditClicked = this.handleEditClicked.bind(this);
		this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
	}

	handleEditClicked(){
		const step = this.props.item.hasOwnProperty('lastStep') ? this.props.item.lastStep : '1';
		this.props.onEditClicked(this.props.item.id, step)
	}

	handleDeleteClicked(){
		this.props.onDeleteClicked(this.props.item.id)
	}

	render() {
		const { item, styles, onCloseClicked, readOnly } = this.props;

		const onlyMediaArray = item.media.sort((a: any, b: any) => (a.sort > b.sort) ? 1 : -1).filter((curr: any) => curr.coverType === 0);

		const itemContentTpl = onlyMediaArray.map((block: any, index: number) =>{

			let content = null;

        	switch (block.type) {
		        case 'image': content = (
						<div className={[classes['ProjectItem-content-block'], classes[`ProjectItem-content-block--${block.type}`]].join(' ')}>
							<img src={block.file.url} alt={'image'} />
						</div>
					);
		            break;
		        case 'embed': content = (
						<div className={[classes['ProjectItem-content-block'], classes[`ProjectItem-content-block--${block.type}`]].join(' ')}>
							<div dangerouslySetInnerHTML={{__html: block.content}} />
						</div>
					);
			        break;
		        case 'text': content = (
						<div className={[classes['ProjectItem-content-block'], classes[`ProjectItem-content-block--${block.type}`]].join(' ')}>
							<div dangerouslySetInnerHTML={{__html: block.content}} />
						</div>
					);
			        break;
		        case 'video': content = (
						<div className={[classes['ProjectItem-content-block'], classes[`ProjectItem-content-block--${block.type}`]].join(' ')}>
							<ReactPlayer url={block.content} playsinline={true} width="100%" />
						</div>
					);
			        break;
		        default: content = null;
	        }

			return (
				<Fragment key={index}>
					{ content }
				</Fragment>
			);
		});

		const questionsTpl = item.questions && item.questions.map((question: IQuestion, index: number) => {
			return (
				<div key={index} className={classes['ProjectItem-question']}>
					<label className={classes['ProjectItem-label']}>
						{ question.title }
					</label>
					<div className={classes['ProjectItem-answers']}>
						{
							question.answer.map((ans: IAnswer) => {
								return (
									<div key={ans.id} className={classes['ProjectItem-answer']}>
										{ ans.title }
									</div>
								)
							})
						}
					</div>
				</div>
			);
		});

		const moodsTpl = item.moods && item.moods.map((mood: any, index: number) => {
			return (
				<div key={index} className={classes['ProjectItem-answer']}>
					{ mood.title }
				</div>
			);
		});

		const skillsTpl = item.skills && item.skills.map((skill: any, index: number) => {
			return (
				<div key={index} className={classes['ProjectItem-answer']}>
					{ skill.title }
				</div>
			);
		});

		const isDraft = item.status === 'DRAFT';

		return (
			<div className={classes.ProjectItem} style={styles}>
				<div className={classes['ProjectItem-wrapper']}>
					{ isDraft &&
						<div className={classes['ProjectItem-draft-badge']}>
							<FormattedMessage id="general.draft" />
						</div>
					}
					<div className={classes['ProjectItem-header']}>
						<div className={classes['ProjectItem-header-left']}>
							<label className={classes['ProjectItem-label']}>
								<FormattedMessage id="label.projectItem.title" />
							</label>
							<h2 className={classes['ProjectItem-title']}>{ item.title }</h2>
						</div>
						<div className={classes['ProjectItem-header-right']}>
							{ item.projectRole &&
								<div className={classes['ProjectItem-info']}>
									<label className={classes['ProjectItem-label']}>
										<FormattedMessage id="label.projectItem.role" />
									</label>
									<div className={classes['ProjectItem-info-content']}>{ item.projectRole }</div>
								</div>
							}
							{ item.client &&
								<div className={classes['ProjectItem-info']}>
									<label className={classes['ProjectItem-label']}>
										<FormattedMessage id="label.projectItem.client" />
									</label>
									<div className={classes['ProjectItem-info-content']}>{ item.client }</div>
								</div>
							}
							{ item.projectDate &&
								<div className={classes['ProjectItem-info']}>
									<label className={classes['ProjectItem-label']}>
										<FormattedMessage id="label.projectItem.date" />
									</label>
									<div className={classes['ProjectItem-info-content']}>
									{ moment(item.projectDate).format('MMMM Y') }
									</div>
								</div>
							}
						</div>
					</div>

					<div className={[classes['ProjectItem-content'], classes['ProjectItem-content-details']].join(' ')}>
						<div className={classes['ProjectItem-content-specs']}>
							{/* project questions */}
							{ questionsTpl }
							{/* project skills */}
							{ item.skills.length > 0 &&
								<div className={classes['ProjectItem-content-skills']}>
									<label className={classes['ProjectItem-label']}>
										<FormattedMessage id="label.projectSkills" />
									</label>
									<div className={classes['ProjectItem-skills']}>
										{ skillsTpl }
									</div>
								</div>
							}
						</div>
						
						<div className={classes['ProjectItem-content-description']}>
							{/* project description */}
							<Fragment>
								<label className={classes['ProjectItem-label']}>
									<FormattedMessage id="label.projectItem.description" />
								</label>
								{ item.description && item.description.length > 8 ?
									<div dangerouslySetInnerHTML={{ __html: item.description }} />
								:
									<FormattedMessage id="label.projectItem.noDescription" />
								}
							</Fragment>

							{/* project mood */}
							{/* { item.moods.length > 0 &&
								<div className={classes['ProjectItem-content-moods']}>
									<label className={classes['ProjectItem-label']}>
										<FormattedMessage id="label.projectMoods" />
									</label>
									<div className={classes['ProjectItem-moods']}>
										{ moodsTpl }
									</div>
								</div>
							} */}
						</div>
					</div>

					<div className={[classes['ProjectItem-content'], classes['ProjectItem-content-project']].join(' ')}>
						{ itemContentTpl }
					</div>

				</div>
				<div className={classes['ProjectItem-footer']}>
					<div>
						{ item.projectLink &&
							<a
								className={classes['ProjectItem-nav-button']}
								href={item.projectLink}
								rel="noopener noreferrer"
								target="_blank">
								<FormattedMessage id="label.projectItem.viewLink" />
							</a>
						}
					</div>
					<div>
						<Button type="small" clicked={onCloseClicked}>
							<FormattedMessage id="button.close" />
						</Button>
						{
							!readOnly &&
							<Button type="secondary-small" styles={{ marginRight: '10px' }}
									clicked={this.handleEditClicked}>
								<FormattedMessage id="general.edit"/>
							</Button>
						}
						{
							!readOnly &&
								<Button type="danger-small" clicked={this.handleDeleteClicked}>
									<FormattedMessage id="general.remove" />
								</Button>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default ProjectItem;
