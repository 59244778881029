import * as actionTypes from './actionTypes';
import UsersService from '../../services/api/users';
import { IError, IResponse, IUser } from '../../shared/interfaces';
import constants from '../../shared/constants';

// UPDATE
export const createUserStart = () => {
	return {
		type: actionTypes.UPDATE_USER_START,
	}
};

export const createUserSuccess = (user: object) => {
	return {
		type: actionTypes.UPDATE_USER_SUCCESS,
		user
	}
};

export const createUserFail = (error: string) => {
	return {
		type: actionTypes.UPDATE_USER_FAIL,
		error,
	}
};

export const createUser = (data: object) => {
	return (dispatch: any) => {
		dispatch(createUserStart());

		UsersService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createUserSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(createUserFail(errorMsg));
				}
			);
	}
};

// CURRENT USER
export const fetchCurrentUserStart = () => {
	return {
		type: actionTypes.FETCH_CURRENT_USER_START,
	}
};

export const fetchCurrentUserSuccess = (user: object) => {
	return {
		type: actionTypes.FETCH_CURRENT_USER_SUCCESS,
		user
	}
};

export const fetchCurrentUserFail = (error: string) => {
	return {
		type: actionTypes.FETCH_CURRENT_USER_FAIL,
		error,
	}
};

export const fetchCurrentUser = () => {
	const id = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);

	return (dispatch: any) => {
		dispatch(fetchCurrentUserStart());

		if(id){
			UsersService
				.get(+id)
				.then(
					(response: IResponse) => {

						dispatch(fetchCurrentUserSuccess(response.data));
					},
					(error: any) => {
						const { data: { info } } = error;

						let errorMsg = null;

						if(info && info.length > 0){
							errorMsg = info;
						} else {
							errorMsg = error ? error : 'Internal server error';
						}

						dispatch(fetchCurrentUserFail(errorMsg));
					}
				);
		} else {

			dispatch(fetchCurrentUserFail('USER_NOT_FOUND'));
		}
	}
};

// READ/FETCH
export const fetchUsersStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START,
    }
};

export const fetchUsersSuccess = (users: Array<IUser>) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        users
    }
};

export const fetchUsersFail = (error: string) => {
    return {
        type: actionTypes.FETCH_USERS_FAIL,
        error,
    }
};

export const fetchUsers = () => {
    return (dispatch: any) => {
        dispatch(fetchUsersStart());

        UsersService
            .get()
            .then(
	            (response: IResponse) => {

                    dispatch(fetchUsersSuccess(response.data));
                },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(fetchUsersFail(errorMsg));
                }
            );
    }
};

// READ/FETCH ALL USERS FOR NETWORK
export const fetchNetworkUsersStart = () => {
	return {
		type: actionTypes.FETCH_NETWORK_USERS_START,
	}
};

export const fetchNetworkUsersSuccess = (users: Array<IUser>) => {
	return {
		type: actionTypes.FETCH_NETWORK_USERS_SUCCESS,
		users
	}
};

export const fetchNetworkUsersFail = (error: string) => {
	return {
		type: actionTypes.FETCH_NETWORK_USERS_FAIL,
		error,
	}
};

export const fetchNetworkUsers = () => {
	return (dispatch: any) => {
		dispatch(fetchNetworkUsersStart());

		UsersService
			.getNetworkUsers()
			.then(
				(response: IResponse) => {

					dispatch(fetchNetworkUsersSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchNetworkUsersFail(errorMsg));
				}
			);
	}
};

// FETCH USERS BY FILTERS (USED IN NETWORK)
export const fetchUsersByFiltersStart = () => {
	return {
		type: actionTypes.FETCH_USERS_BY_FILTERS_START,
	}
};

export const fetchUsersByFiltersSuccess = (users: Array<IUser>) => {
	return {
		type: actionTypes.FETCH_USERS_BY_FILTERS_SUCCESS,
		users
	}
};

export const fetchUsersByFiltersFail = (error: string) => {
	return {
		type: actionTypes.FETCH_USERS_BY_FILTERS_FAIL,
		error,
	}
};

export const fetchUsersByFilters = (type: string, value: string, network?: boolean) => {
	return (dispatch: any) => {
		dispatch(fetchUsersByFiltersStart());

		UsersService
			.getByFilters(type, value, network || false)
			.then(
				(response: IResponse) => {

					dispatch(fetchUsersByFiltersSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchUsersByFiltersFail(errorMsg));
				}
			);
	}
};

export const fetchUserByIdStart = () => {
	return {
		type: actionTypes.FETCH_USER_BY_ID_START,
	}
};

export const fetchUserByIdSuccess = (selected: object) => {
	return {
		type: actionTypes.FETCH_USER_BY_ID_SUCCESS,
		selected
	}
};

export const fetchUserByIdFail = (error: string) => {
	return {
		type: actionTypes.FETCH_USER_BY_ID_FAIL,
		error,
	}
};

export const fetchUserById = (id: number) => {
	return (dispatch: any) => {
		dispatch(fetchUserByIdStart());

		UsersService
			.get(id)
			.then(
				(response: IResponse) => {
					dispatch(fetchUserByIdSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchUserByIdFail(errorMsg));
				}
			);
	}
};

// UPDATE
export const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START,
    }
};

export const updateUserSuccess = (user: object) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        user
    }
};

export const updateUserFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        error,
    }
};

export const updateUser = (id: number, data: object) => {
    return (dispatch: any) => {
        dispatch(updateUserStart());

        UsersService
            .update(id, data)
            .then(
	            (response: IResponse) => {

                    dispatch(updateUserSuccess(response.data));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(updateUserFail(errorMsg));
                }
            );
    }
};

// DELETE
export const deleteUserStart = () => {
    return {
        type: actionTypes.DELETE_USER_START,
    }
};

export const deleteUserSuccess = (removed: boolean) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        removed
    }
};

export const deleteUserFail = (error: string) => {
    return {
        type: actionTypes.DELETE_USER_FAIL,
        error,
    }
};

export const deleteUser = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteUserStart());

        UsersService
            .remove(id)
            .then(
	            (response: IResponse) => {

                    dispatch(deleteUserSuccess(true));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(deleteUserFail(errorMsg));
                }
            );
    }
};

// PASSWORD RECOVERY
export const recoveryUserPasswordStart = () => {
	return {
		type: actionTypes.RECOVERY_USER_PASSWORD_START,
	}
};

export const recoveryUserPasswordSuccess = (user: object) => {
	return {
		type: actionTypes.RECOVERY_USER_PASSWORD_SUCCESS,
		user
	}
};

export const recoveryUserPasswordFail = (error: string) => {
	return {
		type: actionTypes.RECOVERY_USER_PASSWORD_FAIL,
		error,
	}
};

export const recoveryUserPassword = (data: object) => {
	return (dispatch: any) => {
		dispatch(recoveryUserPasswordStart());

		UsersService
			.recoveryPassword(data)
			.then(
				(response: IResponse) => {

					dispatch(recoveryUserPasswordSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(recoveryUserPasswordFail(errorMsg));
				}
			);
	}
};

// PASSWORD RECOVERY
export const updateUserPasswordStart = () => {
	return {
		type: actionTypes.UPDATE_USER_PASSWORD_START,
	}
};

export const updateUserPasswordSuccess = (user: object) => {
	return {
		type: actionTypes.UPDATE_USER_PASSWORD_SUCCESS,
		user
	}
};

export const updateUserPasswordFail = (error: string) => {
	return {
		type: actionTypes.UPDATE_USER_PASSWORD_FAIL,
		error,
	}
};

export const updateUserPassword = (data: object) => {
	return (dispatch: any) => {
		dispatch(updateUserPasswordStart());

		UsersService
			.updatePassword(data)
			.then(
				(response: IResponse) => {

					dispatch(updateUserPasswordSuccess(response.data));
				},
				(error: IError) => {
					const { data: { errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(updateUserPasswordFail(errorMsg));
				}
			);
	}
};

// SET TOUR DISABLED
export const setTourDisabledStart = () => {
	return {
		type: actionTypes.SET_TOUR_DISABLED_START,
	}
};

export const setTourDisabledSuccess = (hideTour: boolean) => {
	return {
		type: actionTypes.SET_TOUR_DISABLED_SUCCESS,
		hideTour
	}
};

export const setTourDisabledFail = (error: string) => {
	return {
		type: actionTypes.SET_TOUR_DISABLED_FAIL,
		error,
	}
};

export const setTourDisabled = (id: number) => {
	return (dispatch: any) => {
		dispatch(setTourDisabledStart());

		UsersService
			.setTourDisabled(id)
			.then(
				(response: IResponse) => {

					dispatch(setTourDisabledSuccess(true));
				},
				(error: IError) => {
					const { data: { errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(setTourDisabledFail(errorMsg));
				}
			);
	}
};

