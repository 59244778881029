import React, {Component, Fragment} from 'react';
import classes from './Welcome.module.scss';
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import {NavLink, Link} from "react-router-dom";
import Button from "../Button/Button";
import Logo from '../../components/Logo/Logo';
import * as ReactGA from '../../GA';
import ReactTooltip from 'react-tooltip';
import Typist from 'react-typist';
import StepIndicator from '../Original/StepIndicator/StepIndicator';
import LockIndicator from '../Original/LockIndicator/LockIndicator';
import MyOriginalsAdd from '../MyOriginals/MyOriginalsAdd/MyOriginalsAdd';
import darioAlbertini from '../../assets/img/dario-albertini.png';
import fulvioRisuleo from '../../assets/img/fulvio-risuleo.png';
import nameSurname from '../../assets/img/name-surname.png';
import annamariaMorelli from '../../assets/img/annamaria-morelli.png';
import ludovicoBessegato from '../../assets/img/ludovico-bessegato.png';
import danieleOrazi from '../../assets/img/daniele-orazi.png';
import phaymBhuiyan from '../../assets/img/phaym-bhuiyan.png';
import jacopoBistacchia from '../../assets/img/jacopo-bistacchia.png';
import annamariaGranatello from '../../assets/img/annamaria-granatello.png';
import logoElsinore from '../../assets/img/logo-elsinore.svg';
import logoSolinas from '../../assets/img/logo-solinas.png';
import logoGiffoni from '../../assets/img/home-call-giffoni.svg';
import logoHolden from '../../assets/img/home-call-holden.png';
import originalScreenshot from '../../assets/img/home-originals.png';
import originalCard1 from '../../assets/img/home-original-card-1.png';
import originalCard2 from '../../assets/img/home-original-card-2.png';
import originalCard3 from '../../assets/img/home-original-card-3.png';
import originalDetail1 from '../../assets/img/home-original-detail-1.jpg';
import originalDetail2 from '../../assets/img/home-original-detail-2.jpg';
import originalDetail3 from '../../assets/img/home-original-detail-3.jpg';
import logoPatamu from '../../assets/img/home-patamu.png';
import Rail from '../Rail/Rail';
import * as emailjs from 'emailjs-com';
import { checkValidEmail } from '../../shared/helpers';
import Loader from '../Loader/Loader';

interface IProps {
    history: any;
    onToggleCanvas: any;
    checkSession: any;
}

const peoples = [
    {
        name: 'Annamaria',
        surname: 'Morelli',
        role: 'Producer',
        img: annamariaMorelli,
        hidden: false,
        desc: 'CEO of <em>Elsinore Film</em> and a producer with many years of experience in the film and scripted tv industry. After working for <em>RAI</em>, <em>Mediaset</em>, <em>Mediatrade</em> and <em>Cattleya</em>, among others, from 2017 to 2019 she joined <em>TimVision</em> as board member and Head of Production and Co-production. At <em>TimVision</em>, she (co-)produced several critically acclaimed and award-winning projects such as <em>My Brilliant Friend</em> (first season) and <em>Skam Italia</em>. She was also the Italian commissioning editor for successful premium series such as <em>Killing Eve</em>.<br/>Throughout her career, she has scouted the film debuts of many talented filmmakers and directors, such as Paolo Sorrentino’s first movie <em>The Extra Man</em> (Mostra del Cinema di Venezia 2001); Dario Albertini’s first movie <em>Manuel</em> (Mostra del Cinema di Venezia 2017); Phaim Buyian’s first movie <em>Bangla</em> (Nastro d’Argento for Best Comedy 2019; Globo d’Oro for Best Film Debut 2019; nominated for the Best New Director, Best Producer and Best Original Screenplay David di Donatello Awards)'
    },
    // {
    //     name: 'Daniele',
    //     surname: 'Orazi',
    //     role: 'Talent agent',
    //     logo: '',
    //     img: danieleOrazi,
    //     hidden: false,
    //     desc: 'is an experienced talent agent who represents and manages the careers of many acclaimed talents. After studies in Sociology at the <em>Sapienza University</em> of Rome, he began his profession in the entertainment world. Orazi worked for 13 years in talent agencies before founding <em>"Officine Artistiche"</em> in 2005, where he was dedicated to the success of his clients. Since 2008, he has been a member of <em>LARA</em>, an association of talent agents, in which he also held the position of Vice President. He has been a member of the <em>Academy of Italian Cinema David di Donatello</em> since 2007. Currently, Daniele Orazi is the founder and owner of <em>DO</em>, a talent agency which specializes in talent management, public relations and image consulting as well as film production.'
    // },
    {
        name: 'Fulvio',
        surname: 'Risuleo',
        role: 'Director and Screenwriter',
        logo: '',
        img: fulvioRisuleo,
        hidden: false,
        desc: 'was born in Rome in 1991. He attended the <em>Centro Sperimentale di Cinematografia</em> in the directing class graduating in 2013. In 2014 with his graduation essay <em>"Lievito Madre"</em> won the third prize in the Cinéfondation section of the <em>Cannes festival</em>. In 2015, with his next short film, <em>"Varicella"</em> won the Semaine de la Critique again at the <em>Cannes festival</em>. In 2016 he released his first comic book entitled <em>"Pixel"</Em> published by Ultra - Lit publisher. His first feature called <em>"Guarda in alto"</em> with Giacomo Ferrara was released in 2018, in the same year he directed <em>"Il Caso Ziqqurat"</em> the first interactive series produced in Italy. He recently released the second feature film, <em>"Il colpo del cane"</em> interpreted by Edoardo Pesce.'
    },
    {
        name: 'Jacopo',
        surname: 'Bistacchia',
        role: 'Pr & Comunication',
        logo: '',
        img: jacopoBistacchia,
        hidden: false,
        desc: 'began his career in 2007 as a freelance journalist collaborating with TV broadcasters like <em>MTV</em>, <em>Roma Tre</em> and many others. In 2012 he started working in the <em>YD\'actor</em> as a Publicist and Talent Agent. In 2015 he entered in <em>Cafiero&Partners</em> as a press promoter and artistic consultant for films, TV shows and talent. Nowadays he works as a freelance following films like <em>"Manuel"</em> directed by Dario Albertini and prized at <em>Venice Film Festival</em> and as Press Office for <em>Alice nella Città</em>, one of the most important cinema events in Rome.'
    }
];

const WORDS =
    ['idea',
    'film',
    'tv series',
    'format',
    'web series',
    'documentary',
    'short movie',
    'VR experience'
];

const screenCards = [
    originalCard1,
    originalCard2,
    originalCard3
];
const screenDetails = [
    originalDetail1,
    originalDetail2,
    originalDetail3
];

const template_id = 'template_jTGPs9ht';

class Welcome extends Component<IProps> {

    state = {
        show: true,
        scrolled: 0,
        position: 0,
        left: 0,
        stepActive: 0,
        wordNumber: 0,
        screenshotActive: 1,
        email: '',
        emailLoading: false,
        emailSent: false,
        emailStatus: false
    }

    calcSteps = setInterval(() => {
        let currentStep = this.state.stepActive + 1;
        currentStep = currentStep < 5 ? currentStep : 0;
        this.setState({
            stepActive: currentStep
        });
    }, 600);

    changeWord = setInterval(() => {
        let number = this.state.wordNumber >= WORDS.length - 1 ? 0 : this.state.wordNumber + 1;

        this.setState({
            wordNumber: number
        })
    }, 3000);

    changeScreen = setInterval(() => {
        let number = this.state.screenshotActive >= screenCards.length - 1 ? 0 : this.state.screenshotActive + 1;

        this.setState({
            screenshotActive: number
        })
    }, 4500);

    componentDidMount() {
        document.body.style.backgroundColor = '#000';
        ReactGA.pageview('/');
        window.addEventListener('scroll', this.listenToScroll);

        emailjs.init("user_HXIUSpR8gEpiZyHcEBory");

        this.calcSteps;
        this.changeWord;
        this.changeScreen;
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = '#fff';
        window.removeEventListener('scroll', this.listenToScroll);

        clearInterval(this.calcSteps);
        clearInterval(this.changeWord);
        clearInterval(this.changeScreen);
    }

    goToRegistration = () => {
        this.props.history.push('/registration');
    };

    listenToScroll = () => {
		const winScroll =
		  document.body.scrollTop || document.documentElement.scrollTop;

		const height =
		  document.documentElement.scrollHeight -
		  document.documentElement.clientHeight

        const scrolled = winScroll / height;

        const firstViewHeight = document.documentElement.scrollHeight / document.documentElement.clientHeight;
        
        const min = 0;
        const max = 150;
        const inView = firstViewHeight / 100 + 0.03;
        let position = 0;

        if(scrolled > inView) {
            position = max * (scrolled - inView);
        }

        if (position < min ) {
            position = min;
        }
        if (position > max) {
            position = max
        }

		this.setState({
            scrolled: scrolled,
            position: position
		});
		//console.log(firstViewHeight / 100);
    };

    handleMailChange = (event: React.FormEvent<HTMLInputElement>) => {
        let inputValue = event.currentTarget.value;

        this.setState({
            email: inputValue
        });
    }

    handleMailSubmit = () => {
        this.sendFeedback(
            template_id, 
            {
                user_email: this.state.email
            }
        );
        this.setState({
            emailSent: true,
            emailLoading: true,
        })
    }

    sendFeedback = (templateId: string, variables: any) => {
        emailjs.send(
            'smtp_server', templateId,
            variables
            ).then(res => {
                console.log('Email successfully sent!')
                this.setState({
                    emailStatus: true,
                    emailLoading: false
                })
            })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => {
                console.error('Oh well, you failed. Here some thoughts on the error that occured:', err)
                this.setState({
                    emailSent: false,
                    emailStatus: false,
                    emailLoading: false
                })
            }
        )
    }

    render () {
        const { show, position, stepActive, wordNumber, screenshotActive } = this.state;
        const { checkSession } = this.props;

        const cards = screenCards.map((card: any, index: number) => {
            return (
                <img src={card} key={index} className={[classes['Welcome-img-animated'], this.state.screenshotActive == index ? classes['Welcome-img-animated-fadein'] : classes['Welcome-img-animated-fadeout'] ].join(' ')} />
            )
        });

        const details = screenDetails.map((detail: any, index: number) => {
            return (
                <img src={detail} key={index} className={[classes['Welcome-img-animated'], this.state.screenshotActive == index ? classes['Welcome-img-animated-fadein'] : classes['Welcome-img-animated-fadeout'] ].join(' ')} />
            )
        });

        const wordTpl = (
            <Typist key={wordNumber} className={classes['Welcome-section-txt--typist']}>
                { WORDS[wordNumber] }
                <Typist.Backspace count={WORDS[wordNumber].length} delay={1500} />
            </Typist>
        );

        const emailTpl = (
            <div className={classes['Welcome-email']}>
                {
                    !this.state.emailSent && !this.state.emailStatus &&
                    <p>We are currently running a private call with Scuola Holden, but the club is almost ready.<br/>
                    Leave us your e-mail and we will get in touch as soon as we are online.</p>
                }
                {
                    this.state.emailSent && this.state.emailStatus &&
                    <p>Thanks, you will hear from us very soon!</p>
                }

                {
                    !this.state.emailStatus &&
                    <div className={classes['Welcome-email-form']}>
                        {
                            this.state.emailLoading &&
                            <div className={classes['Welcome-email-loading']}>
                                <Loader />
                            </div>
                        }
                        <input 
                            id="test-mailing"
                            name="test-mailing"
                            onChange={this.handleMailChange}
                            placeholder="Your e-mail address"
                            required
                            value={this.state.email} />
                        <button
                            className="btn btn--submit"
                            disabled={checkValidEmail(this.state.email) ? false : true}
                            onClick={this.handleMailSubmit}>
                            Confirm
                        </button>
                    </div>
                }
            </div>
        )

        return (
            <div className={classes.Welcome}>
                <div className={[classes['Welcome-section'], classes['Welcome-section--cta']].join(' ')}>
                    <Logo to="/" position="section" black />

                    <div className={classes['Welcome-section-content']}>
                        <h1 className={classes['Welcome-title']}>
                            <FormattedMessage id={'home.ctaSection.title'} />
                        </h1>
                        <h3 className={classes['Welcome-subtitle']}>
                            <FormattedMessage id={'home.ctaSection.subtitle'} />
                        </h3>
                        <div className={classes['Welcome-section-txt']}>
                            <FormattedMessage id={'home.ctaSection.text'} />
                            { wordTpl }
                        </div>
                    </div>

                    <div className={[classes['Welcome-section-content'], classes['Welcome-section-content--bottom']].join(' ')}>
                        { !checkSession ?
                            <div className={classes['Welcome-email-container']}>
                                {/* <Button type={'confirm-orange-solid'} clicked={this.goToRegistration}>
                                    <FormattedMessage id="welcome.cta" />
                                </Button> */}
                                { emailTpl }
                                {/* <p>Already registered? <button onClick={this.props.onToggleCanvas}>SIGN IN</button></p> */}
                            </div>
                            :
                            <Fragment>
                                <Button type={'confirm-orange-solid'} clicked={this.goToRegistration}>
                                    <FormattedMessage id="home.ctaSection.goto" />
                                </Button>
                            </Fragment>
                        }
                    </div>

                    <div className={classes['Welcome-scroll-down']}>
                        <div className={classes['Welcome-scroll-down-container']}>
                            <div className={classes['Welcome-scroll-down-icon']} />
                        </div>
                    </div>
                </div>

                <div className={[classes['Welcome-section'], classes['Welcome-section--works']].join(' ')}>
                    <div className={classes['Welcome-section-content']}>
                        <header className={classes['Welcome-section-header']}>
                            <h4 className={classes['Welcome-title-tip']}>
                                <FormattedMessage id={'home.whoWorksSection.tip'} />
                            </h4>
                            <h2 className={classes['Welcome-title']}>
                                <FormattedMessage id={'home.whoWorksSection.title'} />
                            </h2>
                            {/* <h3 className={classes['Welcome-subtitle']}>
                            
                                <FormattedMessage id={'home.whoWorksSection.subtitle'} />
                            </h3> */}
                            <h3 className={classes['Welcome-subtitle']}>
                                <FormattedHTMLMessage id={'home.originalsSection.text-1'} />
                            </h3>
                        </header>

                        <Rail elements={peoples} more={true} />
                    </div>
                </div>


                <div className={[classes['Welcome-section-double'], classes['Welcome-section-double--marginBottom']].join(' ')}>
                    <div className={[classes['Welcome-section'], classes['Welcome-section--full'], classes['Welcome-section--originals']].join(' ')}>
                        <header className={[classes['Welcome-section-header'], classes['Welcome-section-header--full']].join(' ')}>
                            <h4 className={classes['Welcome-title-tip']}>
                                <FormattedMessage id={'home.originalsSection.tip2'} />
                            </h4>
                            <h2 className={[classes['Welcome-title']].join(' ')}>
                                <FormattedHTMLMessage id={'home.originalsSection.title-1'} />
                            </h2>
                            <h3 className={classes['Welcome-subtitle']}>
                                <FormattedMessage id={'home.originalsSection.subtitle'} />
                            </h3>
                        </header>

                        <div className={classes['Welcome-locks']}>
                            <div className={classes['Welcome-locks-container']}>
                                <LockIndicator lock={true} />
                                <LockIndicator lock={false} />
                            </div>
                        </div>

                        <div className={classes["Welcome-steps-indicator-container"]}>
                            <div className={classes["Welcome-steps-indicator"]}>
                                <StepIndicator
                                    tip="step-indicator-one"
                                    completed={stepActive >= 0 ? true : false}
                                    active={false}
                                    title="1. The Idea"
                                    lock={true}>
                                    <span>Shape your idea by adding<br/>Title, Tagline, Synopsis, Intention.</span>
                                </StepIndicator>
                                <StepIndicator
                                    tip="step-indicator-two"
                                    completed={stepActive >= 1 ? true : false}
                                    active={false}
                                    title="2. Docs"
                                    lock={true}>
                                    <span>Upload all files relating to<br/>your project and share them<br/>with members who has access.</span>
                                </StepIndicator>
                                <StepIndicator
                                    tip="step-indicator-three"
                                    completed={stepActive >= 2 ? true : false}
                                    active={false}
                                    title="3. Media"
                                    lock={true}>
                                    <span>Make your presentation more attractive<br/>by adding images, videos and text<br/>relating to your idea.</span>
                                </StepIndicator>
                                <StepIndicator
                                    tip="step-indicator-four"
                                    completed={stepActive >= 3 ? true : false}
                                    active={false}
                                    title="4. Details">
                                    <span>These are the only public informations<br/>about your Original. Details about your project<br/>that will help you find like-minded<br/>collaborators and mentors.</span>
                                </StepIndicator>
                            </div>
                            <div className={[classes['Welcome-steps-publish'], stepActive >= 4 ? classes['Welcome-steps-publish--active'] : ''].join(' ')}>Publish</div>
                        </div>

                        <div className={classes['Welcome-section-original-bottom']}>

                            <div className={classes['Welcome-original-screenshot']}>
                                <div className={classes['Welcome-original-screenshot-container']}>
                                    <div className={classes['Welcome-original-patamu']} data-tip data-for="patamu-info">
                                        <img src={logoPatamu} />
                                        <ReactTooltip id="patamu-info" effect="solid">
                                            Thanks to Patamu you can get<br/>legal protection on your Originals<br/>when applying to a call.
                                        </ReactTooltip>
                                    </div>
                                    <div className={classes['Welcome-original-details']}>
                                        <div className={classes['Welcome-original-details-container']}>
                                            { details }
                                        </div>
                                    </div>
                                    <div className={classes['Welcome-original-cards']}>
                                        <div className={classes['Welcome-original-cards-container']}>
                                            { cards }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className={[classes['Welcome-title'], classes['Welcome-title--originals']].join(' ')}>
                            <FormattedMessage id={'home.originalsSection.title'} />
                        </h2>

                        {/* <div className={classes['Welcome-original-add']}>
                            { !checkSession ?
                                <NavLink
                                    className={classes['WelcomeAdd']}
                                    to="/registration">
                                    <h4 className={classes['WelcomeAdd-title']}>
                                        <FormattedMessage id={'label.addNew'} />
                                    </h4>
                                </NavLink>
                                :
                                <MyOriginalsAdd />
                            }
                        </div> */}

                    </div>
                </div>


                <div className={[classes['Welcome-section'], classes['Welcome-section--cta-second']].join(' ')}>
                    <div className={classes['Welcome-section-content']}>
                        <h2 className={classes['Welcome-title']}>
                            <FormattedMessage id={'home.ctaSection2.title'} />
                        </h2>
                        <h3 className={classes['Welcome-subtitle']}>
                            <FormattedHTMLMessage id={'home.ctaSection2.subtitle'} />
                        </h3>
                    </div>

                    <div className={[classes['Welcome-section-content'], classes['Welcome-section-content--bottom']].join(' ')}>
                        { !checkSession ?
                            // <Fragment>
                            // <Button type={'confirm-orange'} clicked={this.goToRegistration}>
                            //     <FormattedMessage id="welcome.cta" />
                            // </Button>
                            // <p>Already registered? <button onClick={this.props.onToggleCanvas}>SIGN IN</button></p>
                            // </Fragment>
                            <div className={classes['Welcome-email-container']}>
                                { emailTpl }
                            </div>
                            :
                            <Fragment>
                                <Button type={'confirm-orange'} clicked={this.goToRegistration}>
                                    <FormattedMessage id="home.ctaSection.goto" />
                                </Button>
                            </Fragment>
                        }
                    </div>
                </div>

                <div className={[classes['Welcome-section'], classes['Welcome-section--call']].join(' ')}>
                    <div className={classes['Welcome-section-content']}>
                        <h2 className={classes['Welcome-title']}>
                            <FormattedMessage id={'home.callSection.title'} />
                        </h2>
                        <h3 className={classes['Welcome-subtitle']}>
                            <FormattedMessage id={'home.callSection.subtitle'} />
                        </h3>

                        <div className={classes['Welcome-call-brands']}>
                            <a
                                href="https://giffoni.artchivio.net"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes['Welcome-call-brand']}>
                                <div className={classes['Welcome-call-brand-status']}>
                                    closed
                                </div>
                                <img src={logoGiffoni} />
                            </a>
                            <div className={classes['Welcome-call-brand']} data-tip data-for="holden-call">
                                <div className={classes['Welcome-call-brand-status']}>
                                    active
                                </div>
                                <img src={logoHolden} />
                                <ReactTooltip id="holden-call" effect="solid">
                                    This call is runnig only for Scuola Holden students. To learn more write us info@artchivio.net
                                </ReactTooltip>
                            </div>
                            <div className={classes['Welcome-call-brand']}>
                                <div className={classes['Welcome-call-brand-status']}>
                                    coming soon
                                </div>
                                <img src={logoSolinas} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
};

export default Welcome;
