import React, {Component} from 'react';
import classes from './BoxSelector.module.scss';
import {FormattedMessage} from "react-intl";

interface IProps {
    title?: string;
    description?: string;
    img: string;
    size?: string;
    selected: boolean;
    index: number;
    action: (ev: React.MouseEvent<HTMLButtonElement>, index: number) => void;
}

class BoxSelector extends Component<IProps> {

    render () {
    	const { title, description, img, size, selected, index, action } = this.props;

			return (
                <button
                    className={[classes["BoxSelector"], selected ? classes["BoxSelector--selected"] : ''].join(' ')}
                    onClick={(ev) => action(ev, index)}>
                        <div className={classes['BoxSelector-content']}>
                            <div className={classes['BoxSelector-icon']} />
                            <div className={classes["BoxSelector-infos"]}>
                                {
                                    title &&
                                    <h3>{ title }</h3>
                                }
                                {
                                    description &&
                                        <div className={classes['BoxSelector-description']}>
                                            <p>{ description }</p>
                                        </div>
                                    }
                            </div>
                        </div>
				</button>
			)
    }
};

export default BoxSelector;
