import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IFormat } from '../../shared/interfaces';

interface IAction {
    type: string;
    format: IFormat;
    formats: Array<IFormat>;
    error: string;
}

const INITIAL_STATE = {
    format: null,
	formats: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
};

const fetchFormatStart = (state = INITIAL_STATE) => {
	return updateObject(state, { format: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchFormatSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { format: action.format, isFetching: false });
};

const fetchFormatFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchFormatsStart = (state = INITIAL_STATE) => {
	return updateObject(state, { formats: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchFormatsSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { formats: action.formats, isFetching: false });
};

const fetchFormatsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchFormatsForOriginalsFilterStart = (state = INITIAL_STATE) => {
	return updateObject(state, { formats: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchFormatsForOriginalsFilterSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { formats: action.formats, isFetching: false });
};

const fetchFormatsForOriginalsFilterFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const formatReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // READ/FETCHING
	    case actionTypes.FETCH_FORMAT_START: return fetchFormatStart(state);
	    case actionTypes.FETCH_FORMAT_SUCCESS: return fetchFormatSuccess(state, action);
	    case actionTypes.FETCH_FORMAT_FAIL: return fetchFormatFail(state, action);

	    case actionTypes.FETCH_FORMATS_START: return fetchFormatsStart(state);
	    case actionTypes.FETCH_FORMATS_SUCCESS: return fetchFormatsSuccess(state, action);
	    case actionTypes.FETCH_FORMATS_FAIL: return fetchFormatsFail(state, action);

	    case actionTypes.FETCH_FORMATS_FOR_ORIGINALS_FILTER_START: return fetchFormatsForOriginalsFilterStart(state);
	    case actionTypes.FETCH_FORMATS_FOR_ORIGINALS_FILTER_SUCCESS: return fetchFormatsForOriginalsFilterSuccess(state, action);
	    case actionTypes.FETCH_FORMATS_FOR_ORIGINALS_FILTER_FAIL: return fetchFormatsForOriginalsFilterFail(state, action);


	    default:
            return state;
    }
};

export default formatReducer;
