import request from '../../shared/lib/request'
import constants from "../../shared/constants";
import { IMedia, IOrder, IProject } from '../../shared/interfaces';

const create = (data: IProject) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
	    'Authorization': `Bearer ${authToken}`
    };

    return request({
        url:    '/project',
        method: 'POST',
        headers,
        data
    });
};

const get = (id?: number, edit?: boolean) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	let endpoint = id ? `/project/${id}` : '/project';

	if(edit && id){
		endpoint += '/edit';
	}

    return request({
        url:    endpoint,
        method: 'GET',
        headers
    });
};

const getByUser = (id: number, online: boolean = false) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = online ? `/project/user/${id}/show` : `/project/user/${id}`;

	return request({
		url:    endpoint,
		method: 'GET',
		headers
	});
};

const update = (id: number, data: IProject, step?: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = step ? `/project/${id}/${step}` : `/project/${id}`;

    return request({
        url:    endpoint,
        method: 'PATCH',
        headers,
        data
    });
};

const remove = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/project/${id}`,
        method: 'DELETE',
        headers,
    });
};

const addMedia = (id: number, mediaId: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/project/${id}/media/${mediaId}`,
		method: 'PATCH',
		headers
	});
};

const removeMedia = (id: number, mediaId: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/project/${id}/media/${mediaId}`,
		method: 'DELETE',
		headers
	});
};

const orderMedia = (id: number, data: {media: Array<IOrder>}) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/project/${id}/media`,
		method: 'PATCH',
		headers,
		data
	});
};


const ProjectsService = {
    get, getByUser, create, update, remove, addMedia, removeMedia, orderMedia
};

export default ProjectsService;
