import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import classes from './InvitationsBox.module.scss';
import {FormattedMessage} from 'react-intl';
import { Element } from 'react-scroll';
import Loader from '../Loader/Loader';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { IInvitation } from '../../shared/interfaces';
import { checkValidEmail } from '../../shared/helpers';
import { IUser } from '../../shared/interfaces';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IProps {
	title?: object;
	description?: object;
    styles?: object;
    loading: boolean;
    isSending: boolean;
    referral?: string;
	currentUser: IUser;
	invitations: Array<IInvitation>;
	onSendInvitation(data: IInvitation): IInvitation;
	onGetInvitationsByUser(userId: number): Array<IInvitation>;
	invitation: IInvitation;
	error: string;
}

interface IState {
	emailReceiver: string;
	invitations: Array<IInvitation>;
	acceptedInvitations: number;
	invitation: IInvitation | null;
	invitationCopied: boolean;
}

class InvitationsBox extends Component<IProps, IState> {

	constructor(props: IProps){
		super(props);

		this.state = {
			emailReceiver: '',
			invitations: [],
			acceptedInvitations: 0,
			invitation: null,
			invitationCopied: false,
		};

		this.handleSendInvitationClicked = this.handleSendInvitationClicked.bind(this);
		this.handleInvitationEmailChanged = this.handleInvitationEmailChanged.bind(this);
	}

	componentDidMount(): void {
		const { id } = this.props.currentUser;

		if(id){
			this.props.onGetInvitationsByUser(id);
		}
	}

	handleSendInvitationClicked () {
		const { emailReceiver } = this.state;
		const { id } = this.props.currentUser;

		this.props.onSendInvitation({
			userIdCreator: id,
			emailReceiver
		});
	}

	handleInvitationEmailChanged(email: string){
		this.setState({
			emailReceiver: email
		})
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevState.invitation !== this.state.invitation && this.state.invitation){
			this.setState({
				emailReceiver: '',
			}, () => {
				toast.success('Invite sent!', {
					position: toast.POSITION.TOP_RIGHT,
					className: 'confirm-bubble'
				});
			})
		}
	}

	static getDerivedStateFromProps(nextProps: Readonly<IProps>): any | null {
		let invitations = nextProps.invitations;
		let invitation = nextProps.invitation;

		const acceptedInvitations = invitations.filter((inv: IInvitation) => inv.userIdReceiver && inv.usedAt).length;

		return {
			invitations,
			invitation,
			acceptedInvitations,
		}
	}

	render () {

		const { styles, title, description, loading, referral, isSending, error } = this.props;
		const { invitations, emailReceiver, acceptedInvitations, invitationCopied } = this.state;

		const buttonTpl = (
			<Button
				clicked={this.handleSendInvitationClicked}
				disabled={!emailReceiver.trim().length || !checkValidEmail(emailReceiver)}>
				{
					<FormattedMessage id={'invitationsBox.button.sendInvitation'}/>
				}
			</Button>
		);

		const copyButtonTpl = (

			<CopyToClipboard
				text={referral || ''}
				onCopy={() => this.setState({ invitationCopied: true })}>
				<button className={[classes['InvitationsBox-copy'], invitationCopied ? classes['InvitationsBox-copy--disabled'] : ''].join(' ')}>
					{
						invitationCopied ? <FormattedMessage id={'invitationsBox.button.referralCopied'}/> : <FormattedMessage id={'invitationsBox.button.copyReferral'}/>
					}
				</button>
			</CopyToClipboard>
		);

		const listTpl = invitations.length > 0 && invitations.map((invitation: IInvitation, index: number) => {
			const { emailReceiver, userIdReceiver, usedAt } = invitation;

			return (
				<div key={index} className={classes['InvitationsBox-table-line']}>
					<div className={[classes['InvitationsBox-table-row'], classes['InvitationsBox-table-row--bold']].join(' ')}>
						{ emailReceiver }
					</div>
					<div className={classes['InvitationsBox-table-row']}>
						<div className={[classes['InvitationsBox-invite-status'], usedAt && userIdReceiver ? classes['InvitationsBox-invite-confirm'] : ''].join(' ')}>
							{
								usedAt && userIdReceiver ? <span>OK</span> : <span>PENDING</span>
							}
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className={classes.InvitationsBox} style={styles}>
				<div className={classes['InvitationsBox-header']}>
					{
						title &&
                        <h3 className={classes['InvitationsBox-title']}>{ title }</h3>
					}
					{
						description &&
						<div className={classes['InvitationsBox-header-description']}>
							{ description }
						</div>
					}
					<div className={classes['InvitationsBox-referral']}>
						<Input
							label={'invitationsBox.label.yourReferral'}
							changed={(e: any) => console.log("Referral:" , e.target.value)}
							type="text"
							name="referral"
							value={referral || ''}
							styles={{marginBottom: '0'}}
							button={copyButtonTpl}
							visible={true}
							disabled={true} />
					</div>

					<div className={classes['InvitationsBox-counter']}>
						<div className={classes['InvitationsBox-label']}>
							<FormattedMessage id={'invitationsBox.counter.label'}/>
						</div>
						<div className={classes['InvitationsBox-counter-circle']}>
							{ 5 - acceptedInvitations }
						</div>
					</div>
				</div>
				<div className={classes['InvitationsBox-operations']}>
					<div className={classes['InvitationsBox-operations-left']}>
						<div className={classes['InvitationsBox-label']}>
							<FormattedMessage id={'invitationsBox.label.howItWorks'}/>
						</div>
						<p><FormattedMessage id={'invitationsBox.description'}/></p>
						{
							isSending && <span>Send...</span>
						}
						{
							error &&
							<span style={{color: 'red', fontWeight: 'bold'}}>{
								error.toLowerCase().includes('entity already exist') ? 'Invitation already sent!' : error
							}</span>
						}
					</div>

					<div className={classes['InvitationsBox-operations-right']}>
						<div className={classes['InvitationBox-invite']}>
							<div className={classes['InvitationBox-invite-left']}>
								<Input
									label={'invitationsBox.label.inviteCreatives'}
									changed={(e: any) => this.handleInvitationEmailChanged(e.target.value)}
									type="email"
									name="invites"
									value={emailReceiver || ''}
									visible={true}
									styles={{marginTop: '10px'}}
									placeholderId={'invitationsBox.placeholder.invite'}
									disabled={acceptedInvitations >= 5} />
							</div>
							<div className={classes['InvitationBox-invite-right']}>
								{ buttonTpl }
							</div>
						</div>
						{
							loading ?
								<div className={classes['InvitationsBox-loading']}>
									<Loader />
								</div>
								:
								invitations.length > 0 ?
								<Element name="invitationsBox-list">
									<div className={classes['InvitationsBox-table']}>
										<div className={classes['InvitationsBox-table-head']}>
											<div className={classes['InvitationsBox-table-line']}>
												<div className={classes['InvitationsBox-table-row']}>
													<div className={classes['InvitationsBox-label']}>Email</div>
												</div>
												<div className={classes['InvitationsBox-table-row']}>
													<div className={classes['InvitationsBox-label']}>Status</div>
												</div>
											</div>
										</div>
										<div className={classes['InvitationsBox-table-body']}>
											{
												listTpl
											}
										</div>
									</div>
								</Element> : <span>No invitations found</span>
						}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		invitation: state.invitationState.invitation,
		error: state.invitationState.error,
		invitations: state.invitationState.list,
		isSending: state.invitationState.isStoring,
		loading: state.invitationState.isFetching
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSendInvitation: (data: IInvitation) => dispatch(actions.createInvitation(data)),
		onGetInvitationsByUser: (userId: number) => dispatch(actions.fetchInvitationsByUser(userId)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( InvitationsBox );
