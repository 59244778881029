import request from '../../shared/lib/request'
import constants from "../../shared/constants";
import { IOrder, ISearchOriginal } from '../../shared/interfaces';

const create = (data: object) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
	    'Authorization': `Bearer ${authToken}`
    };

    return request({
        url:    '/original',
        method: 'POST',
        headers,
        data
    });
};

const get = (id?: number, edit?: boolean) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	let endpoint = id ? `/original/${id}` : '/original';

	if(edit && id){
		endpoint += '/edit'
	}

    return request({
        url:    endpoint,
        method: 'GET',
        headers
    });
};

const search = (data: ISearchOriginal) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    '/original/search',
		method: 'POST',
		headers,
		data
	});
};

const getByUser = (id: number, limit: number, offset: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	let endpoint = `/original/user/${id}`;

	if(limit >= 0 && offset >= 0) {
		endpoint += `?limit=${limit}&offset=${offset}`;
	}

	return request({
		url:    endpoint,
		method: 'GET',
		headers
	});
};

const update = (id: number, data: object, step?: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = step ? `/original/${id}/${step}` : `/original/${id}`;

    return request({
        url:    endpoint,
        method: 'PATCH',
        headers,
        data
    });
};

const remove = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/original/${id}`,
        method: 'DELETE',
        headers,
    });
};

const addMedia = (id: number, mediaId: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${id}/media/${mediaId}`,
		method: 'PATCH',
		headers
	});
};

const removeMedia = (id: number, mediaId: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${id}/media/${mediaId}`,
		method: 'DELETE',
		headers
	});
};

const orderMedia = (id: number, data: {media: Array<IOrder>}) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${id}/media`,
		method: 'PATCH',
		headers,
		data
	});
};

const addAttachment = (id: number, attachmentId: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${id}/attachment/${attachmentId}`,
		method: 'PATCH',
		headers
	});
};

const removeAttachment = (id: number, attachmentId: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${id}/attachment/${attachmentId}`,
		method: 'DELETE',
		headers
	});
};

const orderAttachments = (id: number, data: {attachment: Array<IOrder>}) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${id}/attachment`,
		method: 'PATCH',
		headers,
		data
	});
};

const getRequestsByOriginal = (id: number ) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${id}/request`,
		method: 'GET',
		headers,
	});
};

const addRequest = (userId: number, originalId: number ) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const data = {
		userId,
		originalId
	};

	return request({
		url:    `/original/${originalId}/request`,
		method: 'POST',
		headers,
		data
	});
};

const removeRequest = (originalId: number, requestId: number ) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/${originalId}/request/${requestId}`,
		method: 'DELETE',
		headers,
	});
};

const updateRequest = (originalId: number, requestId: number, status: string) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const data = {
		status
	};

	return request({
		url:    `/original/${originalId}/request/${requestId}`,
		method: 'PATCH',
		headers,
		data
	});
};

const addAccess = (userId: number, originalId: number ) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const data = {
		userId,
		originalId
	};

	return request({
		url:    `/original/${originalId}/access`,
		method: 'POST',
		headers,
		data
	});
};

const getRequestsByUser = (userId: number ) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/original/user/${userId}/request`,
		method: 'GET',
		headers,
	});
};

const updateCover = (id: number, data: { fileCodeImage: string }) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = `/original/${id}/cover`;

	return request({
		url:    endpoint,
		method: 'PATCH',
		headers,
		data
	});
};

const removeCover = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = `/original/${id}/cover`;

	return request({
		url:    endpoint,
		method: 'PATCH',
		headers,
		data: {
			isDelete: true,
		}
	});
};

const addPatamu = (id: number, data: { patamu: string | number }) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	const endpoint = `/original/${id}/patamu`;

	return request({
		url:    endpoint,
		method: 'PATCH',
		headers,
		data
	});
};

const OriginalsService = {
    get,
	search,
	getByUser,
	create,
	update,
	remove,
	addMedia,
	removeMedia,
	orderMedia,
	addAttachment,
	removeAttachment,
	orderAttachments,
	getRequestsByOriginal,
	addRequest,
	removeRequest,
	updateRequest,
	getRequestsByUser,
	addAccess,
	updateCover,
	removeCover,
	addPatamu
};

export default OriginalsService;
