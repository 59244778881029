export default {
    PROJECT_STEPS:  ['type', 'content', 'info', 'publish'],
	ORIGINAL_STEPS: ['update', 'medias', 'attachments', 'info', 'publish'],
	ORIGINAL_STAGES: [
		{
			value: 0,
			key: 'First draft'
		},
		{
			value: 20,
			key: 'Advanced draft'
		},
		{
			value: 40,
			key: 'Pitching stage'
		},
		{
			value: 60,
			key: 'Fundraising'
		},
		{
			value: 80,
			key: 'Fully funded'
		},
		{
			value: 100,
			key: 'In production'
		},
	],
    LOCAL_STORAGE: {
        KEYS: {
            COUNTRY: 'country',
	        USER_NAME: 'ac_user_name',
	        USER_SURNAME: 'ac_user_surname',
	        USER_EMAIL: 'ac_user_email',
	        USER_IMAGE: 'ac_user_image',
	        USER_CITY: 'ac_user_city',
            USER_ID: 'ac_user_id',
	        CURRENT_STEP: 'ac_current_step',
	        CURRENT_PROJECT_ID: 'ac_current_project_id',
	        EXPIRATION_DATE: 'expiration_date',
            AUTH_TOKEN: 'ac_auth_token',
            USER: 'user',
            LOCALE: 'locale',
        }
    },
	ENTITY_REDIRECTS: {
		dashboard_unlock: {
    		cta: 'Go to Invites',
    		route: '/invites',
			hasEntityId: false,
	    },
		dashboard_access: {
			cta: 'Go to Invites',
			route: '/invites',
			hasEntityId: false,
		},
		project_to_draft: {
			cta: 'Edit and publish',
			route: '/work',
			hasEntityId: true,
		},
		invitation_sent: {
			cta: 'Go to Invites',
			route: '/invites',
			hasEntityId: false,
		},
		invitation_accepted: {
			cta: 'Go to Invites',
			route: '/invites',
			hasEntityId: false,
		},
		dashboard_welcome: {
			cta: 'Go to your Portfolio',
			route: '/profile',
			hasEntityId: false
		},
		project_to_online: {
			cta: 'Browse the network',
			route: '/network',
			hasEntityId: false
		},
		user_to_ambassador: {
			cta: '',
			route: '',
			hasEntityId: false
		},
		original_collaboration_sent: {
			cta: 'View the Original',
			route: '/originals',
			hasEntityId: true
		},
		original_user_sent: {
			cta: 'View the Original',
			route: '/originals',
			hasEntityId: true
		},
		original_to_publish: {
			cta: 'Go to Original',
			route: '/originals',
			hasEntityId: true
		},
		original_to_draft: {
			cta: 'Edit and publish',
			route: '/original',
			hasEntityId: true
		},
		original_request_access: {
			cta: 'Manage access',
			route: '/my-originals/requests',
			hasEntityId: false
		},
		original_request_accepted: {
			cta: 'View the Original',
			route: '/originals',
			hasEntityId: true
		},
	},
	NOTIFICATION_FETCH_TIME: 60000,
	NOTIFICATIONS_DISABLED: false,
	DOCUMENT_MIME_TYPES: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/mspowerpoint, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/jpeg, image/png, image/gif',
	IMAGE_MIME_TYPES: 'image/jpeg, image/png, image/gif',
	DOCUMENT_LABEL_FOR_MIME_TYPES: [
		{
			mime_type: 'application/pdf',
			label: 'PDF File'
		},
		{
			mime_type: 'application/msword',
			label: 'MS Office Word'
		},
		{
			mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			label: 'MS Office Word'
		},
		{
			mime_type: 'application/mspowerpoint',
			label: 'MS Office Power Point'
		},
		{
			mime_type: 'application/vnd.ms-powerpoint',
			label: 'MS Office Power Point'
		},
		{
			mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			label: 'MS Office Power Point'
		},
		{
			mime_type: 'image/jpeg',
			label: 'Image'
		},
		{
			mime_type: 'image/png',
			label: 'Image'
		},
		{
			mime_type: 'image/gif',
			label: 'GIF'
		},
	],
	DEFAULT_PAGINATION: {
    	offset: 0,
		limit: 12,
	},
	CONVERSATION_FETCH_TIME: 10000,
	CONVERSATION_INTERLOCUTORS_FETCH_TIME: 10000,
	UNREAD_MESSAGES_FETCH_TIME: 10000,
	PATAMU_CODE: 'ART-12-AP',
}
