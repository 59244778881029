import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IBell } from '../../shared/interfaces';

interface IAction {
    type: string;
    bell: IBell;
    bells: Array<IBell>;
    error: string;
}

const INITIAL_STATE = {
    bell: null,
	bells: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
};

const fetchBellStart = (state = INITIAL_STATE) => {
	return updateObject(state, { bell: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchBellSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { bell: action.bell, isFetching: false });
};

const fetchBellFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchBellsStart = (state = INITIAL_STATE) => {
	return updateObject(state, { bells: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchBellsSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { bells: action.bells, isFetching: false });
};

const fetchBellsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchBellsForOriginalsFilterStart = (state = INITIAL_STATE) => {
	return updateObject(state, { bells: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchBellsForOriginalsFilterSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { bells: action.bells, isFetching: false });
};

const fetchBellsForOriginalsFilterFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const bellReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // READ/FETCHING
	    case actionTypes.FETCH_BELL_START: return fetchBellStart(state);
	    case actionTypes.FETCH_BELL_SUCCESS: return fetchBellSuccess(state, action);
	    case actionTypes.FETCH_BELL_FAIL: return fetchBellFail(state, action);

	    case actionTypes.FETCH_BELLS_START: return fetchBellsStart(state);
	    case actionTypes.FETCH_BELLS_SUCCESS: return fetchBellsSuccess(state, action);
	    case actionTypes.FETCH_BELLS_FAIL: return fetchBellsFail(state, action);

	    case actionTypes.FETCH_BELLS_FOR_ORIGINALS_FILTER_START: return fetchBellsForOriginalsFilterStart(state);
	    case actionTypes.FETCH_BELLS_FOR_ORIGINALS_FILTER_SUCCESS: return fetchBellsForOriginalsFilterSuccess(state, action);
	    case actionTypes.FETCH_BELLS_FOR_ORIGINALS_FILTER_FAIL: return fetchBellsForOriginalsFilterFail(state, action);


	    default:
            return state;
    }
};

export default bellReducer;
