import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import * as routes from "../../shared/routes";
import classes from './Signin.module.scss';
import { ISession } from '../../shared/interfaces';
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import Input from '../../components/Input/Input';
import CheckboxSlider from '../../components/CheckboxSlider/CheckboxSlider';
import Button from "../../components/Button/Button";
import Loader from "../Loader/Loader";

import signinImg from '../../assets/img/signin-header.jpg';
import forgotImg from '../../assets/img/recovery-header.jpg';
import { checkValidEmail } from '../../shared/helpers';

interface IProps {
	loading?: boolean;
	error?: string;
	isAuthenticated?: boolean;
	showOffCanvas?: boolean;
	onSignin( data: ISession ): object;
	onRecoveryUserPassword(data: any): object;
	onToggleOffCanvasVisibilityClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
	history?: any;
	user: any;
	userErrors: string;
}

interface IState {
	controls: any;
	checkboxes: any;
	showFeedback: boolean;
	isForgot: boolean;
	resetDone: boolean;
}

class Signin extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			showFeedback: false,
			isForgot: false,
			controls: {
				email: {
					name: 'email',
					value: '',
					placeholderId: 'placeholder.email',
					type: 'email',
					styles: {
						margin: '0 0 20px 0',
					},
					label: 'label.signin.email',
					visible: true,
					readOnly: false,
				},
				password: {
					name: 'password',
					value: '',
					placeholderId: 'placeholder.password',
					type: 'password',
					styles: {
						margin: '0 0 20px 0',
					},
					label: 'label.signin.password',
					visible: true,
					readOnly: false,
				},
				emailToReset: {
					name: 'emailToReset',
					value: '',
					placeholderId: 'placeholder.email',
					type: 'email',
					styles: {
						margin: '0 0 20px 0',
					},
					label: 'label.signin.forgot.email',
					visible: true,
					readOnly: false,
				},
			},
			resetDone: false,
			checkboxes: {
				/*
				remember: {
					name: 'remember',
					labelId: 'checkbox.remember',
					checked: false,
					type: 'toggle',
					styles: {
						margin: '0 0 10px 0',
					}
				},*/
			}
		};
	}

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;

		let controls = {...this.state.controls};

		controls[inputName].value = inputValue;

		this.setState({
			controls,
			showFeedback: false,
		});
	};

	handleCheckboxChanged = (ev: React.MouseEvent<HTMLButtonElement>) => {
		const buttonName = ev.currentTarget.getAttribute('data-name');

		if(buttonName){

			let checkboxes = {...this.state.checkboxes};

			checkboxes[buttonName].checked = !checkboxes[buttonName].checked;

			this.setState({
				checkboxes,
				showFeedback: false,
			});
		}
	};

	onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.keyCode === 13) {
			this.submitHandler();
		}
	};

	submitHandler = () => {
		this.setState({
			showFeedback: true,
		});

		const { controls: { email, password } } = this.state;

		const data = {
			email: email.value,
			password: password.value,
		};

		this.props.onSignin(data);
	};

	submitForgot = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();

		const { controls: { emailToReset } } = this.state;

		const data = {
			email: emailToReset.value,
		};

		this.props.onRecoveryUserPassword(data);
	};

	redirectToRegistration = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		this.props.onToggleOffCanvasVisibilityClicked(ev);

		setTimeout(() => {
			this.props.history.push(routes.REGISTRATION.replace('/:code?', ''));
		}, 100);
	};

	redirectToFogot = () => {
		const isForgot = this.state.isForgot;
		this.setState({
			showFeedback: false,
			isForgot: !isForgot
		})
	};

	componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
		if(this.props.user !== nextProps.user && nextProps.user){
			this.setState({
				resetDone: true,
			})
		}

		if(this.props.userErrors !== nextProps.userErrors && nextProps.userErrors){
			this.setState({
				resetDone: false,
			})
		}

		if(this.props.isAuthenticated !== nextProps.isAuthenticated){
			this.props.history.push(routes.MY_ORIGINALS);
		}

		if(this.props.error !== nextProps.error){
			console.error("Error on signin:", nextProps.error);
		}

	}

	render () {
		const { controls, checkboxes, showFeedback, isForgot, resetDone } = this.state;

		let inputElementsTpl = null;

		if(!isForgot){
			inputElementsTpl = Object.keys(controls).map((key: string) => {
				return (
					key !== 'emailToReset' ? <Input
						key={controls[key].name}
						styles={controls[key].styles}
						name={controls[key].name}
						value={controls[key].value}
						changed={this.handleInputChanged.bind(this)}
						placeholderId={controls[key].placeholderId}
						visible={controls[key].visible}
						readOnly={controls[key].readOnly}
						type={controls[key].type}
						label={controls[key].label}
						keyPressed={controls[key].name === 'password' || controls[key].name === 'email' ? this.onPressEnter : undefined}
						error={showFeedback && this.props.error !== null}/> : null
				)
			});
		} else {
			inputElementsTpl = (
				<Input
					key={controls['emailToReset'].name}
					styles={controls['emailToReset'].styles}
					name={controls['emailToReset'].name}
					value={controls['emailToReset'].value}
					changed={this.handleInputChanged.bind(this)}
					placeholderId={controls['emailToReset'].placeholderId}
					visible={controls['emailToReset'].visible}
					readOnly={controls['emailToReset'].readOnly}
					type={controls['emailToReset'].type}
					label={controls['emailToReset'].label}
					error={showFeedback && this.props.error !== null}/>
			)
		}

		const checkboxElementsTpl = Object.keys(checkboxes).map((key: string) => {
			return (
				<CheckboxSlider
					key={checkboxes[key].name}
					name={checkboxes[key].name}
					clicked={this.handleCheckboxChanged.bind(this)}
					labelId={checkboxes[key].labelId}
					checked={checkboxes[key].checked}/>
			)
		});

		return (
			<div className={classes.Signin}>
				<div className={classes["Signin-top"]}>
					<div className={classes["Signin-header"]}>
						<div
							className={classes["Signin-head"]}
							style={{backgroundImage: `url(${isForgot ? forgotImg : signinImg})`}}>
							<h3 className={classes["Signin-title"]}>
								{ !isForgot ?
									<FormattedHTMLMessage id={'signin.form.title'}/>
								:
									<FormattedHTMLMessage id={'signin.form.title.forgot'}/>
								}
							</h3>
						</div>
						{/* <div className={classes["Signin-description"]}>
							<p><FormattedMessage id={'signin.form.description'}/></p>
						</div> */}
					</div>
					<div className={classes["Signin-content"]}>
						{

							inputElementsTpl
						}
						{ !isForgot &&
							checkboxElementsTpl
						}
						<button style={{display: !isForgot ? 'inline-block' : 'none'}} className={classes['Signin-forgot-button']} onClick={this.redirectToFogot}>
							<FormattedMessage id={'label.signin.forgot.link'} />
						</button>
						<button style={{display: isForgot ? 'inline-block' : 'none'}} className={classes['Signin-forgot-button']} onClick={this.redirectToFogot}>
							<FormattedMessage id={'label.signin.forgot.back'} />
						</button>
						{
							showFeedback &&
								<div className={classes["Signin-feedback"]}>
									{
										this.props.loading &&
										<div className={classes["Signin-loading"]}>
											<Loader />
										</div>
									}
									{
										this.props.error &&
										<div className={classes['Signin-errors']}>
											<FormattedMessage id={'errors.signin'}/>
										</div>
									}
								</div>
						}
						{
							resetDone &&
							<div className={classes["Signin-feedback-forgot"]}>
								<span>{ 'Reset done please check email.' }</span>
							</div>
						}
						{
							this.props.userErrors &&
							<div className={classes['Signin-errors']}>
                            	{ this.props.userErrors === 'Entity not found' ? 'You are not registered' : this.props.userErrors }
							</div>
						}
					</div>
				</div>
				<div className={classes["Signin-bottom"]}>
					<div className={classes["Signin-footer"]}>
						{/* <button
							onClick={this.redirectToRegistration}
							className={classes["Signin-back"]}>
							<FormattedMessage id={'signin.form.register'}/>
						</button> */}
						<div />
						{ isForgot
							?
							<Button disabled={!checkValidEmail(controls.emailToReset.value)} clicked={this.submitForgot}>
								<FormattedMessage id={'navigation.resend'}/>
							</Button>
							:
							<Button clicked={this.submitHandler}>
								<FormattedMessage id={'navigation.signin'}/>
							</Button>
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		user: state.userState.user,
		userErrors: state.userState.error,
		loading: state.sessionState.isFetching,
		error: state.sessionState.error,
		isAuthenticated: state.sessionState.token !== null
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSignin: ( data: ISession ) => dispatch( actions.session( data ) ),
		onRecoveryUserPassword: ( data: any ) => dispatch( actions.recoveryUserPassword( data ) ),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Signin );
