import * as actionTypes from './actionTypes';
import FormatsService from '../../services/api/formats';
import { IResponse, IFormat, IError } from '../../shared/interfaces';

// READ/FETCH
export const fetchFormatStart = () => {
    return {
        type: actionTypes.FETCH_FORMAT_START,
    }
};

export const fetchFormatSuccess = (format: IFormat) => {
    return {
        type: actionTypes.FETCH_FORMAT_SUCCESS,
        format
    }
};

export const fetchFormatFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FORMAT_FAIL,
        error,
    }
};

export const fetchFormat = (id: number) => {
	return (dispatch: any) => {

		if(id)
			dispatch(fetchFormatStart());

		FormatsService
			.get(id)
			.then(
				(response: IResponse) => {
					dispatch(fetchFormatSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchFormatFail(errorMsg));
				}
			);
	}
};

export const fetchFormatsStart = () => {
	return {
		type: actionTypes.FETCH_FORMATS_START,
	}
};

export const fetchFormatsSuccess = (formats: any) => {
	return {
		type: actionTypes.FETCH_FORMATS_SUCCESS,
		formats
	}
};

export const fetchFormatsFail = (error: string) => {
	return {
		type: actionTypes.FETCH_FORMATS_FAIL,
		error,
	}
};

export const fetchFormats = () => {
	return (dispatch: any) => {
		dispatch(fetchFormatsStart());

		FormatsService
			.get()
            .then(
	            (response: IResponse) => {
		            dispatch(fetchFormatsSuccess(response.data));
	            },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(fetchFormatsFail(errorMsg));
	            }
            );
    }
};

export const fetchFormatsForOriginalsFilterStart = () => {
	return {
		type: actionTypes.FETCH_FORMATS_FOR_ORIGINALS_FILTER_START,
	}
};

export const fetchFormatsForOriginalsFilterSuccess = (formats: IFormat) => {
	return {
		type: actionTypes.FETCH_FORMATS_FOR_ORIGINALS_FILTER_SUCCESS,
		formats
	}
};

export const fetchFormatsForOriginalsFilterFail = (error: string) => {
	return {
		type: actionTypes.FETCH_FORMATS_FOR_ORIGINALS_FILTER_FAIL,
		error,
	}
};

export const fetchFormatsForOriginalsFilter = () => {
	return (dispatch: any) => {
		dispatch(fetchFormatsForOriginalsFilterStart());

		FormatsService
			.getForOriginalsFilter()
			.then(
				(response: IResponse) => {
					dispatch(fetchFormatsForOriginalsFilterSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchFormatsForOriginalsFilterFail(errorMsg));
				}
			);
	}
};
