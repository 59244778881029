import React from 'react';
import classes from './SelectItem.module.scss';

interface IProps {
	id: string;
	value: any;
	description: object;
	inputName: string;
	itemClicked: any;
}

const selectItem = (props: IProps) => {

    const { id, value, description, inputName, itemClicked } = props;

    const selectItemClasses = [classes.SelectItem].join(' ');

    return (
        <button
            onClick={itemClicked}
            data-id={id}
            data-description={description}
            data-value={value}
            data-name={inputName}
            className={selectItemClasses}
        >
            {
                description
            }
        </button>
    );

};

export default selectItem;
