import * as actionTypes from './actionTypes';
import NotificationsService from '../../services/api/notifications';
import { IError, INotification, IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchNotificationsStart = () => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_START,
    }
};

export const fetchNotificationsSuccess = (list: Array<INotification>) => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
        list
    }
};

export const fetchNotificationsFail = (error: string) => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_FAIL,
        error,
    }
};

export const fetchNotifications = (userId: number) => {
    return (dispatch: any) => {
        dispatch(fetchNotificationsStart());

        NotificationsService
            .get(userId)
            .then(
	            (response: IResponse) => {

                    dispatch(fetchNotificationsSuccess(response.data));
                },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(fetchNotificationsFail(errorMsg));
	            }
            );
    }
};

// UPDATE
export const setReadNotificationStart = () => {
    return {
        type: actionTypes.SET_READ_NOTIFICATION_START,
    }
};

export const setReadNotificationSuccess = (notificationRead: INotification) => {
    return {
        type: actionTypes.SET_READ_NOTIFICATION_SUCCESS,
        notificationRead
    }
};

export const setReadNotificationFail = (error: string) => {
    return {
        type: actionTypes.SET_READ_NOTIFICATION_FAIL,
        error,
    }
};

export const setReadNotification = (id: number) => {
    return (dispatch: any) => {
        dispatch(setReadNotificationStart());

        NotificationsService
            .setRead(id)
            .then(
	            (response: IResponse) => {

                    dispatch(setReadNotificationSuccess(response.data));
                },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(setReadNotificationFail(errorMsg));
	            }
            );
    }
};

// UPDATE ALL NOTIFICATIONS
export const setAllReadNotificationByUserIdStart = () => {
	return {
		type: actionTypes.SET_ALL_READ_NOTIFICATION_BY_USER_START,
	}
};

export const setAllReadNotificationByUserIdSuccess = (list: Array<INotification>) => {
	return {
		type: actionTypes.SET_ALL_READ_NOTIFICATION_BY_USER_SUCCESS,
		list
	}
};

export const setAllReadNotificationByUserIdFail = (error: string) => {
	return {
		type: actionTypes.SET_ALL_READ_NOTIFICATION_BY_USER_FAIL,
		error,
	}
};

export const setAllReadNotificationByUserId = (userId: number) => {
	return (dispatch: any) => {
		dispatch(setAllReadNotificationByUserIdStart());

		NotificationsService
			.setAllReadByUser(userId)
			.then(
				(response: IResponse) => {

					dispatch(setAllReadNotificationByUserIdSuccess(response.data));
				},
				(error: IError) => {
					const { data: { errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(setAllReadNotificationByUserIdFail(errorMsg));
				}
			);
	}
};

