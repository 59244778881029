import React, { Component } from 'react';
import classes from './Notification.module.scss';
import { INotification } from '../../shared/interfaces';
import CircleImage from '../CircleImage/CircleImage';
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';

interface INotificationProps {
	notification: INotification;
	history?: any;
	notificationRead: INotification;
	onSetReadNotification: (id: number) => boolean,
}

const ENTITIES: any = constants.ENTITY_REDIRECTS;

interface INotificationState {
	lastNotificationRead: INotification | null;
}

class Notification extends Component<INotificationProps, INotificationState> {
	state = {
		lastNotificationRead: null
	};

	handleNotificationClicked = (id: number, notificationType: string, entityId: number | null, readAt: string | null) => {
		const redirectTo = ENTITIES[notificationType].route + (ENTITIES[notificationType].hasEntityId ? `/${entityId}` : '');
		if(readAt)
			this.props.history.push(redirectTo);
		else {
			this.props.onSetReadNotification(id);
			this.props.history.push(redirectTo);
		}
	};

	static getDerivedStateFromProps(nextProps: Readonly<INotificationProps>, nextState: Readonly<INotificationState>): any | null {
		let lastNotificationRead = nextState.lastNotificationRead;

		if(nextProps.notificationRead) {
			lastNotificationRead = nextProps.notificationRead;
		}

		return {
			lastNotificationRead
		}
	}

	render () {
		const { notification: { userImage, id, message, notificationType, entityId, readAt } } = this.props;

		const hasCta = ENTITIES[notificationType] && ENTITIES[notificationType].cta.length > 0;

        return (
            <button
				onClick={hasCta ? () => this.handleNotificationClicked(id, notificationType, entityId, readAt) : undefined}
                className={[classes.Notification, hasCta ? classes['Notification--hasCta'] : ''].join(' ')}>
	            <div className={classes['Notification-content']}>
					<div className={classes['Notification-img']}>
		            	<CircleImage url={userImage ? userImage.url : undefined} medium />
					</div>
		            <div className={classes['Notification-message']} dangerouslySetInnerHTML={{ __html: message }} />
	            </div>
	            {
	            	hasCta &&
	                    <div className={classes['Notification-footer']}>
	                        <div className={classes['Notification-cta']}>{ ENTITIES[notificationType] ? ENTITIES[notificationType].cta : ''  }</div>
	                    </div>
	            }
			</button>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		loading: state.notificationState.isFetching,
		error: state.notificationState.error,
		isSettingRead: state.notificationState.isStoring,
		notificationRead: state.notificationState.notificationRead,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSetReadNotification: (id: number) => dispatch(actions.setReadNotification(id)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Notification );
