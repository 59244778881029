import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';

interface IAction {
    type: string;
    category: object;
    categories: any;
    error: string;
}

const INITIAL_STATE = {
    category: null,
	categories: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
};

const fetchCategoryStart = (state = INITIAL_STATE) => {
	return updateObject(state, { category: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchCategorySuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { category: action.category, isFetching: false });
};

const fetchCategoryFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchCategoriesStart = (state = INITIAL_STATE) => {
	return updateObject(state, { categories: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchCategoriesSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { categories: action.categories, isFetching: false });
};

const fetchCategoriesFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const categoryReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // READ/FETCHING
	    case actionTypes.FETCH_CATEGORY_START: return fetchCategoryStart(state);
	    case actionTypes.FETCH_CATEGORY_SUCCESS: return fetchCategorySuccess(state, action);
	    case actionTypes.FETCH_CATEGORY_FAIL: return fetchCategoryFail(state, action);
	    case actionTypes.FETCH_CATEGORIES_START: return fetchCategoriesStart(state);
	    case actionTypes.FETCH_CATEGORIES_SUCCESS: return fetchCategoriesSuccess(state, action);
	    case actionTypes.FETCH_CATEGORIES_FAIL: return fetchCategoriesFail(state, action);


	    default:
            return state;
    }
};

export default categoryReducer;
