import React, {Component, createRef} from 'react';
import ReactPlayer from 'react-player';
import * as Scroll from 'react-scroll';
import stepsClasses from '../Steps.module.scss';
import classes from './Step3.module.scss';
import { IAddWorkContentBlocks, IOrder, IOriginal } from '../../../../shared/interfaces';
import { FormattedMessage } from 'react-intl';
import SelectMedia from '../../../SelectMedia/SelectMedia';
import UploadBox from "../../../UploadBox/UploadBox";
import Button from "../../../Button/Button";
import EditorTextarea from '../../../EditorTextarea/EditorTextarea';
import Input from '../../../Input/Input';
import Loader from '../../../Loader/Loader';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import { randomId } from '../../../../shared/helpers';
import * as ReactGA from '../../../../GA';
import Modal from '../../../../containers/Modal/Modal';
import OrderBox from '../../../OrderBox/OrderBox';
import constants from '../../../../shared/constants';

const scroll = Scroll.animateScroll;

interface IProps {
	setData: (field: string, value: any) => void;
	onCreateMedia: (data: any) => any;
	onUpdateMedia: (id: number, data: any) => any;
	onCreateFile: (data: any) => any;
	onAddMediaToOriginal: (id: number, mediaId: string) => any;
	onRemoveMediaFromOriginal: (id: number, mediaId: string) => any;
	onChangeMediaOrder: (id: number, data: Array<IOrder>) => Array<IOrder>;
	onResetFile: () => any;
	onResetMedia: () => any;
	data?: any;
	media: any;
	file: any;
	mediaUpdated: any;
	original: IOriginal;
	mediaStoring: boolean;
	fileStoring: boolean;
	associateMediaToOriginal: boolean;
	editMode: boolean;
}

interface IState {
	contentBlocks: Array<IAddWorkContentBlocks>;
	savedBlocks: Array<IAddWorkContentBlocks>;
	uploadedFile: any;
	textIndex: number;
	idToSave: any;
	mediaToRemove: number;
	newMedia: any;
	newMediaId: any;
	orderChanged: boolean;
	newOrder: any;
	showOrderModal: boolean;
	mediaToEdit: {
		id: number;
		content: any;
		draft: boolean;
	};
	mediaUpdated: boolean;
	showEditModal: boolean;
}

class Step3 extends Component<IProps, IState> {
	private previewContainer = createRef<HTMLDivElement>();
	private previewImage = createRef<HTMLImageElement>();
	private upload = createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);

		this.state = {
			showOrderModal: false,
			mediaToRemove: -1,
			contentBlocks: [],
			savedBlocks: [],
			uploadedFile: null,
			textIndex: -1,
			idToSave: [],
			newMedia: null,
			newMediaId: null,
			orderChanged: false,
			newOrder: {
				media: [],
				draft: []
			},
			mediaUpdated: false,
			mediaToEdit: {
				id: -1,
				content: '',
				draft: false,
			},
			showEditModal: false,
		};

		this.addBlock = this.addBlock.bind(this);
		this.removeBlock = this.removeBlock.bind(this);
		this.saveBlock = this.saveBlock.bind(this);
		this.setBlock = this.setBlock.bind(this);
		this.editContentBlock = this.editContentBlock.bind(this);
		this.onToggleShowOrderModal = this.onToggleShowOrderModal.bind(this);
		this.onToggleShowEditModal = this.onToggleShowEditModal.bind(this);
		this.changeOrder = this.changeOrder.bind(this);
		this.saveOrder = this.saveOrder.bind(this);
	}

	componentDidMount(): void {
		window.scrollTo(0,0);

		const { editMode, data } = this.props;

		if(editMode && data.hasOwnProperty('id')) {
			ReactGA.pageview(`/original/${data.id}/2`);
		}
		else {
			ReactGA.pageview('/add-original/2');
		}

		this.setCurrentMedia();
	}

	setCurrentMedia = () => {
		if(this.props.original) {
			const { media } = this.props.original;

			if(media){
				let savedBlocks = media.filter((curr: any) => curr.coverType === 0).sort((a: any, b: any) => (a.sort > b.sort) ? 1 : -1);

				let { newOrder } = this.state;
				newOrder.media = savedBlocks;

				this.setState({
					savedBlocks,
					newOrder,
					contentBlocks: [],
				});
			}
		}
	};

	handleUploadFileContent = (uploaded: any) => {
		const data = new FormData();
		//(uploaded[0]);
		data.append('file', uploaded[0]);

		this.props.onCreateFile(data);
	};

	handleChangeVideoContent = (tempId: string, e: any) => {
		//console.log("Video: ", e.target.value, tempId);
		this.setBlock(tempId, e.target.value);
	};

	handleChangeEmbedContent = (tempId: string, e: any) => {
		//console.log("Embed: ", e.target.value, tempId);
		this.setBlock(tempId, e.target.value);
	};

	handleChangeTextContent = (value: any, tempId: string, id?: number) => {
		//console.log("Text: ", value, tempId);

		if(id){
			this.editContentBlock(id, value);
		} else {
			this.setBlock(tempId, value);
		}
	};

	setBlock(tempId: string, value: any){
		let contentBlocks = [...this.state.contentBlocks];

		const blockToUpdate = contentBlocks.filter((block: any) => block.tempId === tempId)[0];

		blockToUpdate.content = value;

		this.setState({ contentBlocks })
	}

	editContentBlock(id: number, value: any){
		let savedBlocks = [...this.state.savedBlocks];

		const blockToUpdate = savedBlocks.filter((block: any) => block.id === id)[0];

		if(blockToUpdate){
			if(!blockToUpdate.backup)
				blockToUpdate.backup = blockToUpdate.content;

			blockToUpdate.content = value;

			this.setState({ savedBlocks })
		}
	}

	addBlock = (ev: React.MouseEvent<HTMLButtonElement>, type: string) => {
		ev.preventDefault();

		let contentBlocks = [...this.state.contentBlocks];

		//const testNews = contentBlocks.filter(media => media.type === type && media.hasOwnProperty('meta') && media.meta.new);
		const testNews = contentBlocks;

		const index = contentBlocks.length;

		if(testNews.length > 0){
			alert("There is already an empty block. Please save or remove before continuing.");
		} else {
			const tempId = randomId();

			const previewStyle = {
				width: '100%',
				height: 'auto',
				justifyContent: 'center',
				display: 'none'
			};

			switch (type) {
				case 'image':
					contentBlocks.push({
						type: 'image',
						content: null,
						tempId,
						component:
							<div className={[classes['Step3-add-contenBlock'], classes['Step3-add-contenBlock--image']].join(' ')}>
								<div style={previewStyle} className={classes['Step3-add-image-preview']} ref={this.previewContainer}>
									<img ref={this.previewImage} alt={'preview'}/>
								</div>
								<UploadBox
									reference={this.upload}
									titleId={'label.addWork.uploadImage'}
									accept={constants.IMAGE_MIME_TYPES}
									onSelectedFiles={this.handleUploadFileContent}
									multiple={false}
									styles={{margin: '0 0 30px 0'}}/>

								<div className={classes['Step3-add-contenBlock-footer']}>
									<Button type="small" clicked={() => this.removeBlock(tempId)}>
										<FormattedMessage id={'general.remove'} />
									</Button>
									<Button type="primary-small" styles={{marginRight: '10px'}} clicked={() => this.saveBlock(tempId)}>
										<FormattedMessage id={'general.add'} />
									</Button>
								</div>
							</div>,
						meta: {
							id: null,
							new: true,
						}
					});
					break;
				case 'video': contentBlocks.push({
					type: 'video',
					content: null,
					tempId,
					component:
						<div className={classes['Step3-add-contenBlock']}>
							<div className={stepsClasses['Steps-label']}>
								<FormattedMessage id={'label.addWork.mediaUrl'} />
							</div>
							<div className={classes['Step3-add-supported']}>
								<FormattedMessage id={'label.addWork.supportedMedia'} />
							</div>
							<Input
								name="content-block-video"
								styles={{width: '90%', marginBottom: '30px'}}
								type="text"
								changed={(e) => this.handleChangeVideoContent(tempId, e)} placeholderId={'placeholder.mediaUrl'} visible={true} />
							{/* <input type={'text'} onChange={(e) => this.handleChangeVideoContent(index, e)} /> */}

							<div className={classes['Step3-add-contenBlock-footer']}>
								<Button type="small" clicked={() => this.removeBlock(tempId)}>
									<FormattedMessage id={'general.remove'} />
								</Button>
								<Button type="primary-small" styles={{marginRight: '10px'}} clicked={() => this.saveBlock(tempId)}>
									<FormattedMessage id={'general.add'} />
								</Button>
							</div>
						</div>,
					meta: {
						id: null,
						new: true,
					}
				});
					break;
				case 'embed': contentBlocks.push({
					type: 'embed',
					content: null,
					tempId,
					component:
						<div className={classes['Step3-add-contenBlock']}>
							<textarea onChange={(e) => this.handleChangeEmbedContent(tempId, e)} />

							<div className={classes['Step3-add-contenBlock-footer']}>
								<Button type="small" clicked={() => this.removeBlock(tempId)}>
									<FormattedMessage id={'general.remove'} />
								</Button>
								<Button type="primary-small" styles={{marginRight: '10px'}} clicked={() => this.saveBlock(tempId)}>
									<FormattedMessage id={'general.add'} />
								</Button>
							</div>
						</div>,
					meta: {
						id: null,
						new: true,
					}
				});
					break;
				case 'text':
					this.setState({
						textIndex: index
					});
					contentBlocks.push({
						type: 'text',
						content: null,
						tempId,
						component:
							<div className={[classes['Step3-add-contenBlock'], classes['Step3-add-contenBlock--text']].join(' ')}>
								<EditorTextarea
									toolbarHidden={false}
									value={''}
									placeholderId={'placeholder.addWork.textEditor'}
									onChange={(e: any) => this.handleChangeTextContent(e, tempId)}/>

								<div className={classes['Step3-add-contenBlock-footer']}>
									<Button type="small" clicked={() => this.removeBlock(tempId)}>
										<FormattedMessage id={'general.remove'} />
									</Button>
									<Button type="primary-small" styles={{marginRight: '10px'}} clicked={() => this.saveBlock(tempId)}>
										<FormattedMessage id={'general.add'} />
									</Button>
								</div>
							</div>,
						meta: {
							id: null,
							new: true,
						}
					});
					break;
			}

			this.setState({
				contentBlocks
			}, () => {

				scroll.scrollToBottom();
			})
		}

	};

	removeBlock = (tempId: string | null, id?: number) => {
		if(!id){
			let currentBlocks = this.state.contentBlocks.filter((block: any) => block.tempId !== tempId);

			this.setState({
				contentBlocks: currentBlocks
			})
		} else {
			this.setState({
				mediaToRemove: id
			}, () => {

				this.props.onRemoveMediaFromOriginal(this.props.data.id, id.toString())
			});
		}
	};

	editBlock = (id?: number) => {

		if(id){
			let mediaToEdit = {...this.state.mediaToEdit};

			mediaToEdit.id = id;
			mediaToEdit.draft = true;
			mediaToEdit.content = this.state.savedBlocks.filter((item: any) => item.id === id)[0].content;

			this.setState({
				mediaToEdit
			}, () => this.onToggleShowEditModal());
		}
	};

	saveBlock(tempId: string, id?: number){
		const currentProject = this.props.data.id;
		let data = null;

		if(currentProject){
			let contentBlocks = [...this.state.contentBlocks];

			if(!id){
				const blockToSave = contentBlocks.filter((block: any) => block.tempId === tempId)[0];

				if(blockToSave.hasOwnProperty('meta'))
					delete blockToSave.meta;

				if(blockToSave.hasOwnProperty('component'))
					delete blockToSave.component;

				let data = {
					...blockToSave,
					sort: this.state.savedBlocks.length
				};

				if(blockToSave.type === 'image'){
					data = {
						...data,
						fileCode: this.state.uploadedFile.code,
					}
				}

				this.props.onCreateMedia(data);
				scroll.scrollToBottom();
			} else {
				let savedBlocks = [...this.state.savedBlocks];
				const blockToSave = savedBlocks.filter((block: any) => block.id === id)[0];
				this.props.onUpdateMedia(id, { content: blockToSave.content });
			}
		}
	}

	changeOrder = (items: Array<any>) =>{
		let newOrder = {...this.state.newOrder};

		newOrder.draft = items.map((item: any, index: number) => { return { id: item.id, sort: index } });

		this.setState({ newOrder });
	};

	saveOrder = () =>{

		this.setState({
			showOrderModal: false,
		}, () => {
			let newOrder = {...this.state.newOrder};
			this.props.onChangeMediaOrder( this.props.data.id, newOrder.draft );
		})
	};

	onToggleShowOrderModal(){
		const showOrderModal = !this.state.showOrderModal;

		this.setState({
			showOrderModal
		})
	}

	onToggleShowEditModal(forceClose?: boolean){
		const showEditModal = forceClose ? false : !this.state.showEditModal;

		this.setState({
			showEditModal,
			mediaUpdated: false,
		})
	}

	componentWillUnmount(): void {
		this.props.onResetFile();
		this.props.onResetMedia();
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevState.uploadedFile !== this.state.uploadedFile && this.state.uploadedFile){
			const node = this.previewImage.current!;
			const container = this.previewContainer.current!;
			const uploadBox = this.upload.current!;

			if(container && node && uploadBox){
				uploadBox.style.display = 'none';
				node.src = this.state.uploadedFile.url;
				container.style.display = 'flex';
			}
		}
		if(prevState.newMedia !== this.state.newMedia && this.state.newMedia){
			this.props.onCreateMedia(this.state.newMedia);
		}
		if(prevState.newMediaId !== this.state.newMediaId && this.state.newMediaId){
			this.props.onResetFile();
			const originalId = this.props.data.id;
			const mediaId = this.state.newMediaId;
			this.props.onAddMediaToOriginal(originalId, mediaId);
		}

		if(this.props.mediaUpdated !== prevProps.mediaUpdated && this.props.mediaUpdated){
			this.props.onResetMedia();
			this.onToggleShowEditModal(true);
		}

		if(this.props.original !== prevProps.original && this.props.original){
			this.setCurrentMedia();
		}
	}

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let uploadedFile = nextState.uploadedFile;
		let newMedia = nextState.newMedia;
		let newMediaId = nextState.newMediaId;
		let newOrder = nextState.newOrder;
		let mediaUpdated = nextState.mediaUpdated;

		if(nextProps.media){
			newMediaId = nextProps.media.id;
		}

		if(nextProps.file){
			uploadedFile = nextProps.file[0];
		} else {
			uploadedFile = null;
		}

		if(nextProps.mediaUpdated){
			mediaUpdated = true;
		}

		return {
			uploadedFile,
			newMedia,
			newMediaId,
			newOrder,
			mediaUpdated,
		}
	}

	render () {
		const { contentBlocks, savedBlocks, showOrderModal, newOrder, showEditModal, mediaToEdit } = this.state;

		const savedBlocksTpl = savedBlocks.map((block, index) => {
			let content = null;

			const value = showEditModal && block.type === 'text' && block.hasOwnProperty('backup') && this.state.mediaToEdit.id > -1 ? block.backup : block.content;

			switch (block.type) {
				case 'image': content = (
					<div className={[classes['Step3-content-block'], classes['Step3-content-block--image']].join(' ')}>
						<img src={block.file.url} alt={'image'} />
					</div>
				);
					break;
				case 'embed': content = (
					<div className={[classes['Step3-content-block'], classes['Step3-content-block--embed']].join(' ')}>
						<div dangerouslySetInnerHTML={{__html: block.content}} />
					</div>
				);
					break;
				case 'text': content = (
					<div className={[classes['Step3-content-block'], classes['Step3-content-block--text']].join(' ')}>
						<div dangerouslySetInnerHTML={{__html: value}} />
					</div>
				);
					break;
				case 'video': content = (
					<div className={[classes['Step3-content-block'], classes['Step3-content-block--video']].join(' ')}>
						<ReactPlayer url={block.content} playsinline={true} width="100%" />
						{/* <div dangerouslySetInnerHTML={{__html: block.content}} /> */}
					</div>
				);
					break;
				default: content = null;
			}

			return (
				<div
					key={index}
					className={[classes["Step3-block"], classes[`Step3--${block.type}`]].join(' ')}>
					<div
						className={classes["Step3-removeBlock"]}>
						<button
							className={classes["Step3-removeBlock-remove-btn"]}
							onClick={() => this.removeBlock(null, block.id)}>
							<FormattedMessage id={'general.delete'} />
						</button>
						{
							block.type === 'text' && block.hasOwnProperty('id') &&
                            <button
                                className={classes["Step3-removeBlock-edit-btn"]}
                                onClick={() => this.editBlock(block.id)}>
                                <FormattedMessage id={'general.edit'} />
                            </button>
						}
					</div>
					{
						content
					}
				</div>
			);
		});


		const contentBlocksTpl = contentBlocks.map((block, index) => {
			return (
				<div key={index}>
					{
						block.hasOwnProperty('component') && block.component
					}
				</div>
			);
		});

		return (
			<div className={classes.Step3}>
				{
					showOrderModal &&
                    <Modal
                        forceClose={true}
                        title={<FormattedMessage id={'order.title'}/>}
                        onModalCloseClicked={this.onToggleShowOrderModal}>
                        <OrderBox currentItems={newOrder.media} changedOrder={this.changeOrder} />
                        <div className={classes["ChangeOrderModal-footer"]}>
                            <Button clicked={this.saveOrder} type="confirm">
                                <FormattedMessage id={'button.confirm'}/>
                            </Button>
                        </div>
                    </Modal>
				}
				{
					showEditModal &&
                    <Modal
                        large={true}
                        forceClose={true}
                        title={<FormattedMessage id={'edit.title'}/>}
                        onModalCloseClicked={() => this.onToggleShowEditModal(true)}>
                        <div>
                            <EditorTextarea
                                toolbarHidden={false}
                                value={mediaToEdit.content}
                                placeholderId={'placeholder.addWork.textEditor'}
                                onChange={(e: any) => this.handleChangeTextContent(e, 'empty', mediaToEdit.id)}/>
                        </div>
                        <div className={classes["EditModal-footer"]}>
                            <Button clicked={() => this.saveBlock('empty', mediaToEdit.id)} type="confirm">
                                <FormattedMessage id={'button.confirm'}/>
                            </Button>
                        </div>
                    </Modal>
				}
				<div className={stepsClasses['Steps-side']}>
					<div className={stepsClasses['Steps-label']}>
						<FormattedMessage id={'label.addMedia'} />
					</div>
					<div className={[stepsClasses['Steps-info-box'], stepsClasses['Steps-info-box--info']].join(' ')}>
						<div className={stepsClasses['Steps-info-box-content']}>
							<FormattedMessage id={'myOriginals.mediaInfo'} />
						</div>
						<div className={stepsClasses['Steps-info-box-icon']} />
					</div>
					<SelectMedia
						// label="label.selectMedia"
						vertical={true}
						action={this.addBlock} />
					<Button
						type="edit-smaller"
						styles={{marginTop: '20px'}}
						clicked={this.onToggleShowOrderModal}>{ 'change order' }</Button>
				</div>
				<div className={stepsClasses['Steps-main']}>
					<div className={stepsClasses['Steps-main-row']}>
						<div className={stepsClasses['Steps-label']}>
							<FormattedMessage id={'label.originalContent'} />
						</div>
						<Button
							type="edit-smaller"
							styles={{marginBottom: '16px', marginTop: 0}}
							clicked={this.onToggleShowOrderModal}>{ 'change order' }</Button>
					</div>
					<div className={classes['Step3-container']}>
						{
							contentBlocks.length <= 0 && savedBlocks.length <= 0 &&
                            <div className={classes['Step3-empty-content']}>
                                <FormattedMessage id={'label.selectMedia'} />
                            </div>
						}

						{/* loading */}
						{
							this.props.fileStoring &&
                            <div className={classes['Step3-loading-container']}>
                                <div className={classes['Step3-loading']}>
                                    <Loader />
                                </div>
                            </div>
						}
						{
							this.props.mediaStoring &&
                            <div className={classes['Step3-loading-container']}>
                                <div className={classes['Step3-loading']}>
                                    <Loader />
                                </div>
                            </div>
						}
						{
							this.props.associateMediaToOriginal &&
                            <div className={classes['Step3-loading-container']}>
                                <div className={classes['Step3-loading']}>
                                    <Loader />
                                </div>
                            </div>
						}
						{
							savedBlocksTpl
						}
						{
							contentBlocksTpl
						}
						{
							// @todo: Mike can you recover if you want!
							// <div className={classes['Step3-addType']}>
							//   <SelectMedia vertical={false} action={this.addBlock} />
							// </div>
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		media: state.mediaState.media,
		mediaUpdated: state.mediaState.mediaUpdated,
		mediaStoring: state.mediaState.isStoring,
		file: state.fileState.file,
		fileStoring: state.fileState.isStoring,
		associateMediaToOriginal: state.originalState.isStoring,
		loading: state.mediaState.isFetching,
		error: state.mediaState.error,
		original: state.originalState.original,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onCreateMedia: (data: any) => dispatch(actions.createMedia(data)),
		onUpdateMedia: (id: number, data: any) => dispatch(actions.updateMedia(id, data)),
		onChangeMediaOrder: (id: number, data: Array<IOrder>) => dispatch(actions.orderMediaForOriginal(id, data)),
		onCreateFile: (data: any) => dispatch(actions.createFile(data)),
		onAddMediaToOriginal: (id: number, mediaId: string) => dispatch(actions.addMediaToOriginal(id, mediaId)),
		onRemoveMediaFromOriginal: (id: number, mediaId: string) => dispatch(actions.removeMediaFromOriginal(id, mediaId)),
		onResetFile: () => dispatch(actions.resetFile()),
		onResetMedia: () => dispatch(actions.resetMedia()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Step3 );
