import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IDistribution } from '../../shared/interfaces';

interface IAction {
    type: string;
    distribution: IDistribution;
    distributions: Array<IDistribution>;
    error: string;
}

const INITIAL_STATE = {
    distribution: null,
	distributions: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
};

const fetchDistributionStart = (state = INITIAL_STATE) => {
	return updateObject(state, { distribution: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchDistributionSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { distribution: action.distribution, isFetching: false });
};

const fetchDistributionFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchDistributionsStart = (state = INITIAL_STATE) => {
	return updateObject(state, { distributions: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchDistributionsSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { distributions: action.distributions, isFetching: false });
};

const fetchDistributionsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchDistributionsForOriginalsFilterStart = (state = INITIAL_STATE) => {
	return updateObject(state, { distributions: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchDistributionsForOriginalsFilterSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { distributions: action.distributions, isFetching: false });
};

const fetchDistributionsForOriginalsFilterFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const distributionReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // READ/FETCHING
	    case actionTypes.FETCH_DISTRIBUTION_START: return fetchDistributionStart(state);
	    case actionTypes.FETCH_DISTRIBUTION_SUCCESS: return fetchDistributionSuccess(state, action);
	    case actionTypes.FETCH_DISTRIBUTION_FAIL: return fetchDistributionFail(state, action);

	    case actionTypes.FETCH_DISTRIBUTIONS_START: return fetchDistributionsStart(state);
	    case actionTypes.FETCH_DISTRIBUTIONS_SUCCESS: return fetchDistributionsSuccess(state, action);
	    case actionTypes.FETCH_DISTRIBUTIONS_FAIL: return fetchDistributionsFail(state, action);

	    case actionTypes.FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_START: return fetchDistributionsForOriginalsFilterStart(state);
	    case actionTypes.FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_SUCCESS: return fetchDistributionsForOriginalsFilterSuccess(state, action);
	    case actionTypes.FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_FAIL: return fetchDistributionsForOriginalsFilterFail(state, action);


	    default:
            return state;
    }
};

export default distributionReducer;
