import React, {Component} from 'react';
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import { connect } from 'react-redux';
import classes from './PatamuBox.module.scss';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { IOriginal, IUser } from '../../shared/interfaces';

const API_BASE = process.env.REACT_APP_API_ENDPOINT;

interface IProps {
	currentUser: IUser;
	currentOriginal: IOriginal;
	currentPatamu: string;
	onAddPatamuToOriginal: (id: number, data: { patamu: string | number }) => void;
}

interface IState {
	referral: string;
	downloadZipUrl: string;
	currentPatamu: string;
	step: number;
}

class PatamuBox extends Component<IProps, IState> {
	state = {
		referral: '',
		downloadZipUrl: '',
		currentPatamu: '',
		step: 0
	};

	componentDidMount(): void {
		const { currentOriginal: { id }, currentPatamu } = this.props;
		const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

		if(id && authToken) {
			this.setState({
				downloadZipUrl: `${API_BASE}/original/${id}/zip/${authToken}`,
				currentPatamu: currentPatamu || '',
			})
		}
	}

	goToDownload = () => {
		const win = window.open(this.state.downloadZipUrl, '_blank');
		if (win != null) {
			win.focus();
		}
		this.setState({
			step: 1
		});
		ReactGA.event({
			category: 'Patamu',
			action: 'Click on download Original',
		});
	};

	goToPatamu = () => {
		const win = window.open('https://www.patamu.com/index.php/it/abbonamenti/professional-it?cp=CALLHOLDEN', '_blank');
		if (win != null) {
			win.focus();
		}
		this.setState({
			step: 2
		});
		ReactGA.event({
			category: 'Patamu',
			action: 'Click on Go to Patamu',
		});
	};

	onClosePatamu = () => {
		const { currentOriginal: { id } } = this.props;

		if(id){
			this.props.onAddPatamuToOriginal(id, { patamu: 1 });
		}
	};

	onClickConfirmPatamu = () => {
		const { currentOriginal: { id } } = this.props;
		const { referral } = this.state;

		if (id && referral){
			this.props.onAddPatamuToOriginal(id, { patamu: referral });
		}
	};

	render () {
		const { referral, downloadZipUrl, currentPatamu, step } = this.state;

		const buttonTpl = (
			<Button
				clicked={this.onClickConfirmPatamu}
				disabled={!referral.trim().length}
				type="confirm-dark-left-solid">
				{
					<FormattedMessage id={'patamu.send'}/>
				}
			</Button>
		);

		return (
			<div className={classes.PatamuBox}>

				<div className={classes['PatamuBox-header']}>
					{
						/*
					<button onClick={this.onClosePatamu} className={classes['PatamuBox-close']} title="Skip">
						X
					</button>
						 */
					}
					<div className={classes['PatamuBox-header-left']}>
						<h3><FormattedMessage id={'patamu.banner.title'}/></h3>
						<div className={classes['PatamuBox-description']}>
							<p><FormattedHTMLMessage id={'patamu.banner.txt'}/></p>
							<p className={classes['PatamuBox-spec']}>
								<FormattedHTMLMessage id={'patamu.banner.spec'}/></p>
						</div>
					</div>
					<div className={classes['PatamuBox-header-right']}>
						<div className={classes['PatamuBox-list']}>
							<div className={[classes['PatamuBox-list-item'], step >= 0 ? classes['PatamuBox-list-item--active'] : ''].join(' ')}>
								<div className={[classes['PatamuBox-order'], classes['PatamuBox-order--small']].join(' ')}>1</div>
								<div className={classes['PatamuBox-list-item-action']}>
									<FormattedMessage id={'patamu.banner.step.2'}/>
									<button
										onClick={this.goToDownload}
										className={classes['PatamuBox-cta']}>
										<FormattedMessage id={'download.zip'} />
									</button>
								</div>
							</div>
							<div className={[classes['PatamuBox-list-item'], step >= 1 ? classes['PatamuBox-list-item--active'] : ''].join(' ')}>
								<div className={[classes['PatamuBox-order'], classes['PatamuBox-order--small']].join(' ')}>2</div>
								<div className={classes['PatamuBox-list-item-action']}>
									<FormattedMessage id={'patamu.banner.step.3'}/>
									<button
									onClick={this.goToPatamu}
									className={classes['PatamuBox-cta']}>
										<FormattedMessage id={'patamu.redirect'} />
									</button>
								</div>
							</div>
							<div className={[classes['PatamuBox-list-item'], step >= 2 ? classes['PatamuBox-list-item--active'] : ''].join(' ')}>
								<div className={[classes['PatamuBox-order'], classes['PatamuBox-order--small']].join(' ')}>3</div>
								<div className={classes['PatamuBox-list-item-action']}>
									<FormattedMessage id={'patamu.banner.step.4'}/>
									<em><FormattedMessage id={'patamu.banner.step.4.info'}/></em>
									<Input
										changed={(e: React.FormEvent<HTMLInputElement>) => this.setState({ referral: e.currentTarget.value })}
										type="text"
										name="patamuReferral"
										value={currentPatamu}
										styles={{marginBottom: '0', maxWidth: '17rem', padding:'10px'}}
										button={buttonTpl}
										visible={true} />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		)
    }
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		currentOriginal: state.originalState.original,
		currentPatamu: state.originalState.patamu
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onAddPatamuToOriginal: (id: number, data: { patamu: string | number }) => dispatch(actions.addPatamuToOriginal(id, data))
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( PatamuBox );
