import React, { Fragment } from 'react';
import classes from './UploadBox.module.scss';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

interface uploadBoxProps {
	titleId?: string;
	styles?: object;
	multiple?: boolean;
	accept?: string | undefined;
	files?: any[];
	onSelectedFiles: (uploaded: any, rejected: any) => void;
	onDelete?: (index: number) => void;
	reference?: any;
	squared?: boolean;
	large?: boolean;
	fullWidth?: boolean;
	maxSize?: number;
	disabled?: boolean;
}

const uploadBox = ({
    titleId,
    styles,
    multiple,
    accept,
    onSelectedFiles,
	reference,
	squared,
	large,
	fullWidth,
	maxSize,
	disabled
}: uploadBoxProps) => {

    const onDrop = (acceptedFiles: Array<any>, rejectedFiles: Array<any>) => {
    	if(rejectedFiles.length === 0) {
		    onSelectedFiles(acceptedFiles, rejectedFiles);
	    } else {
    		const message = maxSize ? "Please check media size!" : "Please check media size! (Max 8 MB)";

		    toast.error(message, {
			    position: toast.POSITION.TOP_RIGHT
		    });
	    }
	};

	const largeTpl = (
		titleId &&
		<label className={[classes['UploadBox-label'], classes['UploadBox-label--plus']].join(' ')}><FormattedMessage id={titleId} /></label>
	);

    return (
        <div
	        className={[
	        	classes.UploadBoxContainer
	        ].join(' ')}
	        ref={reference}>
            <Dropzone
	            disabled={disabled}
	            maxSize={maxSize || 8704000}
		        onDrop={onDrop}
		        multiple={multiple ? multiple : false}
		        accept={accept}>
                    {
                    	({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                            <div
                                {...getRootProps()}
                                style={styles}
                                className={[
                                    classes.UploadBox,
                                    isDragActive ? classes['is-active'] : ''
                                    ].join(' ')}>
			                            <input {...getInputProps()} />
			                            {
			                                isDragActive ? (
			                                    <span
				                                    className={[
					                                    classes['UploadBox-content'],
					                                    disabled ? classes['is-disabled'] : ''
				                                    ].join(' ')}>
			                                        <div className={classes['UploadBox-round']}>
				                                        +
			                                        </div>
				                                    {
														titleId &&
														<div className={classes['UploadBox-infos']}>
                                                            <label className={classes['UploadBox-label']}><FormattedMessage id={titleId} /></label>
															<div className={classes['UploadBox-max']}>
																<FormattedMessage id="label.maxSize"/>{' '}{ maxSize ? maxSize/1024000 : '8' }{' MB'}
															</div>
														</div>
				                                    }
			                                    </span>
			                            	) : (
												<div className={[
													classes['UploadBox-content'],
													large ? classes['UploadBox-large'] : '',
													fullWidth ? classes['UploadBox-fullWidth'] : '',
													disabled ? classes['is-disabled'] : ''
												].join(' ')}>
													<div className={[classes['UploadBox-round'], squared ? classes['UploadBox-squared'] : ''].join(' ')}>
														{ large ?
															largeTpl
														:
															'+'
														}
														{
															!titleId ?
																squared ?
																<div className={[classes['UploadBox-max'], classes['UploadBox-max--small']].join(' ')}>
																	<FormattedMessage id="label.maxSizeSmall"/>
																	{' '}{ maxSize ? maxSize/1024000 : '8' }{' MB'}
																</div>
																:
																<div className={classes['UploadBox-max']}>
																	<FormattedMessage id="label.maxSize"/>
																	{' '}{ maxSize ? maxSize/1024000 : '8' }{' MB'}
																</div>
																:
																''
														}
													</div>
													{
														titleId &&
														<div className={classes['UploadBox-infos']}>
															<label className={[classes['UploadBox-label'], large ? classes['UploadBox-label--hidden'] : ''].join(' ')}><FormattedMessage id={titleId} /></label>
															<div className={classes['UploadBox-max']}>
																<FormattedMessage id="label.maxSize"/>
																{' '}{ maxSize ? maxSize/1024000 : '8' }{' MB'}
															</div>
														</div>
													}
												</div>
											)
										}
                            </div>
                        );
                    }}
            </Dropzone>
        </div>
    );
};

export default uploadBox;
