import React, {Component} from 'react';
import classes from './ErrorPage.module.scss';
import {FormattedMessage} from "react-intl";

interface IProps {
	match: any;
}

interface IState {
	errorId: string;
}

class ErrorPage extends Component<IProps, IState> {

	state = {
		errorId: 'error.userNotFound',
	};

	render () {
    	const { errorId } = this.state;

		return (
			<div className={classes.ErrorPage}>
				<div className={classes['ErrorPage-message']}>
					<div className={classes['ErrorPage-img']} />
					<div className={classes['ErrorPage-code']}>404</div>
					{ errorId ? <FormattedMessage id={errorId}/> : <FormattedMessage id={'error.404'}/>}
				</div>
			</div>
		)
    }
}

export default ErrorPage;
