import React, { Component } from 'react';
import classes from './NotificationsBox.module.scss';
import Notification from '../Notification/Notification';
import { INotification } from '../../shared/interfaces';

interface INotificationsBoxProps {
	notifications: Array<INotification>;
	title: object;
	history: any;
}

interface INotificationsBoxState {}

class NotificationsBox extends Component<INotificationsBoxProps, INotificationsBoxState> {
	render () {
		const { notifications, title, history } = this.props;

    	const notificationsTpl = notifications.map((item: INotification, index: number) => {
    		return (
    			<Notification history={history} key={index} notification={item} />
		    )
	    });

        return (
            <div
                className={classes.NotificationsBox}>
	            {/* <h4 className={classes['NotificationsBox-title']}>{ title }</h4> */}
	            <div className={classes['NotificationsBox-list']}>
		            { notificationsTpl }
	            </div>
			</div>
        )
    }
}

export default NotificationsBox;
