import React, {Component, Fragment} from 'react';
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import { connect } from 'react-redux';
import classes from './Originals.module.scss';
import 'react-widgets/dist/css/react-widgets.css';
import './Multiselect.scss';
import Template from '../../containers/Template/Template';
import Loader from '../Loader/Loader';
import { FormattedMessage } from 'react-intl';
import {
	IBell,
	IDistribution,
	IFormat,
	IMood,
	IOriginal, IOriginalRequest,
	ISearchOriginal,
	IUser
} from '../../shared/interfaces';
import OriginalCard from '../OriginalCard/OriginalCard';
import * as routes from '../../shared/routes';
// https://jquense.github.io/react-widgets/api/Multiselect/
import { Multiselect } from 'react-widgets';
import Masonry from 'react-masonry-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IProps {
	onGetCurrentUser(): object;
	onSearchOriginals(data: ISearchOriginal, page: number): Array<IOriginal>;
	onCreateRequest(userId: number, originalId: number): Array<IOriginalRequest>;
	onGetMoods: () => Array<IMood>;
	onGetDistributions: () => Array<IDistribution>;
	onGetFormats: () => Array<IFormat>;
	onGetBells: () => Array<IBell>;
	originals: Array<IOriginal>;
	currentUser: IUser;
	history: any;
	match: any;
	loading: boolean;
	moodsList: Array<IMood>;
	formatsList: Array<IFormat>;
	distributionsList: Array<IDistribution>;
	bellsList: Array<IBell>;
	isLastPage: boolean;
	requestAdded: boolean;
	storing: boolean;
	isAdmin: boolean;
}

interface IState {
	currentUser: IUser | null;
	data: ISearchOriginal;
	originalMoods: Array<string>;
	originalDistributions: Array<string>;
	originalFormats: Array<string>;
	originalBells: Array<string>;
	currentPage: number;
	sendRequestToOriginalId: number;
	requestsSent: Array<number>;
	filtersOpen: boolean;
}

let STAGES = constants.ORIGINAL_STAGES.map((item: { value: number; key: string; }) => item.key);

const masonryOptions = {
	transitionDuration: 0,
	gutter: '.gutter-sizer',
};

class Originals extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			currentUser: null,
			data: {
				stage: [],
				mood: [],
				format: [],
				distribution: [],
				bell: [],
				offset: constants.DEFAULT_PAGINATION.offset,
				limit: constants.DEFAULT_PAGINATION.limit,
			},
			originalFormats: [],
			originalDistributions: [],
			originalMoods: [],
			originalBells: [],
			currentPage: 1,
			sendRequestToOriginalId: -1,
			requestsSent: [],
			filtersOpen: false
		};
	}

	componentDidMount(): void {
		window.addEventListener('scroll', this.handleScroll, false);
		window.scrollTo(0, 0);
		ReactGA.pageview('/originals');

		this.handleFiltersChanged();
		this.getCurrentUser();
		this.props.onGetFormats();
		this.props.onGetDistributions();
		this.props.onGetMoods();
		//this.props.onGetBells();
	}

	handleScroll = () => {
		const { loading, isLastPage } = this.props;
		const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
		const body = document.body;
		const html = document.documentElement;
		const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
		const windowBottom = windowHeight + window.pageYOffset;
		if (windowBottom >= docHeight - 900 && !loading && !isLastPage) {
			this.setState({
				currentPage: this.state.currentPage + 1
			}, () => {
				this.handleFiltersChanged()
			})
		}
	};

	getCurrentUser(){
		this.props.onGetCurrentUser();
	}

	handleChangeFormats = (values: Array<string>) => {
		let data = {...this.state.data};
		data.format = values;

		this.setState({ data, currentPage: 1 }, () => {
			this.handleFiltersChanged()
		})
	};

	handleChangeDistributions = (values: Array<string>) => {
		let data = {...this.state.data};
		data.distribution = values;

		this.setState({ data, currentPage: 1 }, () => {
			this.handleFiltersChanged()
		})
	};

	handleChangeMoods = (values: Array<string>) => {
		let data = {...this.state.data};
		data.mood = values;

		this.setState({ data, currentPage: 1 }, () => {
			this.handleFiltersChanged()
		})
	};

	handleChangeBells = (values: Array<string>) => {
		let data = {...this.state.data};
		data.bell = values;

		this.setState({ data, currentPage: 1 }, () => {
			this.handleFiltersChanged()
		})
	};

	handleChangeStages = (values: Array<string>) => {
		let data = {...this.state.data};
		data.stage = values;

		this.setState({ data, currentPage: 1 }, () => {
			this.handleFiltersChanged()
		})
	};

	handleFiltersChanged = () => {
		const { currentPage } = this.state;
		const offset = (currentPage - 1)*constants.DEFAULT_PAGINATION.limit;

		const data = {
			...this.state.data,
			offset
		};

		this.props.onSearchOriginals(data, currentPage);
	};

	handleSendRequest = (originalId: number) => {
		const { currentUser: { id } } = this.props;

		if(id && originalId) {
			this.setState({
				sendRequestToOriginalId: originalId
			}, () => {
				this.props.onCreateRequest(id, originalId);
			})
		}
	};

	handleOpenFilters = () => {
		this.setState({
			filtersOpen: !this.state.filtersOpen
		});
	};

	setFormat = (value: string) => {
		let data = {...this.state.data};
		data.format = [value];

		this.setState({ data, currentPage: 1, filtersOpen: true }, () => {
			this.handleFiltersChanged();
			window.scrollTo(0, 0);
		});
	};

	setMood = (value: string) => {
		let data = {...this.state.data};
		data.mood = [value];

		this.setState({ data, currentPage: 1, filtersOpen: true }, () => {
			this.handleFiltersChanged();
			window.scrollTo(0, 0);
		});
	};

	setDistribution = (value: string) => {
		let data = {...this.state.data};
		data.distribution = [value];

		this.setState({ data, currentPage: 1, filtersOpen: true }, () => {
			this.handleFiltersChanged();
			window.scrollTo(0, 0);
		});
	};

	clearAllFilter = () => {
		let data = {...this.state.data};
		data.format = [];
		data.distribution = [];
		data.mood = [];
		data.stage = [];
		data.bell = [];

		this.setState({ data, currentPage: 1, filtersOpen: true }, () => {
			this.handleFiltersChanged()
		})
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.currentUser !== prevProps.currentUser && this.props.currentUser){
			const currentUser = this.props.currentUser;

			if(this.props.currentUser.hasOwnProperty('fileCodeImage'))
				localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, this.props.currentUser.fileCodeImage.url);

			if(this.props.isAdmin)
				this.props.onGetBells();

			this.setState({
				currentUser
			})
		}

		if(prevProps.formatsList !== this.props.formatsList){
			const originalFormats = this.props.formatsList.map((format: IFormat) => format.title);

			this.setState({
				originalFormats
			})
		}
		if(prevProps.moodsList !== this.props.moodsList){
			const originalMoods = this.props.moodsList.map((format: IFormat) => format.title);

			this.setState({
				originalMoods
			})
		}
		if(prevProps.distributionsList !== this.props.distributionsList){
			const originalDistributions = this.props.distributionsList.map((format: IFormat) => format.title);

			this.setState({
				originalDistributions
			})
		}
		if(prevProps.bellsList !== this.props.bellsList){
			const originalBells = this.props.bellsList.map((format: IFormat) => format.title);

			this.setState({
				originalBells
			})
		}

		if(prevProps.requestAdded !== this.props.requestAdded && this.props.requestAdded) {
			let requestsSent = [...this.state.requestsSent];
			const originalId = this.state.sendRequestToOriginalId;
			requestsSent = requestsSent.concat(originalId);

			this.setState({
				requestsSent,
				sendRequestToOriginalId: -1
			}, () => {
				toast.success('Request sent!', {
					position: toast.POSITION.TOP_RIGHT,
					className: 'confirm-bubble'
				});
			})
		}
	}

	componentWillUnmount(): void {
		window.removeEventListener('scroll', this.handleScroll, false);
	}

	goToOriginals = () => {
		this.props.history.push('/my-originals')
	};

	render () {
		const { data, originalDistributions, originalFormats, originalMoods, originalBells, requestsSent, filtersOpen, currentUser } = this.state;
		const { originals, loading, storing, isAdmin } = this.props;

		let distributionLength = data.distribution ? data.distribution.length : 0;
		let moodLength = data.mood ? data.mood.length : 0;
		let formatLength = data.format ? data.format.length : 0;
		let stageLength = data.stage ? data.stage.length : 0;
		let filtersLenght = distributionLength + moodLength + formatLength + stageLength;

		return (
			<Template theme={'dark'} noPadding={true}>
				<Fragment>
					<div className={classes.Originals}>
						<div className={classes['Originals-header']}>
							<div className={classes['Originals-header-left']}>
								<h1 className={classes['Originals-header-title']}>
									<span></span>
									<em>ORIGINALS</em>
								</h1>
								{/* <div className={classes['Originals-header-description']}>
									<FormattedMessage id={'originals.description'}/>
								</div> */}
							</div>
							<div className={classes['Originals-header-right']}>
								{/* <label><FormattedMessage id={'label.goto'}/></label> */}
								<button
									className={[classes['Originals-filters-btn'], filtersOpen ? classes['Originals-filters-btn--active'] : '', filtersLenght > 0 ? classes['Originals-filters-btn--noicon']: ''].join(' ')}
									onClick={this.handleOpenFilters}>
									{
										filtersLenght > 0 &&
										<span className={classes['Originals-filters-btn-counter']}>
											{ filtersLenght }
										</span>
									}
									<span>Filters</span>
								</button>
								<button className={classes['Originals-goto-my']} onClick={this.goToOriginals}>
									<h2 className={[classes['Originals-header-title'], classes['Originals-header-title--small']].join(' ')}>
										MY
										<span></span>
										<em>ORIGINALS</em>
									</h2>
								</button>
							</div>
						</div>

						<div
							className={[classes['Originals-filters'], filtersOpen ? classes['Originals-filters--open'] : ''].join(' ')}>
							<div className={classes['Originals-filters-container']}>
								<div className={classes['Originals-filter']}>
									<label><FormattedMessage id={'label.originalFormats'}/></label>
									<Multiselect
										placeholder={'Ex. Film'}
										data={originalFormats}
										value={data.format}
										onChange={this.handleChangeFormats}
									/>
								</div>
								<div className={classes['Originals-filter']}>
									<label><FormattedMessage id={'label.originalDistributions'}/></label>
									<Multiselect
										placeholder={'Ex. Web'}
										data={originalDistributions}
										value={data.distribution}
										onChange={this.handleChangeDistributions}
									/>
								</div>
								<div className={classes['Originals-filter']}>
									<label><FormattedMessage id={'label.originalMoods'}/></label>
									<Multiselect
										placeholder={'Ex. Crime'}
										data={originalMoods}
										value={data.mood}
										onChange={this.handleChangeMoods}
									/>
								</div>
								{
									isAdmin &&
                                    <div className={classes['Originals-filter']}>
                                        <label><FormattedMessage id={'label.originalBellsFilter'}/></label>
                                        <Multiselect
                                            placeholder={'Ex. Scuola'}
                                            data={originalBells}
                                            value={data.bell}
                                            onChange={this.handleChangeBells}
                                        />
                                    </div>
								}
								<div className={classes['Originals-filter']}>
									<label><FormattedMessage id={'label.originalStage'}/></label>
									<Multiselect
										placeholder={'Ex. Rough Draft'}
										data={STAGES}
										value={data.stage}
										onChange={this.handleChangeStages}
									/>
								</div>
								{
									filtersLenght > 0 &&
									<div className={[classes['Originals-filter'], classes['Originals-filter--clear']].join(' ')}>
										<button
											onClick={this.clearAllFilter}
											className={classes['Original-filter-clearall-btn']}>
											<FormattedMessage id={'label.clearAll'}/>
										</button>
									</div>
								}
							</div>
						</div>
						<div className={classes['Originals-content']}>
							<Masonry
								className={[classes['Originals-list']].join(' ')}
								options={masonryOptions}>
								{
									originals.map((original: IOriginal, index: number) => {
										const id = original.hasOwnProperty('id') && original.id;
										const requestStatus = original.hasOwnProperty('requestStatus') && original.requestStatus;
										const clicked = id && (requestStatus === 'CREATOR' || requestStatus === 'COLLABORATION' || requestStatus === 'APPROVED' || (isAdmin && original.bell.length > 0)) ?  () => this.props.history.push(routes.ORIGINAL.replace(':id', id.toString())) : undefined;

										let backgroundImageUrl = original.file && original.file.childrens ? original.file.childrens[1].url : undefined;

										let requestAccessDisabled = false;

										if(original.id)
											requestAccessDisabled = (this.state.sendRequestToOriginalId === +original.id && storing) || requestsSent.indexOf(+original.id) > -1 || requestStatus === 'PENDING';

										return (
											<OriginalCard
												backgroundImageUrl={backgroundImageUrl}
												showLabelId={'general.view'}
												styles={{margin: '10px'}}
												userName={original.user.name + ' ' + original.user.surname}
												userThumbnail={original.user.file && original.user.file.url}
												showClicked={clicked}
												titleClicked={clicked}
												key={index}
												requestAccessRejected={!clicked && requestStatus === 'REJECTED'}
												requestAccessDisabled={!clicked && requestAccessDisabled}
												requestAccessClicked={!clicked && id && requestStatus !== 'CREATOR' && requestStatus !== 'COLLABORATION' && requestStatus !== 'APPROVED' ? () => this.handleSendRequest(id) : undefined}
												original={original}
												setFormat={this.setFormat}
												setMood={this.setMood}
												setDistribution={this.setDistribution}
												currentUser={currentUser ? currentUser.id : undefined} />
										)
									})
								}
								<div className="gutter-sizer" style={{width: '2%'}} />
							</Masonry>
						</div>
						<div className={classes['Originals-footer']}>
							{
								loading && <Loader white={true} />
							}
						</div>
					</div>
				</Fragment>
			</Template>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		originals: state.originalState.paginatedList,
		isLastPage: state.originalState.isLastPage,
		storing: state.originalState.isStoring,
		currentUser: state.userState.user,
		isAdmin: state.userState.user && state.userState.user.roles.indexOf('ADMIN') > -1,
		loading: state.originalState.isFetching,
		error: state.originalState.error,
		moodsList: state.moodState.moods,
		distributionsList: state.distributionState.distributions,
		formatsList: state.formatState.formats,
		bellsList: state.bellState.bells,
		requestAdded: state.originalState.requestAdded,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onSearchOriginals: (data: ISearchOriginal, page: number) => dispatch(actions.searchOriginals(data, page)),
		onGetFormats: () => dispatch(actions.fetchFormatsForOriginalsFilter()),
		onGetMoods: () => dispatch(actions.fetchMoodsForOriginalsFilter()),
		onGetDistributions: () => dispatch(actions.fetchDistributionsForOriginalsFilter()),
		onGetBells: () => dispatch(actions.fetchBellsForOriginalsFilter()),
		onCreateRequest: (userId: number, originalId: number) => dispatch(actions.addRequestToOriginal(userId, originalId)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Originals );
