import React, {Component, Fragment} from 'react';
import * as routes from "../../shared/routes";
import * as ReactGA from '../../GA';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classes from './Original.module.scss';
import { ICollaborator, IOriginal, IOriginalSteps, ISelect, IUser } from '../../shared/interfaces';
import {FormattedMessage} from "react-intl";
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import Steps from './Steps/Steps';
import Modal from '../../containers/Modal/Modal';
import StepsIndicator from '../StepsIndicator/StepsIndicator';
import StepIndicator from './StepIndicator/StepIndicator';
import LockIndicator from './LockIndicator/LockIndicator';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import constants from '../../shared/constants';
import { resetProjectSession } from '../../shared/helpers';
import UnauthorizedRoute from '../UnauthorizedRoute/UnauthorizedRoute';

toast.configure({
	autoClose: 3000,
	draggable: false,
});

interface IProps {
	match: {
		params: { step: number, id: number };
	};
	history?: any;
	onCreateOriginal: (data: IOriginal) => IOriginal;
	onUpdateOriginal: (id: number, data: any, step: string) => IOriginal;
	onGetOriginal: (id: number, edit: boolean) => IOriginal;
	onResetMyOriginal: () => void;
	original: any;
	created: boolean;
	updated: boolean;
	storing: boolean;
	error: any;
	currentUser: IUser;
	onResetOriginalCover: () => void;
}

interface IState {
    currentStep: number;
    steps: Array<IOriginalSteps>;
	data?: any;
	originalFound: boolean;
	invalidFields: any;
	showValidationPopup: boolean;
	editMode: boolean;
	unauthorized: boolean;
	forceButtonsStatus: boolean;
}

const VALUES_TO_CHECK = [
	[
		{
			label: 'Original title',
			field: 'title',
		},
	],
	[],
	[],
	[],
	[],
];

class Original extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			showValidationPopup: false,
			originalFound: false,
			currentStep: 0,
			invalidFields: [],
			editMode: false,
			data: {
				isDraft: 1,
				userId: null,
				title: '',
				description: '',
				inspiration: '',
				logline: '',
				collaborators: [],
				collaboratorsInDraft: [],
				//collaboratorsWaiting: [],
				lookingFor: '',
				mood: [],
				format: [],
				bell: [],
				distribution: [],
				stage: 'Rough draft',
				new: true,
				requestUserIds: [],
			},
			steps: [
				{
					title: <FormattedMessage id={'addOriginal.step.1.title'} />,
				},
				{
					title: <FormattedMessage id={'addOriginal.step.2.title'} />,
					subtitle: <FormattedMessage id={'addOriginal.step.2.description'} />,
				},
				{
					title: <FormattedMessage id={'addOriginal.step.3.title'} />,
					subtitle: <FormattedMessage id={'addOriginal.step.3.description'} />,
				},
				{
					title: <FormattedMessage id={'addOriginal.step.4.title'} />,
					subtitle: <FormattedMessage id={'addOriginal.step.4.description'} />,
				},
				{
					title: <FormattedMessage id={'addOriginal.step.5.title'} />,
				}
			],
			unauthorized: false,
			forceButtonsStatus: false,
		};

		this.setData = this.setData.bind(this);
		this.onToggleShowValidationPopup = this.onToggleShowValidationPopup.bind(this);
		this.checkInvalidFields = this.checkInvalidFields.bind(this);
		this.publish = this.publish.bind(this);
	}

	componentDidMount() {
		this.props.onResetMyOriginal();

		const { match: { params: { id } } } = this.props;
		if(id){
			ReactGA.pageview('/original');
			this.getCurrentOriginal(id);

			this.setState({ editMode: true });
		} else {
			this.setState({ editMode: false });
			ReactGA.pageview('/add-original');
			this.setState({
				currentStep: 1,
			}, () => {
				this.props.history.push(routes.ADD_ORIGINAL.replace(':step', '1'))
			})
		}
	}

	getCurrentOriginal(originalId: number) {
		this.props.onGetOriginal(originalId, true);
	}

    goBack = () => {
		if(this.state.currentStep === 1){
			this.props.history.push(routes.PROFILE);
		} else {
			const currentStep = this.state.currentStep - 1;

			this.setState({
				currentStep,
			}, () => this.pushRoute(this.state.data.id))
		}
	};

    backToMyOriginals = () => {
		this.props.history.push(routes.MY_ORIGINALS);
    };

	checkInvalidFields(all?: boolean){
		const { currentStep } = this.state;

		let fieldsToCheck: any = [];

		if(!all)
			fieldsToCheck = currentStep < 6 ? VALUES_TO_CHECK[currentStep - 1] : [];
		else
			fieldsToCheck = currentStep < 6 && VALUES_TO_CHECK.flat(1);

		//console.log(fieldsToCheck);

		let invalidFields = [...this.state.invalidFields];

		invalidFields = [];

		if(fieldsToCheck.length){
			let valid = true;
			fieldsToCheck.map((value: any) => {

				if(value.hasOwnProperty('isArray') && value.isArray){
					if(this.state.data[value.field] && !this.state.data[value.field].length){
						invalidFields.push(value)
					}

					valid = this.state.data[value.field] && this.state.data[value.field].length && valid;
				} else {
					if(!this.state.data[value.field]){
						invalidFields.push(value)
					}
					valid = this.state.data[value.field] && valid;
				}
			});

			if(!valid){
				this.setState({
					showValidationPopup: true,
					invalidFields
				})
			}
		}

		return invalidFields.length > 0
	}


    goNextStep = () => {
		if(!this.checkInvalidFields()){

			if(this.state.currentStep < 5){
				this.saveOriginal();
			} else {
				alert('Available soon!')
			}
		}
    };

    pushRoute(originalId?: string, step?: string){
	    if(originalId){
		    let route = routes.EDIT_ORIGINAL.replace(':step?', step ? step : this.state.currentStep.toString());
		    route = route.replace(':id', originalId);

		    this.props.history.push(route.replace(':id', originalId));
	    } else {
		    this.props.history.push(routes.EDIT_ORIGINAL.replace(':step?', this.state.currentStep.toString()));
	    }
    }

    setData(field: string, value: any){
	    let data = {
		    ...this.state.data
	    };

	    if(field === 'collaboratorsInDraft'){
		    data = {
			    ...data,
			    [field]: value,
			    collaborators: value.length ? value.map((user: ISelect) => +user.value) : [],
		    };
	    } else {
		    data = {
			    ...data,
			    [field]: value,
		    };
	    }

	    this.setState({
		    data
	    }, () => {
	    	//console.log("New original data:", data)
	    })
    }

    saveDraft = () => {
        //console.log('Saving draft:', this.state.data);
        this.saveOriginal(1);
	};

    publish(){
    	if(!this.checkInvalidFields(true)){
			this.saveOriginal();
	    }
    }

    saveOriginal = (isDraft?: number) => {
    	let data = {...this.state.data};
    	const { currentStep } = this.state;
	    const userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);
	    const currentOriginalId = data.hasOwnProperty('id') ? data.id : null;

    	if(userId){
    		if(data.hasOwnProperty('new') && data.new){
    			delete data.new;

    			const newData = {
				    ...data,
				    userId: +userId,
				    isDraft: 0
			    };

    			this.props.onCreateOriginal(newData);
		    } else {

    			if(currentStep === 1){
    			    data = {
				        title: data.title,
				        inspiration: data.inspiration,
				        description: data.description,
				        logline: data.logline,
				        collaborators: data.collaboratorsInDraft.map((user: ISelect) => +user.value),
				        userId: data.userId,
				        isDraft: isDraft ? isDraft : 0
			        }
			    }

			    if(currentStep === 2 || currentStep === 3){
				    data = {
					    isDraft: isDraft ? isDraft : 0
				    }
			    }

			    if(currentStep === 4){
				    data = {
					    lookingFor: data.lookingFor,
					    mood: data.mood.length > 0 ? data.mood.map((tag: any) => tag.hasOwnProperty('text') ? tag.text : tag.title) : [],
					    distribution: data.distribution.length > 0 ? data.distribution.map((tag: any) => tag.hasOwnProperty('text') ? tag.text : tag.title) : [],
					    format: data.format.length > 0 ? data.format.map((tag: any) => tag.hasOwnProperty('text') ? tag.text : tag.title) : [],
					    stage: data.stage,
					    //userId: data.userId,
					    isDraft: isDraft ? isDraft : 0
				    }
			    }

			    if(currentStep === 5){
				    data = {
				    	users: data.requestsUsersIds,
					    isDraft: isDraft ? isDraft : 0,
					    bell: data.bell.length > 0 ? data.bell.map((tag: any) => tag.hasOwnProperty('text') ? tag.text : tag.title) : [],
				    }
			    }

    			this.props.onUpdateOriginal(currentOriginalId, data, constants.ORIGINAL_STEPS[currentStep - 1])
		    }
	    }
    };

	onToggleShowValidationPopup(){
		const showValidationPopup = !this.state.showValidationPopup;

		this.setState({
			showValidationPopup
		})
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.created !== prevProps.created && this.props.created){
			//console.log('Project created with id:', this.props.original.id);
			const currentStep = this.state.currentStep + 1;

			//localStorage.setItem(constants.LOCAL_STORAGE.KEYS.CURRENT_PROJECT_ID, this.props.original.id);
			localStorage.setItem(constants.LOCAL_STORAGE.KEYS.CURRENT_STEP, currentStep.toString());
			const currentCollaborators = this.props.original.collaborators.concat(this.props.original.collaboratorsWaiting);

			this.setState({
				currentStep,
				data: {
					...this.props.original,
					collaboratorsInDraft: currentCollaborators.length ? currentCollaborators.map((user: ICollaborator) => { return { value: +user.id, label: user.name + ' ' + user.surname } }) : [],
					isDraft: 1,
				},
			}, () => {
				/*
				toast.success("Original saved!", {
					position: toast.POSITION.TOP_RIGHT
				});*/
				this.pushRoute(this.props.original.id, '2');
			})
		}

		if(this.props.updated !== prevProps.updated && this.props.updated){
			//console.log('Updated');
			const currentStep = this.state.currentStep + 1;

			if(currentStep === 6){
				let goToRoute = routes.MY_ORIGINALS;
				if(this.props.original){
					let message = 'Original saved!';
					if(this.props.original.status === 'ONLINE'){
						message = 'Original published!';
						goToRoute = routes.ORIGINAL.replace(':id', this.props.original.id.toString());
					}

					toast.success(message, {
						position: toast.POSITION.TOP_RIGHT,
						className: 'confirm-bubble'
					});
				}

				this.props.history.push(goToRoute);
			} else {
				const currentCollaborators = this.props.original.collaborators.concat(this.props.original.collaboratorsWaiting);

				this.setState({
					currentStep,
					data: {
						...this.props.original,
						collaboratorsInDraft: currentCollaborators.length ? currentCollaborators.map((user: ICollaborator) => { return { value: +user.id, label: user.name + ' ' + user.surname } }) : [],
						isDraft: 1,
					},
				}, () => {
					/*
					toast.success("Original saved!", {
						position: toast.POSITION.TOP_RIGHT
					});
					this.pushRoute(this.props.original.id)*/
				})
			}
		}

		if(this.props.original !== prevProps.original && this.props.original && !this.state.originalFound){
			//console.log('ORIGINAL FOUND', nextProps.original);

			const lastSavedStep = this.props.original.lastStep === 1 ? this.props.original.lastStep + 1 : this.props.original.lastStep;
			const currentCollaborators = this.props.original.collaborators.concat(this.props.original.collaboratorsWaiting);

			if(this.props.original.file === null){
				this.props.onResetOriginalCover();
			}

			this.setState({
				originalFound: true,
				data: {
					...this.props.original,
					collaboratorsInDraft: currentCollaborators.length ? currentCollaborators.map((user: ICollaborator) => { return { value: +user.id, label: user.name + ' ' + user.surname } }) : [],
					isDraft: 1,
				},
				currentStep: lastSavedStep,
			}, () => this.pushRoute(this.props.original.id, lastSavedStep))
		}

		if(this.props.error !== prevProps.error && this.props.error){
			const { data: { code, errors } } = this.props.error;

			if(code === 404){
				this.props.history.push(routes.PROFILE)
			}

			if(errors && errors[0].message.toLowerCase().includes('access to this resource is not allowed')){
				this.setState({
					unauthorized: true,
				})
			}
		}
	}

	onForceButtonsStatus = (status: boolean = false) => {
		this.setState({
			forceButtonsStatus: status
		})
	};

    componentWillUnmount(): void {
    	resetProjectSession();
    	this.props.onResetMyOriginal();
    }

	render () {
		const { currentStep, steps, data, showValidationPopup, invalidFields, editMode, unauthorized, forceButtonsStatus } = this.state;

		if(unauthorized){
			return <UnauthorizedRoute />
		}

		if(currentStep === 0) {
			return (
				<div className={classes['Original-loading']}>
					<Loader />
				</div>
			);
		}

        // const footerTpl =
        //     <div className={classes["Original-footer"]}>
        //         <div className={classes["Original-footer-left"]}>
		// 			<Button
		// 				type="small"
		// 				styles={{color: '#D05A5A', paddingRight: '0'}}
		// 				clicked={() => this.backToMyOriginals()}>
		// 				<FormattedMessage id={'general.close'}/>
		// 			</Button>
		// 			{ currentStep > 1 &&
		// 				<Button
		// 					type="small"
		// 					clicked={() => this.goBack()}>
		// 					<FormattedMessage id={'general.back'}/>
		// 				</Button>
		// 			}
        //         </div>
		// 		<div className={classes["Original-footer-center"]}>
		// 			<StepsIndicator currentStep={currentStep} steps={steps} />
		// 		</div>
        //         <div className={classes["Original-footer-right"]}>
		// 			{ currentStep >= 1 &&
		// 				<Button
		// 					type="secondary-small"
		// 					clicked={() => this.saveDraft()}>
		// 					<FormattedMessage id={'general.saveDraft'}/>
		// 				</Button>
		// 			}
		// 			{ data && currentStep <= 4 &&
		// 				<Button
		// 				type="primary-small"
        //                 clicked={() => this.goNextStep()}>
		// 					<FormattedMessage id={'general.nextStep'}/>
		// 				</Button>
		// 			}
		// 			{ data && currentStep > 4 &&
		// 				<Button
		// 				type="confirm"
        //                 clicked={() => this.publish()}>
		// 					<FormattedMessage id={'general.publish'}/>
		// 				</Button>
		// 			}

        //         </div>
		// 	</div>;

		const originalFooter =
			<Fragment>
				<div className={classes["Original-footer"]}>
					<div className={classes["Original-footer-left"]}>
						<StepIndicator
							completed={currentStep > 1}
							active={currentStep === 1}
							title={steps[0].title}
							lock={true} />
						<StepIndicator
							completed={currentStep > 2}
							active={currentStep === 2}
							title={steps[1].title}
							lock={true} />
						<StepIndicator
							completed={currentStep > 3}
							active={currentStep === 3}
							title={steps[2].title}
							lock={true} />
						<StepIndicator
							completed={currentStep > 4}
							active={currentStep === 4}
							title={steps[3].title} />
					</div>
					<div className={classes["Original-footer-right"]}>
						{ data && currentStep > 4 &&
							<Button
							type="confirm"
							clicked={() => this.publish()}>
								{ data.bell.length <= 0 ?
									<FormattedMessage id={'general.publish'}/>
									:
									<FormattedMessage id={'general.apply'}/>
								}
							</Button>
						}
					</div>
				</div>

				<div className={classes['Original-footer-navigation']}>
					<div className={classes['Original-footer-left']}>
						{ currentStep > 1 &&
							<Button
								type="back-small"
								styles={{marginRight: '20px'}}
								clicked={() => this.goBack()}>
								<FormattedMessage id={'general.back'}/>
							</Button>
						}
						<Button
							type="small"
							styles={{color: '#D05A5A', paddingRight: '0'}}
							clicked={() => this.backToMyOriginals()}>
							<FormattedMessage id={'general.close'}/>
						</Button>
					</div>
					<div className={classes["Original-footer-right"]}>
						{ currentStep >= 1 &&
							<Button
								disabled={forceButtonsStatus}
								type="secondary-small"
								clicked={() => this.saveDraft()}>
								<FormattedMessage id={'general.saveDraft'}/>
							</Button>
						}
						{ data && currentStep <= 4 &&
							<Button
                                disabled={forceButtonsStatus}
							type="primary-small"
							clicked={() => this.goNextStep()}>
								<FormattedMessage id={'general.nextStep'}/>
							</Button>
						}
						{ data && currentStep > 4 &&
							<Button
                                disabled={forceButtonsStatus}
							type="confirm"
							clicked={() => this.publish()}>
								{ data.bell.length <= 0 ?
									<FormattedMessage id={'general.publish'}/>
									:
									<FormattedMessage id={'general.apply'}/>
								}
							</Button>
						}
					</div>
				</div>
			</Fragment>

		const invalidFieldsTpl = invalidFields.map((field: any, index: number) => {
			return (
				<div key={index} className={classes['Original-error-message']}>
					{ field.label }
				</div>
			);
		});

        return (
            <div className={[classes['Original'], classes[`Original--step-${currentStep}`]].join(' ')}>
				<div className={classes['Original-mobile-block']}>
					<FormattedMessage id={'addOriginal.mobileBlock'}/>
				</div>
	            {
		            showValidationPopup &&
	                    <Modal
	                        title={<FormattedMessage id={'validation.title'}/>}
	                        onModalCloseClicked={this.onToggleShowValidationPopup}>
		                    {
		                    	invalidFieldsTpl
		                    }
	                    </Modal>
	            }
                <div className={classes["Original-header"]}>
	                {
	                	currentStep < 6 &&
                        <div className={classes["Original-header-content"]}>
                            <h2 className={classes["Original-title"]}>
                                <strong><FormattedMessage id={'addOriginal.title'} /></strong>
				                { steps[currentStep - 1].title }
                            </h2>
                            <p className={classes["Original-subtitle"]}>{ steps[currentStep - 1].subtitle }</p>
                            {/* <button onClick={this.notify}>Notify</button> */}
                        </div>
					}
					<div className={classes['Original-lock-indicator']}>
						{ currentStep === 4
							?
							<LockIndicator />
							:
							<LockIndicator lock />
						}
					</div>
                </div>
	            <Steps
		            forceStatusButtons={this.onForceButtonsStatus}
		            editMode={editMode}
		            data={data}
		            setData={this.setData}
		            step={currentStep} />
                {/* { footerTpl } */}
                { originalFooter }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		original: state.originalState.original,
		created: state.originalState.created,
		updated: state.originalState.updated,
		loading: state.originalState.isFetching,
		storing: state.originalState.isStoring,
		error: state.originalState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onCreateOriginal: (data: IOriginal) => dispatch(actions.createOriginal(data)),
		onUpdateOriginal: (id: number, data: IOriginal, step: string) => dispatch(actions.updateOriginal(id, data, step)),
		onGetOriginal: (id: number, edit: boolean) => dispatch(actions.fetchOriginal(id, edit)),
		onResetMyOriginal: () => dispatch(actions.resetMyOriginal()),
		onResetOriginalCover: () => dispatch(actions.resetCover()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Original );
