import React, { Component, Fragment } from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl';
import classes from './MyOriginalsRequests.module.scss';
import { IUser, IUserOriginalsRequests } from '../../shared/interfaces';
import MyOriginalsRequestsBox from '../MyOriginalsRequestsBox/MyOriginalsRequestsBox';
import Loader from '../Loader/Loader';
import Template from '../../containers/Template/Template';
import MyOriginalsSidebar from '../MyOriginalsSidebar/MyOriginalsSidebar';
import constants from '../../shared/constants';
import * as ReactGA from '../../GA';

interface IProps {
	onGetCurrentUser(): IUser;
	onGetUserRequests(userId: number): Array<object>;
	requests: Array<IUserOriginalsRequests>;
	loading: boolean;
	currentUser: IUser;
	history?: any;
}

interface IState {
	currentUser: IUser | null;
	roles: any;
}

class MyOriginalsRequests extends Component<IProps, IState> {

	state = {
		currentUser: null,
		roles: [],
	};

	componentDidMount(): void {
		window.scrollTo(0, 0);
		ReactGA.pageview('/my-originals/requests');

		this.getCurrentUser();
	}

	getCurrentUser(){
		if(!this.props.currentUser)
			this.props.onGetCurrentUser();
		else {
			this.props.onGetUserRequests(this.props.currentUser.id);
		}
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevProps.currentUser !== this.props.currentUser && this.props.currentUser){
			this.setState({
				currentUser: this.props.currentUser
			}, () =>{
				this.props.onGetUserRequests(this.props.currentUser.id);
			});

			if(this.props.currentUser.hasOwnProperty('fileCodeImage'))
				localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, this.props.currentUser.fileCodeImage.url);
		}
	}

	render () {
		const { roles } = this.state;
		const { loading, requests } = this.props;

        return (
		        <div
			        className={classes.MyOriginalsRequests}>
			        <Template
				        sidebar={<MyOriginalsSidebar roles={roles} />}
				        sidebarSize="small"
						theme="dark">
			            <h3 className={classes['MyOriginalsRequests-title']}>
				            <FormattedMessage id={'myOriginalsRequests.title'} />
			            </h3>
				        {
				        	loading ?
						        <div className={classes['MyOriginalsRequests-loading']}>
							        <Loader />
						        </div> :
						        <Fragment>
							        {
								        !loading && requests &&
								        requests.length === 0 ?
									        <div className={classes['MyOriginalsRequests-empty']}>
										        <span>{'No new access requests found'}</span>
									        </div> :
									        <Fragment>
										        <MyOriginalsRequestsBox requests={requests} />
									        </Fragment>
							        }
						        </Fragment>
				        }
			        </Template>
		        </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		requests: state.originalState.userRequests,
		loading: state.originalState.isFetchingUserRequests,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onGetUserRequests: (userId: number) => dispatch(actions.fetchRequestsByUser(userId))
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( MyOriginalsRequests );
