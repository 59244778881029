import React from 'react';
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import classes from './RegistrationWizardHeader.module.scss';

interface IProps {
    title?: string;
    description?: string;
    img?: string;
}

const RegistrationWizardHeader = ( {title, description, img }: IProps) => {

    // const linkClass = [
    //     classes["RegistrationWizardHeader-link"],
    //     visible === false ? classes["is-hidden"] : '',
    //     name ? classes[`RegistrationWizardHeader-link--${name}`] : '',
    // ].join(' ');

    return (
        <div className={classes.RegistrationWizardHeader}>
            <div
                className={[classes["RegistrationWizardHeader-head"], img ? '' : classes['RegistrationWizarHeader-head--noimg']].join(' ')}
                style={{backgroundImage: img ? `url(${img})` : 'none'}}>
                {
                    title &&
                    <h3 className={classes["RegistrationWizardHeader-title"]}>
                        <FormattedHTMLMessage id={title}/>
                    </h3>
                }
            </div>
            {
                description &&
                <div className={classes["RegistrationWizardHeader-description"]}>
                    <p><FormattedHTMLMessage id={description}/></p>
                </div>
            }
        </div>
    )
};

export default RegistrationWizardHeader
