import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import classes from './MyOriginalsSidebar.module.scss';
import UserCard from '../UserCard/UserCard';
import Modal from '../../containers/Modal/Modal';
import UploadBox from "../UploadBox/UploadBox";
import EditorTextarea from "../EditorTextarea/EditorTextarea";
import CircleImage from '../CircleImage/CircleImage';
import Button from '../Button/Button';
import CityAutocomplete from '../CityAutocomplete/CityAutocomplete';
import autosize from 'autosize';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import constants from '../../shared/constants';
import { IUser } from '../../shared/interfaces';
import ReactTooltip from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import PrivateSidebarNavigation from '../PrivateSidebarNavigation/PrivateSidebarNavigation';

interface IState {
	showEditModal: boolean;
	biography: any;
	file: null;
	city: string;
	fileCodeImage: any;
	noImage: boolean;
}
interface IProps {
	currentUser: IUser;
	onUpdateUser: (id: number, data: any) => any;
	onCreateFile: (data: any) => any;
	file: any;
	updatedUser: any;
	onResetFile: () => any;
	roles: any;
	loading: boolean;
	tourDisabled?: boolean;
	restartTour?: () => any;
}

class MyOriginalsSidebar extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			showEditModal: false,
			biography: this.props.currentUser ? this.props.currentUser.biography : '',
			file: null,
			city: '',
			fileCodeImage: null,
			noImage: false,
		};

		this.onToggleShowEditModal = this.onToggleShowEditModal.bind(this);
		this.setUserInfo = this.setUserInfo.bind(this);
		this.removeImage = this.removeImage.bind(this);
	}

	componentDidMount() {
		autosize(document.querySelectorAll('textarea'));

		if(this.props.currentUser){
			this.setUserInfo(this.props.currentUser)
		}
	}

	setUserInfo(user: any) {
		const { city, fileCodeImage, biography } = user;

		this.setState({
			fileCodeImage,
			city,
			biography
		})
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.file !== prevProps.file && this.props.file){
			const file = this.props.file[0];

			this.setState({
				file,
				noImage: false,
				fileCodeImage: this.props.file[0]
			}, () => {
				localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, file.url);
			});
		}

		if(this.props.updatedUser !== prevProps.updatedUser && this.props.updatedUser){

			this.setState({
				showEditModal: false,
			})
		}

		if(this.props.currentUser !== prevProps.currentUser && this.props.currentUser){
			this.setUserInfo(this.props.currentUser);
		}
	}

	componentWillUnmount(): void {
		this.props.onResetFile();
	}

	onToggleShowEditModal(){
		const showEditModal = !this.state.showEditModal;

		this.setState({
			showEditModal
		})
	}

	handleUploadImage = (uploaded: any) => {
	    const data = new FormData();
	    data.append('file', uploaded[0]);

	    this.props.onCreateFile(data);
    };

    handleChangeAbout = (value: any) => {
        this.setState({
            biography: value
        });
	};

	handleChangeCity = (place: any) => {
		const { name } = place;

		if(name){
			this.setState({
				city: name
			})
		} else {
			alert('City not found');
		}
	};

	removeImage(){
		this.setState({
			fileCodeImage: null,
			file: null,
			noImage: true,
		}, () => {
			this.props.onResetFile();
		})
	}

	saveInfos = () => {
		const { file, biography, city, noImage } = this.state;

		const uid = this.props.currentUser.id;

		if(uid) {
			let data = {
				biography,
				city,
				fileCodeImage: '',
			};

			if(file){
				data = {
					...data,
					fileCodeImage: file['code'],
				}
			} else {
				if(!noImage)
					delete data.fileCodeImage;
			}

			this.props.onUpdateUser(uid, data);
		}
	};

	render () {

		const {
			showEditModal,
			fileCodeImage,
			biography,
			city
		} = this.state;
		const { currentUser, roles, tourDisabled, restartTour } = this.props;

		// console.log(roles);

		const rolesTpl = roles.map((role: any, index: number) => {
			return (
				<div key={index} className={classes['MyOriginalsSidebar-role']}>
					{ role }
				</div>
			)
		});

		return (
			<div className={classes.MyOriginalsSidebar}>
				<div className={classes['MyOriginalsSidebar-header']}>
					{
						currentUser && <UserCard user={currentUser} />
					}
					{ currentUser &&
						<div className={classes['MyOriginalsSidebar-actions']}>
							<Button
								type="edit-small-icon"
								clicked={this.onToggleShowEditModal}
								id="profile.edit.details"
								tooltip="profile.edit.details">
								<FormattedMessage id={'profile.edit.details'} />
							</Button>
							<NavLink
								target="_blank"
								className={classes['MyOriginalsSidebar-view-as-user']}
								to={`/user/${currentUser.id}`}
								data-tip>
								<ReactTooltip effect="solid">
									<FormattedMessage id={'profile.viewAsUser'} />
								</ReactTooltip>
							</NavLink>
							{
								!tourDisabled && restartTour &&
								<button
									data-tip
									data-for="tour-info"
									className={classes['MyOriginalsSidebar-tour-icon']}
									onClick={this.props.restartTour}>
									<ReactTooltip id="tour-info" effect="solid">
										<FormattedMessage id="label.tour.help" />
									</ReactTooltip>
								</button>
							}
						</div>
					}
				</div>
				<PrivateSidebarNavigation />
				{

					biography &&
	                    <div className={classes['MyOriginalsSidebar-box']}>

	                        <label><FormattedMessage id={'user.biography'}/></label>
							<div dangerouslySetInnerHTML={{ __html: biography}} />
	                    </div>
				}
				{
					roles.length > 0 &&
                    <div className={classes['MyOriginalsSidebar-box']}>
                        <label><FormattedMessage id={'user.roles'}/></label>
                        { rolesTpl }
                    </div>
				}
				{
		            showEditModal &&
	                    <Modal
		                    forceClose={true}
	                        title={<FormattedMessage id={'profile.edit.details.title'}/>}
	                        onModalCloseClicked={this.onToggleShowEditModal}>
							{
								fileCodeImage ?
									<div className={classes['MyOriginalsSidebar-edit-content-preview']}>
										<div>
											<CircleImage
												large
												styles={{
													margin: '0 auto 30px'
												}}
												url={fileCodeImage['url']} />
										</div>
										<div>
											<Button type="small" styles={{ margin: '10px auto 20px'}} clicked={this.removeImage}>
												<FormattedMessage id="general.remove" />
											</Button>
										</div>
									</div>
									:
									<UploadBox
										titleId={'upload.image.title'}
										accept={'image/jpeg, image/png'}
										onSelectedFiles={this.handleUploadImage}
										multiple={false}
										styles={{margin: '0 0 10px 0'}}/>
							}
							<div className={classes['MyOriginalsSidebar-edit-content-space']}>
								<CityAutocomplete
									value={city}
									onPlaceSelected={this.handleChangeCity}
									name="city"
									label="user.city" />
							</div>
							<div className={classes['MyOriginalsSidebar-edit-content-space']}>
								<EditorTextarea
									isPlainText={true}
									toolbarHidden={false}
									value={biography}
									autosize
									id="myoriginals-sidebar-textarea"
									labelId={'label.aboutYou'}
									placeholderId={'placeholder.about'}
									onChange={this.handleChangeAbout}/>
							</div>
							<div className={classes["MyOriginalsSidebar-edit-footer"]}>
								<Button clicked={this.saveInfos} disabled={this.props.loading} type="confirm">
									<FormattedMessage id={'profile.edit.details.save'}/>
								</Button>
							</div>
	                    </Modal>
	            }
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		file: state.fileState.file,
		loading: state.fileState.isStoring,
		updatedUser: state.userState.user,
		userLoading: state.userState.loading,
		userError: state.userState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onCreateFile: (data: any) => dispatch(actions.createFile(data)),
		onResetFile: () => dispatch(actions.resetFile()),
		onUpdateUser: (id: number, data: any) => dispatch(actions.updateUser(id, data)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( MyOriginalsSidebar );
