import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { IProject, IMedia } from '../../shared/interfaces';

interface IAction {
	id: number;
    type: string;
    list: Array<IProject>;
    project: IProject;
    error: string;
    added: boolean;
    updatedMedia: Array<IMedia>;
}

const INITIAL_STATE = {
    list: [],
    project: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
	created: false,
	updated: false,
	mediaAdded: false,
	mediaRemoved: false,
	updatedMedia: [],
};

const createProjectStart = (state = INITIAL_STATE) => {
	return updateObject(state, { project: null, updatedMedia: [], created: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createProjectSuccess = (state = INITIAL_STATE, action: IAction) => {
	const oldList: Array<IProject> = [...state.list];
	const updatedList = oldList.concat(action.project);

	return updateObject(state, { project: action.project, list: updatedList, created: true, isStoring: false });
};

const createProjectFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchProjectStart = (state = INITIAL_STATE) => {
	return updateObject(state, { project: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchProjectSuccess = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { project: action.project, updatedMedia: action.updatedMedia, isFetching: false });
};

const fetchProjectFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchProjectsStart = (state = INITIAL_STATE) => {
	return updateObject(state, { list: [], project: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchProjectsSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { list: action.list, isFetching: false });
};

const fetchProjectsFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchProjectsByUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { list: [], project: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchProjectsByUserSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { list: action.list, isFetching: false });
};

const fetchProjectsByUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateProjectStart = (state = INITIAL_STATE) => {
    return updateObject(state, { project: null, updatedMedia: [], isStoring: true, error: null, didInvalidate: false, updated: false, });
};

const updateProjectSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { project: action.project, isStoring: false, updated: true, });
};

const updateProjectFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const deleteProjectStart = (state = INITIAL_STATE) => {
    return updateObject(state, { isStoring: true, project: null, removed: null, error: null, didInvalidate: false });
};

const deleteProjectSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { removed: true, list: state.list.filter((item:IProject) => item.id !== action.id),  isStoring: false });
};

const deleteProjectFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, removed: false, didInvalidate: true, isStoring: false });
};

const addMediaToProjectStart = (state = INITIAL_STATE) => {
	return updateObject(state, { mediaAdded: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const addMediaToProjectSuccess = (state = INITIAL_STATE, action: IAction) => {
	const project = {
		// @ts-ignore
		...state.project,
		media: action.updatedMedia,
	};

	return updateObject(state, { mediaAdded: true, project, updatedMedia: action.updatedMedia, isStoring: false });
};

const addMediaToProjectFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const removeMediaFromProjectStart = (state = INITIAL_STATE) => {
	return updateObject(state, { mediaRemoved: false, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const removeMediaFromProjectSuccess = (state = INITIAL_STATE, action: IAction) => {
	const project = {
		// @ts-ignore
		...state.project,
		media: action.updatedMedia,
	};

	return updateObject(state, { updatedMedia: action.updatedMedia, project, mediaRemoved: true, isStoring: false });
};

const removeMediaFromProjectFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const orderMediaForProjectStart = (state = INITIAL_STATE) => {
	return updateObject(state, { isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const orderMediaForProjectSuccess = (state = INITIAL_STATE, action: IAction) => {
	const project = {
		// @ts-ignore
		...state.project,
		media: action.updatedMedia,
	};

	return updateObject(state, { updatedMedia: action.updatedMedia, project, isStoring: false });
};

const orderMediaForProjectFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const projectReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_PROJECT_START: return createProjectStart(state);
	    case actionTypes.CREATE_PROJECT_SUCCESS: return createProjectSuccess(state, action);
	    case actionTypes.CREATE_PROJECT_FAIL: return createProjectFail(state, action);
        // READ/FETCHING
	    case actionTypes.FETCH_PROJECT_START: return fetchProjectStart(state);
	    case actionTypes.FETCH_PROJECT_SUCCESS: return fetchProjectSuccess(state, action);
	    case actionTypes.FETCH_PROJECT_FAIL: return fetchProjectFail(state, action);
	    case actionTypes.FETCH_PROJECTS_START: return fetchProjectsStart(state);
	    case actionTypes.FETCH_PROJECTS_SUCCESS: return fetchProjectsSuccess(state, action);
	    case actionTypes.FETCH_PROJECTS_FAIL: return fetchProjectsFail(state, action);
	    case actionTypes.FETCH_PROJECTS_BY_USER_START: return fetchProjectsByUserStart(state);
	    case actionTypes.FETCH_PROJECTS_BY_USER_SUCCESS: return fetchProjectsByUserSuccess(state, action);
	    case actionTypes.FETCH_PROJECTS_BY_USER_FAIL: return fetchProjectsByUserFail(state, action);
        // UPDATE
        case actionTypes.UPDATE_PROJECT_START: return updateProjectStart(state);
        case actionTypes.UPDATE_PROJECT_SUCCESS: return updateProjectSuccess(state, action);
        case actionTypes.UPDATE_PROJECT_FAIL: return updateProjectFail(state, action);
        // DELETE
        case actionTypes.DELETE_PROJECT_START: return deleteProjectStart(state);
        case actionTypes.DELETE_PROJECT_SUCCESS: return deleteProjectSuccess(state, action);
        case actionTypes.DELETE_PROJECT_FAIL: return deleteProjectFail(state, action);
        // MEDIA
	    case actionTypes.CREATE_PROJECT_MEDIA_START: return addMediaToProjectStart(state);
	    case actionTypes.CREATE_PROJECT_MEDIA_SUCCESS: return addMediaToProjectSuccess(state, action);
	    case actionTypes.CREATE_PROJECT_MEDIA_FAIL: return addMediaToProjectFail(state, action);
	    case actionTypes.DELETE_PROJECT_MEDIA_START: return removeMediaFromProjectStart(state);
	    case actionTypes.DELETE_PROJECT_MEDIA_SUCCESS: return removeMediaFromProjectSuccess(state, action);
	    case actionTypes.DELETE_PROJECT_MEDIA_FAIL: return removeMediaFromProjectFail(state, action);
	    case actionTypes.CHANGE_MEDIA_ORDER_FOR_PROJECT_START: return orderMediaForProjectStart(state);
	    case actionTypes.CHANGE_MEDIA_ORDER_FOR_PROJECT_SUCCESS: return orderMediaForProjectSuccess(state, action);
	    case actionTypes.CHANGE_MEDIA_ORDER_FOR_PROJECT_FAIL: return orderMediaForProjectFail(state, action);

        default:
            return state;
    }
};

export default projectReducer;
