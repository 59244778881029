import React from 'react';
import classes from './Template.module.scss';

interface templateProps {
	sidebar?: any;
	children?: any;
	sidebarSize?: string;
	theme?: 'light' | 'dark';
	noPadding?: boolean;
}

const template = ({ sidebar, children, sidebarSize, theme, noPadding }: templateProps) => {
    return (
	    <div className={[
	    	classes.Template,
		    theme ? classes[`Template--${theme}`] : ''
	    ].join(' ')}>
		    {
		    	sidebar &&
				    <div className={[
				    	classes['Template-sidebar'],
					    sidebarSize ? classes[`Template-sidebar--${sidebarSize}`] : '',
				    ].join(' ')}>
					    { sidebar }
				    </div>
		    }
		    <div className={[classes['Template-content'], sidebar ? classes['Template-content--withSidebar'] : '', sidebarSize ? classes[`Template-content--withSidebar-${sidebarSize}`] : '', noPadding ? classes['Template-content--noPadding'] : ''].join(' ')}>
			    {
			    	children
			    }
		    </div>
	    </div>
    )
};

export default template;
