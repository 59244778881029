import React, { Component } from 'react';
import classes from './Footer.module.scss';
import Logo from '../../components/Logo/Logo';
import Socials from '../../components/Socials/Socials';
import {NavLink} from 'react-router-dom';
import { SPECIAL_ROUTES } from '../../shared/special-routes';
import partners from '../../assets/img/partners.svg';

interface IProps {
    currentRoute: string;
}

class footer extends Component<IProps> {

    render () {
        const socials = [
            {
                title: 'Facebook',
                name: 'fb',
                href: 'https://facebook.com/artchivio'
            },
            {
                title: 'Instagram',
                name: 'ig',
                href: 'https://www.instagram.com/artchivio/'
            },
            {
                title: 'Linkedin',
                name: 'in',
                href: 'https://www.linkedin.com/company/artchivio/'
            }
        ]

        let visibility = true;
        SPECIAL_ROUTES.map((route: any, index: number) => {
            if(SPECIAL_ROUTES[index].path === this.props.currentRoute) {
                if(SPECIAL_ROUTES[index].footer === false) {
                    visibility = false;
                }
            }
        });

        return (
            <div>
                { visibility &&
                    <div className={classes.Footer}>
                        <div className={classes["Footer-header"]}>
                            <Logo to="/" position="footer" />
                            <Socials items={socials} />
                        </div>
                        <div className={classes["Footer-content"]}>
                            <div className={classes['Footer-content-left']}>
                                <div className={classes['Footer-partners']}>
                                    <p>Supported by</p>
                                    <img src={partners} alt="Supported by" />
                                </div>
                            </div>
                            <div className={classes['Footer-content-right']}>
                                <p>Artchivio s.r.l.<br/>Via dei Barbieri 5, 00186 Roma - P.IVA e C.F. 14817111009</p>
                                <NavLink to="/privacy" className={classes['Footer-small']}>Privacy</NavLink>
                                {' | '}
                                <NavLink to="/privacy" className={classes['Footer-small']}>Cookies</NavLink>
                                <br/>
                                <p>Having trouble? Found something you think we could do better?</p>
                                <a href="mailto:hello@artchivio.net"
                                    className={classes['Footer-feedback']}>Write us here!</a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
};

export default footer;
