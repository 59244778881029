import * as actionTypes from './actionTypes';
import DistributionsService from '../../services/api/distributions';
import { IResponse, IDistribution, IError } from '../../shared/interfaces';

// READ/FETCH
export const fetchDistributionStart = () => {
    return {
        type: actionTypes.FETCH_DISTRIBUTION_START,
    }
};

export const fetchDistributionSuccess = (distribution: IDistribution) => {
    return {
        type: actionTypes.FETCH_DISTRIBUTION_SUCCESS,
        distribution
    }
};

export const fetchDistributionFail = (error: string) => {
    return {
        type: actionTypes.FETCH_DISTRIBUTION_FAIL,
        error,
    }
};

export const fetchDistribution = (id: number) => {
	return (dispatch: any) => {

		if(id)
			dispatch(fetchDistributionStart());

		DistributionsService
			.get(id)
			.then(
				(response: IResponse) => {
					dispatch(fetchDistributionSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchDistributionFail(errorMsg));
				}
			);
	}
};

export const fetchDistributionsStart = () => {
	return {
		type: actionTypes.FETCH_DISTRIBUTIONS_START,
	}
};

export const fetchDistributionsSuccess = (distributions: any) => {
	return {
		type: actionTypes.FETCH_DISTRIBUTIONS_SUCCESS,
		distributions
	}
};

export const fetchDistributionsFail = (error: string) => {
	return {
		type: actionTypes.FETCH_DISTRIBUTIONS_FAIL,
		error,
	}
};

export const fetchDistributions = () => {
	return (dispatch: any) => {
		dispatch(fetchDistributionsStart());

		DistributionsService
			.get()
            .then(
	            (response: IResponse) => {
		            dispatch(fetchDistributionsSuccess(response.data));
	            },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(fetchDistributionsFail(errorMsg));
	            }
            );
    }
};

export const fetchDistributionsForOriginalsFilterStart = () => {
	return {
		type: actionTypes.FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_START,
	}
};

export const fetchDistributionsForOriginalsFilterSuccess = (distributions: IDistribution) => {
	return {
		type: actionTypes.FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_SUCCESS,
		distributions
	}
};

export const fetchDistributionsForOriginalsFilterFail = (error: string) => {
	return {
		type: actionTypes.FETCH_DISTRIBUTIONS_FOR_ORIGINALS_FILTER_FAIL,
		error,
	}
};

export const fetchDistributionsForOriginalsFilter = () => {
	return (dispatch: any) => {
		dispatch(fetchDistributionsForOriginalsFilterStart());

		DistributionsService
			.getForOriginalsFilter()
			.then(
				(response: IResponse) => {
					dispatch(fetchDistributionsForOriginalsFilterSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchDistributionsForOriginalsFilterFail(errorMsg));
				}
			);
	}
};
