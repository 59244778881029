import React from 'react';
import classes from './Curriculum.module.scss';
import {FormattedMessage} from 'react-intl';
import { Element } from 'react-scroll';
import CurriculumItem from './CurriculumItem/CurriculumItem';
import Loader from '../Loader/Loader';
import { ICurriculum } from '../../shared/interfaces';

interface curriculumProps {
	add: () => void;
	setData: (field: string, value: any, id: string) => void;
	removeData: (id: number) => void;
	closeData: (id: number) => void;
	title?: object;
	list?: Array<ICurriculum>;
    clicked?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    styles?: object;
    disabled?: boolean;
    type?: string;
    loading?: boolean;
	readOnly: boolean;
}

const curriculum = ({ title, list, setData, add, removeData, closeData, loading, readOnly }: curriculumProps) => {

	const listTpl = list && list.map((item: any, index: number) => {
		return (
			<CurriculumItem
				closeData={closeData}
				removeData={removeData}
				setData={setData}
				current={item.dateEnd === null}
				key={index}
				readOnly={readOnly}
				item={item} />
		);
	});

    return (
    	<div className={classes.Curriculum}>
			<div className={classes['Curriculum-header']}>
				{
					title &&
						<h3 className={classes['Curriculum-title']}>{ title }</h3>
				}
				{
					!readOnly &&
					<div className="addcv-step">
	                    <button onClick={add} className={classes['Curriculum-add']}>
	                        <FormattedMessage id={'curriculum.add'} />
	                        <div className={classes['Curriculum-add-icon']}>+</div>
	                    </button>
					</div>
				}
			</div>
		    {
				loading ?
					<div className={classes['Curriculum-loading']}>
				    	<Loader />
					</div>
				:
					<Element name="CurriculumList" className={classes['Curriculum-list']}>
						<div id={"CurriculumListWrapper"} className={classes['Curriculum-list']}>
							{ listTpl }
						</div>
					</Element>
		    }
    	</div>
    );
};

export default curriculum;
