import * as actionTypes from './actionTypes';
import RegistrationsService from '../../services/api/sessions';
import constants from '../../shared/constants';
import { resetSession } from '../../shared/helpers';
import { IError, IResponse } from '../../shared/interfaces';
import SessionsService from '../../services/api/sessions';

// SESSION
export const sessionStart = () => {
	resetSession();

    return {
        type: actionTypes.SESSION_START,
    }
};

export const sessionSuccess = (session: object) => {
    return {
        type: actionTypes.SESSION_SUCCESS,
        session
    }
};

export const sessionFail = (error: string) => {
    return {
        type: actionTypes.SESSION_FAIL,
        error,
    }
};

export const session = (data: object) => {
    return (dispatch: any) => {
        dispatch(sessionStart());

        RegistrationsService
            .create(data)
            .then(
                (response: IResponse) => {
                	const { data } = response;

	                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN, response.data.token);
	                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_ID, response.data.uid);
	                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_NAME, response.data.name);
	                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME, response.data.surname);
	                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_EMAIL, response.data.email);

                    dispatch(sessionSuccess(data));
                },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(sessionFail(errorMsg));
	            }
            );
    }
};

// FETCH ME
export const fetchMeStart = () => {
	return {
		type: actionTypes.FETCH_ME_START,
	}
};

export const fetchMeSuccess = (session: object) => {
	return {
		type: actionTypes.FETCH_ME_SUCCESS,
		session
	}
};

export const fetchMeFail = (error: string) => {
	return {
		type: actionTypes.FETCH_ME_FAIL,
		error,
	}
};

export const fetchMe = () => {
	return (dispatch: any) => {
		dispatch(fetchMeStart());

		SessionsService
			.me()
			.then(
				(response: IResponse) => {

					dispatch(fetchMeSuccess(response.data));
				},
				(error: IError) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchMeFail(errorMsg));
				}
			);
	}
};
