import React, { Fragment } from 'react';
import classes from './MainNavigationItem.module.scss';
import { NavLink } from "react-router-dom";

interface IProps {
    label: object;
    to?: string;
    hidden?: boolean;
    name?: string;
    disabled?: boolean;
    clicked?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    highlight?: boolean;
    tourId?: string;
}

const MainNavigationItem = (props: IProps) => {

    const { label, to, hidden, disabled, name, clicked, highlight, tourId } = props;

    const linkClass = [
        classes["MainNavigationItem-link"],
        disabled ? classes["is-disabled"] : '',
        name ? classes[`MainNavigationItem-link--${name}`] : '',

    ].join(' ');

    const itemContent = (
            tourId ? <div className={tourId}>{ label }</div>
            :
            <Fragment>
                { label }
            </Fragment>
    )


    return  (
	    !hidden ?
		    <li className={[classes.MainNavigationItem, highlight ? classes["is-highlighted"] : ''].join(' ')}>
			    <i />
			    {
                    !clicked && to ?
                        <NavLink exact to={ !hidden ? to : '#'} className={linkClass} activeClassName={classes["is-active"]}>
                            { itemContent }
                        </NavLink> :
                        <button disabled={disabled} onClick={clicked} className={linkClass}>
                            { itemContent }
                        </button>
			    }
		    </li> : null
    )
};

export default MainNavigationItem
