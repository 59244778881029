export const SPECIAL_ROUTES = [
    {
        path: '/',
        header: true,
        headerPartial: true,
        headerTransparent: true,
        sidebar: false,
        footer: true
    },
    {
        path: '/registration',
        header: true,
        headerPartial: true,
        headerTransparent: true,
        sidebar: false,
        footer: true   
    },
    {
        path: '/registration/',
        header: true,
        headerPartial: true,
        headerTransparent: true,
        sidebar: false,
        footer: true   
    },
    {
        path: 'registration',
        header: true,
        headerPartial: true,
        headerTransparent: true,
        sidebar: false,
        footer: true   
    },
    {
        path: '/activation',
        header: true,
        headerPartial: true,
        sidebar: false,
        footer: true   
    },
    {
        path: '/activation/',
        header: true,
        headerPartial: true,
        sidebar: false,
        footer: true   
    },
    {
        path: 'activation',
        header: true,
        headerPartial: true,
        sidebar: false,
        footer: true   
    },
    {
        path: '/privacy',
        header: true,
        headerPartial: true,
        sidebar: false,
        footer: true   
    },
    {
        path: '/scuolaholden',
        header: false,
        headerPartial: false,
        headerTransparent: false,
        sidebar: false,
        footer: true
    },
    {
        path: '/scuolaholden/',
        header: false,
        headerPartial: false,
        headerTransparent: false,
        sidebar: false,
        footer: true
    },
]
