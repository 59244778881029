import React from 'react';
import classes from './Socials.module.scss';
import SocialItem from './SocialItem/SocialItem';
import {ISocialItem} from "../../shared/interfaces";

interface IProps {
    items: Array<ISocialItem>;
}

const Socials = (props: IProps) => {

    const socialTpl = props.items.map((item: ISocialItem, index: number) => {
        return <SocialItem key={index} title={item.title} name={item.name} href={item.href}>{item.title}</SocialItem>
    });


    return (
        <div className={classes.Socials}>
            {
                socialTpl
            }
        </div>
    )
}

export default Socials
