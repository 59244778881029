import React, { Component } from 'react';
import classes from './MainNavigation.module.scss';
import {FormattedMessage} from "react-intl";
import MainNavigationItem from './MainNavigationItem/MainNavigationItem';
import { INavigationItem, INotification, IUser } from '../../shared/interfaces';
import OffCanvasSidebar from "../../containers/OffCanvasSidebar/OffCanvasSidebar";
import Backdrop from "../Backdrop/Backdrop";
import Signin from "../Signin/Signin";
import LoggedUserNavigator from '../LoggedUserNavigator/LoggedUserNavigator';
import Notifications from '../Notifications/Notifications';
import * as actions from '../../store/actions';
import * as routes from "../../shared/routes";
import { connect } from 'react-redux';
import constants from '../../shared/constants';

interface IProps {
	onGetNotifications(userId: number): Array<INotification>;
	onGetUnreadMessagesFound(userId: number): boolean;
	onSetAllReadNotification(userId: number): Array<INotification>;
	currentUser: IUser;
	unreadMessagesFound: boolean;
    onToggleOffCanvas: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    showOffCanvas?: boolean;
    items: Array<INavigationItem>;
    code?: string;
    history: any;
	isAuthenticated: boolean;
	notifications: Array<INotification>;
	loadingNotifications: boolean;
}

interface IState {
	showNotifications: boolean;
	notifications: Array<INotification>;
}

class MainNavigation extends Component<IProps, IState> {
	private checkNotificationsInterval: any;
	private checkUnreadMessagesInterval: any;
	private privateMounted: boolean = false;

	constructor(props: IProps){
		super(props);

		this.state = {
			showNotifications: false,
			notifications: []
		};

		this.handleToggleNotificationsVisibility = this.handleToggleNotificationsVisibility.bind(this);
	}

	getNotificationsAndMessages = () => {
		const userId = this.props.currentUser && this.props.currentUser.id;

		if(userId && !constants.NOTIFICATIONS_DISABLED) {
			//console.log('Ready for notifications and messages!');
			this.props.onGetNotifications(+userId);
			this.props.onGetUnreadMessagesFound(+userId);

			this.checkNotificationsInterval = setInterval(() => {
				if (userId) {
					this.props.onGetNotifications(+userId);
				}
			}, constants.NOTIFICATION_FETCH_TIME);

			this.checkUnreadMessagesInterval = setInterval(() => {
				if (userId) {
					this.props.onGetUnreadMessagesFound(+userId);
				}
			}, constants.UNREAD_MESSAGES_FETCH_TIME);

			this.privateMounted = true;
		}
	};

	componentWillUnmount(): void {
		clearInterval(this.checkNotificationsInterval);
		clearInterval(this.checkUnreadMessagesInterval);
	}

	handleToggleNotificationsVisibility = () => {
		const showNotifications = !this.state.showNotifications;
		let userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);

		if(showNotifications && userId){
			this.props.onSetAllReadNotification(+userId)
		}

		this.setState({ showNotifications });
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(prevProps.showOffCanvas !== this.props.showOffCanvas){
			this.setState({ showNotifications: false });
		}
		if(prevProps.notifications !== this.props.notifications){
			this.setState({ notifications: this.props.notifications });
		}
		if(prevProps.showOffCanvas !== this.props.showOffCanvas){
			this.setState({ showNotifications: false })
		}
		if(this.props.currentUser !== prevProps.currentUser && this.props.currentUser){
			if(!this.privateMounted)
				this.getNotificationsAndMessages();
		}
	}

	render(){

		const {
			showOffCanvas,
			onToggleOffCanvas,
			history,
			isAuthenticated,
			items,
			loadingNotifications,
			unreadMessagesFound,
		} = this.props;
		const { showNotifications, notifications } = this.state;

		const itemsTpl = items.map((item: INavigationItem, index: number) => {
			const highlight = item.to === routes.MESSAGES && unreadMessagesFound;

			return (
				<MainNavigationItem
					key={index}
					highlight={highlight}
					label={item.label}
					disabled={item.disabled}
					clicked={item.clicked}
					to={item.to}
					hidden={item.hasOwnProperty('visible') && !item.visible}
					name={item.name}
					tourId={item.tourId}
					/>
			)
		});

		const loggedUserTpl = isAuthenticated &&
                <LoggedUserNavigator history={history} />;

		const newNotificationsCounter = notifications.filter((notification: INotification) => notification.readAt === null).length;

		return (
			<div className={classes.MainNavigationContainer}>
				<ul className={[classes['MainNavigation'], isAuthenticated ? classes['MainNavigation-logged'] : ''].join(' ')}>
					{
						itemsTpl
					}
					{
						loggedUserTpl
					}
					{ newNotificationsCounter > 0 &&
						<div className={classes['MainNavigation-notification-count']}>
							{ newNotificationsCounter }
						</div>
					}
					<MainNavigationItem label={<FormattedMessage id={'navigation.notifications'} />} clicked={this.handleToggleNotificationsVisibility} name={newNotificationsCounter > 0 ? 'Notifications-active' : 'Notifications'}/>
				</ul>
				{
					showOffCanvas &&
					!isAuthenticated &&
                    <Backdrop show={showOffCanvas}>
                        <OffCanvasSidebar
                            onOffCanvasSidebarCloseClicked={onToggleOffCanvas}>
                            <Signin
                                history={history}
                                onToggleOffCanvasVisibilityClicked={onToggleOffCanvas}/>
                        </OffCanvasSidebar>
                    </Backdrop>
				}
				{
					showNotifications &&
					isAuthenticated &&
	                    <Backdrop show={showNotifications}>
                            <OffCanvasSidebar
								transparent
                                onOffCanvasSidebarCloseClicked={onToggleOffCanvas}>
	                            <Notifications
		                            loading={loadingNotifications}
		                            list={notifications}
                                    history={history} />
                            </OffCanvasSidebar>
	                    </Backdrop>
				}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		notifications: state.notificationState.list,
		loadingNotifications: state.notificationState.isFetching,
		error: state.notificationState.error,
		unreadMessagesFound: state.messageState.unreadMessagesFound,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetNotifications: (userId: number) => dispatch(actions.fetchNotifications(userId)),
		onGetUnreadMessagesFound: (userId: number) => dispatch(actions.fetchUnreadMessagesByUser(userId)),
		onSetAllReadNotification: (userId: number) => dispatch(actions.setAllReadNotificationByUserId(userId))
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( MainNavigation );
