import React, {Component, Fragment} from 'react';
import classes from './Messages.module.scss';
import * as actions from '../../store/actions';
import moment from 'moment';
import { connect } from 'react-redux';
import * as Scroll from 'react-scroll';
import { IInterlocutorMessage, IMessage, ISelect, IUser } from '../../shared/interfaces';
import * as ReactGA from '../../GA';
import constants from '../../shared/constants';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import CircleImage from '../CircleImage/CircleImage';
import UsersAutocomplete from '../UsersAutocomplete/UsersAutocomplete';
import messagesGif from '../../assets/img/messages.gif';
import { FormattedMessage } from 'react-intl';

const scroll = Scroll.animateScroll;

const HOURS_OFFSET = 0;

interface IProps {
	onGetCurrentUser(): IUser;
	onGetInterlocutors(): Array<IInterlocutorMessage>;
	onGetConversation(userSenderId: number, userDestinationId: number, isNew: boolean, limit: number, offset: number, page: number): Array<IMessage>;
	onSendMessage(data: IMessage): IMessage;
	onGetUsersByFilters(type: string, value: string): Array<IUser>;
	onSetReadMessage(id: number): boolean;
	onResetCurrentConversation(): void;
	isLastPage: boolean;
	loadingInterlocutors: boolean;
	currentUser: IUser;
	history?: any;
	interlocutors: Array<IInterlocutorMessage>;
	currentConversation: Array<IMessage>;
	loadingConversation: boolean;
	lastMessage: IMessage;
	isSendingMessage: boolean;
	users: Array<IUser>;
	usersLoading: boolean;
	usersError: boolean;
}

interface IState {
	currentUserId: number;
	currentUserDestinationId: number;
	message: string;
	suggestions: Array<ISelect>;
	newConversations: Array<ISelect>;
	page: number;
	waitConversation: boolean;
}

class Messages extends Component<IProps, IState> {
	private showConversationInterval: any;
	private showInterlocutorsInterval: any;

	state = {
		currentUserId: -1,
		currentUserDestinationId: -1,
		message: '',
		suggestions: [],
		newConversations: [],
		page: 1,
		waitConversation: false,
	};

	componentDidMount(): void {
		window.scrollTo(0, 0);
		ReactGA.pageview('/messages');

		this.getCurrentUser();
	}

	getCurrentUser(){
		this.props.onGetCurrentUser();
	}

	handleShowConversation = (userDestinationId: number, messageId?: number, page?: number) => {
		clearInterval(this.showConversationInterval);

		this.setState({
			currentUserDestinationId: userDestinationId,
			page: page || 1,
			waitConversation: true,
		}, () => {
			this.props.onGetConversation(this.state.currentUserId, userDestinationId, true, this.state.page*constants.DEFAULT_PAGINATION.limit, constants.DEFAULT_PAGINATION.offset, this.state.page);

			if(messageId){
				this.props.onSetReadMessage(messageId);
			}

			this.showConversationInterval =
				setInterval(() =>
						this.props.onGetConversation(this.state.currentUserId, userDestinationId, false, this.state.page*constants.DEFAULT_PAGINATION.limit, constants.DEFAULT_PAGINATION.offset, this.state.page),
					constants.CONVERSATION_FETCH_TIME);

			scroll.scrollToBottom({
				containerId: "Conversation"
			});
		});
	};

	handleShowMoreMessages = () => {
		clearInterval(this.showConversationInterval);
		const { page, currentUserDestinationId } = this.state;


		this.setState({
			page: page + 1,
		}, () => {
			this.props.onGetConversation(this.state.currentUserId, currentUserDestinationId, true, page*this.state.page*constants.DEFAULT_PAGINATION.limit, 0, this.state.page);

			scroll.scrollToTop({
				containerId: "Conversation"
			});

			this.showConversationInterval =
				setInterval(() =>
						this.props.onGetConversation(this.state.currentUserId, currentUserDestinationId, false, page*constants.DEFAULT_PAGINATION.limit, constants.DEFAULT_PAGINATION.offset, this.state.page),
					constants.CONVERSATION_FETCH_TIME);
		})
	};

	onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.keyCode === 13 && !this.props.isSendingMessage) {
			const message = e.currentTarget.value;

			this.setState({
				message
			}, () => {
				this.onSendMessageClicked();
			})
		}
	};

	handleMessageChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const message = ev.currentTarget.value;

		this.setState({
			message
		})
	};

	onSendMessageClicked = () => {
		const { message, currentUserDestinationId, currentUserId } = this.state;

		if(message && currentUserDestinationId && currentUserId){
			this.props.onSendMessage({
				userDestinationId: +currentUserDestinationId,
				userSenderId: +currentUserId,
				message,
			})
		}
	};

	onSearchUser = (key: string) => {
		if(key.length > 2){
			this.props.onGetUsersByFilters('name', key);
		} else {
			if(this.state.suggestions.length){
				this.setState({
					suggestions: []
				})
			}
		}
	};

	handleChangeUsers = (values: Array<ISelect>) => {
		let newConversations: Array<ISelect> = [...this.state.newConversations];

		if(values && values.length){
			const { interlocutors } = this.props;

			const conversationFound: IInterlocutorMessage = interlocutors.filter((item: IInterlocutorMessage) => +item.userId === +values[0].value)[0];

			if(conversationFound){
				this.handleShowConversation(conversationFound.userId)
			} else {
				newConversations = newConversations.concat(values);

				this.setState({
					newConversations,
					currentUserDestinationId: +values[0].value,
				}, () => {
					this.handleShowConversation(+this.state.currentUserDestinationId)
				})
			}

			scroll.scrollToBottom({
				containerId: "Conversation"
			});
		}
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		// After finding current user we can call Interlocutors EP
		if(prevProps.currentUser !== this.props.currentUser && this.props.currentUser) {
			this.setState({
				currentUserId: this.props.currentUser.id,
			}, () => {
				this.props.onGetInterlocutors();
				this.showInterlocutorsInterval = setInterval(() => this.props.onGetInterlocutors(), constants.CONVERSATION_INTERLOCUTORS_FETCH_TIME);
			});
		}

		// For searching users
		if(prevProps.users !== this.props.users && this.props.users.length) {
			let idsToExclude: Array<number> = [this.state.currentUserId];

			const filteredUsers = this.props.users.filter((user: IUser) => {
				return idsToExclude.indexOf(user.id) === -1;
			});

			const suggestions = filteredUsers.map((user: IUser) => {
				return {
					value: user.id.toString(),
					label: user.name + ' ' + user.surname,
				}
			});

			this.setState({
				suggestions
			})
		}

		if(prevProps.lastMessage !== this.props.lastMessage && !this.props.isSendingMessage) {
			const { page, currentUserDestinationId } = this.state;
			
			if(this.props.lastMessage &&
				this.props.lastMessage.hasOwnProperty('id') &&
				this.props.lastMessage.id &&
				this.props.lastMessage.userSenderId !== this.state.currentUserId)
				this.props.onSetReadMessage(+this.props.lastMessage.id);

			this.setState({
				message: '',
				newConversations: this.state.newConversations.filter((item: ISelect) => +item.value !== this.props.lastMessage.userDestinationId)
			}, () => {

				this.props.onGetInterlocutors();

				scroll.scrollToBottom({
					containerId: "Conversation"
				});

				this.props.onGetConversation(this.state.currentUserId, currentUserDestinationId, false, page*constants.DEFAULT_PAGINATION.limit, constants.DEFAULT_PAGINATION.offset, this.state.page);
			})
		}

		if(prevProps.currentConversation !== this.props.currentConversation &&
			this.props.currentConversation){
			setTimeout(() => {
				this.setState({
					waitConversation: false,
				})
			}, 1000);

			if(this.props.currentConversation.length && prevProps.currentConversation.length && this.props.currentConversation[this.props.currentConversation.length - 1].id !== prevProps.currentConversation[prevProps.currentConversation.length - 1].id){

				const lastMessageId = this.props.currentConversation && this.props.currentConversation[this.props.currentConversation.length - 1]  && this.props.currentConversation[this.props.currentConversation.length - 1].id;

				if(lastMessageId && this.props.currentConversation[this.props.currentConversation.length - 1].readAt === null && this.props.currentConversation[this.props.currentConversation.length - 1].userSenderId !== this.props.currentUser.id)
					this.props.onSetReadMessage(+lastMessageId);

				scroll.scrollToBottom({
					containerId: "Conversation"
				});
			}
		}

		if(this.state.waitConversation !== prevState.waitConversation && !this.state.waitConversation){
			scroll.scrollToBottom({
				containerId: "Conversation"
			});
		}

		if(prevState.currentUserDestinationId !== this.state.currentUserDestinationId){
			if(this.state.currentUserDestinationId) {
				scroll.scrollToBottom({
					containerId: "Conversation"
				});
			}
		}
	}

	componentWillUnmount(): void {
		clearInterval(this.showConversationInterval);
		clearInterval(this.showInterlocutorsInterval);

		this.props.onResetCurrentConversation();
	}

	render () {
		const {
			currentConversation,
			isSendingMessage,
			usersLoading,
			isLastPage,
			interlocutors,
		} = this.props;
		const {
			currentUserId,
			currentUserDestinationId,
			message,
			suggestions,
			newConversations,
			waitConversation
		} = this.state;

		let newConversationsTpl = null;

		if(newConversations.length){
			newConversationsTpl = newConversations.map((item: ISelect) => {
				return (
					<button
						disabled={waitConversation}
						onClick={() => this.handleShowConversation(+item.value)}
						key={+item.value}
						className={[classes.Interlocutor, +item.value === +currentUserDestinationId ? classes['is-active'] : ''].join(' ')}>
						<CircleImage
							small
							fullName={item.label} />
						<span className={classes['Interlocutor-preview']} />
					</button>
				)
			})
		}

		let interlocutorsTpl = null;

		if(interlocutors.length){
			interlocutorsTpl = interlocutors.map((item: IInterlocutorMessage) => {

				return (
					<button
						disabled={waitConversation}
						onClick={() => this.handleShowConversation(+item.userId, +item.id)}
						key={item.id}
						className={[classes.Interlocutor, +item.userId === +currentUserDestinationId ? classes['is-active'] : ''].join(' ')}>
						{
							item.readAt === null &&
							item.userSender !== currentUserId &&
                                <div className={classes['Interlocutor-new']} />
						}
						<CircleImage
							small
							url={item.userFile ? item.userFile.url : ''}
							fullName={item.name + ' ' + item.surname} />
						<div className={classes['Interlocutor-preview']}>
							<span className={classes['Interlocutor-preview-txt']}>
								{
									item.message
								}
							</span>
							<span className={classes['Interlocutor-preview-date']}>
								{
									moment().add(HOURS_OFFSET, 'hours').format("MMM D YY") == moment(item.createdAt).add(HOURS_OFFSET, 'hours').format('MMM D YY') ?
										'Today ' + moment(item.createdAt).add(HOURS_OFFSET, 'hours').format('HH:MM')
									:
										moment(item.createdAt).add(HOURS_OFFSET, 'hours').format('D MMM YY HH:MM')

								}
							</span>
						</div>
					</button>
				)
			})
		}

		let currentConversationTpl = null;
		let lastMessageDate: string;
		let newDate = false;

		if(currentConversation.length && !waitConversation){
			currentConversationTpl = currentConversation.map((item: IMessage, index: number) => {
				if(index === 0){
					newDate = true;
					lastMessageDate = moment(item.createdAt).add(HOURS_OFFSET, 'hours').format('D MMMM');
				} else {
					if(moment(item.createdAt).add(HOURS_OFFSET, 'hours').format('D MMMM') === lastMessageDate) {
						newDate = false;
					} else {
						newDate = true;
						lastMessageDate = moment(item.createdAt).add(HOURS_OFFSET, 'hours').format('D MMMM');
					}
				}

				let dateToShow = lastMessageDate;

				switch (lastMessageDate) {
					case moment().add(HOURS_OFFSET, 'hours').format('D MMMM'): dateToShow = 'Today';
						break;
					case moment().add(HOURS_OFFSET, 'hours').add(-1, 'days').format('D MMMM'): dateToShow = 'Yesterday';
						break;
					default: dateToShow = lastMessageDate;
				}

				return (
					<Fragment
						key={item.id}>
						{
							newDate &&
	                            <div className={classes.MessageDate}>
									<span>{ dateToShow }</span>
	                            </div>
						}
						<div
							id={`#conversation-item-${index}`}
							className={[
								classes.Message,
								currentUserId === item.userSenderId ? classes['Message--sender'] : classes['Message--destination']
							].join(' ')}>
							<span>
								{ item.message }
							</span>
						</div>
					</Fragment>
				)
			})
		}

		return (
			<div className={classes.Messages}>
				<div className={classes.Interlocutors}>
					<div className={classes['Interlocutors-search']}>
						<UsersAutocomplete
							isSingleValue={true}
							loading={usersLoading}
							selected={[]}
							suggestions={suggestions}
							onSubmit={this.handleChangeUsers}
							onInputChanged={this.onSearchUser}/>
					</div>
					{
						newConversationsTpl
					}
					{
						interlocutorsTpl
					}
				</div>
				<div className={classes.Conversation}>
					<div className={classes['Conversation-content']} id={'Conversation'} style={{backgroundImage: `url('${currentUserDestinationId < 1 ? messagesGif : ''}')`}}>
						{
							!waitConversation &&
							currentUserDestinationId > 0 &&
							!isLastPage &&
								<div className={classes['Conversation-more']}>
									<Button type={'edit-small'} clicked={this.handleShowMoreMessages}>
										{ 'Show more messages' }
									</Button>
								</div>
						}
						{
							waitConversation ?
								<div className={classes['Conversation-loading']}>
									<Loader />
								</div>
								:
								currentConversationTpl
						}
						{
							currentUserDestinationId < 1 &&
								<div className={classes['Conversation-alert']}>
									<FormattedMessage id="messages.selectConversation" />
								</div>
						}
					</div>
					{
						currentUserDestinationId > 0 &&
	                        <div className={classes['Conversation-actions']}>
	                            <input
		                            className={classes['Conversation-input']}
		                            onKeyDown={this.onPressEnter}
		                            onChange={this.handleMessageChanged}
		                            type={'text'} value={message} />
	                            <Button disabled={isSendingMessage} clicked={this.onSendMessageClicked}>{ 'Send' }</Button>
	                        </div>
					}
				</div>
			</div>
		)
    }
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		interlocutors: state.messageState.interlocutors,
		lastMessage: state.messageState.message,
		currentConversation: state.messageState.currentConversation,
		isLastPage: state.messageState.isLastPage,
		loadingInterlocutors: state.messageState.isFetchingInterlocutors,
		loadingConversation: state.messageState.isFetchingConversation,
		isSendingMessage: state.messageState.isSending,
		users: state.userState.users,
		usersLoading: state.userState.isFetching,
		usersError: state.userState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSendMessage: (data: IMessage) => dispatch(actions.createMessage(data)),
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onGetInterlocutors: () => dispatch(actions.fetchInterlocutors()),
		onGetConversation: (userSenderId: number, userDestinationId: number, isNew: boolean, limit: number, offset: number, page: number) => dispatch(actions.fetchConversation(userSenderId, userDestinationId, isNew, limit, offset, page)),
		onGetUsersByFilters: (type: string, value: string) => dispatch(actions.fetchUsersByFilters(type, value)),
		onSetReadMessage: (id: number) => dispatch(actions.setReadMessage(id)),
		onResetCurrentConversation: () => dispatch(actions.resetConversation()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Messages );
