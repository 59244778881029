import * as actionTypes from './actionTypes';
import CategoriesService from '../../services/api/categories';
import { IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchCategoryStart = () => {
    return {
        type: actionTypes.FETCH_CATEGORY_START,
    }
};

export const fetchCategorySuccess = (category: object) => {
    return {
        type: actionTypes.FETCH_CATEGORY_SUCCESS,
        category
    }
};

export const fetchCategoryFail = (error: string) => {
    return {
        type: actionTypes.FETCH_CATEGORY_FAIL,
        error,
    }
};

export const fetchCategory = (id: number) => {
	return (dispatch: any) => {

		if(id)
			dispatch(fetchCategoryStart());

		CategoriesService
			.get(id)
			.then(
				(response: IResponse) => {
					dispatch(fetchCategorySuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}
					dispatch(fetchCategoryFail(errorMsg));
				}
			);
	}
};

export const fetchCategoriesStart = () => {
	return {
		type: actionTypes.FETCH_CATEGORIES_START,
	}
};

export const fetchCategoriesSuccess = (categories: any) => {
	return {
		type: actionTypes.FETCH_CATEGORIES_SUCCESS,
		categories
	}
};

export const fetchCategoriesFail = (error: string) => {
	return {
		type: actionTypes.FETCH_CATEGORIES_FAIL,
		error,
	}
};

export const fetchCategories = () => {
	return (dispatch: any) => {
		dispatch(fetchCategoriesStart());

		CategoriesService
			.get()
            .then(
	            (response: IResponse) => {
		            dispatch(fetchCategoriesSuccess(response.data));
	            },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }
	                dispatch(fetchCategoriesFail(errorMsg));
                }
            );
    }
};
