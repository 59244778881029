import React, {Component, Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import * as routes from "../../shared/routes";
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import moment from 'moment';
import { connect } from 'react-redux';
import classes from './User.module.scss';
import Template from '../../containers/Template/Template';
import Curriculum from '../Curriculum/Curriculum';
import Projects from '../Projects/Projects';
import Loader from '../Loader/Loader';
import { countWordInArray } from '../../shared/helpers';
import UserSidebar from '../UserSidebar/UserSidebar';
import { IProject } from '../../shared/interfaces';

interface IProps {
	onGetCurrentUser(): object;
	onGetUserById(userId: number): object;
	onGetExperiencesList( userId: number ): object;
	onDeleteExperience( id: string ): any;
	onDeleteProject( id: string ): any;
	onGetProjectsByUserId(userId: number, online: boolean): Array<IProject>;
	experienceRemoved: boolean;
	experiences: any;
	profile: any;
	projects: any;
	history: any;
	loadingProjects: boolean;
	loading: boolean;
	projectRemoved: boolean;
	loadingExperiences: boolean;
	match: any;
	errorUser: any;
}

interface IState {
	projects: any;
	experiences: any;
	lastExperienceRemovedId: string | null;
	addWorkNumber: number;
	projectsLoading: boolean;
	experiencesLoading: boolean;
	projectIdToRemove: string | null;
	showProjectDeleteDialog: boolean;
	showExperienceDeleteDialog: boolean;
	roles: any;
	readOnly: boolean;
	profile: any;
}

class User extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			showProjectDeleteDialog: false,
			showExperienceDeleteDialog: false,
			projects: [],
			experiences: [],
			lastExperienceRemovedId: null,
			addWorkNumber: 3,
			projectsLoading: true,
			experiencesLoading: true,
			projectIdToRemove: null,
			roles: [],
			readOnly: false,
			profile: null,
		};

		this.getExperiences = this.getExperiences.bind(this);
		this.getProjects = this.getProjects.bind(this);
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);

		let userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);

		const {
			match: {
				params: { user }
			}
		} = this.props;

		if(user) {
			ReactGA.pageview(`/user/${user}`);

			userId = user;

			this.setState({
				readOnly: true,
			}, () => {
				if(userId) {
					this.getUser(+userId)
				}
			});
		} else {
			this.props.history.push('/profile')
		}

		this.props.onGetCurrentUser();

		this.setState({
			projectsLoading: this.state.projects.length > 0
		});
	}

	getUser(userId: number){
		this.getExperiences(userId);
		this.getProjects(userId);

		this.props.onGetUserById(userId);
	}

	getExperiences(userId: number) {
		this.props.onGetExperiencesList(userId);
	}

	getProjects(userId: number) {
		this.props.onGetProjectsByUserId(userId, true);
	}

	componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
		if(nextProps.experiences !== this.props.experiences){
			//console.log(nextProps.experiences);
			const experiences = nextProps.experiences.map((cv: any) => {
				return {
					...cv,
					dateStart: moment(cv.dateStart).format('YYYY-MM-DD'),
					dateEnd: moment(cv.dateEnd).format('YYYY-MM-DD'),
				}
			});

			this.setState({
				experiences
			})
		}

		if(nextProps.profile !== this.props.profile && nextProps.profile){

			this.setState({
				profile: nextProps.profile
			});
		}

		if(nextProps.projects !== this.props.projects){
			let currentRoles: any = [];

			const projects = nextProps.projects.map((project: IProject) => {
				if(project.projectRole && project.status === 'ONLINE'){
					currentRoles.push(project.projectRole)
				}

				return {
					project
				};
			});

			currentRoles = countWordInArray(currentRoles);

			//console.log(countWordInArray(currentRoles));

			let roles: any = [];

			currentRoles.forEach((role: any) => {
				roles.push(role.value);
			});

			const addWorkNumber = this.state.addWorkNumber - projects.length;

			this.setState({
				projects,
				roles,
				addWorkNumber: addWorkNumber > 0 ? addWorkNumber : 0,
			})
		}

		if(this.props.errorUser !== nextProps.errorUser && nextProps.errorUser){
			this.setState({ profile: null }, () => {
				this.props.history.push(routes.ERROR_USER)
			})
		}
	}

	render () {
		const { projects, experiences, profile, addWorkNumber, roles, readOnly } = this.state;

		const projectsTpl = projects.length > 0 ?
			<Projects
				readOnly={readOnly}
				onDelete={() => console.error('Not allowed.')}
				onEdit={() => console.error('Not allowed.')}
				list={projects}
				addWorkNumber={addWorkNumber} /> :
			<div className={[classes['User-emptyBox'], classes['User-emptyBox--projects']].join(' ')}>
				<div className={classes['User-emptyBox-info']}>
					<FormattedMessage id={'user.noProjects'} />
				</div>
				<div className={classes['User-emptyBox--projects-placeholder']}>
					<div/>
					<div/>
					<div/>
				</div>
			</div>

		const experiencesTpl = experiences.length > 0 ?
			<Curriculum
				readOnly={readOnly}
				loading={this.props.loadingExperiences}
				closeData={() => console.error('Not allowed.')}
				removeData={() => console.error('Not allowed.')}
				add={() => console.error('Not allowed.')}
				setData={() => console.error('Not allowed.')}
				list={experiences}
				title={<FormattedMessage id={'curriculum.title'} />}/> :
			<div className={[classes['User-emptyBox'], classes['User-emptyBox--experiences']].join(' ')}>
				<div className={classes['User-emptyBox-info']}>
					<FormattedMessage id={'user.noExperiences'} />
				</div>
				<div className={classes['User-emptyBox--experiences-placeholder']}>
					<div/>
				</div>
			</div>

		return (
			<div className={classes.User}>
				{
					<Template
						sidebar={<UserSidebar user={profile} roles={roles} />}
						sidebarSize="small">
						{
							this.props.loadingProjects  ?
								<div className={classes["User-loading"]}>
									<Loader />
								</div>
								:
								<Fragment>
									<div className={[classes['User-row'], classes['User-row--block'], classes['User-row--extra']].join(' ')}>
										{
											projectsTpl
										}
									</div>
									<div className={[classes['User-row'], classes['User-row--column']].join(' ')}>
										{
											experiencesTpl
										}
									</div>
								</Fragment>
						}
					</Template>
				}
			</div>
		)
    }
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.userState.selected,
		errorUser: state.userState.error,
		loading: state.curriculumState.isStoring,
		error: state.curriculumState.error,
		experiences: state.curriculumState.list,
		loadingExperiences: state.curriculumState.isFetching,
		projects: state.projectState.list,
		loadingProjects: state.projectState.isFetching,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
		onGetUserById: (userId: number) => dispatch(actions.fetchUserById(userId)),
		onGetExperiencesList: ( userId: number ) => dispatch( actions.fetchCurriculumByUser(userId) ),
		onGetProjectsByUserId: (userId: number, online: boolean) => dispatch(actions.fetchProjectsByUser(userId, online)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( User );
