import * as actionTypes from './actionTypes';
import SkillsService from '../../services/api/skills';
import { IError, IResponse, ISkill } from '../../shared/interfaces';

// READ/FETCH
export const fetchSkillStart = () => {
    return {
        type: actionTypes.FETCH_SKILL_START,
    }
};

export const fetchSkillSuccess = (skill: ISkill) => {
    return {
        type: actionTypes.FETCH_SKILL_SUCCESS,
        skill
    }
};

export const fetchSkillFail = (error: string) => {
    return {
        type: actionTypes.FETCH_SKILL_FAIL,
        error,
    }
};

export const fetchSkill = (id: number) => {
	return (dispatch: any) => {

		if(id)
			dispatch(fetchSkillStart());

		SkillsService
			.get(id)
			.then(
				(response: IResponse) => {
					dispatch(fetchSkillSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchSkillFail(errorMsg));
				}
			);
	}
};

export const fetchSkillsStart = () => {
	return {
		type: actionTypes.FETCH_SKILLS_START,
	}
};

export const fetchSkillsSuccess = (skills: any) => {
	return {
		type: actionTypes.FETCH_SKILLS_SUCCESS,
		skills
	}
};

export const fetchSkillsFail = (error: string) => {
	return {
		type: actionTypes.FETCH_SKILLS_FAIL,
		error,
	}
};

export const fetchSkills = () => {
	return (dispatch: any) => {
		dispatch(fetchSkillsStart());

		SkillsService
			.get()
            .then(
	            (response: IResponse) => {
		            dispatch(fetchSkillsSuccess(response.data));
	            },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(fetchSkillsFail(errorMsg));
	            }
            );
    }
};
