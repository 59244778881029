import React, { Component } from 'react';
import classes from './LoggedUserNavigator.module.scss';
import { NavLink } from "react-router-dom";
import CircleImage from '../CircleImage/CircleImage';
import LoggedUserMenu from './LoggedUserMenu/LoggedUserMenu';
import { connect } from 'react-redux';
import constants from '../../shared/constants';
import { IUser } from '../../shared/interfaces';

interface IProps {
	history?: any;
	currentUser: IUser;
}

class LoggedUserNavigator extends Component<IProps> {

	state = {
		name: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_NAME),
		image: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE),
	};

	componentDidMount(): void {
		const { currentUser } = this.props;

		if(currentUser)
			this.setUserInfo(currentUser);
	}

	setUserInfo(user: any){
		this.setState({
			name: user.name,
			image: user.fileCodeImage && user.fileCodeImage.url
		})
	}

	render () {

		const { history } = this.props;

		const { name, image } = this.state;

		return (
			name ?
			<div className={[classes['LoggedUserNavigator'], classes['LoggedUserNavigator--small']].join(' ')}>
				<div className={classes['LoggedUserNavigator-thumbnail']}>
					<NavLink to="/my-originals">
						{
							image && image != 'undefined' ?
							<CircleImage url={image} username={name} small={true}/>
							:
							<CircleImage username={name} small={true}/>
						}
					</NavLink>
				</div>
				<h2 className={[classes['LoggedUserNavigator-name'], classes['LoggedUserNavigator-name--small']].join(' ')}>
					<NavLink to="/my-originals">
						{ name }
					</NavLink>
				</h2>
				<LoggedUserMenu history={history} />
			</div> : null
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
	};
};

export default connect( mapStateToProps )( LoggedUserNavigator );
