import React from 'react';
import classes from './BoxLink.module.scss';
import { NavLink } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import {FormattedMessage} from "react-intl";

interface boxLinkProps {
	to: LocationDescriptor<any>;
}

const boxLink = ({ to }: boxLinkProps) => {

    return (
    	<NavLink className={classes.BoxLink} to={to}>
			<div className={classes["BoxLink-content"]}>
				<FormattedMessage id={'general.addProject'} />
				<div className={classes["BoxLink-plus"]} />
			</div>
	    </NavLink>
    );
};

export default boxLink;
