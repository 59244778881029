import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import classes from './UserSidebar.module.scss';
import UserCard from '../UserCard/UserCard';
import autosize from 'autosize';

interface IState {
	showEditModal: boolean;
	biography: any;
	file: null;
	city: string;
	fileCodeImage: any;
	noImage: boolean;
}
interface IProps {
	user: any;
	roles: any;
	loading?: boolean;
	readOnly?: boolean;
}

class UserSidebar extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			showEditModal: false,
			biography: this.props.user ? this.props.user.biography : '',
			file: null,
			city: '',
			fileCodeImage: null,
			noImage: false,
		};

		this.onToggleShowEditModal = this.onToggleShowEditModal.bind(this);
		this.setUserInfo = this.setUserInfo.bind(this);
	}

	componentDidMount() {
		autosize(document.querySelectorAll('textarea'));
	}

	setUserInfo(user: any) {
		const { city, fileCodeImage, biography } = user;

		this.setState({
			fileCodeImage,
			city,
			biography
		})
	}

	onToggleShowEditModal(){
		const showEditModal = !this.state.showEditModal;

		this.setState({
			showEditModal
		})
	}

	componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
		if(this.props.user !== nextProps.user && nextProps.user){
			this.setUserInfo(nextProps.user);
		}
	}

	render () {
		const { user, roles } = this.props;

		const rolesTpl = roles.map((role: any, index: number) => {
			return (
				<div key={index} className={classes['UserSidebar-role']}>
					{ role }
				</div>
			)
		});

		return (
			<div className={classes.UserSidebar}>
				<div className={classes['UserSidebar-header']}>
					{
						user && <UserCard user={user} />
					}
				</div>
				{

					user && user.biography &&
	                    <div className={classes['UserSidebar-box']}>

	                        <label><FormattedMessage id={'user.biography'}/></label>
							<div dangerouslySetInnerHTML={{ __html: user.biography}} />
	                    </div>
				}
				{
					roles.length > 0 &&
                    <div className={classes['UserSidebar-box']}>
                        <label><FormattedMessage id={'user.roles'}/></label>
                        { rolesTpl }
                    </div>
				}
			</div>
		)
	}
}

export default UserSidebar;
