import React, { Component } from 'react';
import classes from './UserCard.module.scss';
import CircleImage from '../CircleImage/CircleImage';
import ReactTooltip from 'react-tooltip';

interface IProps {
	small?: boolean;
	history?: any;
	user: any;
}

class UserCard extends Component<IProps> {
	render () {
		const { user: { name, surname, city, fileCodeImage, ambassador }, small } = this.props;

		return (
			name ?
			<div className={[classes['UserCard'], small ? classes['UserCard--small'] : ''].join(' ')}>
				<div className={classes['UserCard-thumbnail']}>
					<CircleImage
						url={fileCodeImage ? fileCodeImage.url : null}
						username={name} />
				</div>
				<h2 className={[classes['UserCard-name'], small ? classes['UserCard-name--small'] : '', ambassador ? classes['UserCard-name--ambassador'] : ''].join(' ')}>
					<span>{`${name} ${surname}`}</span>
					{ ambassador == 1 &&
						<div
						className={classes['UserCard-ambassador']}
						data-tip="Recruiter">
							<ReactTooltip effect="solid"/>
						</div>
					}
				</h2>
				{
					city &&
					<div className={classes['UserCard-location']}>
						{ city }
					</div>
				}
			</div> : null
		)
	}
}

export default UserCard;
