import React, {Component, Fragment} from 'react';
import constants from '../../shared/constants';
import * as actions from '../../store/actions';
import * as ReactGA from '../../GA';
import { connect } from 'react-redux';
import classes from './OriginalDetail.module.scss';
import Template from '../../containers/Template/Template';
import Loader from '../Loader/Loader';
import { IAttachment, IOriginal, IOriginalCover, IUser } from '../../shared/interfaces';
import * as routes from '../../shared/routes';
import CircleImage from '../CircleImage/CircleImage';
import { FormattedMessage } from 'react-intl';
import TagsList from '../TagsList/TagsList';
import OriginalStages from '../OriginalStages/OriginalStages';
import DocumentCard from '../DocumentCard/DocumentCard';
import ReactPlayer from 'react-player';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import UnauthorizedRoute from '../UnauthorizedRoute/UnauthorizedRoute';
import PatamuBox from '../PatamuBox/PatamuBox';

interface IProps {
	onGetOriginal(id: number): IOriginal;
	original: IOriginal;
	history: any;
	match: any;
	loading: boolean;
	onGetCurrentUser(): IUser;
	currentUser: IUser;
	error: any;
	currentCoverUrls: IOriginalCover;
	patamu: string | null | number;
}

interface IState {
	unauthorized: boolean;
	showPatamuBox: boolean;
	showPatamuSticker: boolean;
}

class OriginalDetail extends Component<IProps, IState> {

	state = {
		unauthorized: false,
		showPatamuBox: false,
		showPatamuSticker: false,
	};

	componentDidMount(): void {
		window.scrollTo(0, 0);

		const { match: { params: { id } } } = this.props;

		if(id) {
			ReactGA.pageview('/originals/' + id);
			this.props.onGetOriginal(id);

			this.getCurrentUser();
		} else {
			this.props.history.push(routes.ORIGINALS);
		}
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.error !== prevProps.error && this.props.error){
			const { data: { code, errors } } = this.props.error;

			if(code === 404){
				this.props.history.push(routes.PROFILE)
			}

			if(errors && errors[0].message.toLowerCase().includes('access to this resource is not allowed')){
				this.setState({
					unauthorized: true,
				})
			}
		}

		if(this.props.patamu !== prevProps.patamu) {
			this.setState({
				showPatamuBox: this.props.patamu === null && this.props.patamu !== 1 && this.props.patamu !== "1",
				showPatamuSticker: this.props.patamu !== null && typeof this.props.patamu === 'string' && this.props.patamu !== "1",
			})
		}

		if(this.props.original !== prevProps.original && this.props.original){
			this.setState({
				showPatamuBox: this.props.original.bell.length > 0 && this.props.original.patamu === null && this.props.original.patamu !== 1 && this.props.original.patamu !== "1",
				showPatamuSticker: this.props.original.patamu !== null && typeof this.props.original.patamu === 'string' && this.props.original.patamu !== "1",
			})
		}
	}


	getCurrentUser(){
		this.props.onGetCurrentUser();
	}

	render () {
		const { loading, original, currentUser, currentCoverUrls } = this.props;
		const { unauthorized, showPatamuBox, showPatamuSticker } = this.state;

		if(!currentUser) {
			return null;
		}

		if(unauthorized){
			return <UnauthorizedRoute />
		}

		let stageValue = 0;
		let mediaContentTpl = null;

		if(original) {
			stageValue = original.stage ? constants.ORIGINAL_STAGES.filter((item: { value: number; key: string }) => item.key.toLowerCase() === original.stage.toLowerCase())[0].value : 0;
			mediaContentTpl = original.media.sort((a, b) => (a.sort > b.sort) ? 1 : -1).map((block: any, index: number) =>{
				let content = null;
				switch (block.type) {
					case 'image': content = (
						<div className={[classes['OriginalDetail-block'], classes[`OriginalDetail-block--${block.type}`]].join(' ')}>
							<img src={block.file.url} alt={'image'} />
						</div>
					);
						break;
					case 'embed': content = (
						<div className={[classes['OriginalDetail-block'], classes[`OriginalDetail-block--${block.type}`]].join(' ')}>
							<div dangerouslySetInnerHTML={{__html: block.content}} />
						</div>
					);
						break;
					case 'text': content = (
						<div className={[classes['OriginalDetail-block'], classes[`OriginalDetail-block--${block.type}`]].join(' ')}>
							<div dangerouslySetInnerHTML={{__html: block.content}} />
						</div>
					);
						break;
					case 'video': content = (
						<div className={[classes['OriginalDetail-block'], classes[`OriginalDetail-block--${block.type}`]].join(' ')}>
							<ReactPlayer url={block.content} playsinline={true} width="100%" />
						</div>
					);
						break;
					default: content = null;
				}

				return (
					<Fragment key={index}>
						{ content }
					</Fragment>
				);
			});
		}

		let dateToShow;
		let dateToShowEdit;

		if(original) {
			dateToShow = moment(original.createdAt).format('MMMM D, YYYY');

			switch (dateToShow) {
				case moment().format('MMMM D, YYYY'): dateToShow = 'Today';
					break;
				case moment().add(-1, 'days').format('MMMM D, YYYY'): dateToShow = 'Yesterday';
					break;
				default: dateToShow = moment(original.createdAt).format('MMMM D, YYYY');
			}

			dateToShowEdit = moment(original.updatedAt).format('MMMM D, YYYY');

			switch (dateToShowEdit) {
				case moment().format('MMMM D, YYYY'): dateToShowEdit = 'today';
					break;
				case moment().add(-1, 'days').format('MMMM D, YYYY'): dateToShowEdit = 'yesterday';
					break;
				default: dateToShowEdit = moment(original.updatedAt).format('MMMM D, YYYY');
			}
		}



		return (
			<Template theme={'dark'} noPadding>
				{
					loading || !original ?
						<Loader /> :
						<Fragment>
							<div className={classes.OriginalDetail}>
								<div
									style={{
										backgroundImage: currentCoverUrls ? `url(${currentCoverUrls.large})` : 'none'
									}}
									className={[classes['OriginalDetail-header'], original.status === 'DRAFT' ? classes['OriginaDetail-header--draft'] : ''].join(' ')}>

									{ original.status === 'DRAFT' &&
										<div className={classes['OriginalDetail-draft']}>DRAFT</div>
									}

									<div className={classes['OriginalDetail-title']}>
										{/* <label className={[classes['OriginalDetail-label'], classes['OriginalDetail-label--light']].join(' ')}><FormattedMessage id={'label.originalItem.title'}/></label> */}
										<h1>
											{ original.title }
										</h1>
										{
											original.logline &&
											<div className={classes['OriginalDetail-logline']} dangerouslySetInnerHTML={{__html: original.logline}} />
										}
										{
											dateToShow &&
											<>
											<div className={classes['OriginalDetail-date']}>
												{ dateToShow }
												{
													dateToShowEdit && dateToShow.toLowerCase() != dateToShowEdit.toLowerCase() ?
													<>
														{' - '}
														<em>
															<FormattedMessage id="label.edited" />
															{' '}
															{ dateToShowEdit }
														</em>
													</>
													:
													''
												}
											</div>
											</>
										}

										{
											original.id &&
											original.userId === currentUser.id &&
											!showPatamuSticker &&
												<NavLink
													className={classes['OriginalDetail-edit']}
													to={routes.EDIT_ORIGINAL.replace(':id', original.id.toString()).replace(':step', '1')}
													>
													<FormattedMessage id={'general.edit'} />
												</NavLink>
										}
									</div>
								</div>
								<div className={classes['OriginalDetail-subHeader']}>
									<div className={classes['OriginalDetail-creator']}>
										{
											original.user &&
											<div className={classes['OriginalDetail-author']}>
												<NavLink to={`/user/${original.user.id}`}>
													<CircleImage
														large
														vertical
														url={original.user.file ? original.user.file.url : ''}
														username={original.user.name}
														fullName={original.user.name + ' ' + original.user.surname} />
												</NavLink>
											</div>
										}
									</div>

									<div className={classes['OriginalDetail-tags']}>
									{
										original.format.length > 0 &&
                                        <div className={[classes['OriginaDetail-tag-group'], classes['OriginaDetail-tag-group--format']].join(' ')}>
                                            <label className={[classes['OriginalDetail-label'], classes['OriginalDetail-label--light']].join(' ')}><FormattedMessage id={'label.originalFormats'}/></label>
                                            <TagsList
                                                theme={'dark'}
                                                items={original.format}/>
                                        </div>
									}
									{/* DISRIBUTION HIDDEN */}
									{/* {
										original.distribution.length > 0 &&
                                        <div className={[classes['OriginaDetail-tag-group'], classes['OriginaDetail-tag-group--distribution']].join(' ')}>
                                            <label className={[classes['OriginalDetail-label'], classes['OriginalDetail-label--light']].join(' ')}><FormattedMessage id={'label.originalDistributions'}/></label>
                                            <TagsList
                                                theme={'dark'}
                                                items={original.distribution}/>
                                        </div>
									} */}
									{
										original.mood.length > 0 &&
                                        <div className={[classes['OriginaDetail-tag-group'], classes['OriginaDetail-tag-group--mood']].join(' ')}>
                                            <label className={[classes['OriginalDetail-label'], classes['OriginalDetail-label--light']].join(' ')}><FormattedMessage id={'label.originalMoods'}/></label>
                                            <TagsList
                                                theme={'dark'}
                                                items={original.mood}/>
                                        </div>
									}
									{
										original.bell.length > 0 &&
                                        <div className={[classes['OriginaDetail-tag-group'], classes['OriginaDetail-tag-group--bell']].join(' ')}>
                                            <label className={[classes['OriginalDetail-label'], classes['OriginalDetail-label--light']].join(' ')}><FormattedMessage id="label.originalBells.detail"/></label>
                                            <TagsList
                                                theme={'dark'}
                                                items={original.bell}/>
                                        </div>
									}
									</div>
									{
										showPatamuSticker &&
											<div className={classes['OriginalDetail-patamu-sticker']}>
												<span>Protected by Patamu</span>
											</div>
									}
								</div>
								{
									showPatamuBox && currentUser.id === original.userId && original.status === 'ONLINE' &&
										<PatamuBox />
								}
								<div className={classes['OriginalDetail-content']}>
									<div className={classes['OriginalDetail-col-small']}>
										<div className={classes['OrginalDetail-sticky']}>
											{ original.collaborators && original.collaborators.length > 0 &&
												<div className={classes['OriginalDetail-collaborators']}>
													<label className={classes['OriginalDetail-label']}><FormattedMessage id={'label.writtenWith'}/></label>
													<ul>
														{
															original.collaborators.map((collaborator: { name: string, surname: string, id: number }, index: number) => {
																return (
																	<li key={index}>
																		<NavLink to={`/user/${collaborator.id}`}>
																			{ collaborator.name } { collaborator.surname }
																		</NavLink>
																	</li>
																)
															})
														}
													</ul>
												</div>
											}
											<div className={classes['OriginalDetail-box']}>
												<label className={classes['OriginalDetail-label']}><FormattedMessage id={'label.originalStage'}/></label>
												<OriginalStages
													styles={{maxWidth: '250px', margin: '0 0 10px 0'}}
													size={'small'}
													value={stageValue} />
												<span>{ original.stage }</span>
											</div>
											{
												original.lookingFor &&
												<div className={classes['OriginalDetail-box']}>
													<label className={classes['OriginalDetail-label']}><FormattedMessage id={'label.originalItem.myRole'}/></label>
													<span>{original.lookingFor}</span>
												</div>
											}
										</div>
									</div>
									<div className={classes['OriginalDetail-col-large']}>
										{
											original.description &&
                                            <div className={classes['OriginalDetail-box']}>
                                                <label className={classes['OriginalDetail-label']}><FormattedMessage id={'label.synopsis'}/></label>
                                                <div dangerouslySetInnerHTML={{__html: original.description}} />
                                            </div>
										}
										{
											original.inspiration &&
                                            <div className={classes['OriginalDetail-box']}>
                                                <label className={classes['OriginalDetail-label']}><FormattedMessage id={'label.inspiration'}/></label>
                                                <div dangerouslySetInnerHTML={{__html: original.inspiration}} />
                                            </div>
										}
										{
											original.media.length > 0 &&
											<div className={classes['OriginalDetail-box']}>
												<label className={classes['OriginalDetail-label']}><FormattedMessage id={'label.somethingSpecial'}/></label>
												{
													mediaContentTpl
												}
											</div>
										}
									</div>
									<div className={[classes['OriginalDetail-attachments'], classes['OriginalDetail-col-smaller']].join(' ')}>
										{
											original.attachment.length > 0 &&
											<label className={classes['OriginalDetail-label']}><FormattedMessage id={'label.attachments'}/></label>
										}
										{
											original.attachment.sort((a, b) => (a.sort > b.sort) ? 1 : -1).map((attachment: IAttachment, index: number) => {
												return (
													<DocumentCard
														hideToggle={true}
														disableReordering={true}
														hidePreview
														key={index}
														document={{
															id: attachment.id || -1,
															title: attachment.title,
															description: attachment.content,
															url: attachment.file && attachment.file.url,
															type: attachment.file && attachment.file.mimeType
														}} />
												)
											})
										}
									</div>
								</div>
							</div>
						</Fragment>
				}
			</Template>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		original: state.originalState.original,
		patamu: state.originalState.patamu,
		loading: state.originalState.isFetching,
		error: state.originalState.error,
		currentCoverUrls: state.originalState.currentCoverUrls,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetOriginal: (id: number) => dispatch(actions.fetchOriginal(id)),
		onGetCurrentUser: () => dispatch(actions.fetchCurrentUser()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( OriginalDetail );
