import React, { Component, Fragment } from 'react';
import {FormattedMessage} from 'react-intl';
import { NavLink } from 'react-router-dom';
import classes from './Notifications.module.scss';
import { INotification } from '../../shared/interfaces';
import NotificationsBox from '../NotificationsBox/NotificationsBox';
import Loader from '../Loader/Loader';

interface INotificationsProps {
	list: Array<INotification>;
	loading?: boolean;
	isRoute?: boolean;
	history: any;
}

interface INotificationsState {
	news: Array<INotification>;
	earlier: Array<INotification>;
}

class Notifications extends Component<INotificationsProps, INotificationsState> {

	state = {
		news: [],
		earlier: [],
	};

	static getDerivedStateFromProps(nextProps: Readonly<INotificationsProps>, nextState: Readonly<INotificationsState>): any | null {
		let news = nextState.news;
		let earlier = nextState.earlier;

		if(nextProps.list) {
			news = nextProps.list.filter((notification: INotification) => notification.readAt === null);
			earlier = nextProps.list.filter((notification: INotification) => notification.readAt !== null);
		}

		return {
			news,
			earlier
		}
	}

	render () {
		const { news, earlier } = this.state;
		const { isRoute, loading, history } = this.props;

		if(loading)
			return (
				<div className={classes.Notifications}>
					<h3 className={classes['Notifications-title']}><FormattedMessage id={'notifications.title'} /></h3>
					<div className={classes['Notifications-loading']}>
						<Loader />
					</div>
				</div>
			);

		// @todo: Mike please create design for notifications not found

        return (
		        <div
			        className={classes.Notifications}>
			        <h3 className={classes['Notifications-title']}><FormattedMessage id={'notifications.title'} /></h3>
					{ news.length === 0 && earlier.length === 0 ?
						<div className={classes['Notifications-empty']}>
							<span>{'No new notifications found'}</span>
						</div>
					:
						<Fragment>
							<div className={classes['Notifications-scroller']}>
								{
									news.length > 0 &&
									<NotificationsBox history={history} notifications={news} title={<FormattedMessage id={'label.new'} />} />
								}
								{
									earlier.length > 0 &&
									<NotificationsBox history={history} notifications={earlier} title={<FormattedMessage id={'label.earlier'} />} />
								}
							</div>
							{ !isRoute &&
							<NavLink
								className={classes['Notifications-viewAll']}
								to={'/profile/notifications'}>
								<FormattedMessage id={'label.viewAll'} />
							</NavLink>
							}
						</Fragment>
					}
		        </div>
        )
    }
}

export default Notifications;
