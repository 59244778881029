import React, { Component } from 'react';
import classes from './MyOriginalsRequestsBox.module.scss';
import Request from '../Request/Request';
import { FormattedMessage } from 'react-intl';
import { IUserOriginalsRequests } from '../../shared/interfaces';
import { connect } from 'react-redux';

interface IMyOriginalsRequestsBoxProps {
	requests: Array<IUserOriginalsRequests>;
	updatedRequests: Array<number>;
}

interface IMyOriginalsRequestsBoxState {}

const REQUESTS_STATUS_CHECKED = [
	'PENDING',
	'APPROVED',
	'REJECTED',
];

class MyOriginalsRequestsBox extends Component<IMyOriginalsRequestsBoxProps, IMyOriginalsRequestsBoxState> {
	render () {
		const { requests, updatedRequests } = this.props;

    	const originalRequestsTpl = requests.map((item: IUserOriginalsRequests, index: number) => {
    		const currentRequests =
			    item.request &&
			    item.request.length ?
				    item.request.filter((req: { status: string, id: number }) => REQUESTS_STATUS_CHECKED.indexOf(req.status) > -1) :
				    [];

    		return currentRequests.length ? (
				<div className={classes['MyOriginalsRequestsBox-content']} key={index}>
					<h4 className={classes['MyOriginalsRequestsBox-title']}>{ item.title }</h4>
					<div className={classes['MyOriginalsRequestsBox-list']}>
						<div className={classes['MyOriginlasRequests-labels']}>
							<div className={classes['MyOriginlasRequests-label--large']}>
								<label className={classes['MyOriginalsRequestsBox-label']}>
									<FormattedMessage id={'label.requests'}/>
								</label>
							</div>
							<div className={classes['MyOriginlasRequests-label--smaller']}>
								<label className={classes['MyOriginalsRequestsBox-label']}>
									<FormattedMessage id={'label.status'}/>
								</label>
							</div>
							<div className={classes['MyOriginlasRequests-label--small']}>
								<label className={classes['MyOriginalsRequestsBox-label']}>
									<FormattedMessage id={'label.actions'}/>
								</label>
							</div>
						</div>
						{
							currentRequests.map((item: any, ix: number) => <Request key={ix} request={item} />)
						}
					</div>
				</div>
		    ) : null;
	    });

        return (
            <div className={classes.MyOriginalsRequestsBox}>
	            {
	            	originalRequestsTpl
	            }
			</div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		updatedRequests: state.originalState.updatedRequests,
	};
};

export default connect( mapStateToProps )( MyOriginalsRequestsBox );
