import React, {Component} from 'react';
import * as routes from "../../shared/routes";
import * as ReactGA from '../../GA';
import classes from './Work.module.scss';
import { ICategory, IProject, IQuestion, IWorkSteps } from '../../shared/interfaces';
import {FormattedMessage} from "react-intl";
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import Steps from './Steps/Steps';
import Modal from '../../containers/Modal/Modal';
import StepsIndicator from '../StepsIndicator/StepsIndicator';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import constants from '../../shared/constants';
import { resetProjectSession } from '../../shared/helpers';
import { toast } from 'react-toastify';
import UnauthorizedRoute from '../UnauthorizedRoute/UnauthorizedRoute';

const DEBUG_WORK = true;

interface IProps {
	match: {
		params: { step: number, id: number };
	};
	history?: any;
	onCreateProject: (data: any) => IProject;
	onUpdateProject: (id: number, data: any, step: string) => IProject;
	onGetProject: (id: number, edit: boolean) => IProject;
	onGetCategories: () => any;
	project: any;
	created: boolean;
	updated: boolean;
	storing: boolean;
	categories: any;
	error: any;
}

interface IState {
    currentStep: number;
    steps: Array<IWorkSteps>;
	data?: any;
	questions: any;
	workFound: boolean;
	invalidFields: any;
	showValidationPopup: boolean;
	unauthorized: boolean;
}

const VALUES_TO_CHECK = [
	[
		{
			label: 'Category',
			field: 'categoryId',
		}
	],
	[],
	[
		{
			label: 'Your role',
			field: 'projectRole',
		},
		{
			label: 'Project date',
			field: 'projectDate',
		},
		{
			label: 'Project details',
			field: 'questions',
			isArray: true,
			compareLength: true,
		}
	],
	[
		{
			label: 'Project title',
			field: 'title',
		},
	],
];

class Work extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			showValidationPopup: false,
			workFound: false,
			currentStep: 0,
			invalidFields: [],
			data: {
				isDraft: 1,
				title: '',
				description: '',
				skills: [],
				moods: [],
				preview: [],
				categoryId: null,
				projectRole: '',
				client: '',
				projectDate: null,
				distribution: [],
				type: [],
				duration: [],
				sector: [],
				meta: [],
				new: true,
				projectLink: '',
			},
			steps: [
				{
					title: <FormattedMessage id={'addWork.step.1.title'} />,
					subtitle: <FormattedMessage id={'addWork.step.1.description'} />,
				},
				{
					title: <FormattedMessage id={'addWork.step.2.title'} />,
					subtitle: <FormattedMessage id={'addWork.step.2.description'} />,
				},
				{
					title: <FormattedMessage id={'addWork.step.3.title'} />,
					subtitle: <FormattedMessage id={'addWork.step.3.description'} />,
				},
				{
					title: <FormattedMessage id={'addWork.step.4.title'} />,
					subtitle: <FormattedMessage id={'addWork.step.4.description'} />,
				}
			],
			questions: [],
			unauthorized: false,
		};

		this.setData = this.setData.bind(this);
		this.setQuestions = this.setQuestions.bind(this);
		this.onToggleShowValidationPopup = this.onToggleShowValidationPopup.bind(this);
		this.checkInvalidFields = this.checkInvalidFields.bind(this);
		this.publish = this.publish.bind(this);
	}

	componentDidMount() {
		this.getCategories();
		ReactGA.pageview('/add-work');
	}

	getCategories() {
		this.props.onGetCategories();
	}

	getCurrentProject(projectId: number) {
		this.props.onGetProject(projectId, true);
	}

    goBack = () => {
		if(this.state.currentStep === 1){
			this.props.history.push(routes.PROFILE);
		} else {
			const currentStep = this.state.currentStep - 1;

			this.setState({
				currentStep,
			}, () => this.pushRoute(this.state.data.id))
		}
	};

    backToProfile = () => {
		this.props.history.push('/profile');
    };

	checkInvalidFields(all?: boolean){
		const { currentStep } = this.state;

		let fieldsToCheck: any = [];

		if(!all)
			fieldsToCheck = currentStep < 5 ? VALUES_TO_CHECK[currentStep - 1] : [];
		else
			fieldsToCheck = currentStep < 5 && VALUES_TO_CHECK.flat(1);

		//console.log(fieldsToCheck);

		let invalidFields = [...this.state.invalidFields];

		invalidFields = [];

		if(fieldsToCheck.length){
			let valid = true;
			fieldsToCheck.map((value: any) => {

				if(value.hasOwnProperty('isArray') && value.isArray){
					if(this.state.data[value.field] && !this.state.data[value.field].length){
						invalidFields.push(value)
					}

					valid = this.state.data[value.field] && this.state.data[value.field].length && valid;

					if(value.hasOwnProperty('compareLength') && value.compareLength) {
						if(this.state.data[value.field].length !== this.state.questions.length && invalidFields.indexOf(value) < 0){
							valid = false;
							invalidFields.push(value)
						}
					}
				} else {
					if(!this.state.data[value.field]){
						invalidFields.push(value)
					}
					valid = this.state.data[value.field] && valid;
				}
			});

			if(!valid){
				this.setState({
					showValidationPopup: true,
					invalidFields
				})
			}
		}

		return invalidFields.length > 0
	}


    goNextStep = () => {
		if(!this.checkInvalidFields()){

			if(this.state.currentStep < 4){
				this.saveProject();
			} else {
				alert('Available soon!')
			}
		}
    };

    pushRoute(projectId?: string, step?: string){
	    if(projectId){
		    let route = routes.EDIT_WORK.replace(':step?', step ? step : this.state.currentStep.toString());
		    route = route.replace(':id', projectId);

		    this.props.history.push(route.replace(':id', projectId));
	    } else {
		    this.props.history.push(routes.EDIT_WORK.replace(':step?', this.state.currentStep.toString()));
	    }
    }

    setQuestions(questions: any){
    	this.setState({
		    questions,
	    })
    }

    setData(field: string, value: any){
	    let data = {...this.state.data};

	    if(field === 'questions'){
			let checkIndex = -1;
	    	data.questions.forEach((question: IQuestion, index: number) => {
				if(question.id === value.id){
					checkIndex = index;
				}
		    });

	    	if(checkIndex >= 0){
				data.questions.splice(checkIndex, 1);
		    }

	    	let questions = value.answer.length > 0 ? data.questions.concat(value) : data.questions;

		    data = {
			    ...data,
	    		questions,
		    }
	    } else {
	    	if(field === 'categoryId'){
	    		data = {
				    ...data,
				    questions: [],
			    }
		    }

		    data = {
			    ...data,
			    [field]: value,
		    };
	    }

	    this.setState({
		    data
	    }, () => {
	    	if(DEBUG_WORK)
	    	    console.log("New work data:", data)
	    })
    }

    saveDraft = () => {
        //console.log('Saving draft:', this.state.data);
        this.saveProject(1);
	};

    publish(){
    	if(!this.checkInvalidFields(true)){
			this.saveProject();
	    }
    }

    saveProject = (isDraft?: number) => {
    	let data = {...this.state.data};
	    const userId = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID);
	    const currentProjectId = data.hasOwnProperty('id') ? data.id : null;

    	if(userId){
    		if(data.hasOwnProperty('new') && data.new){
    			delete data.new;

    			const { categoryId } = data;

    			const newData = {
    				categoryId,
				    userId: +userId,
				    isDraft: 0
			    };

    			this.props.onCreateProject(newData);
		    } else {

			    const skills = data.skills.map((tag: any) => tag.hasOwnProperty('text') ? tag.text : tag.title);
			    const moods = data.moods.map((tag: any) => tag.hasOwnProperty('text') ? tag.text : tag.title);

    			data = {
    				...data,
				    skills,
				    moods,
				    isDraft: isDraft ? isDraft : 0
			    };

    			this.props.onUpdateProject(currentProjectId, data, constants.PROJECT_STEPS[this.state.currentStep - 1])
		    }
	    }
    };

	onToggleShowValidationPopup(){
		const showValidationPopup = !this.state.showValidationPopup;

		this.setState({
			showValidationPopup
		})
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.created !== prevProps.created && this.props.created){
			//console.log('Project created with id:', nextProps.project.id);
			const currentStep = this.state.currentStep + 1;

			//localStorage.setItem(constants.LOCAL_STORAGE.KEYS.CURRENT_PROJECT_ID, nextProps.project.id);
			localStorage.setItem(constants.LOCAL_STORAGE.KEYS.CURRENT_STEP, currentStep.toString());

			this.setState({
				currentStep,
				data: {
					...this.props.project,
					isDraft: 1,
				},
			}, () => this.pushRoute(this.props.project.id, '2'))
		}

		if(this.props.updated !== prevProps.updated && this.props.updated){
			//console.log('Updated');
			const currentStep = this.state.currentStep + 1;

			if(currentStep === 5){
				if(this.props.project){
					let message = 'Project saved!';
					if(this.props.project.status === 'ONLINE'){
						message = 'Project published!';
					}

					toast.success(message, {
						position: toast.POSITION.TOP_RIGHT,
						className: 'confirm-bubble'
					});
				}

				this.props.history.push(routes.PROFILE);
			} else {
				this.setState({
					currentStep,
					data: {
						...this.props.project,
						isDraft: 1,
					},
				}, () => this.pushRoute(this.props.project.id))
			}
		}

		if(this.props.project !== prevProps.project && this.props.project && !this.state.workFound){
			//console.log('WORK FOUND', nextProps.project);

			const lastSavedStep = this.props.project.lastStep === 1 ? this.props.project.lastStep + 1 : this.props.project.lastStep;

			const selCategory = this.props.categories.filter((category: ICategory) => category.id === this.props.project.categoryId)[0];

			this.setState({
				workFound: true,
				data: {
					...this.props.project,
					isDraft: 1,
				},
				currentStep: lastSavedStep,
				questions: selCategory ? selCategory.questions : [],
			}, () => this.pushRoute(this.props.project.id, lastSavedStep))
		}

		if(this.props.categories !== prevProps.categories && this.props.categories && this.props.categories.length > 0){

			const { match: { params: { id } } } = this.props;

			if(id){
				this.getCurrentProject(id);
			} else {
				this.setState({
					currentStep: 1,
				}, () => {
					this.props.history.push(routes.ADD_WORK.replace(':step', '1'))
				})
			}
		}

		if(this.props.error !== prevProps.error && this.props.error){
			const { data: { code, errors } } = this.props.error;

			if(code === 404){
				this.props.history.push(routes.PROFILE)
			}

			if(errors && errors[0].message.toLowerCase().includes('access to this resource is not allowed')){
				this.setState({
					unauthorized: true,
				})
			}
		}
	}

    componentWillUnmount(): void {
    	resetProjectSession();
    }

	render () {
		const { currentStep, steps, data, questions, showValidationPopup, invalidFields, unauthorized } = this.state;

		if(unauthorized){
			return <UnauthorizedRoute />
		}

		const { categories } = this.props;

		if(currentStep === 0) {
			return (
				<div className={classes['Work-loading']}>
					<Loader />
				</div>
			);
		}

        const footerTpl =
            <div className={classes["Work-footer"]}>
                <div className={classes["Work-footer-left"]}>
					{ currentStep > 1 &&
						<Button
							type="back-small"
							clicked={() => this.goBack()}>
							<FormattedMessage id={'general.back'}/>
						</Button>
					}
					<Button
						type="small"
						styles={{color: '#D05A5A', paddingRight: '0'}}
						clicked={() => this.backToProfile()}>
						<FormattedMessage id={'general.close'}/>
					</Button>
                </div>
				<div className={classes["Work-footer-center"]}>
					<StepsIndicator currentStep={currentStep} steps={steps} />
				</div>
                <div className={classes["Work-footer-right"]}>
					{ currentStep >= 2 &&
						<Button
							type="secondary-small"
							clicked={() => this.saveDraft()}>
							<FormattedMessage id={'general.saveDraft'}/>
						</Button>
					}
					{ data && data.categoryId && currentStep <= 3 &&
						<Button
						type="primary-small"
                        clicked={() => this.goNextStep()}>
							<FormattedMessage id={'general.nextStep'}/>
						</Button>
					}
					{ data && data.categoryId && currentStep > 3 &&
						<Button
						type="confirm"
                        clicked={() => this.publish()}>
							<FormattedMessage id={'general.publishProject'}/>
						</Button>
					}

                </div>
            </div>;

		const invalidFieldsTpl = invalidFields.map((field: any, index: number) => {
			return (
				<div key={index} className={classes['Work-error-message']}>
					{ field.label }
				</div>
			);
		});

        return (
            <div className={classes.Work}>
				<div className={classes['Work-mobile-block']}>
					<FormattedMessage id={'addWork.mobileBlock'}/>
				</div>
	            {
		            showValidationPopup &&
	                    <Modal
	                        title={<FormattedMessage id={'validation.title'}/>}
	                        onModalCloseClicked={this.onToggleShowValidationPopup}>
		                    {
		                    	invalidFieldsTpl
		                    }
	                    </Modal>
	            }
                <div className={classes["Work-header"]}>
	                {
	                	currentStep < 5 &&
                        <div className={classes["Work-header-content"]}>
                            <h2 className={classes["Work-title"]}>
                                <strong><FormattedMessage id={'addWork.title'} /></strong>
				                { steps[currentStep - 1].title }
                            </h2>
                            <p className={classes["Work-subtitle"]}>{ steps[currentStep - 1].subtitle }</p>
                        </div>
	                }
                </div>
	            <Steps
		            categories={categories}
		            questions={questions}
		            setQuestions={this.setQuestions}
		            data={data}
		            setData={this.setData}
		            step={currentStep} />
                { footerTpl }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		project: state.projectState.project,
		created: state.projectState.created,
		updated: state.projectState.updated,
		loading: state.projectState.isFetching,
		storing: state.projectState.isStoring,
		error: state.projectState.error,
		categories: state.categoryState.categories,
		loadingCategories: state.categoryState.isFetching,
		errorCategories: state.categoryState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onCreateProject: (data: any) => dispatch(actions.createProject(data)),
		onUpdateProject: (id: number, data: any, step: string) => dispatch(actions.updateProject(id, data, step)),
		onGetProject: (id: number, edit: boolean) => dispatch(actions.fetchProject(id, edit)),
		onGetCategories: () => dispatch(actions.fetchCategories()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Work );
