import React, {Component} from 'react';
import stepsClasses from '../Steps.module.scss';
import classes from './Step1.module.scss';
import { IAddWorkContentTypes } from "../../../../shared/interfaces";
import ImageSelector from '../../../ImageSelector/ImageSelector';
import BoxSelector from '../../../BoxSelector/BoxSelector';
import Loader from '../../../Loader/Loader';
// import typeVideo from '../../../../assets/img/type-video.jpg';
// import typePhoto from '../../../../assets/img/type-photo.jpg';
// import typeDigital from '../../../../assets/img/type-digital.jpg';
// import typeGraphic from '../../../../assets/img/type-graphic.jpg';
// import typeVr from '../../../../assets/img/type-vr.jpg';
// import typeGamification from '../../../../assets/img/type-gamification.jpg';
// import typeAi from '../../../../assets/img/type-ai.jpg';
// import typeArts from '../../../../assets/img/type-arts.jpg';
// import typeStrategy from '../../../../assets/img/type-strategy.jpg';
// import typeEvent from '../../../../assets/img/type-events.jpg';
import typeFilm from '../../../../assets/img/type-film.jpg';
import typeWriting from '../../../../assets/img/type-writing.jpg';
import typeGraphic from '../../../../assets/img/type-graphic.jpg';
import typeOther from '../../../../assets/img/type-other.jpg';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import * as ReactGA from '../../../../GA';

interface IProps {
	setQuestions: (questions: any) => any;
	setData: (field: string, value: any) => void;
	onGetCategories: () => any;
	categories?: any;
	data?: any;
	loading?: boolean;
}

interface IState {
	contentTypes: Array<IAddWorkContentTypes>;
	currentType: any;
}

class Step1 extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			contentTypes: [],
			currentType: null,
		};
	}

	componentDidMount(): void {
		window.scrollTo(0,0);
		ReactGA.pageview('/add-work/1');
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		//this.props.onGetCategories();
		if(prevProps.data.categoryId !== this.props.data.categoryId && this.props.data.categoryId) {
			console.log('Category ID Found:', this.props.data.categoryId);
			const categories = prevState.contentTypes.map((category: any) => {

				return {
					...category,
					selected: category.id === this.props.data.categoryId
				};
			});

			this.setState({
				contentTypes: categories
			})
		}
	}

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		//console.log('SELECTED', nextProps.data.categoryId);
		let categories = [];
		if(nextProps.categories.length) {
			categories = nextProps.categories.map((category: any) => {
				let img = null;

				switch (category.img) {
					// case 'type-video': img = typeVideo;
					// 	break;
					// case 'type-photo': img = typePhoto;
					// 	break;
					// case 'type-digital': img = typeDigital;
					// 	break;
					// case 'type-graphic-design': img = typeGraphic;
					// 	break;
					// case 'type-vr-ar': img = typeVr;
					// 	break;
					// case 'type-gaming': img = typeGamification;
					// 	break;
					// case 'type-ai': img = typeAi;
					// 	break;
					// case 'type-arts': img = typeArts;
					// 	break;
					// case 'type-strategy': img = typeStrategy;
					// 	break;
					// case 'type-events': img = typeEvent;
					// 	break;
					case 'type-film':
						img = typeFilm;
						break;
					case 'type-writing':
						img = typeWriting;
						break;
					case 'type-graphic':
						img = typeGraphic;
						break;
					case 'type-other':
						img = typeOther;
						break;
					default:
						img = null;
				}
				
				return {
					...category,
					img,
					selected: category.id === nextProps.data.categoryId,
				}
			});
		} else {
			categories = [];
		}

		return {
			contentTypes: categories
		}
	}

	selectType = (ev: React.MouseEvent<HTMLButtonElement>, id: number) => {
		ev.preventDefault();
		let currentTypes = this.state.contentTypes;
		let newCurrentTypes = currentTypes.map((type, index) =>{
			type.selected = index === id;

			if(type.selected){
				this.props.setData('categoryId', type.id);
				this.props.setQuestions(type.questions);
			}
		});

		this.setState({
			currentType: newCurrentTypes
		})
	};

    render () {
        const { contentTypes } = this.state;
        const { loading } = this.props;

        if(loading){
        	return (
				<div className={stepsClasses['Steps-loading']}>
					<Loader />
				</div>
			);
        }

        const typesTpl = contentTypes.map((type, index) => {
            return (
				// hide other and graphic
				type.id == 1 || type.id == 2 ?
                <BoxSelector
                    key={index}
                    index={index}
                    size="fourth"
                    selected={type.selected}
					title={type.title}
					description={type.description}
                    action={this.selectType.bind(this)}
                    img={type.img} />
				:
				''
            );
        });

        return (
	        <div className={classes.Step1}>
		        <div className={classes['Step1-content']}>
			        { typesTpl }
		        </div>
	        </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		//categories: state.categoryState.categories,
		loading: state.categoryState.isFetching,
		error: state.categoryState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetCategories: () => dispatch(actions.fetchCategories()),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Step1 );
