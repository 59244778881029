import React, { Component } from 'react';
import classes from './Modal.module.scss';
import Backdrop from '../../components/Backdrop/Backdrop';

interface IProps {
    title?: object | string;
    children: any;
    onModalCloseClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    forceClose?: boolean;
    large?: boolean;
    checkBackdropClicked?: boolean;
}

class Modal extends Component<IProps> {
    private modal!: HTMLDivElement;

    constructor(props: IProps){
        super(props);
    }

    handleClickedOutsideModal = (e: any) => {
        if (this.modal && !this.modal.contains(e.target) && !this.props.forceClose) {
        	if(this.props.checkBackdropClicked && e.target.className.includes('Backdrop')){
		        this.props.onModalCloseClicked(e);
	        }

        	if(!this.props.checkBackdropClicked) {
		        this.props.onModalCloseClicked(e);
	        }
        }
    };

    componentWillMount(){
        document.addEventListener('click', this.handleClickedOutsideModal, false);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.handleClickedOutsideModal, false);
    }

    renderModalHeader() {
        let modalHeader;
        if (this.props.title) {
            modalHeader = <div className={classes["Modal-header"]}><h4 className={classes["Modal-header-title"]}>{this.props.title}</h4></div>;
        }
        return modalHeader;
    }

    render() {

        const { children, onModalCloseClicked, large } = this.props;

        return (
            <Backdrop show={true}>
                <div className={[classes["Modal"], large ? classes["Modal-large"] : ''].join(' ')} ref={(node: any) => { this.modal = node; }}>
                    <button className={classes["Modal-close"]} onClick={onModalCloseClicked}>
                        <span className={classes["Modal-closeIcon"]}>X</span>
                    </button>
                    {this.renderModalHeader()}
                    <div className={classes["Modal-content"]}>
                        {
                            children
                        }
                    </div>
                </div>
            </Backdrop>
        )
    }
}

export default Modal;
