import request from '../../shared/lib/request'
import constants from "../../shared/constants";

const create = (data: object) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
	    'Authorization': `Bearer ${authToken}`
    };

    return request({
        url:    '/invitations',
        method: 'POST',
        headers,
        data
    });
};

const getByUser = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/invitations/users/${id}`,
		method: 'GET',
		headers
	});
};


const InvitationsService = {
    create, getByUser
};

export default InvitationsService;
