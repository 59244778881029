import * as actionTypes from './actionTypes';
import ProjectsService from '../../services/api/projects';
import { IMedia, IOrder, IProject, IResponse } from '../../shared/interfaces';

// CREATE
export const createProjectStart = () => {
	return {
		type: actionTypes.CREATE_PROJECT_START,
	}
};

export const createProjectSuccess = (project: IProject) => {
	return {
		type: actionTypes.CREATE_PROJECT_SUCCESS,
		project
	}
};

export const createProjectFail = (error: string) => {
	return {
		type: actionTypes.CREATE_PROJECT_FAIL,
		error,
	}
};

export const createProject = (data: IProject) => {
	return (dispatch: any) => {
		dispatch(createProjectStart());

		ProjectsService
			.create(data)
			.then(
				(response: IResponse) => {

					dispatch(createProjectSuccess(response.data));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(createProjectFail(errorMsg));
				}
			);
	}
};

// READ/FETCH
export const fetchProjectStart = () => {
    return {
        type: actionTypes.FETCH_PROJECT_START,
    }
};

export const fetchProjectSuccess = (project: IProject, updatedMedia: Array<IMedia>) => {
    return {
        type: actionTypes.FETCH_PROJECT_SUCCESS,
        project,
	    updatedMedia,
    }
};

export const fetchProjectFail = (error: string) => {
    return {
        type: actionTypes.FETCH_PROJECT_FAIL,
        error,
    }
};

export const fetchProject = (id: number, edit?: boolean) => {
	return (dispatch: any) => {
		dispatch(fetchProjectStart());

		ProjectsService
			.get(id, edit)
            .then(
	            (response: IResponse) => {
		            const updatedMedia = response.data.media;

                    dispatch(fetchProjectSuccess(response.data, updatedMedia));
                },
                (error: any) => {
                    const { data: { info } } = error;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(fetchProjectFail(errorMsg));
                }
            );
    }
};

export const fetchProjectsStart = () => {
	return {
		type: actionTypes.FETCH_PROJECTS_START,
	}
};

export const fetchProjectsSuccess = (list: Array<IProject>) => {
	return {
		type: actionTypes.FETCH_PROJECTS_SUCCESS,
		list
	}
};

export const fetchProjectsFail = (error: string) => {
	return {
		type: actionTypes.FETCH_PROJECTS_FAIL,
		error,
	}
};

export const fetchProjects = (userId: number) => {
	return (dispatch: any) => {
		dispatch(fetchProjectsStart());

		ProjectsService
			.getByUser(userId)
			.then(
				(response: IResponse) => {

					dispatch(fetchProjectsSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchProjectsFail(errorMsg));
				}
			);
	}
};

export const fetchProjectsByUserStart = () => {
	return {
		type: actionTypes.FETCH_PROJECTS_BY_USER_START,
	}
};

export const fetchProjectsByUserSuccess = (list: Array<IProject>) => {
	return {
		type: actionTypes.FETCH_PROJECTS_BY_USER_SUCCESS,
		list
	}
};

export const fetchProjectsByUserFail = (error: string) => {
	return {
		type: actionTypes.FETCH_PROJECTS_BY_USER_FAIL,
		error,
	}
};

export const fetchProjectsByUser = (userId: number, online: boolean = false) => {
	return (dispatch: any) => {
		dispatch(fetchProjectsByUserStart());

		ProjectsService
			.getByUser(userId, online)
			.then(
				(response: IResponse) => {

					dispatch(fetchProjectsByUserSuccess(response.data));
				},
				(error: any) => {
					const { data: { info } } = error;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(fetchProjectsByUserFail(errorMsg));
				}
			);
	}
};

// UPDATE
export const updateProjectStart = () => {
    return {
        type: actionTypes.UPDATE_PROJECT_START,
    }
};

export const updateProjectSuccess = (project: IProject) => {
    return {
        type: actionTypes.UPDATE_PROJECT_SUCCESS,
        project
    }
};

export const updateProjectFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_PROJECT_FAIL,
        error,
    }
};

export const updateProject = (id: number, data: IProject, step?: string) => {
    return (dispatch: any) => {
        dispatch(updateProjectStart());

        ProjectsService
            .update(id, data, step)
            .then(
	            (response: IResponse) => {

                    dispatch(updateProjectSuccess(response.data));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(updateProjectFail(errorMsg));
                }
            );
    }
};

// DELETE
export const deleteProjectStart = () => {
    return {
        type: actionTypes.DELETE_PROJECT_START,
    }
};

export const deleteProjectSuccess = (id: number) => {
    return {
        type: actionTypes.DELETE_PROJECT_SUCCESS,
        id
    }
};

export const deleteProjectFail = (error: string) => {
    return {
        type: actionTypes.DELETE_PROJECT_FAIL,
        error,
    }
};

export const deleteProject = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteProjectStart());

        ProjectsService
            .remove(id)
            .then(
	            (response: IResponse) => {

                    dispatch(deleteProjectSuccess(id));
                },
                (errors: any) => {
                    const { data: { info, error } } = errors;

                    let errorMsg = null;

                    if(info && info.length > 0){
                        errorMsg = info;
                    } else {
                        errorMsg = error ? error : 'Internal server error';
                    }

                    dispatch(deleteProjectFail(errorMsg));
                }
            );
    }
};

// ADD MEDIA
export const addMediaToProjectStart = () => {
	return {
		type: actionTypes.CREATE_PROJECT_MEDIA_START,
	}
};

export const addMediaToProjectSuccess = (updatedMedia: Array<IMedia>) => {
	return {
		type: actionTypes.CREATE_PROJECT_MEDIA_SUCCESS,
		updatedMedia
	}
};

export const addMediaToProjectFail = (error: string) => {
	return {
		type: actionTypes.CREATE_PROJECT_MEDIA_FAIL,
		error,
	}
};

export const addMediaToProject = (id: number, mediaId: string) => {
	return (dispatch: any) => {
		dispatch(addMediaToProjectStart());

		ProjectsService
			.addMedia(id, mediaId)
			.then(
				(response: IResponse) => {
					const updatedMedia = response.data.media;

					dispatch(addMediaToProjectSuccess(updatedMedia));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(addMediaToProjectFail(errorMsg));
				}
			);
	}
};

// REMOVE MEDIA
export const removeMediaFromProjectStart = () => {
	return {
		type: actionTypes.DELETE_PROJECT_MEDIA_START,
	}
};

export const removeMediaFromProjectSuccess = (updatedMedia: Array<IMedia>) => {
	return {
		type: actionTypes.DELETE_PROJECT_MEDIA_SUCCESS,
		updatedMedia
	}
};

export const removeMediaFromProjectFail = (error: string) => {
	return {
		type: actionTypes.DELETE_PROJECT_MEDIA_FAIL,
		error,
	}
};

export const removeMediaFromProject = (id: number, mediaId: string) => {
	return (dispatch: any) => {
		dispatch(removeMediaFromProjectStart());

		ProjectsService
			.removeMedia(id, mediaId)
			.then(
				(response: IResponse) => {

					const updatedMedia = response.data.media.map((media: any) => media);

					dispatch(removeMediaFromProjectSuccess(updatedMedia));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(removeMediaFromProjectFail(errorMsg));
				}
			);
	}
};

// ORDER MEDIA
export const orderMediaForProjectStart = () => {
	return {
		type: actionTypes.CHANGE_MEDIA_ORDER_FOR_PROJECT_START,
	}
};

export const orderMediaForProjectSuccess = (updatedMedia: any) => {
	return {
		type: actionTypes.CHANGE_MEDIA_ORDER_FOR_PROJECT_SUCCESS,
		updatedMedia
	}
};

export const orderMediaForProjectFail = (error: string) => {
	return {
		type: actionTypes.CHANGE_MEDIA_ORDER_FOR_PROJECT_FAIL,
		error,
	}
};

export const orderMediaForProject = (id: number, mediaArray: Array<IOrder>) => {
	return (dispatch: any) => {
		dispatch(orderMediaForProjectStart());

		const data = {
			media: mediaArray
		};

		ProjectsService
			.orderMedia(id, data)
			.then(
				(response: IResponse) => {
					const updateMedia = response.data.media;

					dispatch(orderMediaForProjectSuccess(updateMedia));
				},
				(errors: any) => {
					const { data: { info, error } } = errors;

					let errorMsg = null;

					if(info && info.length > 0){
						errorMsg = info;
					} else {
						errorMsg = error ? error : 'Internal server error';
					}

					dispatch(orderMediaForProjectFail(errorMsg));
				}
			);
	}
};
