import React from 'react';
import classes from './Steps.module.scss';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';

interface stepsProps {
	setQuestions: (questions: any) => any;
	questions: any;
	step: number;
	setData: (field: string, value: any) => void;
	data: any;
	categories: any;
}

const steps = ({ step, setData, data, questions, setQuestions, categories }: stepsProps) => {

	//console.log(questions);

	let contentTpl = null;

	switch (step) {
		case 1: contentTpl = data && (
			<Step1 categories={categories} setQuestions={setQuestions} data={data} setData={setData} />
		);
			break;
		case 2: contentTpl = (
			<Step2 setData={setData} data={data} />
		);
			break;
		case 3: contentTpl = data && (
			<Step3 questions={questions} data={data} setData={setData} />
		);
			break;
		case 4: contentTpl = (
			<Step4 setData={setData} data={data} />
		);
			break;
		default: contentTpl = null;
	}

	return (
		<div className={classes.Steps}>
			<div className={classes['Steps-content']}>
				{
					contentTpl
				}
			</div>
		</div>
	)
};

export default steps;
