import React, {Component} from 'react';
import classes from './ImageSelector.module.scss';
import {FormattedMessage} from "react-intl";

interface IProps {
    title?: string;
    description?: string;
    img: string;
    size?: string;
    selected: boolean;
    index: number;
    action: (ev: React.MouseEvent<HTMLButtonElement>, index: number) => void;
}

class ImageSelector extends Component<IProps> {

    render () {
    	const { title, description, img, size, selected, index, action } = this.props;

			return (
                <button
                    className={[classes["ImageSelector"], size ? classes[`ImageSelector--${size}`] : '', title ? classes["ImageSelector--shadow"] : '', selected ? classes["ImageSelector--selected"] : ''].join(' ')}
                    onClick={(ev) => action(ev, index)}>
                    <div
                        className={classes["ImageSelector-image"]}
                        style={{backgroundImage: `url(${img})`}}>
                        <div className={classes["ImageSelector-infos"]}>
                            {
                                title &&
                                <h3>{ title }</h3>
                            }
                            {
                                description &&
                                    <div className={classes['ImageSelector-description']}>
                                        <p>{ description }</p>
                                    </div>
                                }
                        </div>
                        <img src={img} alt={description} className="hidden" />
                    </div>
				</button>
			)
    }
};

export default ImageSelector;
