import React, {Component, MouseEvent} from 'react';
import classes from './Rail.module.scss';
import "./styles.scss";
import Slider from "react-slick";

interface IProps {
    elements: Array<any>;
    more?: boolean;
}

class Rail extends Component<IProps> {

	state = {
        activeSlide: 0,
        more: false
    }
    
    handleItemClick = () => {
        if(this.props.more) {
            this.setState({
                more: true
            });
        }
    }

    handleCloseMore = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        this.setState({
            more: false
        });
    }

	render () {
        const { activeSlide } = this.state;
        const { elements, more } = this.props;

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                }
            ],
            // beforeChange: (current:number, next:number) => this.setState({ activeSlide: next }),
            afterChange: (current:number) => this.setState({ activeSlide: current })
        }
        
        const railItems = elements.map((element: any, index: number) => {
            return (
                <div
                    key={index}
                    onClick={this.handleItemClick}
                    className={[classes['Rail-item'], elements[index].hidden ? classes['Rail-item--hidden'] : ''].join(' ')}>

                    <div className={classes['Rail-item-left']}>
                        <img src={ elements[index].img } alt={ elements[index].surname } />
                    </div>
                    <div className={classes['Rail-item-right']}>
                        <div className={classes['Rail-item-name']}>
                            { elements[index].name }<br/>
                            <strong>{ elements[index].surname }</strong>
                        </div>
                        <div className={classes['Rail-item-role']}>
                            { elements[index].role }
                        </div>
                        {
                            elements[index].logo && 
                            <div className={classes['Rail-item-logo']}>
                                <img src={elements[index].logo} />
                            </div>
                        }
                    </div>

                </div>
            )
        });
        
        const moreTpl = (
                <div className={[classes['Rail-more'], this.state.more ? classes['Rail-more--active'] : ''].join(' ')}>
                    { this.state.more && 
                        <div className={classes['Rail-more-content']}>
                            <a
                                href="#"
                                onClick={this.handleCloseMore}
                                className={classes['Rail-close']}>
                                    <span>Close</span>
                                </a>
                            <p><strong>{ elements[activeSlide].name + ' ' + elements[activeSlide].surname }</strong> { ' ' } <span dangerouslySetInnerHTML={{__html: elements[activeSlide].desc}}/></p>
                        </div>
                    }
                </div>
        )

		return (
			<div className={[classes.Rail, this.state.more ? 'people-slider--active' : '', 'people-slider'].join(' ')}>
                <Slider {...settings}>
                    { railItems }
                </Slider>
                { more && moreTpl }
			</div>
		)
    }
}

export default Rail;
