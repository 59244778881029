import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';
import { ICurriculum } from '../../shared/interfaces';

interface IAction {
	id: number;
    type: string;
    list: Array<ICurriculum>;
    curriculum: ICurriculum;
    error: string;
}

const INITIAL_STATE = {
    list: [],
    curriculum: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
};

const createCurriculumStart = (state = INITIAL_STATE) => {
	return updateObject(state, { curriculum: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const createCurriculumSuccess = (state = INITIAL_STATE, action: IAction) => {
	const oldList: Array<ICurriculum> = [...state.list];
	const updatedList = oldList.concat(action.curriculum);

	return updateObject(state, { curriculum: action.curriculum, list: updatedList, isStoring: false });
};

const createCurriculumFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchCurriculumStart = (state = INITIAL_STATE) => {
	return updateObject(state, { curriculum: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchCurriculumSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { curriculum: action.curriculum, isFetching: false });
};

const fetchCurriculumFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchCurriculumByUserStart = (state = INITIAL_STATE) => {
	return updateObject(state, { list: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchCurriculumByUserSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { list: action.list, isFetching: false });
};

const fetchCurriculumByUserFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateCurriculumStart = (state = INITIAL_STATE) => {
    return updateObject(state, { curriculum: null, isStoring: true, error: null, didInvalidate: false, isStored: null });
};

const updateCurriculumSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { curriculum: action.curriculum, isStoring: false });
};

const updateCurriculumFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const deleteCurriculumStart = (state = INITIAL_STATE) => {
    return updateObject(state, { isStoring: true, removed: null, error: null, didInvalidate: false });
};

const deleteCurriculumSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { list: state.list.filter((item:ICurriculum) => item.id !== action.id), removed: true, isStoring: false });
};

const deleteCurriculumFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, removed: false, didInvalidate: true, isStoring: false });
};

const curriculumReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
	    // CREATE
	    case actionTypes.CREATE_CURRICULUM_START: return createCurriculumStart(state);
	    case actionTypes.CREATE_CURRICULUM_SUCCESS: return createCurriculumSuccess(state, action);
	    case actionTypes.CREATE_CURRICULUM_FAIL: return createCurriculumFail(state, action);
        // READ/FETCHING
	    case actionTypes.FETCH_CURRICULUM_START: return fetchCurriculumStart(state);
	    case actionTypes.FETCH_CURRICULUM_SUCCESS: return fetchCurriculumSuccess(state, action);
	    case actionTypes.FETCH_CURRICULUM_FAIL: return fetchCurriculumFail(state, action);
	    case actionTypes.FETCH_CURRICULUM_BY_USER_START: return fetchCurriculumByUserStart(state);
	    case actionTypes.FETCH_CURRICULUM_BY_USER_SUCCESS: return fetchCurriculumByUserSuccess(state, action);
	    case actionTypes.FETCH_CURRICULUM_BY_USER_FAIL: return fetchCurriculumByUserFail(state, action);
        // UPDATE
        case actionTypes.UPDATE_CURRICULUM_START: return updateCurriculumStart(state);
        case actionTypes.UPDATE_CURRICULUM_SUCCESS: return updateCurriculumSuccess(state, action);
        case actionTypes.UPDATE_CURRICULUM_FAIL: return updateCurriculumFail(state, action);
        // DELETE
        case actionTypes.DELETE_CURRICULUM_START: return deleteCurriculumStart(state);
        case actionTypes.DELETE_CURRICULUM_SUCCESS: return deleteCurriculumSuccess(state, action);
        case actionTypes.DELETE_CURRICULUM_FAIL: return deleteCurriculumFail(state, action);

        default:
            return state;
    }
};

export default curriculumReducer;
