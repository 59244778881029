import React, {Component} from 'react';
import stepsClasses from '../../Steps.module.scss';
import classes from './ImagePicker.module.scss';
import {FormattedMessage} from "react-intl";
import ImageSelector from '../../../../ImageSelector/ImageSelector';
import Loader from '../../../../Loader/Loader';
import Placeholder from '../../../../Placeholder/Placeholder';
import { IMedia } from '../../../../../shared/interfaces';

interface IProps {
    label: string;
    selected: string;
    images: Array<IMedia>;
    action: (id: number) => void;
    addComponent: any;
    loading: boolean;
	// titleId: string;
}

class ImagePicker extends Component<IProps> {

    render () {
        const { label, images, action, addComponent, loading } = this.props;

        let preview = null;

        console.log(images)

        const imagesTpl = images.filter((media: IMedia) => media.type === 'image').map((image: any, index: number) => {
        	if(image.preview){
        		preview = image.file.url;
	        }

            return (
                <ImageSelector
                    key={index}
                    index={index}
                    size="squared"
                    img={image.file.url}
                    selected={image.preview}
                    action={() => action(image.id)} />
            )
        });

        return (
            <div className={classes.ImagePicker}>
                <div className={classes['ImagePicker-container']}>
                    <div className={classes['ImagePicker-selected']}>
                        <label className={stepsClasses['Steps-label']}>
                            <FormattedMessage id={label} />
                        </label>
                        <div
                            className={classes['ImagePicker-selected-image']}
                            style={{backgroundImage: `url(${preview})`}}>
	                        {
	                        	preview ?
                                    <img src={preview} alt="" />
                                    :
                                    <Placeholder label={'label.projectItem.nocover'} symbol=":(" />
                            }

                            {
                                loading &&
                                <div className={classes['ImagePicker-loading']}>
                                    <Loader />
                                </div>
                            }
                        </div>
                    </div>
                    <div className={classes['ImagePicker-images']}>
                        { imagesTpl }
                        <div className={classes['ImagePicker-upload']}>
		                    {
		                        addComponent
		                    }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default ImagePicker;
