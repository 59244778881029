import React, {Component} from 'react';
import classes from './Recovery.module.scss';
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import * as routes from "../../shared/routes";
import Button from "../Button/Button";
import Input from '../Input/Input';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import constants from '../../shared/constants';
import { resetSession } from '../../shared/helpers';
import * as ReactGA from '../../GA';

interface IProps {
	onUpdatePassword: (data: any) => any;
	user: any;
	match: any;
	history: any;
}

interface IState {
    password: any;
    code: any;
    error: any;
    changed: boolean;
}

class Recovery extends Component<IProps, IState> {

    constructor(props: IProps){
    	super(props);

	    this.state = {
		    code: null,
		    password: {
			    value: '',
			    confirm: '',
		    },
		    changed: false,
		    error: null,
	    };

	    this.updateClicked = this.updateClicked.bind(this);
    }
    componentDidMount(): void {
		ReactGA.pageview('/recovery');
		resetSession();
	    const { match: { params: { code } } } = this.props;
	    this.setState({ code });
	}

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>, field: 'confirm' | 'value') => {
    	const value = ev.currentTarget.value;

    	let password = {...this.state.password};

    	password[field] = value;

    	this.setState({
		    password
	    });
	};

    isPasswordsValid (){
	    const { password } = this.state;

	    let error = null;
	    let valid = true;

	    console.log(password.value, password.confirm)
	    if(password.value !== password.confirm) {
		    // @todo: Mike use here error = <Formatted..... and then set State for error
	    	error = 'Passwords don\'t match';
		    valid = false;
	    } else {
	    	if(password.value.length < 8){
	    		// @todo: Mike use here error = <Formatted..... and then set State for error
	    		error = 'At least 8 characters';
	    		valid = false;
		    }
	    }

	    this.setState({
		    error
	    });

	    return valid;
    }

    updateClicked () {
    	const { code, password } = this.state;

    	if(this.isPasswordsValid()) {
		    this.props.onUpdatePassword({ code, password: password.value });
    	}
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    	if(this.props.user !== nextProps.user && nextProps.user){
    		console.log(nextProps.user);
    		this.setState({
			    changed: true
		    }, () => {
    			setTimeout(() => {
    				this.props.history.push(routes.WELCOME);
			    }, 3000)
		    });
	    }
    }

	render () {
    	const { password, error, changed } = this.state;

		return (
			<div className={classes.Recovery}>
                <div className={classes['Recovery-container']}>
                    <div className={classes['Recovery-header']}>
                        <h4 className={classes['Recovery-header-subtitle']}>
                            <FormattedHTMLMessage id="recovery.claim" />
                        </h4>
                    </div>
                    <div className={classes['Recovery-content']}>
                        <Input
							label={'recovery.label.newPassword'}
							changed={(e: any) => this.handleInputChanged(e,'value')}
							type="password"
							name="new-password"
							value={password.value}
							styles={{marginBottom: '20px'}}
							visible={true} />
                        <Input
							label={'recovery.label.newPasswordRepeat'}
							changed={(e: any) => this.handleInputChanged(e,'confirm')}
							type="password"
							name="new-password-repeat"
							value={password.confirm}
							visible={true} />
                    </div>
	                {
	                	// @todo: Mike style for error here or where you want
	                	error &&
						<div className={classes['Recovery-errors']}>{ error }</div>
	                }
	                {
						changed &&
						<div className={classes['Recovery-confirms']}>
							<FormattedMessage id={'recovery.label.passwordChanged'} />
						</div>
					}
                    <div className={classes['Recovery-footer']}>
                        <Button disabled={password.value.trim() === '' || password.confirm.trim() === ''} clicked={this.updateClicked}>
                            <FormattedMessage id="recovery.save" />
                        </Button>
                    </div>
                </div>
			</div>
		)
    }
}

const mapStateToProps = (state: any) => {
	return {
		user: state.userState.user,
		error: state.userState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onUpdatePassword: (data: any) => dispatch(actions.updateUserPassword(data)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Recovery );
