import React, { Component }  from 'react';
import classes from './RightSidebar.module.scss';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Button from '../Button/Button';
import Modal from '../../containers/Modal/Modal';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

interface rightSidebarProps {
    children?: object,
    isAuthenticated: boolean;
}

class RightSidebar extends Component<rightSidebarProps, {}> {

    state = {
        feedback: false
    }

    showFeedbackModal = () => {
        let feedback = this.state.feedback;

        this.setState({
            feedback: !feedback
        })
    }
    
    sendFeedbackMail = () => {
        window.location.href = 'mailto:hello@artchivio.net';
    }

    render(){
		const { children, isAuthenticated } = this.props;
		const { feedback } = this.state;

		return (
			<div className={classes["RightSidebar"]}>
                { children }
                { isAuthenticated &&
                    <div className={classes['RightSidebar-content']}>
                        <div className={[classes['RightSidebar-sticky'], 'becreative-step'].join(' ')}>
                            <NavLink
                                to="/messages"
                                data-tip
                                data-for="RightSidebar-btn--Messages"
                                className={[classes['RightSidebar-btn'], classes['RightSidebar-btn--Messages']].join(' ')}>
                                <span>Messages</span>
                                <ReactTooltip id="RightSidebar-btn--Messages" effect="solid">
                                    Messages
                                </ReactTooltip>
                            </NavLink>
                            <NavLink
                                to="/my-originals"
                                data-tip
                                data-for="RightSidebar-btn--My"
                                className={[classes['RightSidebar-btn'], classes['RightSidebar-btn--My']].join(' ')}>
                                <span>My Originals</span>
                                <ReactTooltip id="RightSidebar-btn--My" effect="solid">
                                    My Originals
                                </ReactTooltip>
                            </NavLink>
                            <NavLink
                                to="/my-originals/requests"
                                data-tip
                                data-for="RightSidebar-btn--Request"
                                className={[classes['RightSidebar-btn'], classes['RightSidebar-btn--Request']].join(' ')}>
                                <span>Manage Requests</span>
                                <ReactTooltip id="RightSidebar-btn--Request" effect="solid">
                                    Manage Requests
                                </ReactTooltip>
                            </NavLink>
                            <NavLink
                                to="/add-original/1"
                                data-tip
                                data-for="RightSidebar-btn--Add"
                                className={[classes['RightSidebar-btn'], classes['RightSidebar-btn--Add']].join(' ')}>
                                <span>Add Original</span>
                                <ReactTooltip id="RightSidebar-btn--Add" effect="solid">
                                    Add Original
                                </ReactTooltip>
                            </NavLink>
                            <button
                                onClick={this.showFeedbackModal}
                                data-tip
                                data-for="RightSidebar-btn--Feedback"
                                className={[classes['RightSidebar-btn'], classes['RightSidebar-btn--Feedback']].join(' ')}>
                                <span>Send your feedback</span>
                                <ReactTooltip id="RightSidebar-btn--Feedback" effect="solid">
                                    Send your feedback
                                </ReactTooltip>
                            </button>
                        </div>
                    </div>
                }

                {
		            feedback &&
	                    <Modal
                            checkBackdropClicked={true}
	                        title={<FormattedMessage id={'feedback.title'} />}
	                        onModalCloseClicked={this.showFeedbackModal}>
                                <div className={classes['RightSidebar-feedback-content']}>
                                    <p><FormattedHTMLMessage id={'feedback.txt'} /></p>
                                    <p><FormattedHTMLMessage id={'feedback.txt2'} /></p>
                                </div>
                                <Button
                                    clicked={this.sendFeedbackMail}>
                                    <FormattedMessage id={'feedback.btn'} />
                                </Button>
	                    </Modal>
	            }
            </div>
		);
    }
}

export default RightSidebar;
