import React, { Component } from 'react';
import classes from './UsersAutocomplete.module.scss';
import Select from 'react-select';
import { ISelect } from '../../shared/interfaces';
import { FormattedMessage } from 'react-intl';

interface usersAutocompleteProps {
	selected: Array<object>;
	onInputChanged: (key: string) => void;
	onSubmit: (values: Array<any>) => void;
	suggestions: Array<ISelect>;
	loading: boolean;
	isSingleValue?: boolean;
}

interface usersAutocompleteState {}

class UsersAutocomplete extends Component<usersAutocompleteProps, usersAutocompleteState> {
	render (){
		const { suggestions, onInputChanged, onSubmit, selected, loading, isSingleValue } = this.props;

		const isMulti = isSingleValue ? isSingleValue : true;

		return (
			<div className={classes.UsersAutocomplete}>
				<FormattedMessage id={'usersAutocomplete.placeholder'} defaultMessage={'Select...'}>
					{
						(placeholder: any) => (
							<Select
								value={selected}
								openMenuOnClick={false}
								isLoading={loading}
								placeholder={placeholder || 'Select...'}
								onChange={onSubmit}
								isMulti={isMulti}
								onInputChange={(newValue: string) => onInputChanged(newValue)}
								options={suggestions}
							/>
						)
					}
				</FormattedMessage>
			</div>
		)
	}
}

export default UsersAutocomplete;
