import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import { NavLink } from 'react-router-dom';
import classes from './RegistrationWizard.module.scss';
import { IRegistration } from '../../shared/interfaces';
import {FormattedMessage} from "react-intl";
import Loader from '../Loader/Loader';
import ChecksRows from "../ChecksRows/ChecksRows";
import Input from '../../components/Input/Input';
import CheckboxSlider from '../../components/CheckboxSlider/CheckboxSlider';
import Button from "../../components/Button/Button";
import * as Scroll from 'react-scroll';
import RegistrationWizardHeader from './RegistrationWizardHeader/RegistrationWizardHeader';
import RegistrationWizardLandingLeft from "./RegistrationWizardLanding/RegistrationWizardLandingLeft";
import RegistrationWizardLandingRight from "./RegistrationWizardLanding/RegistrationWizardLandingRight";
import * as ReactGA from '../../GA';

import RegistrationImg from '../../assets/img/registration-header.jpg';

const scroll = Scroll.animateScroll;

interface IProps {
	code?: string;
	loading?: boolean;
	error?: string;
	isAuthenticated?: boolean;
	showOffCanvas?: boolean;
	registration: object;
	onRegistration(data: IRegistration): object;
	onToggleOffCanvasVisibilityClicked?: (ev: React.MouseEvent<HTMLButtonElement>, hide?: boolean) => void;
}

interface IState {
	controls: any;
	checkboxes: any;
	code?: string;
	currentStep: number;
	showFeedback: boolean;
	userTypes: any;
}

class RegistrationWizard extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			currentStep: 2,
			showFeedback: false,
			code: '',
			userTypes: [
				{
					value: 'job',
					label: <FormattedMessage id={'label.findProjects'} />,
					selected: true
				},
				{
					value: 'network',
					label: <FormattedMessage id={'label.browse'} />,
					selected: false
				}
			],
			controls: {
				code: {
					label: 'label.code',
					name: 'code',
					value: '',
					placeholderId: 'placeholder.code',
					type: 'text',
					styles: {
						margin: '0 0 40px 0',
					},
					visible: false,
					readOnly: false,
				},
				firstName: {
					name: 'firstName',
					value: '',
					placeholderId: 'placeholder.firstName',
					type: 'text',
					styles: {
						margin: '0 0 10px 0',
					},
					visible: true,
						readOnly: false,
				},
				lastName: {
					name: 'lastName',
					value: '',
					placeholderId: 'placeholder.lastName',
					type: 'text',
					styles: {
						margin: '0 0 10px 0',
					},
					visible: true,
						readOnly: false,
				},
				email: {
					name: 'email',
					value: '',
					placeholderId: 'placeholder.email',
					type: 'email',
					styles: {
						margin: '0 0 10px 0',
					},
					visible: true,
					readOnly: false,
				},
				password: {
					name: 'password',
					value: '',
					placeholderId: 'placeholder.password',
					type: 'password',
					styles: {
						margin: '0 0 10px 0',
					},
					visible: true,
					readOnly: false,
				},
			},
			checkboxes: {
				policy: {
					name: 'policy',
					labelId: 'checkbox.privacy',
					checked: false,
					type: 'toggle',
					styles: {
						margin: '0 0 10px 0',
					},
					link: '/privacy'
				},
				cookie: {
					name: 'cookie',
					labelId: 'checkbox.cookie',
					checked: false,
					type: 'toggle',
					styles: {
						margin: '0 0 10px 0',
					},
					link: '/privacy'
				},
			}
		};
	}

	handleChecksRowsClicked = (value: string): void => {
		let userTypes = [...this.state.userTypes];

		userTypes.map(option => (option.selected = option.value === value));
		this.setState({ userTypes });
	};

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;

		let controls = {...this.state.controls};

		controls[inputName].value = inputValue;

		this.setState({
			controls,
			showFeedback: false,
		});
	};

	handleCheckboxChanged = (ev: React.MouseEvent<HTMLButtonElement>) => {
		const buttonName = ev.currentTarget.getAttribute('data-name');

		if(buttonName){

			let checkboxes = {...this.state.checkboxes};

			checkboxes[buttonName].checked = !checkboxes[buttonName].checked;

			this.setState({
				checkboxes,
				showFeedback: false,
			});
		}
	};

	submitHandler = () => {
		this.setState({
			showFeedback: true,
		});

		const { controls: { email, password, firstName, lastName, code } } = this.state;

		let data: IRegistration = {
			username: email.value,
			email: email.value,
			password: password.value,
			name: firstName.value,
			surname: lastName.value,
			//referralCode: code.value,
			role: 'member'
		};

		if(code.value){
			data = {
				...data,
				referralCode: code.value,
			}
		}

		this.props.onRegistration(data);
	};

	onChangeStep = (step: number, showCode?: boolean) => {
		let controls = { ...this.state.controls };

		controls.code.visible = showCode;
		controls.code.readOnly = false;

		this.setState({
			currentStep: step,
			showFeedback: false,
			controls,
		})
		ReactGA.pageview(`/registration/${step}`);
		scroll.scrollTo(0,0);
	};

	componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
		if(this.props.code !== nextProps.code){
			console.log("Code found:", nextProps.code);

			let controls = {...this.state.controls};

			controls.code.value = nextProps.code;
			controls.code.visible = true;
			//controls.code.readOnly = true;

			this.setState({ code: nextProps.code, controls, currentStep: 2 })
		}

		if(this.props.registration !== nextProps.registration){

			if(nextProps.registration){
				this.setState({
					currentStep: this.state.currentStep + 1,
				})
			}
			scroll.scrollTo(0,0);
		}
	}

	render () {
		const { controls, checkboxes, currentStep, showFeedback, userTypes } = this.state;

		const inputElementsTpl = Object.keys(controls).map((key: string) => {
			return (
				<Input
					label={controls[key].label}
					key={controls[key].name}
					styles={controls[key].styles}
					name={controls[key].name}
					value={controls[key].value}
					changed={this.handleInputChanged.bind(this)}
					placeholderId={controls[key].placeholderId}
					visible={controls[key].visible}
					readOnly={controls[key].readOnly}
					type={controls[key].type}/>
			)
		});

		const checkboxElementsTpl = Object.keys(checkboxes).map((key: string) => {
			return (
				<CheckboxSlider
					key={checkboxes[key].name}
					name={checkboxes[key].name}
					clicked={this.handleCheckboxChanged.bind(this)}
					labelId={checkboxes[key].labelId}
					checked={checkboxes[key].checked}
					link={checkboxes[key].link}
					styles={checkboxes[key].styles}/>
			)
		});

		const stepTplClass = [classes["RegistrationWizard-step"], currentStep === 1 ? classes["RegistrationWizard-step-buttons"] : ''].join(' ');

		let stepTpl = null;
		let registrationHeaderDescriptions = null;
		let footerTpl = null;

		switch (currentStep) {
			case 0:
				stepTpl = (
					<div className={stepTplClass}>
						<ChecksRows
							clicked={this.handleChecksRowsClicked}
							options={userTypes}
							label={<FormattedMessage id={'label.userType'} />}
						/>
					</div>
				);
				registrationHeaderDescriptions = 'registration.form.typology.description';
				footerTpl = (
					<div className={classes["RegistrationWizard-footer"]}>
						<div />
						{
							this.state.userTypes[0].selected === true ?
								<Button
									clicked={() => this.onChangeStep(1, false)}>
									<FormattedMessage id={'general.next'}/>
								</Button>
								:
								<Button
									clicked={() => this.onChangeStep(2, false)}>
									<FormattedMessage id={'general.next'}/>
								</Button>
						}
					</div>
				);
				break;
			case 1:
				stepTpl = (
					<div className={stepTplClass}>
						<Button type={'confirm'} clicked={() => this.onChangeStep(2, true)}>
							<FormattedMessage id={'button.invited'}/>
						</Button>
						<div className={classes["RegistrationWizard-divider"]}>
							<FormattedMessage id={'general.or'}/>
						</div>
						<Button clicked={() => this.onChangeStep(2, false)}>
							<FormattedMessage id={'button.requestAccess'}/>
						</Button>
					</div>
				);
				registrationHeaderDescriptions = 'registration.form.general.description';
				footerTpl = '';
				break;
			case 2:
				stepTpl = (
					<div className={stepTplClass}>
						{
							inputElementsTpl
						}
						{
							checkboxElementsTpl
						}
					</div>
				);

				if(controls.code.visible)
					registrationHeaderDescriptions = 'registration.form.code.description';
				else
					registrationHeaderDescriptions = 'registration.form.requestaccess.description';

				footerTpl = (
					<div className={classes["RegistrationWizard-footer"]}>
						{/* {
							this.state.userTypes[0].selected === true ?
								<Button
									type="small"
									clicked={() => this.onChangeStep(1, false)}>
									<FormattedMessage id={'general.back'}/>
								</Button>
								:
								<Button
									type="small"
									clicked={() => this.onChangeStep(0, false)}>
									<FormattedMessage id={'general.back'}/>
								</Button>
						} */}
						<NavLink to="/" className={classes['RegistrationWizard-back']}>
							<FormattedMessage id={'general.back'}/>
						</NavLink>
						<Button disabled={!checkboxes.policy.checked || !checkboxes.cookie.checked || !controls.firstName.value || !controls.lastName.value || !controls.email.value || !controls.password.value} clicked={this.submitHandler} type="confirm">
							<FormattedMessage id={'navigation.signup'}/>
						</Button>
					</div>
				);
				break;
			case 3:
				stepTpl = (
					<div className={stepTplClass}>
						<h3><FormattedMessage id={'registration.form.email.confirm.title'}/></h3>
						<FormattedMessage id={'registration.form.email.confirm.descripton'}/>
					</div>
				);
				// @todo: Another message
				registrationHeaderDescriptions = '';
				footerTpl = null;
				break;
			default:
				stepTpl = (
					<div className={stepTplClass}>
						{
							inputElementsTpl
						}
						{
							checkboxElementsTpl
						}
					</div>
				);
				registrationHeaderDescriptions = 'registration.form.requestaccess.description';
				footerTpl = (
					<div className={classes["RegistrationWizard-footer"]}>
						{/* {
							this.state.userTypes[0].selected === true ?
								<Button
									type="small"
									clicked={() => this.onChangeStep(1, false)}>
									<FormattedMessage id={'general.back'}/>
								</Button>
								:
								<Button
									type="small"
									clicked={() => this.onChangeStep(0, false)}>
									<FormattedMessage id={'general.back'}/>
								</Button>
						} */}
						<NavLink to="/" className={classes['RegistrationWizard-back']}>
							<FormattedMessage id={'general.back'}/>
						</NavLink>
						<Button disabled={!checkboxes.policy.checked || !checkboxes.cookie.checked || !controls.firstName.value || !controls.lastName.value || !controls.email.value || !controls.password.value} clicked={this.submitHandler} type="confirm">
							<FormattedMessage id={'navigation.signup'}/>
						</Button>
					</div>
				)
		}

		return (
			<div className={classes['RegistrationWizard']}>

				{/* { currentStep === 2 && this.state.controls.code.visible &&
					<RegistrationWizardLandingLeft />
				}
				{ currentStep === 2 && this.state.controls.code.visible &&
					<RegistrationWizardLandingRight />
				} */}


				<div className={classes["RegistrationWizard-container"]}>
					<RegistrationWizardHeader
						title="registration.form.title"
						// description={registrationHeaderDescriptions}
						// img={RegistrationImg}
						/>
					<div className={classes["RegistrationWizard-content"]}>
						{
							stepTpl
						}
						{
							showFeedback &&
								<div className={classes["RegistrationWizard-feedback"]}>
									{
										this.props.loading &&
										<div className={classes["RegistrationWizard-loading"]}>
											<Loader />
										</div>
									}
									{
										this.props.error &&
										<div className={classes['RegistrationWizard-errors']}>
											<span>{ this.props.error }</span>
										</div>
									}
									{/* {
										this.props.isAuthenticated &&
										<span style={{color: 'green'}}>{ 'Created:' + controls.email.value }</span>
									} */}
								</div>
						}
					</div>
					{
						footerTpl
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		registration: state.registrationState.registration,
		loading: state.registrationState.loading,
		error: state.registrationState.error,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onRegistration: ( data: IRegistration ) => dispatch( actions.registration( data ))
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( RegistrationWizard );
