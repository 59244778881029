import axios from 'axios'
import { resetSession } from '../helpers';

const API_BASE = process.env.REACT_APP_API_ENDPOINT && process.env.REACT_APP_API_ENDPOINT.replace('/api', '');

//console.log('ENV', process.env);

/**
 * Create an Axios Clients with defaults
 */
// Default config options
const defaultOptions = {
    baseURL: `${API_BASE}`,
};

// Create instance
const Client = axios.create(defaultOptions);

interface IResponse {
    data: any;
    config: object;
    status: number;
    headers: object;
}

interface IError {
    message: any;
    config: any;
    response: IResponse;
}

interface IOptions {
    method: string;
    url: string;
    headers: object;
    data?: any;
    noApi?: boolean;
}

/**
 * Request Wrapper with default success/error actions
 */
const request = (options: IOptions) => {

    const onSuccess = (response: IResponse) => {
        //console.log('Request Successful!', response);
        return response.data;
    };

    const onError = (error: IError) => {
        console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:',  error.response.status);
            console.error('Data:',    error.response.data);
            console.error('Headers:', error.response.headers);

            if(error.response.status === 401) {
            	resetSession();

            	location.href = '/';
            }

        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return Client(options).then(onSuccess).catch(onError);
};

export default request;
