import React, { Component, Fragment } from 'react';
import classes from './ChecksButtons.module.scss';
import { IOption } from '../../shared/interfaces';
import { randomId } from '../../shared/helpers';
import ReactTooltip from 'react-tooltip';

interface IProps {
	parentId: string;
	label: any;
	name: string;
	options: Array<IOption>;
	changed: (field: string, parentId: string, selected: Array<{ id: string }>) => void;
	handleChecksButtonsClicked?: (field: string, value: string) => void;
	disabled?: boolean;
	selectedIds: Array<{ id: string }>;
	maxSelections?: number;
	required?: boolean;
}

interface IState {
	selectedIds: Array<{ id: string }>
}

class ChecksButtons extends Component<IProps, IState> {
	state = {
		selectedIds: []
	};

	constructor(props: IProps) {
		super(props);

		this.handleChecksButtonsClicked = this.handleChecksButtonsClicked.bind(this);
	}

	componentDidMount(): void {
		this.setState({
			selectedIds: this.props.selectedIds
		})
	}

	handleChecksButtonsClicked = (id: string) => {
		let selectedIds: Array<{ id: string }> = [...this.state.selectedIds];
		const found = selectedIds.find((item: { id: string }) => +item.id === +id);

		if(found){
			selectedIds = selectedIds.filter((item: { id: string }) => +item.id !== +found.id);
		} else {
			if(this.props.maxSelections && selectedIds.length < this.props.maxSelections){
				selectedIds.push({ id })
			}
		}

		this.setState({
			selectedIds
		}, () => {
			this.props.changed(this.props.name, this.props.parentId, this.state.selectedIds);
		})
	};

	render() {
		const { label, options, disabled, required }: IProps = this.props;
		const { selectedIds } = this.state;

		const optionsTpl = options.map((option: IOption) => {
			const { id, title } = option;

			const active = selectedIds.find((item: { id: string }) => +item.id ===  +id) || false;

			const optionClass = [
				classes['ChecksButtons-option'],
				disabled || (selectedIds.length === 3 && !active) ? classes['is-disabled'] : '',
				active ? classes['is-active'] : ''
			].join(' ');

			return (
				<button
					disabled={disabled || (selectedIds.length === 3 && !active)}
					key={`${randomId()}_${id}`}
					onClick={() => this.handleChecksButtonsClicked(id)}
					className={optionClass}>
					{title}
				</button>
			);
		});

		return (
			<div className={classes.ChecksButtons}>
				{label && (
					<label className={classes['ChecksButtons-label']} data-tip data-for={label}>
						{label}
						{
							required &&
							<Fragment>
								<span
									style={{color: '#D05A5A', marginLeft: '4px', fontSize: '1.3rem'}}>
									*
								</span>
								<ReactTooltip id={label} effect="solid">
									Required
								</ReactTooltip>
							</Fragment>
						}
						<span>select up to 3</span>
					</label>
				)}
				<div className={classes['ChecksButtons-content']}>{optionsTpl}</div>
			</div>
		);
	}
}

export default ChecksButtons;
