import React from 'react';
import { Link } from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import ReactTooltip from 'react-tooltip';
import classes from './CheckboxSlider.module.scss';

interface checkboxSliderProps {
    labelId?: string;
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    link?: string;
    clicked?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    styles?: object;
    optional?: boolean;
    autoSize?: boolean;
    tooltip?: string | object;
    simple?: boolean;
}

const checkboxSlider = ({ labelId, checked, disabled, clicked, name, styles, link, optional, tooltip, autoSize, simple }: checkboxSliderProps) => {

    return (
      <div className={[classes.CheckboxSlider, checked ? classes["is-active"] : '', autoSize ? classes['CheckboxSlider--autosize'] : ''].join(' ')}>
        {
          labelId &&
              <label htmlFor={name} data-tip data-for={name} className={[classes["CheckboxSlider-label"], optional ? classes['CheckboxSlider-label--large'] : ''].join(' ')}>
                {
                  optional ?
                  <FormattedMessage id={labelId} />
                  :
                  <>
                    { !simple &&
                      <>
                      <FormattedMessage id={'label.accept'} />{' '}
                      </>
                    }
                    <Link to={link ? link : ''} target={'_blank'}>
                      <FormattedMessage id={labelId} />
                    </Link>
                  </>
                }
                { tooltip &&
                  <>
                    <span className={classes['CheckboxSlider-info']} />
                    <ReactTooltip id={name} effect="solid">{ tooltip }</ReactTooltip>
                  </>
                }
              </label>
        }
        <div className={classes["CheckboxSlider-button-container"]}>
          <button type={'button'} id={name} data-name={name} style={styles} onClick={clicked} className={classes["CheckboxSlider-button"]}>
            <div className={classes["CheckboxSlider-content"]}>
              <div className={classes["CheckboxSlider-round"]} />
            </div>
          </button>
        </div>
      </div>

    )
};

export default checkboxSlider;
