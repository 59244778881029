import React, { Component } from 'react';
import classes from './Step1.module.scss';
import stepClasses from '../Steps.module.scss';
import Input from '../../../Input/Input';
import EditorTextarea from '../../../EditorTextarea/EditorTextarea';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import * as ReactGA from '../../../../GA';
import { FormattedMessage } from 'react-intl';
import { IInput, IOriginalRequest, ISelect, IUser } from '../../../../shared/interfaces';
import UsersAutocomplete from '../../../UsersAutocomplete/UsersAutocomplete';
import CircleImage from '../../../CircleImage/CircleImage';
import constants from '../../../../shared/constants';

interface IProps {
	setData: (field: string, value: any) => void;
	data: any;
	onGetUsersByFilters(type: string, value: string): Array<IUser>;
	users: Array<IUser>;
	loading: boolean;
	editMode: boolean;
	onGetRequestsByOriginal(id: number): Array<IOriginalRequest>;
	requests: Array<IOriginalRequest>;
	forceStatusButtons: (status: boolean) => void;
}

interface IState {
	title: IInput;
	description: string;
	inspiration: string;
	logline: string;
	suggestions: Array<ISelect>;
	collaborators: {
		selected: Array<number>;
		objects: Array<ISelect>;
	};
	currentUser: { name: string; thumbnailUrl?: string | null };
	currentRequests: Array<IOriginalRequest>;
}

class Step1 extends Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			title: {
				name: 'title',
				placeholderId: 'placeholder.originalTitle',
				value: '',
				visible: true,
				readOnly: false,
				type: 'text',
			},
			description: '',
			inspiration: '',
			logline: '',
			collaborators: {
				selected: [],
				objects: [],
			},
			suggestions: [],
			currentUser: {
				name: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_NAME) + ' ' + localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME),
				thumbnailUrl: localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE)
			},
			currentRequests: [],
		};
	}

	componentDidMount(): void {
		window.scrollTo(0,0);

		const { editMode, data } = this.props;

		if(editMode && data.hasOwnProperty('id')) {
			ReactGA.pageview(`/original/${data.id}/1`);
			this.props.onGetRequestsByOriginal(data.id);
		}
		else {
			ReactGA.pageview('/add-original/1');
		}
	}

	handleInputChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		const inputName = ev.currentTarget.name;
		const inputValue = ev.currentTarget.value;

		let title = {...this.state.title};

		title.value = inputValue;

		this.setState({
			title,
		}, () => {
			this.props.setData(inputName, inputValue);
		});
	};

	handleChangeLogline = ( value: any) => {
		this.setState({
			logline: value,
		}, () => {
			this.props.setData('logline', value);
		});
	};

	handleChangeDescription = ( value: any) => {
		this.setState({
			description: value,
		}, () => {
			this.props.setData('description', value);
		});
	};

	handleChangeInspiration = ( value: any) => {
		this.setState({
			inspiration: value,
		}, () => {
			this.props.setData('inspiration', value);
		});
	};

	handleChangeUsers = (values: Array<ISelect>) => {
		let collaborators = {
			selected: values.length ? values.map((user: ISelect) => +user.value) : [],
			objects: values.length ? values.map((user: ISelect) => { return { value: +user.value, label: user.label } }) : [],
		};

		console.log(values);

		this.setState({
			collaborators,
			suggestions: []
		}, () => {
			//this.props.setData('collaborators', collaborators.selected);
			this.props.setData('collaboratorsInDraft', collaborators.objects);
		});
	};

	onSearchUser = (key: string) => {
		if(key.length > 2){
			this.props.onGetUsersByFilters('name', key);
		}
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.users !== prevProps.users && this.props.users){
			console.log("draft:", this.props.data.collaboratorsInDraft);

			let idsToExclude = [Number(localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID))];

			let suggestions = this.props.users.map((user: IUser) => {
				return {
					value: user.id.toString(),
					label: user.name + ' ' + user.surname,
				}
			});

			let draft = this.props.data.collaboratorsInDraft.map((item: ISelect) => item.value);
			let requests = this.state.currentRequests.map((item: IOriginalRequest) => item.userId);

			idsToExclude = idsToExclude.concat(draft, requests);

			if(idsToExclude.length){
				suggestions = suggestions.filter(suggestion => idsToExclude.indexOf(+suggestion.value) < 0)
			}

			this.setState({
				suggestions
			})
		}

		if(this.props.requests !== prevProps.requests){
			this.setState({
				//currentRequests: this.props.requests.filter((req: IOriginalRequest) => req.status === 'APPROVED') || []
				currentRequests: []
			})
		}
	}

	static getDerivedStateFromProps(nextProps: Readonly<IProps>, nextState: Readonly<IState>): any | null {
		let title = nextState.title;
		let description = nextState.description;
		let inspiration = nextState.inspiration;
		let logline = nextState.logline;
		let collaborators = nextState.collaborators;

		if(nextProps.data){
			title = {
				...title,
				value: nextProps.data.title,
			};

			description = nextProps.data.description;
			inspiration = nextProps.data.inspiration;
			logline = nextProps.data.logline;

			// Concat all collaborators, both waiting and accepted
			const users = nextProps.data.collaboratorsInDraft;
			const selected: Array<number> = users.length ? users.map((user: ISelect) => +user.value) : [];
			const objects: Array<ISelect> = users.length ? users.map((user: ISelect) => { return { value: +user.value, label: user.label } }) : [];

			collaborators = {
				selected,
				objects,
			};
		}

		return {
			title,
			description,
			inspiration,
			logline,
			collaborators
		}
	}

    render () {

		const { title, description, inspiration, logline, suggestions, collaborators, currentUser: { name, thumbnailUrl } } = this.state;
		const { loading, forceStatusButtons } = this.props;

        return (
	        <div className={classes.Step1}>
		        <div className={classes['Step1-content']}>
					<div className={[classes['Step1-column'], classes['Step1-column--large']].join(' ')}>

						{/* <div className={[classes['Step1-row'], classes['Step1-row--flex'], classes['Step1-row--noline']].join(' ')}>
							<div className={[classes['Step1-flex-item'], classes['Step1-flex-item--small']].join(' ')}>
								<label className={stepClasses['Steps-label']}>
									<FormattedMessage id={'label.originalCreator'} />
								</label>
								<CircleImage
									small
									url={thumbnailUrl || ''}
									fullName={name} />
							</div>
							<div className={[classes['Step1-flex-item'], classes['Step1-flex-item--large']].join(' ')}>
								<label className={stepClasses['Steps-label']}>
									<FormattedMessage id={'label.originalCollaborations'} />
								</label>
								<UsersAutocomplete
									loading={loading}
									selected={collaborators.objects}
									suggestions={suggestions}
									onSubmit={this.handleChangeUsers}
									onInputChanged={this.onSearchUser}/>
							</div>
						</div> */}

						<div className={[classes['Step1-row'], classes['Step1-row--title'], classes['Step1-row--noline']].join(' ')}>
							<Input
								name={title.name}
								value={title.value}
								changed={this.handleInputChanged.bind(this)}
								placeholderId={title.placeholderId}
								visible={title.visible}
								readOnly={title.readOnly}
								type={title.type}
								label={'label.originalItem.title'}
								required />
						</div>

						<div className={[classes['Step1-row'], classes['Step1-row--noline']].join(' ')}>
							<label className={stepClasses['Steps-label']}>
								<FormattedMessage id={'label.originalLogline'} />
							</label>
							<div className={classes['Step1-row--flex']}>
								<div className={[classes['Step1-flex-item'], classes['Step1-flex-item--large']].join(' ')}>
									<div className={classes['Step1-row--textarea']}>
										<EditorTextarea
											validation={forceStatusButtons}
											maxLength={200}
											isPlainText={true}
											minHeight
											toolbarHidden={true}
											value={logline}
											autosize
											placeholderId={'placeholder.originalLogline'}
											id="logline-textarea"
											onChange={this.handleChangeLogline}/>
									</div>
								</div>
								<div className={classes['Step1-flex-item']}>
									<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
										<div className={stepClasses['Steps-info-box-content']}>
											<FormattedMessage id={'myOriginals.loglineInfo'} />
										</div>
										<div className={stepClasses['Steps-info-box-icon']} />
									</div>
								</div>
							</div>
						</div>

						<div className={[classes['Step1-row'], classes['Step1-row--noline']].join(' ')}>
							<label className={stepClasses['Steps-label']}>
								<FormattedMessage id={'label.originalIdea'} />
							</label>
							<div className={classes['Step1-row--flex']}>
								<div className={[classes['Step1-flex-item'], classes['Step1-flex-item--large']].join(' ')}>
									<div className={classes['Step1-row--textarea']}>
									<EditorTextarea
										minHeight
										toolbarHidden={true}
										value={description}
										placeholderId={'placeholder.originalIdea'}
										onChange={this.handleChangeDescription}/>
									</div>
								</div>
								<div className={classes['Step1-flex-item']}>
									<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
										<div className={stepClasses['Steps-info-box-content']}>
											<FormattedMessage id={'myOriginals.ideaInfo'} />
										</div>
										<div className={stepClasses['Steps-info-box-icon']} />
									</div>
								</div>
							</div>
						</div>

						<div className={[classes['Step1-row'], classes['Step1-row--noline']].join(' ')}>
							<label className={stepClasses['Steps-label']}>
								<FormattedMessage id={'label.originalInspiration'} />
							</label>
							<div className={classes['Step1-row--flex']}>
								<div className={[classes['Step1-flex-item'], classes['Step1-flex-item--large']].join(' ')}>
									<div className={classes['Step1-row--textarea']}>
									<EditorTextarea
										minHeight
										toolbarHidden={true}
										value={inspiration}
										placeholderId={'placeholder.originalInspiration'}
										onChange={this.handleChangeInspiration}/>
									</div>
								</div>
								<div className={classes['Step1-flex-item']}>
									<div className={[stepClasses['Steps-info-box'], stepClasses['Steps-info-box--info']].join(' ')}>
										<div className={stepClasses['Steps-info-box-content']}>
											<FormattedMessage id={'myOriginals.projectInfo'} />
										</div>
										<div className={stepClasses['Steps-info-box-icon']} />
									</div>
								</div>
							</div>
						</div>

					</div>
		        </div>
	        </div>
        )
    }
}

const mapStateToProps = (state: any) => {
	return {
		users: state.userState.users,
		loading: state.userState.isFetching,
		error: state.userState.error,
		requests: state.originalState.requests,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetUsersByFilters: (type: string, value: string) => dispatch(actions.fetchUsersByFilters(type, value)),
		onGetRequestsByOriginal: (id: number) => dispatch(actions.fetchRequestsByOriginal(id)),
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( Step1 );
