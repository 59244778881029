import React, { Component } from 'react';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import classes from './PrivateSidebarNavigation.module.scss';
import { NavLink } from 'react-router-dom';
import { INVITES, MY_ORIGINALS, MY_ORIGINALS_REQUESTS, PROFILE, TEAM } from '../../shared/routes';
import { connect } from 'react-redux';
import { IUser } from '../../shared/interfaces';

interface IProps {
	currentUser: IUser;
	experiences: number;
	projects: number;
	showDashboard: boolean;
}

class PrivateSidebarNavigation extends Component<IProps> {

	render () {
		const { experiences, projects, showDashboard, currentUser } = this.props;

		/*
		console.log("Counter experiences:", experiences);
		console.log("Counter projects:", projects);
		console.log("Dashboard enabled from CurrentUser:", showDashboard);
		*/

		return (
			<ul className={classes.PrivateSidebarNavigation}>
				<li>
					<NavLink
						exact
						activeClassName={classes['is-active']}
						to={MY_ORIGINALS}
						className={[classes['PrivateSidebarNavigation-item'], classes['PrivateSidebarNavigation-item--originals']].join(' ')}>
							<div className="myoriginals-step">
								<FormattedHTMLMessage id={'navigation.myOriginals'} />
							</div>
					</NavLink>
				</li>
				<li>
					<NavLink
						activeClassName={classes['is-active']}
						to={MY_ORIGINALS_REQUESTS}
						className={[classes['PrivateSidebarNavigation-item'], classes['PrivateSidebarNavigation-item--originals-requests']].join(' ')}>
						<FormattedMessage id={'navigation.accessRequests'} />
					</NavLink>
				</li>
				<li>
					<NavLink activeClassName={classes['is-active']} to={PROFILE} className={classes['PrivateSidebarNavigation-item']}>
						<div className="myprofile-step">
							<FormattedMessage id={'navigation.yourProfile'} />
						</div>
					</NavLink>
				</li>
				{/* <li style={{display: 'none'}}>
					<NavLink activeClassName={classes['is-active']} to={TEAM} className={classes['PrivateSidebarNavigation-item']}>
						<FormattedMessage id={'navigation.team'} />
					</NavLink>
				</li> */}
				{
					currentUser && currentUser.ambassador == 1 &&
	                    <li>
	                        <NavLink activeClassName={classes['is-active']} to={INVITES} className={classes['PrivateSidebarNavigation-item']}>
	                            <FormattedMessage id={'navigation.invites'} />
	                        </NavLink>
	                    </li>
				}
			</ul>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		currentUser: state.userState.user,
		showDashboard: state.userState.showDashboard,
		experiences: state.curriculumState.list.length,
		projects: state.projectState.list.filter((project: any) => project.status.toLowerCase() === 'online').length,
	};
};

export default connect( mapStateToProps )( PrivateSidebarNavigation );
