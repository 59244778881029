import request from '../../shared/lib/request'
import constants from "../../shared/constants";

const get = (userId: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/notification/user/${userId}`,
        method: 'GET',
        headers
    });
};

const setRead = (id: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

    return request({
        url:    `/notification/${id}`,
        method: 'PATCH',
        headers,
    });
};

const setAllReadByUser = (userId: number) => {
	const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

	const headers = {
		'Authorization': `Bearer ${authToken}`
	};

	return request({
		url:    `/notification/user/${userId}`,
		method: 'PATCH',
		headers,
	});
};

const NotificationsService = {
    get, setRead, setAllReadByUser
};

export default NotificationsService;
