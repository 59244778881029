import * as actionTypes from './actionTypes';
import BellsService from '../../services/api/bells';
import { IResponse, IBell, IError } from '../../shared/interfaces';

// READ/FETCH
export const fetchBellStart = () => {
    return {
        type: actionTypes.FETCH_BELL_START,
    }
};

export const fetchBellSuccess = (bell: IBell) => {
    return {
        type: actionTypes.FETCH_BELL_SUCCESS,
        bell
    }
};

export const fetchBellFail = (error: string) => {
    return {
        type: actionTypes.FETCH_BELL_FAIL,
        error,
    }
};

export const fetchBell = (id: number) => {
	return (dispatch: any) => {

		if(id)
			dispatch(fetchBellStart());

		BellsService
			.get(id)
			.then(
				(response: IResponse) => {
					dispatch(fetchBellSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchBellFail(errorMsg));
				}
			);
	}
};

export const fetchBellsStart = () => {
	return {
		type: actionTypes.FETCH_BELLS_START,
	}
};

export const fetchBellsSuccess = (bells: any) => {
	return {
		type: actionTypes.FETCH_BELLS_SUCCESS,
		bells
	}
};

export const fetchBellsFail = (error: string) => {
	return {
		type: actionTypes.FETCH_BELLS_FAIL,
		error,
	}
};

export const fetchBells = () => {
	return (dispatch: any) => {
		dispatch(fetchBellsStart());

		BellsService
			.get()
            .then(
	            (response: IResponse) => {
		            dispatch(fetchBellsSuccess(response.data));
	            },
	            (error: IError) => {
		            const { data: { code, errors } } = error;

		            let errorMsg = null;

		            if(errors && errors.length > 0){
			            errorMsg = errors.map((error: any) => error.message).join('; ');
		            } else {
			            errorMsg = 'Internal server error';
		            }

		            dispatch(fetchBellsFail(errorMsg));
	            }
            );
    }
};

export const fetchBellsForOriginalsFilterStart = () => {
	return {
		type: actionTypes.FETCH_BELLS_FOR_ORIGINALS_FILTER_START,
	}
};

export const fetchBellsForOriginalsFilterSuccess = (bells: IBell) => {
	return {
		type: actionTypes.FETCH_BELLS_FOR_ORIGINALS_FILTER_SUCCESS,
		bells
	}
};

export const fetchBellsForOriginalsFilterFail = (error: string) => {
	return {
		type: actionTypes.FETCH_BELLS_FOR_ORIGINALS_FILTER_FAIL,
		error,
	}
};

export const fetchBellsForOriginalsFilter = () => {
	return (dispatch: any) => {
		dispatch(fetchBellsForOriginalsFilterStart());

		BellsService
			.getForOriginalsFilter()
			.then(
				(response: IResponse) => {
					dispatch(fetchBellsForOriginalsFilterSuccess(response.data));
				},
				(error: IError) => {
					const { data: { code, errors } } = error;

					let errorMsg = null;

					if(errors && errors.length > 0){
						errorMsg = errors.map((error: any) => error.message).join('; ');
					} else {
						errorMsg = 'Internal server error';
					}

					dispatch(fetchBellsForOriginalsFilterFail(errorMsg));
				}
			);
	}
};
