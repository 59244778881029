import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';
import classes from './UserBox.module.scss';
import CircleImage from '../CircleImage/CircleImage';
import Button from '../Button/Button';
import { NavLink } from 'react-router-dom';
import { IUser } from '../../shared/interfaces';
import ReactTooltip from 'react-tooltip';

interface IProps {
	history?: any;
	user: IUser;
	clicked?: (userId: number) => void;
	selected?: boolean;
	complete?: boolean;
}

class UserBox extends Component<IProps> {
	render () {
		const { user: { id, name, city, fileCodeImage, surname, ambassador, biography }, clicked, selected, complete } = this.props;

		return (
			name ?
			<NavLink className={classes['UserBox']} to={`/user/${id}`}>
				<div className={classes['UserBox-header']}>
					<CircleImage url={fileCodeImage ? fileCodeImage.url : null} username={name} />
					{
						clicked &&
                            <Button clicked={() => clicked(id)} disabled={selected}>{ selected ? <FormattedMessage id={'button.selected'}/> : <FormattedMessage id={'button.select'}/> }</Button>
					}
				</div>
				<h2 className={[classes['UserBox-name'], ambassador ? classes['UserBox-name--ambassador'] : ''].join(' ')}>
					<span>{ name } {complete ? surname : ''}</span>
					{ ambassador == 1 &&
						<div
						className={classes['UserBox-ambassador']}
						data-tip="Recruiter">
							<ReactTooltip effect="solid"/>
						</div>
					}
				</h2>
				{
					city &&
					<div className={classes['UserBox-location']}>
						{ city }
					</div>
				}
				{
					biography &&
					<div className={[classes['UserBox-biography'], biography.length > 250 ? classes['UserBox-biography--long'] : ''].join(' ')}>
						<div dangerouslySetInnerHTML={{ __html: biography}} />
					</div>
				}
			</NavLink> : null
		)
	}
}

export default UserBox;
