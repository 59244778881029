import React, {Component} from 'react';
import * as Scroll from 'react-scroll';
import * as routes from '../../shared/routes';
import classes from './Registration.module.scss';
import RegistrationWizard from "../RegistrationWizard/RegistrationWizard";
import * as ReactGA from '../../GA';
import background1 from '../../assets/img/registration-bg-1.jpg';
import background2 from '../../assets/img/registration-bg-2.jpg';
import background3 from '../../assets/img/registration-bg-3.jpg';
import background4 from '../../assets/img/registration-bg-4.jpg';
import { checkSession } from '../../shared/helpers';

const scroll = Scroll.animateScroll;

interface IState {
	code?: string;
	background: string;
}

const backgrounds = [
	background1,
	background2,
	background3,
	background4
];

class Registration extends Component<any, IState> {
	state = {
		code: '',
		background: ''
	};

	componentDidMount(): void {
		if(checkSession()){
			this.props.history.push(routes.MY_ORIGINALS)
		}

		const {
			match: {
				params: { code }
			}
		} = this.props;

		if (code) {

			this.setState({ code })
		} else {

			console.log("No registration code provided");
		}
		scroll.scrollTo(0,0);
		ReactGA.pageview('/registration');

		let background = Math.floor((Math.random() * 4) + 1);

		this.setState({
			background: backgrounds[background-1]
		})
	}

	render () {
		const { code, background } = this.state;

		return (
			<div className={classes.Registration} style={{backgroundImage: `url(${background})`}}>
				<RegistrationWizard code={code}/>
			</div>
		)
	}
}

export default Registration;
