import React, {Component} from 'react';
import classes from './CityAutocomplete.module.scss';
import Autocomplete from 'react-google-autocomplete';
import {FormattedMessage} from "react-intl";

interface IProps {
    label?: string;
    name: string;
    onPlaceSelected: (place: any) => any;
    value?: string;
}

class CityAutocomplete extends Component<IProps> {

    render () {
    	const { label, name, onPlaceSelected, value } = this.props;

			return (
				<div className={classes.CityAutocomplete}>
                    { label &&
                        <label className={classes["CityAutocomplete-label"]} htmlFor={name}>
                            <FormattedMessage id={label}/>
                        </label>
                    }
                    <div className={classes['CityAutocomplete-input']}>
					<Autocomplete
						defaultValue={value}
                        name={name}
                        onPlaceSelected={onPlaceSelected}
                        types={['(cities)']}/>
                    </div>
				</div>
			)
    }
};

export default CityAutocomplete;
