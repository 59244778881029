import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utilities';

interface IAction {
	type: string;
    session: any;
    token: string;
    error: string;
}

const INITIAL_STATE = {
    session: null,
	token: null,
    isFetching: false,
    didInvalidate: false,
    error: null,
};

const createSessionStart = (state = INITIAL_STATE) => {
    return updateObject(state, { session: null, error: null, didInvalidate: false, isFetching: true });
};

const createSessionSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { session: action.session, token: action.session.token, isFetching: false });
};

const createSessionFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchMeStart = (state = INITIAL_STATE) => {
	return updateObject(state, { session: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchMeSuccess = (state = INITIAL_STATE, action: IAction) => {

	return updateObject(state, { session: action.session, isFetching: false });
};

const fetchMeFail = (state = INITIAL_STATE, action: IAction) => {
	return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};


const sessionReducer = (state = INITIAL_STATE, action: IAction) => {

    switch(action.type){
        // CREATE
        case actionTypes.SESSION_START: return createSessionStart(state);
        case actionTypes.SESSION_SUCCESS: return createSessionSuccess(state, action);
        case actionTypes.SESSION_FAIL: return createSessionFail(state, action)

	    // FETCH ME
	    case actionTypes.FETCH_ME_START: return fetchMeStart(state);
	    case actionTypes.FETCH_ME_SUCCESS: return fetchMeSuccess(state, action);
	    case actionTypes.FETCH_ME_FAIL: return fetchMeFail(state, action);

        default:
            return state;
    }
};

export default sessionReducer;
