import React from 'react';
import ReactTooltip from 'react-tooltip';
import classes from './StepIndicator.module.scss';
import LockIndicator from '../LockIndicator/LockIndicator';
import {FormattedMessage} from "react-intl";

interface indicatorProps {
	title?: string;
	lock?: boolean;
	active: boolean;
	completed: boolean;
	tip?: string;
	children?: any;
}

const StepIndicator = ({ title, lock, active, completed, children, tip }: indicatorProps) => {

	return (
		<div className={[classes['OriginalStepIndicator'], active ? classes['OriginalStepIndicator--active'] : '', completed ? classes['OriginalStepIndicator--completed'] : '', tip ? classes['OriginalStepIndicator--withtip'] : ''].join(' ')} data-tip data-place="bottom" data-for={tip ? tip : ''}>
			<div className={classes['OriginalStepIndicator-content']}>
				<div className={classes['OriginalStepIndicator-lock']}>
					<LockIndicator lock={lock} small />
				</div>
				<div className={classes['OriginalStepIndicator-infos']}>
					{ title && 
						<h4 className={classes['OriginalStepIndicator-title']}>{title}</h4>
					}
					<span className={classes['OriginalStepIndicator-description']}>
					{ lock ?
						<FormattedMessage id={'myOriginals.privateInfos.title'} />
						:
						<FormattedMessage id={'myOriginals.publicInfos.title'} />
					}
					</span>
				</div>
			</div>
			{ children &&
				<ReactTooltip id={tip ? tip : ''} effect="solid">
					{ children }
				</ReactTooltip>
			}
		</div>
	)
};

export default StepIndicator;
